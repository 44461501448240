import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { insertPixelCode } from './constant';

export const TrackingCodeComponent = ({ categories }) => (
    <Helmet>
        <meta
            property="og:image:alt"
            content={categories && categories.merchant_name}
        />
        <meta
            content={categories && categories.page_hero_image}
            property="og:image:url"
        />

        {/* <!-- Facebook Pixel Code --> */}
        <script>
            {insertPixelCode.script}
        </script>
        <noscript>
            {insertPixelCode.noscript}
        </noscript>
    </Helmet>

);

TrackingCodeComponent.propTypes = {
    categories: PropTypes.shape({
        merchant_name: PropTypes.string.isRequired,
        page_hero_image: PropTypes.string,
    }).isRequired,
};
