import styled, { css } from "styled-components";
import Select from "react-select";

// ////STORE CLOSED ALERT

export const StyledModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    padding: 48px;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    /* transform:${(props) => (props.isOrderTypeModalOpen === true ? "scale(1)" : "scale(0)")}; */
    visibility: ${(props) => (props.isOpen === true ? "visible" : "hidden")};
    /* height: ${(props) => (props.isOrderTypeModalOpen === true ? "100%" : "0px")}; */
    transition: opacity 0.001s ease-in, transform 0.001s ease-in 0.1s, visibility 0.2s ease-in 0.2s;
    /* opacity:${(props) => (props.isOrderTypeModalOpen === true ? "0" : "1")}; */

    @media only screen and (max-height: 500px), only screen and (max-width: 768px) {
        padding: 20px;
    }
`;
export const StyledModalContent = styled.div`
    z-index: 3;
    max-height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 350px;
    background-color: #fff;
    border-radius: 10px;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
    position: relative;

    top: ${(props) => (props.isOpen === true ? "0" : "3000px")};
    visibility: ${(props) => (props.isOpen === true ? "visible" : "hidden")};
    transition: ${(props) =>
        props.isOpen === true
            ? "top 568.546ms ease-in-out 10.7681ms, visibility 0s ease-in"
            : "top 568.546ms ease-in-out 10.7681ms, visibility 1s ease-in"};
    /* height: ${(props) => (props.isOpen ? "0px" : "100%")} */
`;
export const StyledModalTop = styled.div`
    background-color: red;
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    & img {
        width: 100px;
    }
`;
export const StyledModalBottom = styled.button`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    background-color: ${(props) => (props.disabled ? "#e0e0e0" : props.theme.colors.primary)};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    pointer-events: ${(props) => (props.disabled ? "none" : "visible")};
    height: 70px;
    border: none;
    text-align: center;
    color: #fff;
    text-transform: capitalize;
    font-size: 17px;
    font-family: "rubik";
    pointer-events: ${(props) => (props.scheduledOrderTime === null ? "none" : "visible")};

    :hover {
        opacity: ${(props) => (props.disabled ? "1" : "0.6")};
    }
`;
export const StyledModalCloser = styled.div`
    width: 10px;

    img {
        width: 30px;
        position: absolute;
        top: 10px;
        left: 10px;
        cursor: pointer;
    }
`;
export const StyledModalHeader = styled.div`
    padding: 20px 10px 10px;
    display: flex;
    p {
        margin: 0;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.17;
        letter-spacing: 0.3px;
        text-align: center;
        width: 100%;
        text-transform: capitalize;
    }
`;
export const StyledModalMiddleContent = styled.div`
    max-height: calc(100vh - 200px);
    /* overflow-y: auto;
    overflow-x: hidden; */
    padding: 0px 12px;

    @media (min-width: 768px) {
        padding: 12px;
    }

    .modal-content {
    }

    .content-warning {
        background-color: #ff00001f;
        padding: 10px;
        font-size: 15px;
        margin: 0;
        color: red;
        border-radius: 10px;
    }

    .schedule-title {
        font-size: 16px;
        line-height: 1.33;
        letter-spacing: 0.3px;
        margin: 16px 0 0;
        font-weight: 500;
        text-transform: capitalize;
    }

    @media only screen and (max-height: 500px), only screen and (max-width: 768px) {
        max-height: none;
        /* overflow-y: scroll; */
    }
`;
export const StyledOrderTypeButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px auto 3px;

    input[type="button"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .pickup_button,
    .delivery_button,
    .gift_button {
        flex-basis: 45%;
        height: 50px;
        color: ${(props) => props.theme.colors.primary};
        cursor: pointer;
        border-radius: 5px;
        font-weight: 700;
        font-size: 14px;
        border: none;
        letter-spacing: 0.63px;
        font-family: inherit;

        :hover {
            border: ${(props) => `2px solid ${props.theme.colors.primary}`};
        }
    }
    .pickup_button {
        background-color: ${(props) =>
            props.selectedOrderMethod === "pickup" ? `${props.theme.colors.primary}21` : "#f5f5f5"};
        border: ${(props) =>
            props.selectedOrderMethod === "pickup" ? `2px solid ${props.theme.colors.primary}` : null};
    }
    .delivery_button {
        background-color: ${(props) =>
            props.selectedOrderMethod === "delivery" ? `${props.theme.colors.primary}21` : "#f5f5f5"};
        border: ${(props) =>
            props.selectedOrderMethod === "delivery" ? `2px solid ${props.theme.colors.primary}` : null};
    }

    .gift_button {
        background-color: ${(props) =>
            props.selectedOrderMethod === "giftcard" ? `${props.theme.colors.primary}21` : "#f5f5f5"};
        border: ${(props) =>
            props.selectedOrderMethod === "giftcard" ? `2px solid ${props.theme.colors.primary}` : null};
    }
`;
export const StyledAddressWrapper = styled.div`
    margin: 30px 0;
    input {
        width: calc(100% - 20px);
        border: none;
        border-bottom: 1px solid black;
        padding: 10px;
        font-size: 14px;
    }
    p {
        margin-bottom: 10px;
        font-weight: 500;
    }
`;

export const StyledOrderBodySelectorWrapper = styled.div`
    height: ${(props) => (props.selectedOrderMethod ? "100%" : "0")};
    opacity: ${(props) => (props.selectedOrderMethod ? "1" : "0")};
    visibility: ${(props) => (props.selectedOrderMethod ? "visible" : "hidden")};
    transition: height 0.4s ease-in, visibility 0.3s ease-in 0.5s, opacity 0.4s ease-in-out 0.4s;

    .recommendation-available-modal {
        display: flex;
        color: #a8272d;
        font-weight: bold;
        gap: 10px;
    }

    .recommendation-available-tooltip {
        position: relative;
        display: inline-block;
        padding: 0 5px;
        width: 22px;
        height: 22px;
        text-align: center;
        border-radius: 50%;
        border: 2px solid #a8272d;
    }

    .recommendation-available-tooltip .recommendation-available-tooltip-text {
        visibility: hidden;
        width: 250px;
        background-color: #a8272dbd;
        color: #fff;
        text-align: center;
        padding: 5px;
        border-radius: 6px;
        position: absolute;
        font-weight: 500;
        z-index: 1;
        bottom: 150%;
        font-size: 12px;
        left: 50%;
        margin-left: -125px;
        opacity: 0;
        transition: 0.3s;
    }

    .recommendation-available-tooltip:hover {
        cursor: pointer;
    }

    .recommendation-available-tooltip:hover .recommendation-available-tooltip-text {
        visibility: visible;
        opacity: 1;
    }

    .recommendation-available-tooltip .recommendation-available-tooltip-text::after {
        content: " ";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #a8272dbd transparent transparent transparent;
    }
`;

export const StyledOrderChooserWrapper = styled.div`
    margin: 30px 0;
    p {
        margin-bottom: 10px;
        font-weight: 500;
    }
    div {
        padding: 15px 0;
        border-bottom: 1px solid #f5f5f5;
    }
    input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    input[type="radio"]:checked ~ label:after {
        background-color: ${(props) => props.theme.colors.primary};
    }

    input[type="radio"]:checked ~ label:before {
        border-color: ${(props) => props.theme.colors.primary};
    }
    /* input[type="radio"]:hover ~ label:after{
background-color: #ccc;;
} */

    label {
        position: relative;
        padding-left: 30px;
        line-height: 14px;
        display: flex;
        cursor: pointer;
        :before {
            content: "";
            width: 15px;
            height: 16px;
            padding-right: 15px;
            border-radius: 50%;
            border: 1px solid #ccc;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
        }

        :hover :after {
            background-color: #ccc;
        }

        :after {
            content: "";
            width: 11px;
            height: 11px;
            border-radius: 50%;
            position: absolute;
            top: 3px;
            bottom: 0;
            left: 3px;
            background-color: white;
        }
    }

    .order_now {
        opacity: ${(props) => (props.disabled ? ".4" : 1)};
    }

    .order_now:hover label:before,
    .order_later:hover label:before {
        border-color: ${(props) => props.theme.colors.primary};
    }

    input[type="radio"]:disabled ~ label {
        opacity: 0.3;
        cursor: not-allowed;
        line-height: 14px;
    }

    input[type="radio"]:disabled:hover ~ label:before,
    .order_now:hover input:disabled ~ label:before {
        border-color: #ccc;
        line-height: 14px;
    }
`;

export const StyledInputContainer = styled.div`
    visibility: ${(props) => (props.selectedOrderMethod !== "pickup" ? "hidden" : "visible")};
    display: ${(props) => (props.selectedOrderMethod !== "pickup" ? "none" : "block")};
    opacity: ${(props) => (props.selectedOrderMethod !== "pickup" ? "0" : "1")};
    height: ${(props) => (props.selectedOrderMethod !== "pickup" ? "0" : "100%")};
    width: ${(props) => (props.selectedOrderMethod !== "pickup" ? "0" : "100%")};
    position: ${(props) => (props.selectedOrderMethod !== "pickup" ? "absolute" : "static")};
    bottom: ${(props) => props.selectedOrderMethod !== "pickup" && "3000px"};
    transition: all 0.3s ease-in;
`;
export const StyledInput = styled.input`
    margin: 10px 0;

    input[value] :not([value=""]) + input:after {
        content: "" !important;
    }
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 14px;
    height: 41px;
    width: calc(100% - 40px);
    padding: 0 5px;
    border-radius: 7px;
    border: 1px solid #dbdbdb;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:hover {
        border: ${(props) => `1px solid ${props.theme.colors.primary}`};
    }
    &:focus {
        box-shadow: ${(props) => `0 0 1px 1px ${props.theme.colors.primary}b3`};
        outline: none;
    }

    :focus :after {
        content: "" !important;
    }

    :after {
        color: #000;
        content: attr(placeholder) !important;
    }
`;

const renderHeight = (orderMethod, orderType, scheduleDeliveries, schedulePickup) => {
    if (orderType !== "order_now" && orderMethod === "delivery" && scheduleDeliveries) {
        return "100%";
    } else if (orderType !== "order_now" && orderMethod === "pickup" && schedulePickup) {
        return "100%";
    } else {
        return "0%";
    }
};

const renderOpacity = (orderMethod, orderType, scheduleDeliveries, schedulePickup) => {
    if (orderType !== "order_now" && orderMethod === "delivery" && scheduleDeliveries) {
        return "1";
    } else if (orderType !== "order_now" && orderMethod === "pickup" && schedulePickup) {
        return "1";
    } else {
        return "0";
    }
};
const renderVisibility = (orderMethod, orderType, scheduleDeliveries, schedulePickup) => {
    if (orderType !== "order_now" && orderMethod === "delivery" && scheduleDeliveries) {
        return "visible";
    } else if (orderType !== "order_now" && orderMethod === "pickup" && schedulePickup) {
        return "visible";
    } else {
        return "hidden";
    }
};

export const StyledInputWrapperWithCalender = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
    height: ${({ orderMethod, scheduleDeliveries, orderType, schedulePickup }) =>
        renderHeight(orderMethod, orderType, scheduleDeliveries, schedulePickup)};
    opacity: ${({ orderMethod, scheduleDeliveries, orderType, schedulePickup }) =>
        renderOpacity(orderMethod, orderType, scheduleDeliveries, schedulePickup)};
    visibility: ${({ orderMethod, scheduleDeliveries, orderType, schedulePickup }) =>
        renderVisibility(orderMethod, orderType, scheduleDeliveries, schedulePickup)};
    transition: all 0.4s ease-in;

    input[value] :not([value=""]) + input:after {
        content: "" !important;
    }
`;

export const SelectStyles = css`
    flex-basis: calc(50% - 5px);
    font-family: Rubik;
    text-align: center;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 14px;
    /* height: 41px; */
    /* width: calc(100% - 40px); */
    /* padding: 0 15px; */
    border-radius: 70px;
    /* border: 1px solid #DBDBDB; */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    .calender-input__control {
        &:hover,
        &:active {
            border-color: ${(props) => `${props.theme.colors.primary}`};
        }
        &:focus,
        &--is-focused,
        &--menu-is-open {
            border-color: ${(props) => `${props.theme.colors.primary}`};
            box-shadow: ${(props) => `0 0 1px 1px ${props.theme.colors.primary}`};
            /* box-shadow: 0 0 1px 3px rgba(170, 170, 170, .7); */
            box-shadow: 0 0 0 1px-moz-mac-focusring;
            outline: none;
        }

        :focus :after {
            content: "" !important;
        }

        :after {
            color: #000;
            content: attr(placeholder) !important;
        }
    }
`;

export const StyledSelect = styled(Select)`
    ${SelectStyles}
`;
export const StyledSelectMobile = styled.select`
    ${SelectStyles}
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    padding: 2px 8px;
`;
