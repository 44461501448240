import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";

import { getTotalCartPrice, USD, getUrlParams, isObjectEmpty, baseUrl, handlePsId } from "../../utils/utils";

const params = getUrlParams();

const { locationId, merchantId, pageId, psId, orderType, channel } = params;

const useProceedToCheckout = (
    currentMerchantsShop,
    hasCartChanged,
    storeVendor,
    isStoreOpen,
    handleRemoveItemfromCart,
    handleItemCountChange,
    orderType,
    handleResetHasCartChanged,
    guestSession,
    history,
    handleCollatingCheckoutItems,
    scheduledOrderTime,
    activeCategoryPromo
) => {
    const cartState = useSelector((state) => state.cart);

    const [currentDeliveryChoice, setCurrentDeliveryChoice] = useState(1);
    const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
    const [isGuestOrderModalOpen, setIsGuestOrderModalOpen] = useState(false);
    const [isConfirmOrderOpen, setIsConfirmOrderOpen] = useState(false);
    const [usersPsId, setUserPsiD] = useState(handlePsId());
    const [checkingOut, setCheckingOut] = useState(false);
    const [is_active, setIs_active] = useState(true);
    // const { is_active } = currentMerchantsShop.categories;
    React.useEffect(() => {
        const setPromoTextFunction = () => {
            if (currentMerchantsShop.currentShop) {
                setPromotionText(currentMerchantsShop.currentShop[0].promo_text);
            }
        };
        setPromoTextFunction();
    }, [currentMerchantsShop.currentShop]);

    React.useEffect(() => {
        setIs_active(currentMerchantsShop.categories?.is_active);
    }, [currentMerchantsShop.categories?.is_active]);

    //call the add item endpoint if there is any change in the checkout menu
    React.useEffect(() => {
        if (hasCartChanged !== null) {
            handleSendDataToMessenger();
        }
    }, [hasCartChanged]);

    // gotten from the router props
    // const { goBack } = history;
    const [promotionText, setPromotionText] = React.useState(null);

    const getStoreURL = () => {
        if (storeVendor === "revel") {
            return "https://messenger.joyup.me/orderX/revel/additem";
        }
        return "https://messenger.joyup.me/orderX/square/additem";
    };

    const handleDisplayStoreClosedToast = () => {
        if (is_active === false) {
            return "THIS LOCATION IS NOT ACCEPTING ORDERS RIGHT NOW";
        }
        if (!isStoreOpen) {
            return "Store is Currently Closed";
        }
    };

    const { psId } = getUrlParams();

    const handleCheckMinimumOrderItem = (minimumDeliveryAmount) => {
        const { merchantId, deliveryType } = getUrlParams();
        if (deliveryType === "pickup") {
            return false;
        }

        const totalCartPrice = getTotalCartPrice(currentMerchantsShop.itemsInCheckout, false, activeCategoryPromo);
        if (minimumDeliveryAmount && currentMerchantsShop.itemsInCheckout.length > 0) {
            if (parseFloat(minimumDeliveryAmount) > totalCartPrice) {
                return `The minimum amount for delivery is $${minimumDeliveryAmount}`;
            } else {
                return false;
            }
        }
        if (
            !minimumDeliveryAmount &&
            merchantId === "5f010924df0b114b4f98e7cb" &&
            currentMerchantsShop.itemsInCheckout.length > 0
        ) {
            return totalCartPrice < parseFloat(25) ? "The minimum amount for delivery is $25" : false;
        }

        return false;
    };

    /**
     *this function takes an id and the corresponding sign and do the
     * calculation to increase or reduce the number of selection
     * @param {string} id the id of the item whose quantity we want to change
     * @param {string} sign the operator signs that is used for calculation
     * @example handleCountChange(123sgsg, 'subtract' || 'add')
     */
    async function handleCountChange(id, sign, callback) {
        let newItem;
        (await currentMerchantsShop.itemsInCheckout) &&
            currentMerchantsShop.itemsInCheckout.map((item, index) => {
                if (item.id === id) {
                    newItem = { ...item };

                    newItem.quantity = sign === "subtract" ? newItem.quantity - 1 : newItem.quantity + 1;
                    if (newItem.quantity === 0) {
                        handleRemoveItemfromCart(index);
                    } else {
                        handleItemCountChange(newItem);
                    }
                }
                return newItem;
            });
    }

    /**
     *this function takes an id and the returns the selected options
     * @param {string} id the id of the item whose quantity we want to get
     * @example getOptionsSelected(123sgsg)
     * @return {obj} the options
     */
    const getOptionsSelected = (id) =>
        currentMerchantsShop.itemsInCheckout.map((items) => {
            if (items.id === id) {
                return items.optionsRadioValue;
            }
        });

    // this closes the confirm order for group modal
    const handleCloseConfirmModal = () => {
        setIsConfirmOrderOpen(false);
    };

    // This continues the confirmation order
    const handleContinueGroupOrder = () => {
        handleCheckout();
    };

    const handleOrderType = () => {
        if (orderType === "group") {
            return "group";
        }
        if (psId === "" || psId === null || psId === "null" || psId === undefined || orderType === "guest") {
            return "guest";
        }
    };

    const handleSendDataToMessenger = async () => {
        const data = handlePrepareDataForBackEnd();

        console.log(data, "DATA SENT TO MESSENGER");
        try {
            const request = await fetch(getStoreURL(), {
                method: "post",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                }
            });
            handleResetHasCartChanged();
            return await request;
        } catch (error) {
            handleResetHasCartChanged();
            return error;
        }
    };

    const handleSendWebPromoDataToBackend = async () => {
        const { psid, botid, merchantId, psId } = handlePrepareDataForBackEnd();
        const data = {
            botId: botid,
            psId,
            merchantId,
            promo_text: "",
            promo: getUrlParams().psId || getUrlParams().promo === "true" ? true : false
        };

        console.log(data, "DATA SENT TO PROMO TEXT BACKEND '/save/promoText");

        try {
            const request = await fetch("https://pay.joyup.me/save/promoText", {
                method: "post",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                }
            });
            handleResetHasCartChanged();
            return await request;
        } catch (error) {
            return error;
        }
    };

    const handleSendDataToBackEnd = async (payUrl) => {
        setIsPaymentProcessing(true);
        const content = await handleSendDataToMessenger();

        if (content.status >= 200 && content.status < 300) {
            // if this is an order type and request successfull show the successfull modal else redirect to payment  page
            if (getUrlParams().orderType === "group") {
                // make a request to the server
                handlePostDataToGroupOrder();
            } else {
                window.top.location = payUrl;
            }
        } else {
            ToastsStore.error(" please check your network and try again", 0, "error");
            setIsPaymentProcessing(false);
        }
    };

    // this function post data to the group order
    const handlePostDataToGroupOrder = async () => {
        const { psId } = getUrlParams();
        const url = `${baseUrl}/group/order/${usersPsId}`;

        try {
            const request = await fetch(url, {
                method: "put",
                body: JSON.stringify(guestSession[0]),
                headers: {
                    "Content-Type": "application/json"
                }
            });

            const content = await request;
            if (content.status >= 200 && content.status < 300) {
                setIsConfirmOrderOpen(false);
                history.push("/group_order_success");
                // setIsGuestOrderModalOpen(true)
            } else {
                ToastsStore.error(" please check your network and try again", 0, "error");
                setIsPaymentProcessing(false);
            }
        } catch (error) {
            ToastsStore.error("please check your network and try again", 0, "error");
            setIsPaymentProcessing(false);
        }
    };

    const handlePrepareDataForBackEnd = () => {
        const { pageId, merchantId, locationId, channel } = getUrlParams();
        return {
            botid: pageId,
            items: handleCollatingCheckoutItems(),
            orderType: handleOrderType(),
            // items: newCheckoutItems,
            locationId,
            merchantId,
            channel: channel && channel,
            pageId,
            psId: usersPsId,
            totalPrice: parseFloat(
                getTotalCartPrice(currentMerchantsShop.itemsInCheckout, false, activeCategoryPromo, true)
            ).toFixed(2),
            totalTaxes: 0,
            scheduledOrderTime: scheduledOrderTime && scheduledOrderTime,
            fulfillment_type: getUrlParams().deliveryType !== "pickup" ? "delivery" : "pickup",
            min_delivery_amt: currentMerchantsShop.categories?.minimum_delivery_amount || 0
        };
    };

    const handleGenerateBasePaymentUrl = (paymentType) => {
        let payUrl;
        if (paymentType) {
            if (paymentType.checkoutMethod === "credit" || paymentType === "credit") {
                if (storeVendor === "revel") {
                    payUrl =
                        getUrlParams().deliveryType !== "pickup"
                            ? "https://pay.joyup.me/revelpayment/page?"
                            : "https://pay.joyup.me/revelpayment-pickup/page?";
                } else if (storeVendor === "otter" || storeVendor === "ordermark") {
                    payUrl =
                        getUrlParams().deliveryType !== "pickup"
                        ? "https://pay.joyup.me/payment/squarePayDelivery?"
                        : "https://pay.joyup.me/payment/squarePay?";
                            // ? "https://pay.joyup.me/payment/s4PayDelivery?"
                            // : "https://pay.joyup.me/payment/s4Pay?";
                } else {
                    payUrl =
                        getUrlParams().deliveryType !== "pickup"
                            ? "https://pay.joyup.me/payment/squarePayDelivery?"
                            : "https://pay.joyup.me/payment/squarePay?";
                }
            } else if (paymentType.checkoutMethod === "apple" || paymentType === "credit") {
                payUrl =
                    getUrlParams().deliveryType !== "pickup"
                        ? "https://pay.joyup.me/payment/squareApplePayDelivery?"
                        : "https://pay.joyup.me/payment/squareApplePay?";
            } else if (paymentType.checkoutMethod === "google" || paymentType === "credit") {
                payUrl =
                    getUrlParams().deliveryType !== "pickup"
                        ? "https://pay.joyup.me/payment/squareGPayDelivery?"
                        : "https://pay.joyup.me/payment/squareGPay?";
            }
        }
        return payUrl;
    };

    const handleGeneratePaymentURLString = (paymentType) => {
        let payUrl = handleGenerateBasePaymentUrl(paymentType);
        let data = handlePrepareDataForBackEnd();

        // this is used to construct the url that will redirect users to the payment page
        const orderItems = data.items.map((item) => item.name).join("|");
        const itemIds = data.items.map((item) => item.id).join("|");
        const itemPrices = data.items.map((item) => item.itemPrice).join("|");
        const itemQuantities = data.items.map((item) => item.quantity).join("|");
        const itemSpecialInstructions = data.items.map((item) => item.specialInstructions).join("|");
        const itemModifiers = data.items
            .map((item) =>
                item.modifiers.length > 0
                    ? item.modifiers
                          .map((modifier) =>
                              [modifier.id, modifier.name, modifier.price, modifier.admin_mod_key].join(",,")
                          )
                          .join("||")
                    : null
            )
            .join("##");

        const { pageId, merchantId, locationId, channel } = getUrlParams();
        const orderParams = {
            botid: pageId,
            // fb_first_name: "Abinav",
            fb_id: usersPsId,
            // profile_pic: `https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=${psId}`,
            // width: "1024",
            // ext: "3D1575087907",
            // hash: "3DAeRDMvpON0sB6vz0",
            // fb_last_name: "Kuru",
            merchant_id: merchantId,
            location_id: locationId,
            vendor: currentMerchantsShop.categories.merchant_id,
            // items: orderItems,
            // itemIds,
            // price: data.totalPrice,
            psid: usersPsId,
            orderType: handleOrderType(),
            fulfillment_type: getUrlParams().deliveryType !== "pickup" ? "delivery" : "pickup",
            created: Date.now(),
            // itemPrices,
            // itemQuantities,
            // establishment: 0,
            // itemSpecialInstructions,
            scheduledOrderTime,
            // modifiers: itemModifiers,
            channel: channel && channel,
            promo_codes: JSON.stringify(currentMerchantsShop.categories.promo_codes) || []
        };
        // const orderParams = {
        //     botid: pageId,
        //     fb_first_name: "Abinav",
        //     fb_id: usersPsId,
        //     profile_pic: `https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=${psId}`,
        //     width: "1024",
        //     ext: "3D1575087907",
        //     hash: "3DAeRDMvpON0sB6vz0",
        //     fb_last_name: "Kuru",
        //     merchant_id: merchantId,
        //     location_id: locationId,
        //     vendor: currentMerchantsShop.categories.merchant_id,
        //     items: orderItems,
        //     itemIds,
        //     price: data.totalPrice,
        //     psid: usersPsId,
        //     orderType: handleOrderType(),
        //     fulfillment_type: getUrlParams().deliveryType !== "pickup" ? "delivery" : "pickup",
        //     created: Date.now(),
        //     itemPrices,
        //     itemQuantities,
        //     establishment: 0,
        //     itemSpecialInstructions,
        //     scheduledOrderTime,
        //     modifiers: itemModifiers,
        //     channel: channel && channel,
        //     promo_codes: JSON.stringify(currentMerchantsShop.categories.promo_codes) || []
        // };

        for (const param in orderParams) {
            orderParams[param] = encodeURIComponent(orderParams[param]);
            payUrl += `${param}=${orderParams[param]}&`;
        }
        payUrl = payUrl.slice(0, -1);
        return payUrl;
    };

    // handles the checkout of a items
    const handleCheckout = async (paymentType) => {
        setCheckingOut(true);
        getUrlParams().channel === "web" &&
            getUrlParams().promo === "true" &&
            (await handleSendWebPromoDataToBackend());
        const payUrl = await handleGeneratePaymentURLString(paymentType);
        await handleSendDataToBackEnd(payUrl);
    };

    return {
        isPaymentProcessing,
        handleCountChange,
        handleSendDataToMessenger,
        handleCheckMinimumOrderItem,
        is_active,
        handleDisplayStoreClosedToast,
        isGuestOrderModalOpen,
        setIsGuestOrderModalOpen,
        isConfirmOrderOpen,
        handleCloseConfirmModal,
        handleContinueGroupOrder,
        handleCheckout,
        checkingOut,
        setIsPaymentProcessing
    };
};

export default useProceedToCheckout;
