/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
    View,
    Text
} from 'react-native';
import { styles } from './styles';

const {
    featuredItemCategoryName,
    featuredItemCategoryLocation,
    storeDetails,
} = styles;

export const StoreNameAddressComponent = ({ categories }) => (
    <View style={storeDetails}>
        <Text style={featuredItemCategoryName}>
            {categories && categories.merchant_name}
        </Text>
        {
            categories && categories.location_address
                ? (
                    <Text
                        style={featuredItemCategoryLocation}
                    >
                        {categories && categories.location_address}
                    </Text>
                )
                : <View />
        }
    </View>
);

StoreNameAddressComponent.propTypes = {
    categories: PropTypes.shape({
        location_address: PropTypes.string.isRequired,
        merchant_name: PropTypes.string.isRequired,
    }).isRequired,
};
