//External Packages
import React, { useState, useEffect } from "react";
import "flatpickr/dist/themes/light.css";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import momentTimezone from "moment-timezone";

import SelectFields from "./components/SelectFields";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";

//Styles
import {
    StyledModalOverlay,
    StyledModalContent,
    StyledModalTop,
    StyledModalBottom,
    StyledModalCloser,
    StyledModalHeader,
    StyledModalMiddleContent,
    StyledInputContainer,
    StyledOrderTypeButtonsWrapper,
    StyledAddressWrapper,
    StyledOrderChooserWrapper,
    StyledOrderBodySelectorWrapper,
    StyledInputWrapperWithCalender,
    StyledInput,
    StyledSelect,
    StyledSelectMobile
} from "./OrderTypeChooser.style";

//Utilities
import {
    convert24hrTo12hrTime,
    getTimeInterval,
    handleMakeTimeMultipleOfFifteen,
    getUrlParams
} from "../../../../utils/utils";
import { useCallback } from "react";
import { RECOMMENDATION_MERCHANTS_WHITELIST } from "#/pages/orderPage/constant";
import Switch from "react-switch";

//Main Component
export const OrderTypeChooser = ({
    isOrderTypeModalOpen,
    setOrderTypeModalOpen,
    handleChangeUsersName,
    selectedOrderMethod,
    handleChooseOrderMethod,
    usersDetails,
    handleChangePhoneNumber,
    setScheduledOrderTime,
    scheduledOrderTime,
    isStoreOpen,
    hoursOfOperation,
    closingTime,
    openingTime,
    currentMerchantsShop,
    breakStart,
    breakEnd,
    onBreak,
    afterBreakFirstPickupTime,
    scheduleDeliveries,
    pickupInitialPrepTime,
    deliveryInitialPrepTime,
    deliveryIntervalPrepTime,
    scheduledTimeIntervals,
    schedulePickup,
    businessAlertText,
    isRecommendationAvailable,
    setIsRecommendationAvailable,
    ...props
}) => {
    let merchantId = getUrlParams().merchantId;
    //----------------------------------------------------------------------------------
    //HELPER HOOKS
    //----------------------------------------------------------------------------------
    const history = useHistory();

    //----------------------------------------------------------------------------------
    //STATE
    //----------------------------------------------------------------------------------
    const [orderType, setOrderType] = useState(null);
    const [selectedScheduledDate, setSelectedScheduledDate] = useState({});
    const [timeOptions, setTimeOptions] = useState([]);
    const [orderMethod, setOrderMethod] = useState(selectedOrderMethod);
    const [displayedTime, setDisplayedTime] = useState(null);
    const [displayedDate, setDisplayedDate] = useState(null);

    //----------------------------------------------------------------------------------
    //HELPER FUNCTIONS
    //----------------------------------------------------------------------------------
    const handleDisableSubmitButton = () => {
        // declare days
        const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
        // get open days
        const openDays = Object.keys(hoursOfOperation);
        // get the days shop is not open
        const difference = days.filter((day, index) => !openDays.includes(day));
        // get index of days that shop is not open
        const closeDaysIndex = difference.map((item) => days.findIndex((v) => item === v));

        return closeDaysIndex;
    };

    const handleCalculateMinMaxTime = (date, isToday = false) => {
        if (date !== null && !!date) {
            const dayOfChoosenDate = date.toString().split(" ")[0].toLowerCase();
            const today = new Date().toString().split(" ")[0].toLowerCase();
            const dates = Object.keys(hoursOfOperation).find((item) => item.startsWith(dayOfChoosenDate));
            // let currentTime = `${new Date().getHours()}${new Date().getMinutes()}`
            let hour = `${new Date().getHours()}`;
            let minutes = `${new Date().getMinutes()}`;
            let hourPlusFifteen = `${new Date(new Date().getTime() + 15 * 60000).getHours()}`;
            let minutesPlusFifteen = `${new Date(new Date().getTime() + 15 * 60000).getMinutes()}`;

            if (hour < 10) {
                hour = `0${hour}`;
            }
            if (minutes < 10) {
                minutes = `0${minutes}`;
            }
            if (hourPlusFifteen < 10) {
                hourPlusFifteen = `0${hourPlusFifteen}`;
            }
            if (minutesPlusFifteen < 10) {
                minutesPlusFifteen = `0${minutesPlusFifteen}`;
            }

            const currentTimePlusFifteen = `${hourPlusFifteen}${minutesPlusFifteen}`;
            const currentTime = `${hour}${minutes}`;

            if (today === dayOfChoosenDate) {
                // if start is less than now
                const todayTimings = { ...hoursOfOperation[dates] };
                if (todayTimings.start < currentTime && todayTimings.end > currentTime) {
                    // todayTimings.start =  '0000';
                    todayTimings.start = handleMakeTimeMultipleOfFifteen(currentTimePlusFifteen);
                    return todayTimings;
                } else if (currentTime >= todayTimings.end && isToday) {
                    todayTimings.start = todayTimings.end;
                    return todayTimings;
                } else {
                    return hoursOfOperation[dates];
                }
            }

            return hoursOfOperation[dates];
        }
    };

    const dateDisableArray = [];
    handleDisableSubmitButton().map((item) => {
        dateDisableArray.push((date) => {
            return date.getDay() === item;
        });
    });

    const handleButtonOrderTypeTextDisplay = () => {
        if (orderMethod === "pickup") {
            if (orderType === "order_later" && selectedScheduledDate.time) {
                return `start Scheduled ${orderMethod} order`;
            } else if (orderType === "order_now") {
                return `start ${orderMethod} order`;
            }
        } else if (orderMethod === "delivery") {
            if (orderType === "order_later" && selectedScheduledDate.time) {
                return `start Scheduled ${orderMethod} order`;
            } else if (orderType === "order_now") {
                return `start ${orderMethod} order`;
            }
        } else if (orderMethod === "giftcard") {
            return "Purchase Gift Card";
        }

        return "Choose Order Type";
    };

    const handleSelectWhenToOrder = (e) => {
        if (orderMethod === "delivery" || orderMethod === "giftcard") {
            setScheduledOrderTime(null);
            setOrderType("order_now");
        }
        if (e.target.name === "order_now") {
            setScheduledOrderTime(null);
            setOrderType(e.target.name);
        } else {
            setOrderType(e.target.name);
        }
    };

    const handleEnableOrderButton = () => {
        //Disabled once store is not active
        if (currentMerchantsShop.categories?.is_active === false) {
            return true;
        }

        //Enable once gift card is chosen
        if (orderMethod === "giftcard") {
            return false;
        }

        //Enable if order_now is chosen
        if (orderType === "order_now") {
            return false;
        } else if (orderMethod === "pickup" && orderType === "order_later" && selectedScheduledDate.time) {
            return false;
        } else if (orderMethod === "delivery" && scheduleDeliveries && selectedScheduledDate.time) {
            return false;
        } else {
            return true;
        }
    };

    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    };

    function getDates(startDate, stopDate) {
        var dateArray = new Array();
        var currentDate = startDate;
        while (currentDate <= stopDate) {
            dateArray.push(new Date(currentDate));
            currentDate = currentDate.addDays(1);
        }
        return dateArray;
    }

    const getBusinessOpenDate = () => {
        const days = Object.keys(hoursOfOperation);
        // get the current year
        const year = new Date().getFullYear();

        // get range of the dates from now till the end of the year
        const daylist = getDates(new Date(), new Date(`${year}-12-31`));
        let openDays = [];

        daylist.map((v) => {
            // to control the label of the input field to have the word today and tomorrow map over the days and make the label today and tomorow when the dates re todan and tomorrow respectively
            days.map((item) => {
                var tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);
                var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
                var localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
                var tomorrowLocalISOTime = new Date(tomorrow - tzoffset).toISOString().slice(0, -1);

                // if today is an open day, change the date to "Today"
                if (
                    new Date().toString() === v.toString() &&
                    item.startsWith(v.toString().split(" ")[0].toLowerCase())
                ) {
                    // pass today as argument to get the opening time and closing time, if
                    // they are same, it means restaurant business time has been elapsed so
                    // remove the dates label
                    const { start, end } = handleCalculateMinMaxTime(new Date());
                    // add the time to get opne slots. if there are no open slots remove
                    // 'Today' from select label
                    const openSlots = getTimeInterval(
                        start,
                        end,
                        scheduledTimeIntervals,
                        breakStart,
                        breakEnd,
                        "Today",
                        orderMethod === "delivery" ? deliveryIntervalPrepTime : 0,
                        orderMethod === "delivery" ? deliveryInitialPrepTime : pickupInitialPrepTime,
                        merchantId,
                        orderMethod
                    );
                    if (openSlots && openSlots.length > 0) {
                        openDays.push({
                            label: "Today",
                            value: localISOTime.split("T")[0],
                            fullDate: v
                        });
                        v = "Today";
                    }
                    return;
                }
                // if tomorrow is an open day, change the date to "Tomorrow"
                if (tomorrow.toString() === v.toString() && item.startsWith(v.toString().split(" ")[0].toLowerCase())) {
                    openDays.push({
                        label: "Tomorrow",
                        value: tomorrowLocalISOTime.split("T")[0],
                        fullDate: v
                    });
                    v = "Tomorrow";
                }
                // format all open days to e.g 'Thu, 12/11'
                if (item.startsWith(v.toString().split(" ")[0].toLowerCase())) {
                    openDays.push({
                        label: `${v.toString().slice(0, 10).split(" ")[0]}, ${v
                            .toISOString()
                            .slice(5, 10)
                            .split("-")
                            .join("/")}`,
                        value: v.toISOString().split("T")[0],
                        fullDate: v
                    });
                }
            });
        });

        const returnedRange = handleCalculateMinMaxTime(daylist[0]);

        const start = returnedRange?.start;
        const end = returnedRange?.end;

        const todaysTimeOptions = [
            ...getTimeInterval(
                start,
                end,
                scheduledTimeIntervals,
                breakStart,
                breakEnd,
                "Today",
                orderMethod === "delivery" ? deliveryIntervalPrepTime : 0,
                orderMethod === "delivery" ? deliveryInitialPrepTime : pickupInitialPrepTime,
                merchantId,
                orderMethod
            )
        ];

        if (todaysTimeOptions.length === 0) {
            openDays = openDays.filter((item) => {
                return item.label !== "Today";
            });
        }

        //Check if today has no time slots available

        return openDays;
    };

    const handleSelectDate = (e) => {
        if (e?.target?.dataset?.id === "mobile") {
            if (e.target.value === "") {
                setSelectedScheduledDate({
                    ...selectedScheduledDate
                });
                return;
            }
            e = getBusinessOpenDate()[e.target.value];
        }

        //In case user selects SELECT DATE
        if (e == undefined || e == "undefined") {
            setDisplayedTime(null);
            setDisplayedDate(null);
            setTimeOptions([]);
            return;
        }
        const { start, end } = handleCalculateMinMaxTime(e.fullDate, e.label == "Today" ? true : false);

        let _selectedDay = `${e.fullDate.toString()}`.substring(0, 3);
        let breakStart = "";
        let breakEnd = "";

        for (let day in hoursOfOperation) {
            if (day.substring(0, 3) === _selectedDay.toLowerCase()) {
                breakStart = hoursOfOperation[day].breakStart || "";
                breakEnd = hoursOfOperation[day].breakEnd || "";
            }
        }

        const returnedTimeOptions = [
            ...getTimeInterval(
                start,
                end,
                scheduledTimeIntervals,
                breakStart,
                breakEnd,
                e.label == "Today" ? true : false,
                orderMethod === "delivery" ? deliveryIntervalPrepTime : 0,
                orderMethod === "delivery" ? deliveryInitialPrepTime : pickupInitialPrepTime,
                merchantId,
                orderMethod
            )
        ];

        setTimeOptions(returnedTimeOptions);
        setSelectedScheduledDate({
            ...selectedScheduledDate,
            date: e.value,
            label: e.label
        });

        const availableDates = getBusinessOpenDate();

        const selectedDate = availableDates.find((item) => {
            return item.value === e.value;
        });

        setDisplayedDate(selectedDate);

        setDisplayedTime(null);
    };

    const handleSelectTime = (e) => {
        if (e?.target?.dataset?.id === "mobile") {
            if (e.target.value === "") {
                setSelectedScheduledDate({
                    ...selectedScheduledDate
                });
                return;
            }
            e = timeOptions[e.target.value];
        }

        //In case user selects SELECT TIME
        if (e == undefined || e == "undefined") {
            setDisplayedTime(null);
            return;
        }

        setSelectedScheduledDate({
            ...selectedScheduledDate,
            time: e?.value
        });

        const displayedTime = timeOptions.find((item) => {
            return item.value === e.value;
        });

        setDisplayedTime(displayedTime);
    };

    const handleSubmitModalForm = (e) => {
        e.preventDefault();
        if (orderMethod === "giftcard") {
            setOrderTypeModalOpen(false);
            const {
                locationId,
                merchantId,
                pageId,
                psId,
                orderType,
                deliveryType,
                channel,
                promo,
                userId
            } = getUrlParams();

            if (psId) {
                return history.push(
                    `/giftcard?merchant_id=${merchantId}&location_id=${locationId}&page_id=${pageId}&ps_id=${psId}`
                );
            } else {
                return history.push(`/giftcard?merchant_id=${merchantId}&location_id=${locationId}&page_id=${pageId}`);
            }
        }
        // state.reloadAgain && history.replace('/giftcard', { ...state, reloadAgain: false })
        const { time, date, label } = selectedScheduledDate;

        let timezoneTime = "";
        if (getUrlParams().merchantId === "6075e037666fec40b0bd0773") {
            timezoneTime = momentTimezone.tz(new Date(`${date}T${time}`), "America/Chicago").toISOString();
        } else if (getUrlParams().merchantId === "5a7371c9a67ad0001a1023f8") {
            timezoneTime = momentTimezone.tz(new Date(`${date}T${time}`), "America/Los_Angeles").toISOString();
        } else {
            timezoneTime = orderType === "order_later" ? new Date(`${date}T${time}`).toISOString() : "";
        }

        setScheduledOrderTime({
            date: orderType === "order_later" ? `${date}` : null,
            time: orderType === "order_later" ? `${time}` : null,
            label: orderType === "order_later" ? `${label}` : null,
            dateTime: orderType === "order_later" ? timezoneTime : null
        });

        handleChooseOrderMethod(orderMethod);
    };

    const handleSetOrderFulfilmentType = (e) => {
        const value = e.target.name;
        setOrderMethod(value);

        if (value === "pickup" && isStoreOpen) {
            setOrderType("order_now");
        }
    };
    const handleHideGfCard = () => {
        if (currentMerchantsShop.categories.giftcard_option === false) {
            return true;
        }
        return false;
    };

    //----------------------------------------------------------------------------------
    //SIDE EFFECTS
    //----------------------------------------------------------------------------------
    // select order_now if the order is a web order
    useEffect(() => {
        const handleOpenOrderChooserModal = () => {
            if (getUrlParams().merchantId === "5ed17f0244a2794e36940fb1") {
                setOrderTypeModalOpen(true);
            } else if (currentMerchantsShop.categories?.is_active === false) {
                setOrderTypeModalOpen(true);
            } else if (
                currentMerchantsShop.categories?.is_active === true ||
                currentMerchantsShop.categories?.is_active === undefined
            ) {
                if (!getUrlParams().deliveryType) {
                    setOrderTypeModalOpen(true);
                } else if (!isStoreOpen) {
                    setOrderTypeModalOpen(true);
                } else {
                    setOrderType("order_now");
                    setOrderTypeModalOpen(true);
                }
            }
            if (currentMerchantsShop?.categories.is_delivery === false && getUrlParams().deliveryType == "delivery") {
                setOrderTypeModalOpen(true);
                setOrderMethod("Pickup");
                setOrderType("order_now");
            } else if (
                currentMerchantsShop?.categories.is_pickup === false &&
                getUrlParams().deliveryType == "pickup"
            ) {
                setOrderTypeModalOpen(true);
                setOrderMethod("Delivery");
                setOrderType("order_now");
                handleButtonOrderTypeTextDisplay();
            }
        };
        handleOpenOrderChooserModal();
    }, [isStoreOpen]);

    useEffect(() => {
        const handleSetScheduleOrderWhenStoreIsClosed = () => {
            !isStoreOpen && !scheduledOrderTime && setOrderType("order_later");
        };
        handleSetScheduleOrderWhenStoreIsClosed();
    }, [isStoreOpen]);

    useEffect(() => {
        setDisplayedTime(null);
        setDisplayedDate(null);
        setTimeOptions([]);
        setSelectedScheduledDate({});
    }, [orderMethod]);

    //----------------------------------------------------------------------------------
    //Render store closed text
    //----------------------------------------------------------------------------------
    const renderStoreClosedSection = () => {
        if (currentMerchantsShop.categories?.is_active === false) {
            return (
                <div className="modal-content">
                    <p className="content-warning">This location is not accepting orders right now</p>
                </div>
            );
        } else if (onBreak) {
            return (
                <div className="modal-content">
                    <p className="content-warning">
                        Store is currently closed but you can place order right now to be{" "}
                        {orderMethod === "delivery" ? "delivered" : "picked up"} at {afterBreakFirstPickupTime.label}
                    </p>
                </div>
            );
        } else if (!isStoreOpen && !scheduledOrderTime?.time) {
            return (
                <div className="modal-content">
                    <p className="content-warning">{businessAlertText}</p>
                </div>
            );
        }
    };

    const scheduleFieldIsDisabled = () => {
        if (orderMethod === "delivery" && scheduleDeliveries) {
            return false;
        } else if (orderMethod === "pickup" && schedulePickup) {
            return false;
        } else {
            return true;
        }
    };

    const showOrderNow = () => {
        if (isStoreOpen && orderMethod !== "giftcard") {
            return true;
        } else {
            return false;
        }
    };

    const renderDeliveryLabel = () => {
        return `Schedule For Later`;
    };

    //----------------------------------------------------------------------------------
    //MAIN RETURNED JSX
    //----------------------------------------------------------------------------------
    return (
        <StyledModalOverlay isOpen={isOrderTypeModalOpen}>
            <StyledModalContent isOpen={isOrderTypeModalOpen}>
                {getUrlParams().merchantId === "5ed17f0244a2794e36940fb1" ? (
                    <>
                        <StyledModalHeader>
                            <p>Order Update</p>
                        </StyledModalHeader>
                        <StyledModalMiddleContent>
                            <p>This store is currently closed for online orders.</p>
                            <p>We apologize for any inconveniences as we are working on bringing it back up.</p>
                            <p>
                                To make an order please call <br />
                                <a href="tel:650-235-9715">650-235-9715</a>
                            </p>
                        </StyledModalMiddleContent>
                    </>
                ) : (
                    <>
                        <StyledModalHeader>
                            <p>CHOOSE ORDER TYPE</p>
                        </StyledModalHeader>
                        <form onSubmit={handleSubmitModalForm}>
                            <StyledModalMiddleContent>
                                {/* ------------------------------------- */}
                                {/* Store Closed Text */}
                                {/* ------------------------------------- */}
                                {renderStoreClosedSection()}

                                {/* ------------------------------------- */}
                                {/* Available options, DELIVERY, PICKUP OR GIFT CARD */}
                                {/* ------------------------------------- */}
                                <StyledOrderTypeButtonsWrapper selectedOrderMethod={orderMethod}>
                                    {currentMerchantsShop.categories.is_delivery == false ? (
                                        <></>
                                    ) : (
                                        <>
                                            <input
                                                type="button"
                                                value="Delivery"
                                                className="delivery_button"
                                                name="delivery"
                                                onClick={handleSetOrderFulfilmentType}
                                            />
                                        </>
                                    )}

                                    {currentMerchantsShop.categories.is_pickup == false ? (
                                        <></>
                                    ) : (
                                        <input
                                            type="button"
                                            value="Pickup"
                                            className="pickup_button mx-1"
                                            name="pickup"
                                            onClick={handleSetOrderFulfilmentType}
                                        />
                                    )}

                                    <input
                                        type="button"
                                        value="GiftCard"
                                        className="gift_button"
                                        hidden={handleHideGfCard()}
                                        name="giftcard"
                                        onClick={handleSetOrderFulfilmentType}
                                    />
                                </StyledOrderTypeButtonsWrapper>

                                {/* ------------------------------------- */}
                                {/* Delivered by Postmates text */}
                                {/* ------------------------------------- */}
                                {currentMerchantsShop.categories.is_delivery && (
                                    <h5
                                        style={{
                                            opacity: "0.3",
                                            fontWeight: "400",
                                            fontSize: "9.7px",
                                            marginBottom: "30px"
                                        }}>
                                        Powered by Doordash
                                    </h5>
                                )}

                                {/* ------------------------------------- */}
                                {/* Time to order */}
                                {/* ------------------------------------- */}
                                <StyledOrderBodySelectorWrapper
                                    selectedOrderMethod={selectedOrderMethod}
                                    isStoreOpen={isStoreOpen}>
                                    <StyledOrderChooserWrapper disabled={isStoreOpen ? 0 : 1}>
                                        {showOrderNow() && (
                                            <>
                                                {scheduleFieldIsDisabled() === false && (
                                                    <p className="order-question">When Would you like to order</p>
                                                )}
                                                <div className="order_now">
                                                    <input
                                                        type="radio"
                                                        name="order_now"
                                                        id="order_now"
                                                        value="order_now"
                                                        onChange={handleSelectWhenToOrder}
                                                        disabled={isStoreOpen ? 0 : 1}
                                                        checked={orderType === "order_now"}
                                                        defaultChecked
                                                    />
                                                    <label htmlFor="order_now">Order Now</label>
                                                </div>
                                            </>
                                        )}

                                        <div className="order_later">
                                            <input
                                                type="radio"
                                                name="order_later"
                                                id="order_later"
                                                hidden={orderMethod === "giftcard"}
                                                value="order_later"
                                                checked={orderType === "order_later"}
                                                onChange={handleSelectWhenToOrder}
                                                disabled={scheduleFieldIsDisabled()}
                                            />
                                            <label hidden={orderMethod === "giftcard"} htmlFor="order_later">
                                                {renderDeliveryLabel()}
                                            </label>
                                        </div>
                                    </StyledOrderChooserWrapper>
                                    {orderMethod !== "giftcard" && (
                                        <>
                                            {RECOMMENDATION_MERCHANTS_WHITELIST.includes(merchantId) && (
                                                <div
                                                    className="recommendation-available-modal"
                                                    onClick={(e) => e.stopPropagation()}>
                                                    <Switch
                                                        offColor="#c4c1c3"
                                                        onColor="#c4c1c3"
                                                        onHandleColor="#A8272D"
                                                        handleDiameter={17}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow={"none"}
                                                        height={22}
                                                        width={35}
                                                        onChange={(e) =>
                                                            setIsRecommendationAvailable(!isRecommendationAvailable)
                                                        }
                                                        checked={isRecommendationAvailable}
                                                    />
                                                    <span>Chef Suggestions</span>
                                                    <div className="recommendation-available-tooltip">
                                                        i
                                                        <span className="recommendation-available-tooltip-text">
                                                            Wondering what to order? Help us help you with our custom
                                                            chef suggestions
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                            <SelectFields
                                                orderMethod={orderMethod}
                                                merchantId={merchantId}
                                                orderType={orderType}
                                                getBusinessOpenDate={getBusinessOpenDate}
                                                handleSelectDate={handleSelectDate}
                                                fieldIsDisabled={scheduleFieldIsDisabled}
                                                timeOptions={timeOptions}
                                                handleSelectTime={handleSelectTime}
                                                selectedScheduledDate={selectedScheduledDate}
                                                displayedTime={displayedTime}
                                                displayedDate={displayedDate}
                                                scheduleDeliveries={scheduleDeliveries}
                                                schedulePickup={schedulePickup}
                                            />
                                        </>
                                    )}
                                </StyledOrderBodySelectorWrapper>
                            </StyledModalMiddleContent>

                            <StyledModalBottom orderType={orderType} disabled={handleEnableOrderButton()} type="submit">
                                {handleButtonOrderTypeTextDisplay()}
                            </StyledModalBottom>
                        </form>
                    </>
                )}
            </StyledModalContent>
        </StyledModalOverlay>
    );
};
