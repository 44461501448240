import styled from "styled-components";

export const Box = styled.div`
    position: relative;
    border-radius: 20px;
    background-color: #fff;
    padding: 15px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.06);
    margin: 10px;
    transition: all 200ms ease-in-out;
    width: 373px;
    min-width: 373px;
    cursor: pointer;

    &:hover {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.22);
    }

    &:first-child {
        margin-left: 0px;
    }

    @media (max-width: 600px) {
        padding: 0px;
        flex-direction: column;
        width: auto;
        min-width: auto;
        height: auto;
        border-radius: 10px;
    }

    //--------------------------------------------------------------------------
    //Image section
    //--------------------------------------------------------------------------
    .left-column {
        width: 100px;
        height: 100%;
        object-fit: cover;
        margin-right: 10px;
        border-radius: 10px;

        @media (max-width: 600px) {
            width: 100%;
            height: 95px;
            margin-right: 0px;
            border-radius: 10px 10px 0px 0px;
        }
    }

    .right-column {
        flex: 1;
        display: flex;
        // align-items: center;
        align-self: flex-start;
        flex-direction: column;

        @media (max-width: 600px) {
            padding: 10px 10px 15px;
        }

        //--------------------------------------------------------------------------
        //Text section
        //--------------------------------------------------------------------------
        &__info-section {
            margin-right: 15px;
            margin-bottom: 10px;
            flex: 1;

            @media (max-width: 600px) {
                width: 140px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }

            .product-header {
                font-weight: 400;
                color: #000000;
                font-size: 14px;
                font-weight: 600;
                text-transform: capitalize;
                margin-bottom: 5px;
                line-height: 15px;

                @media (max-width: 600px) {
                    margin-bottom: 0px;
                }
            }

            .product-description {
                font-weight: 400;
                font-size: 12px;
                color: rgb(150, 150, 150);
                margin-bottom: 5px;
                // display: -webkit-box;
                // max-width: 200px;
                // -webkit-line-clamp: 2;
                // -webkit-box-orient: vertical;
                // overflow: hidden;
                line-height: 17px;
            }

            .product-price {
                font-weight: 400;
                font-size: 14px;
                color: #000;
            }
        }

        //--------------------------------------------------------------------------
        //Blue Button
        //--------------------------------------------------------------------------
        &__btn-add {
            background-color: ${(props) => props.theme.colors.primary};
            color: #fff;
            border: 0px;
            border-radius: 30px;
            padding: 5px 11px;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.22);
            flex-shrink: 0;
            align-self: flex-start;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    //--------------------------------------------------------------------------
    //Ribbon
    //--------------------------------------------------------------------------
    .ribbon {
        width: 150px;
        height: 150px;
        overflow: hidden;
        position: absolute;
    }
    .ribbon::before,
    .ribbon::after {
        position: absolute;
        z-index: -1;
        content: "";
        display: block;
        border: 5px solid ${(props) => props.theme.colors.primary};
    }
    .ribbon span {
        position: absolute;
        display: block;
        padding: 2px 0;
        padding-right: 35px;
        background-color: ${(props) => props.theme.colors.primary};
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        color: #fff;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
        text-transform: uppercase;
        text-align: center;
        width: 225px;

        @media (max-width: 600px) {
            width: 290px;
            padding: 0px;
            font-size: 12px;
            padding-right: 0px;
        }
    }

    .ribbon-top-left {
        top: -10px;
        left: -10px;
    }

    .ribbon-top-left::before,
    .ribbon-top-left::after {
        border-top-color: transparent;
        border-left-color: transparent;
    }

    .ribbon-top-left::before {
        top: 0;
        right: 74px;

        @media (max-width: 600px) {
            right: 92px;
        }
    }
    .ribbon-top-left::after {
        bottom: 74px;
        left: 0;

        @media (max-width: 600px) {
            bottom: 92px;
        }
    }
    .ribbon-top-left span {
        right: -5px;
        top: 7px;
        font-size: 12px;
        transform: rotate(-45deg);

        @media (max-width: 600px) {
            right: -15px;
            top: 20px;
            font-size: 10px;
        }
    }
`;
