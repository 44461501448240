import React from "react";
import PropTypes from "prop-types";
import { ImageBackground, Text, TouchableOpacity } from "react-native";
import { Link } from "react-router-dom";
import { styles } from "./styles";
import { getUrlParams } from "../../../utils/utils";

const { promoItem, promoImage, promoItemText } = styles;
const psId = getUrlParams().psId;
// renders the promos data
const PromosData = ({ item }) => {
    const {
        id,
        page_hero_image,
        name,
        location_address,
        promo_image,
        promo_text,
        merchant_uid,
        page_id,
        square_location_id
    } = item;
    return (
        <Link
            to={`/merchant/?merchant_id=${merchant_uid}&location_id=${square_location_id}&page_id=${page_id}&ps_id=${psId}`}
            style={{
                textDecorationLine: "none"
            }}
        >
            <TouchableOpacity
                style={[
                    promoItem,
                    {
                        backgroundColor: "#f6c649"
                    }
                ]}
            >
                <Text style={promoItemText}>{promo_text}</Text>
                <ImageBackground source={promo_image} style={promoImage} resizeMode="cover"></ImageBackground>
            </TouchableOpacity>
        </Link>
    );
};

PromosData.propTypes = {};

export default PromosData;
