import React, { useState, useEffect, useRef } from "react";
import { useTransition, useSprings, useSpring, useChain, config, animated, useTrail } from "react-spring";
import { gsap } from "gsap";
import { useSelector } from "react-redux";
import { StyledInnerContainer, StyledOuterContainer } from "./WebPromoGreeting.style";
import JoyupRobo from "../../../../assets/joyup.jpg";
import { Button } from "../../../../components";
import StarsRating from "stars-rating";
export const WebPromoGreeting = ({ isPromoPresent, promoText, promoDetails, setIsRecommendationShown }) => {
    const [on, toggle] = useState(false);
    const [newData, setNewData] = useState([]);

    const usersDomNodes = [];
    let imgRef = useRef(null);
    let bgImgRef = useRef(null);
    let buttonRef = useRef(null);

    const state = useSelector((state) => state.dashboard);
    const cartState = useSelector((state) => state.cart);
    console.log(state);

    let currentSelectedMerchant = state["currentlySelectedMerchant"];
    let currentMerchantData = state[currentSelectedMerchant];
    const { image_url, promo_image, merchant_name, logo } = currentMerchantData?.categories;

    React.useEffect(() => {
        const setPromoTextFunction = () => {
            let welcomeGreeting = currentMerchantData?.categories?.promo_greeting_text;
            // let promoText = cartState?.userProfile?.promo_text && `Here is a Promo 🎁 specially for you - ${cartState.userProfile.promo_text}`
            let promoText = currentMerchantData?.categories?.promo_text;
            // let promoDetails = cartState?.userProfile?.promo_details && cartState.userProfile.promo_details
            let promo_instruction = currentMerchantData?.categories?.promo_instruction;

            // setTimeout(() => {
            setNewData([
                ...new Set([
                    welcomeGreeting && welcomeGreeting !== "" && welcomeGreeting,
                    promoText && promoText !== "" && promoText,
                    promo_instruction && promo_instruction !== "" && promo_instruction
                ])
            ]);
            // }, 1000);
        };
        setPromoTextFunction();
    }, []);

    useEffect(() => {
        const tl = gsap.timeline({ paused: true });
        tl.to(bgImgRef, {
            duration: 0.3,
            stagger: 1.9,
            autoAlpha: 1,
            delay: 1,
            x: 0
        })
            .to(imgRef, {
                duration: 0.3,
                stagger: 1.9,
                x: -20,
                autoAlpha: 1,
                delay: 1
            })
            .to(usersDomNodes, {
                duration: 0.9,
                delay: 0.5,
                stagger: 1.6,
                y: 0,
                autoAlpha: 1,
                display: "block"
            })
            .to(buttonRef, {
                duration: 0.3,
                delay: 4,
                autoAlpha: 1
            })
            .play();
    }, [newData]);

    return (
        <StyledOuterContainer on={on} background={promo_image || image_url || ""}>
            <section className="image-section">
                {
                    <img
                        src={promo_image || image_url || ""}
                        alt="background"
                        ref={(e) => {
                            bgImgRef = e;
                        }}
                    />
                }
                 <div className="mainRatingSection">
                    <div className="stars">
                        <StarsRating count={5} size={24} value={(currentMerchantData?.categories?.ratings)} edit={false} color2={"#F7B83C"} />
                    </div>

                    <div className="ratingSection">
                        <p>{currentMerchantData?.categories?.reviews}</p>
                    </div>
                </div>
            </section>
            <main className="chat-container">
                {newData.map((item, index) => {
                    return (
                        item &&
                        item !== "" && (
                            <div
                                className="chat-bubble bubble user-card"
                                key={item}
                                ref={(e) => (usersDomNodes[index] = e)}>
                                {item}
                            </div>
                        )
                    );
                })}
                <div
                    className="chat-header"
                    ref={(e) => {
                        imgRef = e;
                    }}>
                    <img src={logo || JoyupRobo} alt="joyup robo head" />
                </div>

                <div
                    className="button-container"
                    ref={(e) => {
                        buttonRef = e;
                    }}>
                    <Button text="Get Started" handleOnPress={() => toggle(!on)} isWideButton={true} />
                </div>
            </main>
        </StyledOuterContainer>
    );
};
