import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import FullStory from "react-fullstory";
import { createBrowserHistory } from "history";
import createGlobalStyle from "styled-components";
import store from "./redux/store";
import CacheBuster from "./utils/CacheBuster";
import Theme from "./utils/theme";
import { renderLoading } from "./utils/utils";

require("dotenv").config();

const history = createBrowserHistory();

const ORG_ID = "H5H26"; // Fill this in here
// const { Dashboard } = dashboardComponents;
// const { Modal } = modalComponents;

// RUN THE SLACK FUNCTION AS A CALLBACK FRO FULLSTORY

const Dashboard = lazy(() => import("./pages/orderPage"));
const Modal = lazy(() => import("./pages/modal"));
const GroupOrderLinkShare = lazy(() => import("./pages/orderPage/components/GroupOrderLinkShare"));
const GuestOrderSuccess = lazy(() => import("./pages/cart/components/GuestOrderSuccess"));
const StoreClosedAlert = lazy(() => import("./components/layout/StoreClosedAlert"));
const Cart = lazy(() => import("./pages/cart/components"));
const Locations = lazy(() => import("./pages/locations"));
const GiftCard = lazy(() => import("./pages/giftCard/GiftCard"));
const GiftBalance = lazy(() => import("./pages/giftCard/GiftCardBalance"));
const CustomerReview = lazy(() => import("./pages/customerReview/CustomerReview"));

const Loader = () => (
    <div
        style={{
            height: "100vh",
            width: "100vw",
            display: "grid",
            placeItems: "center"
        }}>
        {renderLoading()}
    </div>
);

const App = () => (
    // <CacheBuster>
    //     {({ loading, isLatestVersion, refreshCacheAndReload }) => {
    //         if (!loading && !isLatestVersion) {
    //             refreshCacheAndReload();
    //         }
    //         return (
    <Suspense fallback={<Loader />}>
        <Provider store={store}>
            <Theme>
                {/* <GlobalStyles /> */}
                <FullStory org={ORG_ID} />
                <Router history={history}>
                    <Switch>
                        <Route exact path="/" component={Dashboard} />
                        <Route exact path="/cyclebar" component={Dashboard} />
                        <Route exact path="/:url/menu" component={Dashboard} />
                        <Route exact path="/review" component={CustomerReview} />
                        <Route exact path="/modal" component={Modal} />
                        <Route exact path="/giftcard" component={GiftCard} />
                        <Route exact path="/order" component={GroupOrderLinkShare} />
                        <Route exact path="/cart" component={Cart} />
                        <Route exact path="/group_order_success" component={GuestOrderSuccess} />
                        <Route exact path="/locations" component={Locations} />
                        <Route exact path="/locations/:storename" component={Locations} />
                        <Route exact path="/giftcardbalance" component={GiftBalance} />
                    </Switch>
                </Router>
            </Theme>
        </Provider>
    </Suspense>
    //         );
    //     }}
    // </CacheBuster>
);

export default App;
