import actionTypes from "./actionTypes";

const {
    //-----
    SET_CURRENT_MERCHANTS_ID,
    REQUEST_CURRENT_MERCHANTS_ID,

    //-----
    CLEAR_CHECKOUT,

    //-----
    REQUEST_CURRENT_SHOP,
    SET_CURRENT_SHOP_SUCCESS,
    SET_CURRENT_SHOP_FAILURE,

    //-----
    REQUEST_CATEGORIES,
    SET_CATEGORIES_SUCCESS,
    SET_CATEGORIES_FAILURE,

    //-----
    SET_FOCUSED_CATEGORY,

    //-----
    SET_ORDER_TYPE,

    //-----
    HANDLE_ITEM_COUNT_CHANGE,

    //-----
    REQUEST_USER_ADDRESS,
    SET_USER_ADDRESS_SUCCESS,
    SET_USER_ADDRESS_FAILURE,
    SET_USER_FACEBOOK_ID,
    SET_NAME_OF_GUEST,

    ADD_ITEM_TO_CHECKOUT,

    HANDLE_REMOVE_ITEM_FROM_CART,

    //-----
    REQUEST_OPTIONS_DATA_FOR_FOCUSED_CATEGORY,
    SET_OPTIONS_DATA_FOR_FOCUSED_CATEGORY_SUCCESS,
    SET_OPTIONS_DATA_FOR_FOCUSED_CATEGORY_FAILURE,

    RESET_HAS_CART_CHANGED
} = actionTypes;

//-----------------------------
//MERCHANT ID
//-----------------------------
export const loadCurrentMerchantsId = () => ({
    type: REQUEST_CURRENT_MERCHANTS_ID
});

export const setCurrentMerchantsIdSuccess = (payload) => ({
    payload,
    type: SET_CURRENT_MERCHANTS_ID
});

//-----------------------------
//MERCHANT SHOP
//-----------------------------
export const loadMerchantsCurrentShop = (payload) => ({
    payload,
    type: REQUEST_CURRENT_SHOP
});

export const setMerchantsCurrentShopSuccess = (payload) => ({
    payload,
    type: SET_CURRENT_SHOP_SUCCESS
});

export const setMerchantsCurrentShopFailure = (payload) => ({
    payload,
    type: SET_CURRENT_SHOP_FAILURE
});

//-----------------------------
//CATEGORIES / ITEMS
//-----------------------------
export const loadItemsCategories = (payload) => ({
    payload,
    type: REQUEST_CATEGORIES
});

export const setItemsCategoriesSuccess = (payload) => ({
    payload,
    type: SET_CATEGORIES_SUCCESS
});

export const setItemsCategoriesFailure = (payload) => ({
    payload,
    type: SET_CATEGORIES_FAILURE
});

export const setFocusedCategories = (payload) => ({
    payload,
    type: SET_FOCUSED_CATEGORY
});

export const handleLoadOptionsDataForFocusedCategory = (payload) => ({
    payload,
    type: REQUEST_OPTIONS_DATA_FOR_FOCUSED_CATEGORY
});

export const setOptionsDataForFocusedCategorySuccess = (payload) => ({
    payload,
    type: SET_OPTIONS_DATA_FOR_FOCUSED_CATEGORY_SUCCESS
});

export const setOptionsDataForFocusedCategoryFailure = (payload) => ({
    payload,
    type: SET_OPTIONS_DATA_FOR_FOCUSED_CATEGORY_FAILURE
});

//-----------------------------
//CHECKOUT/CART
//-----------------------------
export const clearRedundantCheckout = () => ({
    type: CLEAR_CHECKOUT
});

export const addItemToCheckout = (payload) => ({
    payload,
    type: ADD_ITEM_TO_CHECKOUT
});

export const handleItemCountChange = (payload) => {
    return {
        payload,
        type: HANDLE_ITEM_COUNT_CHANGE
    };
};

export const handleRemoveItemfromCart = (id) => ({
    payload: id,
    type: HANDLE_REMOVE_ITEM_FROM_CART
});

export const handleResetHasCartChanged = () => ({
    type: RESET_HAS_CART_CHANGED
});

//-----------------------------
//USER DATA
//-----------------------------
export const handleLoadUserAddress = (payload) => ({
    payload,
    type: REQUEST_USER_ADDRESS
});

export const setUserAddressSuccess = (payload) => ({
    payload,
    type: SET_USER_ADDRESS_SUCCESS
});

export const setUserAddressFailure = (payload) => ({
    payload,
    type: SET_USER_ADDRESS_FAILURE
});

export const setUserFacebookId = (payload) => ({
    payload,
    type: SET_USER_FACEBOOK_ID
});

export const setNameOfGuest = (payload) => ({
    payload,
    type: SET_NAME_OF_GUEST
});

//-----------------------------
//ORDER TYPE
//-----------------------------
export const setOrderType = (payload) => ({
    payload,
    type: SET_ORDER_TYPE
});
