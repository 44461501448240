import actionTypes from './actionTypes';

const {
    GET_MERCHANTS_DATA,
    SET_MERCHANTS_DATA_SUCCESS,
    SET_MERCHANTS_DATA_FAILURE,
    REQUEST_LOAD_MERCHANTS_DATA,
    HANDLE_CLEAR_CATEGORIES,
    HANDLE_CLEAR_CATEGORY_FOCUSED,
    HANDLE_CLEAR_CURRENT_SHOP,
} = actionTypes

export const loadMerchantsData = () => ({
    type: REQUEST_LOAD_MERCHANTS_DATA
})

export const setMerchantsDataSuccess = payload => ({
    payload,
    type: SET_MERCHANTS_DATA_SUCCESS
})

export const setMerchantsDataFailure = payload => ({
    payload,
    type: SET_MERCHANTS_DATA_FAILURE
})
