/* eslint-disable react/jsx-fragments */
/* eslint-disable camelcase */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";
import uuid from "uuid";

import {
    styles,
    TextBoxDivStyle,
    LabelButtonContainer,
    ArrowImage,
    StyledAddImage,
    StyledMinusImage,
    StyledModalImageContainer,
    StyledItemDescriptionContainer,
    StyledScrollView,
    StyledModalContainer,
    StyledFixedBottom,
    StyledModalMain,
    StyledBottomModal,
    StyledButtonPosition,
    StyledCountContainer,
    StyledFixedButton,
    StyledModalClose,
    StyledClosedModalStoreNotification
} from "./styles";
import { Button, ModifierRadioButton } from "../../../components";

import ModifiersList from "./ModifiersList";
import OptionsList from "./OptionsList";
import arrowImage from "../../../assets/right.svg";

import useAddToCart from "../../../components/hooks/useAddToCart";
import { getUrlParams } from "#/utils/utils";

const { modalClose } = styles;

const Modal = ({
    handleCloseModal,
    modalData,
    merchantID,
    addItemToCheckout,
    optionsValue,
    setOptionsValue,
    handleItemCountChange,
    storeVendor,
    isStoreOpen,
    scheduledOrderTime,
    handleSetIsSlidingCartOpen,
    storeTemplate,
    activeCategoryPromo,
    setIsRecommendationShown,
    isRecommendationAvailable,
    ...props
}) => {
    let merchantId = getUrlParams().merchantId;

    const {
        scrollViewRef,
        handleOptionsSelected,
        handleAttributeSelected,
        modifiedModifiersData,
        loading,
        optionsRadioValue,
        handleModifierRadioSelected,
        handleCheckBoxToggle,
        checkboxDisabled,
        specialInstructionModal,
        handleSpecialInstructionModal,
        specialInstructions,
        handleSpecialInstructions,
        handleChangeCountValue,
        countValue,
        handleChanged,
        handleAddItem,
        buttonDisabled,
        handleDisplayStoreClosedToast,
        handleCheckMinimumOrderItem,
        currentMerchantsShop,
        attributeData
    } = useAddToCart(
        false,
        modalData,
        storeVendor,
        optionsValue,
        merchantID,
        isStoreOpen,
        scheduledOrderTime,
        setOptionsValue,
        addItemToCheckout,
        handleItemCountChange,
        handleCloseModal,
        activeCategoryPromo,
        setIsRecommendationShown,
        isRecommendationAvailable
    );

    const { name, options, image, description } = modalData;

    // refactor ATTRIBUTE DATA

    const onAddItem = (e) => {
        if (storeTemplate === 2 || storeTemplate === 3 || storeTemplate === 4) {
            handleSetIsSlidingCartOpen();
        }
        handleAddItem(e);
    };

    let maxInstrCha = 180;

    if (merchantId === "6075e037666fec40b0bd0773") {
        maxInstrCha = 20;
    }

    const renderInstColor = () => {
        if (maxInstrCha - specialInstructions.length <= 2) {
            return "#ff0000";
        } else {
            return "#000";
        }
    };

    return (
        <StyledModalContainer>
            <StyledModalMain>
                <div className="top_modal">
                    <StyledModalClose onClick={(e) => handleCloseModal(e)} tabIndex="0" focusable="true" />
                    <StyledScrollView isStoreOpen={isStoreOpen} ref={scrollViewRef} decelerationRate={0.5}>
                        {image ? (
                            <StyledModalImageContainer storeTemplate={storeTemplate}>
                                <img src={image} className="dashboardImage" alt="item image" />
                            </StyledModalImageContainer>
                        ) : null}

                        <div className="description_container">
                            <StyledItemDescriptionContainer>
                                <p className="itemTopic">{name}</p>
                                {description ? <p className="itemDescription">{description}</p> : null}
                            </StyledItemDescriptionContainer>

                            <OptionsList
                                options={options}
                                optionsValue={optionsValue}
                                setOptionsValue={setOptionsValue}
                                handleOptionsSelected={handleOptionsSelected}
                                storeVendor={storeVendor}
                                modifiedModifiersData={modifiedModifiersData}
                            />

                            {attributeData.length > 0 ? (
                                <OptionsList
                                    options={attributeData}
                                    optionsValue={optionsValue}
                                    setOptionsValue={setOptionsValue}
                                    handleOptionsSelected={handleOptionsSelected}
                                    storeVendor={storeVendor}
                                    modifiedModifiersData={modifiedModifiersData}
                                />
                            ) : null}

                            <ModifiersList
                                loading={loading}
                                modifiedModifiersData={modifiedModifiersData}
                                ModifierRadioButton={ModifierRadioButton}
                                optionsRadioValue={optionsRadioValue}
                                optionsValue={optionsValue}
                                handleModifierRadioSelected={handleModifierRadioSelected}
                                handleCheckBoxToggle={handleCheckBoxToggle}
                                checkboxDisabled={checkboxDisabled}
                                storeVendor={storeVendor}
                            />
                        </div>
                    </StyledScrollView>
                </div>
                <StyledFixedBottom>
                    <StyledBottomModal specialInstructionModal={specialInstructionModal}>
                        <StyledButtonPosition>
                            <LabelButtonContainer
                                isTextBoxOpen={specialInstructionModal}
                                onClick={handleSpecialInstructionModal}>
                                <label htmlFor="special-instruction">Add Special Instructions</label>
                                <ArrowImage src={arrowImage} isTextBoxOpen={specialInstructionModal} />
                            </LabelButtonContainer>

                            <TextBoxDivStyle specialInstructionModal={specialInstructionModal}>
                                <textarea
                                    name="special-instruction"
                                    id="special-instruction"
                                    placeholder={`Share your notes on this item up to ${maxInstrCha} characters`}
                                    cols="20"
                                    rows="10"
                                    maxLength={maxInstrCha}
                                    value={specialInstructions}
                                    onChange={handleSpecialInstructions}
                                />
                                {specialInstructions.length > 0 && (
                                    <h6 style={{ textAlign: "right", fontSize: "14px", color: renderInstColor() }}>
                                        {specialInstructions.length}/{maxInstrCha}
                                    </h6>
                                )}
                            </TextBoxDivStyle>
                            <div
                                style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginBottom: "10px"
                                }}>
                                <StyledCountContainer>
                                    <div>Quantity</div>

                                    <StyledMinusImage
                                        alt="increase count Item"
                                        onClick={(e) => handleChangeCountValue(e, "minus")}
                                    />
                                    <div>
                                        <input
                                            type="tel"
                                            className="countNumber"
                                            value={countValue}
                                            onChange={handleChanged}
                                        />
                                    </div>
                                    <StyledAddImage
                                        alt="reduce count item"
                                        onClick={(e) => handleChangeCountValue(e, "add")}
                                    />
                                </StyledCountContainer>
                                <StyledFixedButton
                                    isWideButton
                                    text="Add item"
                                    onClick={onAddItem}
                                    disabled={buttonDisabled || (!isStoreOpen && !scheduledOrderTime)}
                                    id="btn-add-item">
                                    Add Item
                                </StyledFixedButton>
                            </div>
                            {!isStoreOpen && !scheduledOrderTime?.time && (
                                <StyledClosedModalStoreNotification>
                                    {handleDisplayStoreClosedToast()}
                                </StyledClosedModalStoreNotification>
                            )}
                            {handleCheckMinimumOrderItem(currentMerchantsShop.categories.minimum_delivery_amount) && (
                                <StyledClosedModalStoreNotification>
                                    {handleCheckMinimumOrderItem(
                                        currentMerchantsShop.categories.minimum_delivery_amount
                                    )}
                                </StyledClosedModalStoreNotification>
                            )}
                        </StyledButtonPosition>
                    </StyledBottomModal>
                </StyledFixedBottom>
            </StyledModalMain>
            <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} lightBackground />
        </StyledModalContainer>
    );
};

export default Modal;
