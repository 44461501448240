import actionTypes from "./actionTypes";

const {
    POST_USER_PROFILE_SUCCESS,
    POST_USER_PROFILE_FAILURE,
    POST_USER_PROFILE,

    REQUEST_LOAD_USER_PROFILE,

    SET_USER_PROFILE_SUCCESS,
    SET_USER_PROFILE_FAILURE,

    REQUEST_WHITELIST_DOMAINS,

    SET_WHITELIST_DOMAINS_SUCCESS,
    SET_WHITELIST_DOMAINS_FAILURE,

    REQUEST_LOAD_GUEST_ORDER_SESSION,

    SET_GUEST_ORDER_SESSION_DATA_SUCCESS,
    SET_GUEST_ORDER_SESSION_DATA_FAILURE,

    SET_ADD_ITEM_TO_GUEST_ORDER
} = actionTypes;

//------------------------------------------------
//User related actions
//------------------------------------------------
export const handleUserProfile = (payload) => ({
    payload,
    type: POST_USER_PROFILE
});

export const PostUserProfileSuccess = (payload) => ({
    payload,
    type: POST_USER_PROFILE_SUCCESS
});

export const PostUserProfileFailure = (payload) => ({
    payload,
    type: POST_USER_PROFILE_FAILURE
});

export const handleLoadUserProfile = (payload) => ({
    payload,
    type: REQUEST_LOAD_USER_PROFILE
});

export const setUserProfileSuccess = (payload) => ({
    payload,
    type: SET_USER_PROFILE_SUCCESS
});

export const setUserProfileFailure = (payload) => ({
    payload,
    type: SET_USER_PROFILE_FAILURE
});

//------------------------------------------------
//Domain related actions
//------------------------------------------------
export const handleDomainsWhitelisting = (payload) => ({
    payload,
    type: REQUEST_WHITELIST_DOMAINS
});

export const setWhitelistDomainsSuccess = (payload) => ({
    payload,
    type: SET_WHITELIST_DOMAINS_SUCCESS
});

export const setWhitelistDomainsFailure = (payload) => ({
    payload,
    type: SET_WHITELIST_DOMAINS_FAILURE
});

//------------------------------------------------
//Guest related actions
//------------------------------------------------
export const handleLoadGuestOrderSession = (payload) => ({
    payload,
    type: REQUEST_LOAD_GUEST_ORDER_SESSION
});

export const setGuestOrderSessionSuccess = (payload) => ({
    payload,
    type: SET_GUEST_ORDER_SESSION_DATA_SUCCESS
});

export const setGuestOrderSessionFailure = (payload) => ({
    payload,
    type: SET_GUEST_ORDER_SESSION_DATA_FAILURE
});
export const addItemToGuestOrder = (payload) => ({
    payload,
    type: SET_ADD_ITEM_TO_GUEST_ORDER
});
