import { isArrayEmpty } from "../../utils/utils";

export const STRINGS = {
    IMAGE_DESCRIPTION: "Description of the image",
    MODIFIERS: "Modifiers",
    OPTIONAL: "Optional",
    OPTIONS: "Options",
    REQUIRED: "required",
    TITLE: "Title",
    USD: "$"
};

// used to get individual modifiers data
export const getCheckedCheckBox = (modalData, modifierData, storeVendor) => {
    const checkedItem = [];
    let modifiedData = {};

    if (modalData && modifierData !== undefined && Object.values(modifierData).length > 0) {
        // get all the modifier that allows multiple selection
        const getMultipleItems = modifierData.filter(
            (item) =>
                item?.selection_type === "MULTIPLE" ||
                item?.minimum_amount === 0 ||
                (item?.minimum_amount > 0 && item?.maximum_amount > 0 && !item?.admin_mod_key) ||
                (Array.isArray(item.quantity_rules) && item.quantity_rules.length === 0) ||
                (Array.isArray(item.quantity_rules) &&
                    item.quantity_rules.length > 0 &&
                    item?.quantity_rules[0]?.min_allowed === 0) ||
                !item.quantity_rules ||
                item.quantity_rules === null ||
                (item?.quantity_rules &&
                    item?.quantity_rules[0]?.min_allowed > 0 &&
                    item?.quantity_rules[0]?.min_allowed < 0)
        );

        getMultipleItems.forEach((item) => item.modifiers.map((item2) => (item2.category = item.name)));
        // filter out all the items which is not selected
        const modArray = getMultipleItems.flatMap((item) => item.modifiers.filter((item2) => item2.isChecked));

        if (!isArrayEmpty(modArray) && modArray.length > 0) {
            modArray.map((item) => {
                const { id, modifier_data, isChecked, category } = item;

                let customTitle = "";

                if (storeVendor === "CHOWLY") {
                    //Loop through all returned modifiers
                    modifierData.forEach((modifier) => {
                        //Loop through the specfic items in the modifiers
                        modifier.modifiers.forEach((mod) => {
                            if (mod.id === id) {
                                customTitle = modifier.title;
                            }
                        });
                    });
                }

                let name;
                let price;
                if (storeVendor === "ordermark" || storeVendor === "otter" || storeVendor === "CHOWLY") {
                    name = item.title;
                    price =
                        storeVendor === "CHOWLY"
                            ? parseFloat(item.price_rules[0].price.toFixed(2))
                            : item.price_rules[0].price / 100;
                } else {
                    name = item.modifier_data ? item.modifier_data.name : item.name;
                    price =
                        item.modifier_data && item.modifier_data.price_money
                            ? item.modifier_data.price_money.amount / 100
                            : item?.price || 0;
                }
                modifiedData = {
                    ...modifiedData,
                    id,
                    isChecked,
                    name,
                    category: storeVendor === "CHOWLY" ? customTitle : category,
                    price: price || 0
                };

                checkedItem.push(modifiedData);
                return checkedItem;
            });
            return checkedItem;
        }
        return checkedItem;
    }
    return checkedItem;
};

export const handleOrderMarkPrice = (price_rules) => {
    if (price_rules.length === 1) {
        return price_rules[0];
    } else {
        let priceRules = price_rules.filter((item) => item.contexts.length > 0);
        return priceRules[0];
    }
};
