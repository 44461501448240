/* eslint-disable react/jsx-fragments */
import React, { useState, useEffect } from "react";
import { View, Text } from "react-native";

import { styles } from "./styles";
import { RadioButton } from "../../../components";
import { STRINGS } from "../constant";

const { OPTIONS, REQUIRED, OPTIONAL, USD } = STRINGS;

const { currency, itemContainer, optionsContainer, optionsHeaderText, optionsText, optionsTextRequired } = styles;

const OptionsList = (props) => {
    const { options, optionsValue, setOptionsValue, handleOptionsSelected } = props;
    const renderOptionsList = () => {
        if (options?.length > 1) {
            return (
                <>
                    <View style={optionsHeaderText}>
                        <Text style={optionsText}>{OPTIONS}</Text>
                        <Text style={optionsTextRequired}>{REQUIRED}</Text>
                    </View>

                    <RadioButton
                        data={options}
                        optionsValue={optionsValue}
                        setOptionsValue={setOptionsValue}
                        handleOptionsSelected={handleOptionsSelected}
                    />
                </>
            );
        }
        if (options?.length === 1) {
            return (
                <View style={itemContainer}>
                    <Text>{options[0].name || ""}</Text>
                    <Text style={currency}>
                        {USD}
                        {parseFloat(options[0].price).toFixed(2)}
                    </Text>
                </View>
            );
        }
        if (options?.length === 0 && optionsValue?.length === 1) {
            return (
                <View style={itemContainer}>
                    <Text>{optionsValue[0].name || ""}</Text>
                    <Text style={currency}>
                        {USD}
                        {parseFloat(optionsValue[0].price).toFixed(2)}
                    </Text>
                </View>
            );
        }
    };

    return (
        <View style={optionsContainer}>
            {/* item options */}
            {renderOptionsList()}
        </View>
    );
};

export default OptionsList;
