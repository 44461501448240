const actionTypes = {
    GET_MERCHANTS_DATA: 'GET_MERCHANTS_DATA',
    REQUEST_LOAD_MERCHANTS_DATA: 'REQUEST_LOAD_MERCHANTS_DATA',
    SET_MERCHANTS_DATA_SUCCESS: 'SET_MERCHANTS_DATA_SUCCESS,',
    SET_MERCHANTS_DATA_FAILURE: 'SET_MERCHANTS_DATA_FAILURE,',
    // HANDLE_CLEAR_CATEGORIES: 'HANDLE_CLEAR_CATEGORIES',
    // HANDLE_CLEAR_CATEGORY_FOCUSED: 'HANDLE_CLEAR_CATEGORY_FOCUSED',
    // HANDLE_CLEAR_CURRENT_SHOP: 'HANDLE_CLEAR_CURRENT_SHOP'
}

export default actionTypes;
