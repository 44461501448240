import React, { memo, Fragment, useEffect, useCallback } from "react";
import uuid from "uuid";

import { RenderItemData } from "../../../ItemList";

import {
    StyledCartSection,
    StyledNavBar,
    StyledContentContainer,
    StyledDesktopBody,
    StyledDashboardSection,
    StyledItemSection,
    StyleMenuItem
} from "../Desktop.style";
import { getUrlParams } from "../../../../../../utils/utils";

import { returnedDesktopCatItemDomNodes } from "#/utils/domManipulations";

const CategoryItems = ({
    currentActiveItems,
    handleModalOpenOrAddItem,
    userProfile,
    storeTemplate,
    showCategoryPromoBasedOnWeb
}) => {
    const renderNodes = () => {
        if (getUrlParams().merchantId == "6075e037666fec40b0bd0773") {
            // AGAS RESTAURANT
            return returnedDesktopCatItemDomNodes(
                currentActiveItems,
                "curries",
                "Chicken Curries",
                showCategoryPromoBasedOnWeb,
                handleModalOpenOrAddItem,
                userProfile,
                storeTemplate
            );
        } else if (getUrlParams().merchantId == "609396e38b525c2d2e92e0fa") {
            // GT STORES
            return returnedDesktopCatItemDomNodes(
                currentActiveItems,
                "superfood",
                "Superfood Smoothies - Large",
                showCategoryPromoBasedOnWeb,
                handleModalOpenOrAddItem,
                userProfile,
                storeTemplate
            );
        } else {
            return (
                currentActiveItems &&
                currentActiveItems.map((categoryItem, i) => {
                    const { id, items, name } = categoryItem;
                    return (
                        <div id={name} key={id} className="category-wrapper">
                            <p className="item-name" id={name}>
                                {categoryItem.promo_text &&
                                (showCategoryPromoBasedOnWeb || getUrlParams().promo === "true")
                                    ? categoryItem.promo_text + " - " + name
                                    : name}
                            </p>
                            <StyledItemSection key={id}>
                                {items.map((data, index) => (
                                    <RenderItemData
                                        key={uuid()}
                                        item={data}
                                        handleModalOpenOrAddItem={handleModalOpenOrAddItem}
                                        menu={currentActiveItems}
                                        index={index}
                                        userProfile={userProfile}
                                        storeTemplate={storeTemplate}
                                    />
                                ))}
                            </StyledItemSection>
                        </div>
                    );
                })
            );
        }
    };

    return <StyledDashboardSection storeTemplate={storeTemplate}>{renderNodes()}</StyledDashboardSection>;
};

export default memo(CategoryItems);
