/* eslint-disable camelcase */
import React from "react";
import { View, Text, Image, CheckBox, ActivityIndicator } from "react-native";
import { STRINGS } from "../constant";
import { styles } from "./styles";
import { getUrlParams, renderLoading } from "../../../utils/utils";

const { OPTIONS, REQUIRED, OPTIONAL, USD } = STRINGS;

const {
    checkboxRow,
    checkboxRowPad,
    checkbox,
    checkboxlabel,
    currency,
    loadingStyle,
    optionsContainer,
    optionsHeaderText,
    optionsText,
    optionsTextRequired
} = styles;

export const RenderSquareMenu = ({ props }) => {
    const {
        loading,
        modifiedModifiersData,
        ModifierRadioButton,
        optionsRadioValue,
        optionsValue,
        handleModifierRadioSelected,
        handleCheckBoxToggle,
        checkboxDisabled,
        storeVendor
    } = props;

    return modifiedModifiersData?.map((modifier) => {
        const { name, selection_type, modifiers } = modifier;
        return (
            <View key={name} style={optionsContainer}>
                <View style={optionsHeaderText}>
                    <Text style={optionsText}>{name}</Text>
                    <Text style={optionsTextRequired}>{selection_type === "SINGLE" ? REQUIRED : OPTIONAL}</Text>
                </View>

                {modifiers?.map((item) => {
                    const { id, modifier_data, isChecked, disabled } = item;

                    const { name, price_money } = modifier_data;
                    return selection_type === "SINGLE" ? (
                        <ModifierRadioButton
                            name={name}
                            price={price_money.amount / 100}
                            id={id}
                            key={id}
                            item={item}
                            optionsRadioValue={optionsRadioValue}
                            modifier_name={modifier.name}
                            handleOptionsSelected={
                                optionsValue !== ""
                                    ? (e) => handleModifierRadioSelected(id, item, modifier.name, e)
                                    : null
                            }
                        />
                    ) : (
                        <View style={checkboxRow} key={id}>
                            <View style={checkboxRowPad}>
                                <CheckBox
                                    value={isChecked}
                                    onValueChange={() => handleCheckBoxToggle(id, isChecked, modifier)}
                                    style={checkbox}
                                    disabled={disabled}
                                />

                                <Text
                                    style={disabled ? [checkboxlabel, { color: "rgb(204, 214, 221)" }] : checkboxlabel}
                                    onPress={disabled ? null : () => handleCheckBoxToggle(id, isChecked, modifier)}
                                    disabled={disabled}
                                >
                                    {item.title || name}
                                </Text>
                            </View>
                            <Text style={currency}>
                                {USD}
                                {price_money
                                    ? Number.parseFloat(price_money.amount / 100).toFixed(2)
                                    : Number.parseFloat(0).toFixed(2)}
                                {/* {handleDisplayPrice()} */}
                            </Text>
                        </View>
                    );
                })}
            </View>
        );
    });
};
