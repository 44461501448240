import { takeEvery, call, put, all, takeLatest } from "redux-saga/effects";
import actionTypes from "./actionTypes";
import {
    setCurrentMerchantsIdSuccess,
    setMerchantsCurrentShopSuccess,
    setMerchantsCurrentShopFailure,
    setItemsCategoriesSuccess,
    setItemsCategoriesFailure,
    setOptionsDataForFocusedCategorySuccess,
    setOptionsDataForFocusedCategoryFailure
} from "./actions";
import { getUrlParams, baseUrl } from "../../utils/utils";

//------------------------------------
//Helpers
//------------------------------------
const {
    REQUEST_CURRENT_MERCHANTS_ID,
    REQUEST_CURRENT_SHOP,
    REQUEST_CATEGORIES,
    REQUEST_USER_ADDRESS,
    REQUEST_OPTIONS_DATA_FOR_FOCUSED_CATEGORY
} = actionTypes;

//------------------------------------
//Merchant ID
//------------------------------------
function* handleSetCurrentMerchantId() {
    const currentMerchantId = getUrlParams().merchantId;
    yield put(setCurrentMerchantsIdSuccess(currentMerchantId));
}

//------------------------------------
//Merchant Shop
//------------------------------------
function* handleSetMerchantsCurrentShop({ payload }) {
    const response = yield call(fetch, `${baseUrl}/merchant/shop/${payload}`);
    if (response.status >= 200 && response.status <= 299) {
        try {
            const responseData = yield response.json();
            yield put(setMerchantsCurrentShopSuccess(responseData));
        } catch (error) {
            yield put(setMerchantsCurrentShopFailure(error));
        }
    } else {
        yield put(setMerchantsCurrentShopFailure(response.statusText));
    }
}

//------------------------------------
//Merchants ITEMS and CATEGPRIES
//------------------------------------
function* handleSetItemsCategories({ payload }) {
    const { merchantId, locationId } = payload;

    try {
        const urlLink =
            process.env.NODE_ENV === "development"
                ? 
                // `http://localhost:3300/menu/square/${merchantId}/${locationId}`
                `https://waitstaff.joyup.me/menu/square/${merchantId}/${locationId}`
                : `https://waitstaff.joyup.me/menu/square/${merchantId}/${locationId}`;
        const response = yield locationId !== null && call(fetch, urlLink);
        if (response.status >= 200 && response.status <= 299) {
            const responseData = yield response.json();
            yield put(setItemsCategoriesSuccess(responseData));
        } else {
            yield put(setItemsCategoriesFailure(response.statusText));
        }
    } catch (error) {
        console.log(error.message);
        yield put(setItemsCategoriesFailure(error.message));
    }
}

//------------------------------------
//Get modifier data
//------------------------------------
function* fetchModifiersData(itemId, merchantID) {
    const CATEGORY_LINK = `https://pay.joyup.me/square/item/${itemId}/modifiers/${merchantID}`;
    const response = yield call(fetch, CATEGORY_LINK);
    if (response.status >= 200 && response.status <= 299) {
        try {
            const responseData = yield response.json();
            return responseData;
        } catch (error) {
            yield put(setOptionsDataForFocusedCategoryFailure(error));
        }
    } else {
        yield put(setOptionsDataForFocusedCategoryFailure(response.statusText));
    }
}

function* handleCategoryDataCall({ payload }) {
    const { itemIds, merchantID } = payload;

    const results = yield all(itemIds.map((id) => call(fetchModifiersData, id, merchantID)));
    let newModifiersArray = [];
    itemIds.map((item, index) => {
        newModifiersArray = [...newModifiersArray, { id: item, data: results[index] }];
    });

    yield put(setOptionsDataForFocusedCategorySuccess({ newModifiersArray, ...payload }));
}

export default function* watchDashboardSagas() {
    yield takeEvery(REQUEST_CURRENT_MERCHANTS_ID, handleSetCurrentMerchantId);
    yield takeEvery(REQUEST_CURRENT_SHOP, handleSetMerchantsCurrentShop);
    yield takeLatest(REQUEST_CATEGORIES, handleSetItemsCategories);
    yield takeEvery(REQUEST_OPTIONS_DATA_FOR_FOCUSED_CATEGORY, handleCategoryDataCall);
}
