import React from 'react';
import { ActivityIndicator } from 'react-native';
import styled from 'styled-components';
import { ReactComponent as LoaderImg } from '../../assets/loader.svg';

const StyledActivityIndicatorContainer = styled.div`
width: 100%;
display: grid;
place-items: center;
`;

const StyledActivityIndicator = styled(LoaderImg)`
.spinner {
padding:20px;
    width: ${props => props.width || '30px'};
    height: ${props => props.width || '30px'};
  -webkit-animation: rotator 1.4s linear infinite;
          animation: rotator 1.4s linear infinite;
}

@-webkit-keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
}

@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
          animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@-webkit-keyframes colors {
  0% {
    stroke: ${props => props?.theme?.colors?.primary || 'rgb(105, 225, 198) '};
  }
  25% {
    stroke: ${props => props?.theme?.colors?.primary || 'rgb(105, 225, 198) '};
  }
  50% {
    stroke: ${props => props?.theme?.colors?.primary || 'rgb(105, 225, 198) '};
  }
  75% {
    stroke: ${props => props?.theme?.colors?.primary || 'rgb(105, 225, 198) '};
  }
  100% {
    stroke: ${props => props?.theme?.colors?.primary || 'rgb(105, 225, 198) '};
  }
}

@keyframes colors {
  0% {
    stroke: ${props => props?.theme?.colors?.primary || 'rgb(105, 225, 198) '};
  }
  100% {
    stroke: ${props => props?.theme?.colors?.primary || 'rgb(105, 225, 198) '};
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg);
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg);
  }
}

`;

export const Loader = ({ color, width }) => (
    <StyledActivityIndicatorContainer>
        <StyledActivityIndicator
            color={color || 'rgb(105, 225, 198'}
            width={width}
            height={width}
        />
    </StyledActivityIndicatorContainer>
);
