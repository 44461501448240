/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Text, View, Dimensions } from "react-native";
import uuid from "uuid";
import { bindActionCreators } from "redux";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";
import axios from "axios";
import { getIsAddressPosting, getHasAddressBeenPosted, getError, getAddress, getGuestSession } from "../selectors";
import { handleUserProfile, handleDomainsWhitelisting, handleLoadUserProfile, addItemToGuestOrder } from "../actions";
import { StyledEditIcon } from "#/pages/orderPage/components/styles";
import { Button } from "../../../components";
import {
    getTotalCartPrice,
    USD,
    getUrlParams,
    isObjectEmpty,
    baseUrl,
    handlePsId,
    getFreeItem,
    convert24hrTo12hrTime
} from "../../../utils/utils";
import { handleItemCountChange, handleRemoveItemfromCart, handleResetHasCartChanged } from "../../orderPage/actions";
import {
    styles,
    StyledModalOuterContainer,
    StyledCartItemContainer,
    StyledCartContainer,
    StyledTotalTab,
    StyledButtonContainer,
    StyledBackImage,
    StyledClosedStoreNotification,
    StyledAddMoreItemButton
} from "./styles";

import { STRINGS } from "../constant";
import { GuestOrderSuccess } from "./GuestOrderSuccess";
import { GuestOrderConfirmation } from "./GuestOrderConfirmation";
import { Loader } from "../../../components/loader/Loader";
import { StyledFixedBottom } from "../../modal/components/styles";
import useProceedToCheckout from "../../../components/hooks/useProceedToCheckout";
const {
    COLON,
    DASH,
    EMPTY_STATE_TEXT,
    YOUR_CART,
    ADD,
    SUBTRACT,
    MAKE_AN_ORDER,
    ADD_TO_GROUP_CHECKOUT,
    EDIT_DELIVERY_ADDRESS,
    BACK_TO_DASHBOARD
} = STRINGS;

const { cartHeaderText, cartDescription, cartFoodName, cartPrice, modifierText, emptyStateContainer } = styles;

const params = getUrlParams();

const { locationId, merchantId, pageId, psId, orderType, channel } = params;

const { height, width } = Dimensions.get("window");
// this formarts chosen modifiers as paragraphs
/**
 * @param {array} data this is items in the checkout
 *@returns {string} this returns the list of optional modifiers in a paragraph format eg Crystal
 * Boba , Lychee Jelly, Mango Popping Boba, Grass Jelly
 */
const formartModifiersAsParagraph = (data) => {
    let newItem = [];
    if (data?.length > 0 && data !== undefined) {
        data.map((order) => {
            if (order.name !== undefined) {
                let newOrderItem = order.name.split("||");
                let transformedItem = newOrderItem[0] + "-" + newOrderItem[newOrderItem.length - 1];

                transformedItem = order.category ? ` ${order.category} - ${transformedItem}` : transformedItem;
                let newTransformedOrder = transformedItem.split("-");
                let newTransformedOrder2 =
                    newTransformedOrder[0] + "-" + newTransformedOrder[newTransformedOrder.length - 1];

                newItem = [...newItem, newTransformedOrder2];
                return newItem;
            }
        });
        return `With ${newItem.join(", ").trim()}`;
    }
    return newItem;
};

const Cart = ({
    address,
    guestSession,
    currentMerchantsShop,
    hasCartChanged,
    orderType,
    guestName,
    scheduledOrderTime,
    error,
    handleItemCountChange,
    handleRemoveItemfromCart,
    handleUserProfile,
    hasAddressBeenPosted,
    history,
    isAddressPosting,
    handleDomainsWhitelisting,
    handleLoadUserProfile,
    addItemToGuestOrder,
    storeVendor,
    handleClosePaymentModal,
    isPaymentModalOpen,
    windowSize,
    isStoreOpen,
    handleAddDataToGuest,
    handleCollatingCheckoutItems,
    handleResetHasCartChanged,
    handleOpenUpsellModal,
    activeCategoryPromo,
    onBreak,
    selectedOrderMethod,
    afterBreakFirstPickupTime,
    storeTemplate,
    setOrderTypeModalOpen
}) => {
    const {
        isPaymentProcessing,
        handleCountChange,
        handleSendDataToMessenger,
        handleCheckMinimumOrderItem,
        is_active,
        handleDisplayStoreClosedToast,
        isGuestOrderModalOpen,
        setIsGuestOrderModalOpen,
        isConfirmOrderOpen,
        handleCloseConfirmModal,
        handleContinueGroupOrder,
        handleCheckout,
        setIsPaymentProcessing
    } = useProceedToCheckout(
        currentMerchantsShop,
        hasCartChanged,
        storeVendor,
        isStoreOpen,
        handleRemoveItemfromCart,
        handleItemCountChange,
        orderType,
        handleResetHasCartChanged,
        guestSession,
        history,
        handleCollatingCheckoutItems,
        scheduledOrderTime,
        activeCategoryPromo
    );

    const handleFireCheckout = async () => {
        const { merchantId, locationId } = getUrlParams();
        //Get upsell items
        try {
            setIsPaymentProcessing(true);
            const response = await axios.get(
                `https://waitstaff.joyup.me/menu/get_upsell_items/${merchantId}/${locationId}`
            );

            if (response.data && response.data.chosenUpsellItems.length > 0) {
                setIsPaymentProcessing(false);
                handleOpenUpsellModal(response.data.chosenUpsellItems);
            } else {
                setIsPaymentProcessing(false);
                handleCheckout("credit");
            }
        } catch (err) {
            setIsPaymentProcessing(false);
            console.log(err, "THE ERROR");
        }
    };

    const renderPromoNote = () => {
        const { itemsInCheckout } = currentMerchantsShop;

        if (getUrlParams().deliveryType === "pickup" || !activeCategoryPromo || !itemsInCheckout) {
            return null;
        } else {
            const promoItem = getFreeItem(itemsInCheckout, activeCategoryPromo);

            if (!promoItem) {
                return null;
            } else {
                return (
                    <p className="discounted-item">
                        {activeCategoryPromo.promo_text} applied
                        <br />
                        <span>
                            ({promoItem.name} - ${parseFloat(promoItem.itemPrice).toFixed(2)})
                        </span>
                    </p>
                );
            }
        }
    };

    const renderOrderText = () => {
        if (onBreak) {
            return (
                <p>
                    <span style={{ textTransform: "capitalize" }}>{selectedOrderMethod}</span> for <span>Today</span> at{" "}
                    <span>{afterBreakFirstPickupTime?.label}</span>
                </p>
            );
        } else if (scheduledOrderTime?.label) {
            return (
                <p>
                    <span style={{ textTransform: "capitalize" }}>{selectedOrderMethod}</span> for{" "}
                    <span>{scheduledOrderTime.label}</span> at{" "}
                    <span>{convert24hrTo12hrTime(scheduledOrderTime?.time)}</span>
                </p>
            );
        } else {
            return (
                <p>
                    <span style={{ textTransform: "capitalize" }}>{selectedOrderMethod}</span> for Now
                </p>
            );
        }
    };

    return (
        <StyledModalOuterContainer isPaymentModalOpen={isPaymentModalOpen} windowSize={windowSize}>
            <div className="modal-content">
                <div className="item-body">
                    {width < 1024 && (
                        <StyledBackImage
                            onClick={(e) => (isPaymentProcessing ? null : handleClosePaymentModal(e))}
                            // style={backImage}
                            alt={BACK_TO_DASHBOARD}
                        />
                    )}

                    {/* <Text style={cartHeaderText}>{YOUR_CART}</Text> */}
                    <h2 className="cart-header">Your Cart</h2>

                    <div className="ordertype-section">
                        {renderOrderText()}
                        <StyledEditIcon onClick={setOrderTypeModalOpen} storeTemplate={storeTemplate} />
                    </div>
                    <StyledCartContainer>
                        <div className="cart-data">
                            {currentMerchantsShop && currentMerchantsShop.itemsInCheckout.length > 0 ? (
                                currentMerchantsShop.itemsInCheckout.map((item) => {
                                    const {
                                        id,
                                        itemPrice,
                                        modifiers,
                                        quantity,
                                        options,
                                        name,
                                        specialInstructions,
                                        price,
                                        optionsRadioValue,
                                        basePrice
                                    } = item;
                                    // console.log(item, "the item");
                                    // remove embelishments from name
                                    const newName = name.split("** -").join("").split("- Regular").join("").trim();

                                    const renderPrice = () => {
                                        if (itemPrice === "0.00") {
                                            return basePrice || "0.00";
                                        }
                                        return itemPrice
                                            ? itemPrice.toLocaleString()
                                            : price
                                            ? price.toLocaleString()
                                            : basePrice;
                                    };

                                    return (
                                        <StyledCartItemContainer key={uuid()}>
                                            <View style={cartDescription}>
                                                {/* title */}
                                                <View>
                                                    <Text style={cartFoodName}>
                                                        {name.split(" - ")[0]}
                                                        {specialInstructions !== ""
                                                            ? ` - ${specialInstructions.split(" - ")[1]}`
                                                            : null}
                                                    </Text>
                                                    <Text style={modifierText}>
                                                        {modifiers && formartModifiersAsParagraph(modifiers)}
                                                    </Text>
                                                </View>
                                                {/* modifier and option */}
                                                {optionsRadioValue?.length > 0 &&
                                                    optionsRadioValue.map((item2) => (
                                                        <Text key={uuid()} style={modifierText}>
                                                            {item2.category.split("(")[0]}
                                                            {DASH}
                                                            {item2.name}
                                                        </Text>
                                                    ))}

                                                {/* price */}
                                                <Text style={cartPrice}>
                                                    {USD}
                                                    {renderPrice()}
                                                </Text>
                                            </View>

                                            <div className="itemCountContainer">
                                                {/* <p><div onClick={() => handleCountChange(id, 'DELETE')}>close</div></p> */}
                                                <div className="cartCountContainer">
                                                    {/* button counter */}
                                                    <p
                                                        className="cartCountMinus"
                                                        onClick={() =>
                                                            handleCountChange(id, SUBTRACT, handleSendDataToMessenger)
                                                        }>
                                                        {DASH}
                                                    </p>
                                                    <p className="cartCountNumber">{quantity}</p>
                                                    <p
                                                        className="cartCountAdd"
                                                        onClick={() =>
                                                            handleCountChange(id, ADD, handleSendDataToMessenger)
                                                        }>
                                                        {ADD}
                                                    </p>
                                                </div>
                                            </div>
                                        </StyledCartItemContainer>
                                    );
                                })
                            ) : (
                                <View style={emptyStateContainer}>
                                    <Text>{EMPTY_STATE_TEXT}</Text>
                                </View>
                            )}
                            <StyledTotalTab>
                                {renderPromoNote()}
                                <p className="shipping-text">
                                    (**Please proceed to payment page for final total amount)
                                </p>
                            </StyledTotalTab>
                        </div>
                    </StyledCartContainer>
                </div>

                {/* button to update the delivery address */}
                {/* only show the button if there is item in the checkout */}
                {isPaymentProcessing ? (
                    <Loader width={30} />
                ) : (
                    currentMerchantsShop &&
                    currentMerchantsShop.itemsInCheckout.length > 0 && (
                        // <View style={buttonContainer}>
                        <StyledButtonContainer>
                            {windowSize < 1024 && (
                                <StyledAddMoreItemButton onClick={(e) => handleClosePaymentModal(e)}>
                                    <div className="addMoreButtonText">Add more items</div>
                                </StyledAddMoreItemButton>
                            )}

                            {/* check if order Type is group and display the add to group order button */}
                            <StyledFixedBottom>
                                {getUrlParams().orderType === "group" ? (
                                    <View>
                                        <Button
                                            firstText={ADD_TO_GROUP_CHECKOUT}
                                            handleOnPress={handleAddDataToGuest}
                                            hasDoubleText
                                            secondText={`$${getTotalCartPrice(
                                                currentMerchantsShop.itemsInCheckout,
                                                false,
                                                activeCategoryPromo
                                            )}`}
                                            isBigButton={true}
                                        />
                                    </View>
                                ) : (
                                    <>
                                        <Button
                                            isWideButton
                                            firstText={MAKE_AN_ORDER}
                                            // handleOnPress={() => handleCheckout("credit")}//Take note of this commented line
                                            handleOnPress={handleFireCheckout}
                                            hasDoubleText
                                            isBigButton={true}
                                            disabled={
                                                (!isStoreOpen && !scheduledOrderTime?.time) ||
                                                handleCheckMinimumOrderItem(
                                                    currentMerchantsShop.categories.minimum_delivery_amount
                                                )
                                                    ? 1
                                                    : 0
                                            }
                                            secondText={`$${getTotalCartPrice(
                                                currentMerchantsShop.itemsInCheckout,
                                                false,
                                                activeCategoryPromo
                                            )}`}
                                            id="btn-checkout"
                                        />
                                        {((!isStoreOpen && !scheduledOrderTime?.time) || is_active === false) && (
                                            <StyledClosedStoreNotification>
                                                {handleDisplayStoreClosedToast()}
                                            </StyledClosedStoreNotification>
                                        )}
                                        {handleCheckMinimumOrderItem(
                                            currentMerchantsShop.categories.minimum_delivery_amount
                                        ) && (
                                            <StyledClosedStoreNotification>
                                                {handleCheckMinimumOrderItem(
                                                    currentMerchantsShop.categories.minimum_delivery_amount
                                                )}
                                            </StyledClosedStoreNotification>
                                        )}
                                    </>
                                )}
                            </StyledFixedBottom>
                        </StyledButtonContainer>
                    )
                )}

                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} lightBackground />

                {isGuestOrderModalOpen && (
                    <View
                        style={{
                            background: "white",
                            position: "fixed",
                            height: "100vh",
                            maxWidth: 375,
                            marginRight: "auto",
                            marginLeft: "auto",
                            right: 0,
                            left: 0
                        }}>
                        <GuestOrderSuccess
                            setIsGuestOrderModalOpen={setIsGuestOrderModalOpen}
                            guestName={guestName}
                            groupHost={guestSession[0].user.first_name}
                        />
                    </View>
                )}
                {isConfirmOrderOpen && (
                    <GuestOrderConfirmation
                        handleCloseConfirmModal={handleCloseConfirmModal}
                        handleContinueGroupOrder={handleContinueGroupOrder}
                        isPaymentProcessing={isPaymentProcessing}
                    />
                )}
            </div>
        </StyledModalOuterContainer>
    );
};

const mapStateToProps = (state) => ({
    // currentMerchantsShop: state.dashboard[getUrlParams().merchantId],
    orderType: state.dashboard.orderType,
    guestName: state.dashboard.guestName,
    hasCartChanged: state.dashboard.hasCartChanged,
    isAddressPosting: getIsAddressPosting(state),
    hasAddressBeenPosted: getHasAddressBeenPosted(state),
    error: getError(state),
    address: getAddress(state),
    guestSession: getGuestSession(state)
});

const CartAction = {
    handleUserProfile,
    handleItemCountChange,
    handleRemoveItemfromCart,
    handleDomainsWhitelisting,
    handleLoadUserProfile,
    addItemToGuestOrder,
    handleResetHasCartChanged
};

const mapDispatchToProps = (dispatch) => bindActionCreators(CartAction, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cart);

Cart.propTypes = {
    address: PropTypes.shape({
        addressCity: PropTypes.string,
        addressState: PropTypes.string,
        postalCode: PropTypes.string,
        streetAddressLine1: PropTypes.string,
        streetAddressLine2: PropTypes.string,
        telephoneNumber: PropTypes.string
    }).isRequired,
    currentMerchantsShop: PropTypes.objectOf(PropTypes.any).isRequired,
    error: PropTypes.string.isRequired,
    handleItemCountChange: PropTypes.func.isRequired,
    handleRemoveItemfromCart: PropTypes.func.isRequired,
    handleUserProfile: PropTypes.func.isRequired,
    hasAddressBeenPosted: PropTypes.bool.isRequired,
    handleDomainsWhitelisting: PropTypes.func.isRequired,
    handleLoadUserProfile: PropTypes.func.isRequired,
    addItemToGuestOrder: PropTypes.func.isRequired,
    isAddressPosting: PropTypes.bool.isRequired,
    orderType: PropTypes.string.isRequired,
    guestName: PropTypes.string.isRequired,
    handleOpenUpsellModal: PropTypes.func.isRequired
};
