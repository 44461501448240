import React from "react";
import { Box } from "#/pages/orderPage/components/productUpsellModal/subComponents/item/Item.styles";
import { sendNotificationToSlackForUserLogin } from "#/utils/utils";

export const WebPromoRecommendationItem = React.memo((props) => {
    const { item } = props;

    return (
        <Box className="box">
            {item.image && item.image !== "" && <img src={item.image} alt="" className="left-column" />}
            <section className="right-column">
                <div className="right-column__info-section">
                    <h1 className="product-header">{item.name || ""}</h1>

                    <p className="product-description">
                        {item.description
                            ? `${item.description.substring(0, 50)}...`
                            : `${item.name} is one of the best, you are certain to enjoy every bit of it.`}
                    </p>
                    <p className="product-price">${item.price.toFixed(2)}</p>
                </div>
                <div
                    className="right-column__btn-add"
                    onClick={() => {
                        sendNotificationToSlackForUserLogin();
                        props.onHide(false);
                        props.handleModalOpenOrAddItem(item);
                    }}>
                    Add Item
                </div>
            </section>
        </Box>
    );
});

export const WebPromoRecommendationItemWrapper = ({ item, handleModalOpenOrAddItem, onHide }) => {
    return (
        <WebPromoRecommendationItem item={item} handleModalOpenOrAddItem={handleModalOpenOrAddItem} onHide={onHide} />
    );
};

export default React.memo(WebPromoRecommendationItemWrapper);
