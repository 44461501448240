/* eslint-disable camelcase */
import React from "react";
import { View, Text, Image, CheckBox, ActivityIndicator } from "react-native";
import { handleOrderMarkPrice, STRINGS } from "../constant";
import { styles } from "./styles";
import { getUrlParams, renderLoading } from "../../../utils/utils";

const { OPTIONS, REQUIRED, OPTIONAL, USD } = STRINGS;

const {
    checkboxRow,
    checkboxRowPad,
    checkbox,
    checkboxlabel,
    currency,
    loadingStyle,
    optionsContainer,
    optionsHeaderText,
    optionsText,
    optionsTextRequired
} = styles;

export const RenderOrderMarkMenu = ({ props }) => {
    const {
        loading,
        modifiedModifiersData,
        ModifierRadioButton,
        optionsRadioValue,
        optionsValue,
        handleModifierRadioSelected,
        handleCheckBoxToggle,
        checkboxDisabled,
        storeVendor
    } = props;

    return modifiedModifiersData.map((modifier, i) => {
        const { id, modifiers, name, quantity_rules } = modifier;

        if (modifier?.is_active == false) return null;
        return (
            <View key={name} style={optionsContainer}>
                <View style={optionsHeaderText}>
                    <Text style={optionsText}>{storeVendor == "CHOWLY" ? modifier.title : name}</Text>
                    <Text style={optionsTextRequired}>{quantity_rules[0]?.min_allowed > 0 ? REQUIRED : OPTIONAL}</Text>
                </View>

                {modifiers.map((item) => {
                    const { id, isChecked, price_rules, title, _id, disabled } = item;

                    const { context, price } = handleOrderMarkPrice(price_rules);

                    return quantity_rules.length > 0 &&
                        quantity_rules[0]?.min_allowed === 1 &&
                        quantity_rules.length > 0 &&
                        quantity_rules[0]?.max_allowed === 1 ? (
                        <ModifierRadioButton
                            name={title}
                            price={storeVendor == "CHOWLY" ? price.toFixed(2) : price / 100}
                            id={_id}
                            key={id}
                            item={item}
                            optionsRadioValue={optionsRadioValue}
                            modifier_name={storeVendor == "CHOWLY" ? modifier.title : modifier.name}
                            handleOptionsSelected={
                                optionsValue !== ""
                                    ? (e) =>
                                          handleModifierRadioSelected(
                                            storeVendor == "ordermark"||storeVendor == "otter" || storeVendor == "CHOWLY" ? id : _id,
                                              item,
                                              storeVendor == "CHOWLY" ? modifier.title : modifier.name,
                                              e
                                          )
                                    : null
                            }
                        />
                    ) : (
                        <View style={checkboxRow} key={id}>
                            <View style={checkboxRowPad}>
                                <CheckBox
                                    value={isChecked}
                                    onValueChange={() => {
                                        handleCheckBoxToggle(
                                            storeVendor == "ordermark"||storeVendor == "otter" || storeVendor == "CHOWLY" ? id : _id,
                                            isChecked,
                                            modifier
                                        );
                                    }}
                                    style={checkbox}
                                    disabled={disabled}
                                />

                                <Text
                                    style={disabled ? [checkboxlabel, { color: "rgb(204, 214, 221)" }] : checkboxlabel}
                                    onPress={
                                        disabled
                                            ? null
                                            : () =>
                                                  handleCheckBoxToggle(
                                                    storeVendor == "ordermark"|| storeVendor == "otter" || storeVendor == "CHOWLY" ? id : _id,
                                                      isChecked,
                                                      modifier
                                                  )
                                    }
                                    disabled={disabled}>
                                    {title}
                                </Text>
                            </View>
                            <Text
                                // style={currency}
                                style={disabled ? [currency, { color: "rgb(204, 214, 221)" }] : currency}>
                                {USD}

                                {storeVendor == "CHOWLY"
                                    ? price.toFixed(2)
                                    : Number.parseFloat(price / 100).toFixed(2) || Number.parseFloat(0).toFixed(2)}
                            </Text>
                        </View>
                    );
                })}
            </View>
        );
    });
};
