import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getUrlParams, handlePsId } from "../../../../utils/utils";
import { Loader } from "../../../../components/loader/Loader";
import Item from "./subComponents/item/Item";
import { ABHIS_SUGGESTIONS, MELBAS_MOCK_DATA } from "./Constants";
import useProceedToCheckout from "../../../../components/hooks/useProceedToCheckout";
import { handleRemoveItemfromCart, handleResetHasCartChanged } from "../../actions";
import "flatpickr/dist/themes/light.css";
import {
    StyledModalOverlay,
    StyledModalContent,
    StyledModalContentBottom,
    StyledModalContentHeader,
    StyledModalContentMiddle,
    StyledModalWrapper
} from "./ProductUpsellModal.style";
import {
    getIsAddressPosting,
    getHasAddressBeenPosted,
    getError,
    getAddress,
    getGuestSession
} from "../../../cart/selectors";
import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

const SliderForward = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="9.849" height="16" viewBox="0 0 9.849 16">
            <g id="noun_Arrow_3134100" transform="translate(-20.81 -2.692)">
                <path
                    id="Path_17155"
                    data-name="Path 17155"
                    d="M20.81,16.788a1.9,1.9,0,0,0,3.239,1.35L30.1,12.076a1.894,1.894,0,0,0,.555-1.35c0-.022,0-.042,0-.062a1.889,1.889,0,0,0-.553-1.4L24.047,3.215A1.9,1.9,0,0,0,21.365,5.9l4.774,4.774-4.774,4.774a1.894,1.894,0,0,0-.555,1.341Z"
                    transform="translate(0)"
                    fill="#fff"
                />
            </g>
        </svg>
    );
};

const SliderBackward = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="9.849" height="16" viewBox="0 0 9.849 16">
            <g id="noun_Arrow_3134100" transform="translate(30.659 18.692) rotate(180)">
                <path
                    id="Path_17155"
                    data-name="Path 17155"
                    d="M20.81,16.788a1.9,1.9,0,0,0,3.239,1.35L30.1,12.076a1.894,1.894,0,0,0,.555-1.35c0-.022,0-.042,0-.062a1.889,1.889,0,0,0-.553-1.4L24.047,3.215A1.9,1.9,0,0,0,21.365,5.9l4.774,4.774-4.774,4.774a1.894,1.894,0,0,0-.555,1.341Z"
                    transform="translate(0)"
                    fill="#fff"
                />
            </g>
        </svg>
    );
};

const ProductUpsellModal = ({
    isOrderTypeModalOpen,
    setOrderTypeModalOpen,
    handleChangeUsersName,
    selectedOrderMethod,
    handleChooseOrderMethod,
    usersDetails,
    handleChangePhoneNumber,
    setScheduledOrderTime,
    hoursOfOperation,
    closingTime,
    openingTime,
    currentMerchantsShop,
    handleModalOpenOrAddItem,
    recentlyAddedItem,
    handleFireCheckout,
    handleCloseProductUpsellModal,
    //---
    //The props below are for adding the item to the cart
    //---
    storeVendor,
    optionsValue,
    merchantID,
    isStoreOpen,
    scheduledOrderTime,
    setOptionsValue,
    addItemToCheckout,
    handleItemCountChange,
    handleCloseModal,

    //---
    //The props below are for checking out
    //---
    hasCartChanged,
    guestSession,
    handleCollatingCheckoutItems,
    orderType = null,
    chosenUpsellItems,
    activeCategoryPromo
}) => {
    //----------------------------------------------------------------------
    //Helper Hooks
    //----------------------------------------------------------------------
    const history = useHistory();
    const upsellScrollRef = useRef(null);
    const {
        isPaymentProcessing,
        handleCountChange,
        handleSendDataToMessenger,
        handleCheckMinimumOrderItem,
        is_active,
        handleDisplayStoreClosedToast,
        isGuestOrderModalOpen,
        setIsGuestOrderModalOpen,
        isConfirmOrderOpen,
        handleCloseConfirmModal,
        handleContinueGroupOrder,
        handleCheckout,
        checkingOut
    } = useProceedToCheckout(
        currentMerchantsShop,
        hasCartChanged,
        storeVendor,
        isStoreOpen,
        handleRemoveItemfromCart,
        handleItemCountChange,
        orderType,
        handleResetHasCartChanged,
        guestSession,
        history,
        handleCollatingCheckoutItems,
        scheduledOrderTime,
        activeCategoryPromo
    );

    //----------------------------------------------------------------------
    //Other Helpers/variables
    //----------------------------------------------------------------------
    const { merchantId, locationId } = getUrlParams();
    let MOCK_DATA;
    if (merchantId == "5f010924df0b114b4f98e7cb" && locationId == "1") {
        MOCK_DATA = MELBAS_MOCK_DATA;
    } else {
        MOCK_DATA = ABHIS_SUGGESTIONS;
    }

    //----------------------------------------------------------------------
    //State
    //----------------------------------------------------------------------
    const [itemsForUpsell, setItemsForUpsell] = useState(chosenUpsellItems);

    //----------------------------------------------------------------------
    //Helper Functions
    //----------------------------------------------------------------------
    //Move upsell modal content to the right
    const handleIncreaseScroll = () => {
        const value = parseInt(upsellScrollRef.current.scrollWidth / itemsForUpsell.length);
        upsellScrollRef.current.scroll({
            left: upsellScrollRef.current.scrollLeft + value,
            behaviour: "smooth"
        });
    };

    //Move upsell modal content to the left
    const handleDecreaseScroll = () => {
        const value = parseInt(upsellScrollRef.current.scrollWidth / itemsForUpsell.length);
        upsellScrollRef.current.scroll({
            left: upsellScrollRef.current.scrollLeft - value,
            behaviour: "smooth"
        });
    };

    //----------------------------------------------------------------------
    //Side effects
    //----------------------------------------------------------------------
    //For updating the products on the upsell modal
    useEffect(() => {
        if (recentlyAddedItem) {
            let filteredData = itemsForUpsell.filter((item) => {
                return item.id !== recentlyAddedItem?.id;
            });

            setItemsForUpsell(filteredData);
        }
    }, [recentlyAddedItem]);

    return (
        <StyledModalWrapper>
            {/* --------------------------------------------- */}
            {/* Overlay */}
            {/* --------------------------------------------- */}
            <StyledModalOverlay
                isOpen={isOrderTypeModalOpen}
                onClick={handleCloseProductUpsellModal}></StyledModalOverlay>

            {/* --------------------------------------------- */}
            {/* White content box */}
            {/* --------------------------------------------- */}
            <StyledModalContent isOpen={isOrderTypeModalOpen}>
                {/* --------------------------------------------- */}
                {/* Modal Header */}
                {/* --------------------------------------------- */}
                <StyledModalContentHeader>
                    <h2>You Might Also Enjoy</h2>
                    <h4>Want to add one of these favourites?</h4>
                    <div className="cancel-icon" onClick={handleCloseProductUpsellModal}>
                        <span></span>
                        <span></span>
                    </div>
                </StyledModalContentHeader>

                {/* --------------------------------------------- */}
                {/* Modal Middle section */}
                {/* --------------------------------------------- */}
                <StyledModalContentMiddle className="main-scroll" ref={upsellScrollRef}>
                    <div className="slider-controls-wrapper">
                        <div className="slider-controls backward" onClick={handleDecreaseScroll}>
                            <SliderBackward />
                        </div>
                        <div className="slider-controls forward" onClick={handleIncreaseScroll}>
                            <SliderForward />
                        </div>
                    </div>
                    <div className="meal-cards-wrapper">
                        {itemsForUpsell.length > 0 ? (
                            itemsForUpsell.map((item) => {
                                let optionsValue;

                                const isPresent = currentMerchantsShop.itemsInCheckout.find((data) => {
                                    return data.name === item.name;
                                });

                                if (isPresent) return null;

                                return (
                                    <Item
                                        key={item._id}
                                        allData={item}
                                        handleModalOpenOrAddItem={handleModalOpenOrAddItem}
                                        storeVendor={storeVendor}
                                        optionsValue={optionsValue}
                                        merchantID={currentMerchantsShop.categories.merchant_id}
                                        isStoreOpen={isStoreOpen}
                                        scheduledOrderTime={scheduledOrderTime}
                                        setOptionsValue={setOptionsValue}
                                        addItemToCheckout={addItemToCheckout}
                                        handleItemCountChange={handleItemCountChange}
                                        handleCloseModal={handleCloseModal}
                                        handlePsId={handlePsId}
                                        currentMerchantsShop={currentMerchantsShop}
                                        activeCategoryPromo={activeCategoryPromo}
                                    />
                                );
                            })
                        ) : (
                            <h2 style={{ textAlign: "center", width: "100%", fontSize: "22px", marginTop: "40px" }}>
                                Thank you for ordering our favourites
                            </h2>
                        )}

                        <div style={{ minHeight: "130px", backgroundColor: "transparent", paddingRight: "20px" }}></div>
                    </div>
                </StyledModalContentMiddle>
                {/* --------------------------------------------- */}
                {/* Modal Bottom section */}
                {/* --------------------------------------------- */}
                {checkingOut ? (
                    <Loader width={30} />
                ) : (
                    <StyledModalContentBottom
                        orderType={orderType}
                        type="submit"
                        onClick={() => handleCheckout("credit")}>
                        Proceed to Checkout
                    </StyledModalContentBottom>
                )}
            </StyledModalContent>
        </StyledModalWrapper>
    );
};

const mapStateToProps = (state) => ({
    // currentMerchantsShop: state.dashboard[getUrlParams().merchantId],
    orderType: state.dashboard.orderType,
    guestName: state.dashboard.guestName,
    hasCartChanged: state.dashboard.hasCartChanged,
    isAddressPosting: getIsAddressPosting(state),
    hasAddressBeenPosted: getHasAddressBeenPosted(state),
    error: getError(state),
    address: getAddress(state),
    guestSession: getGuestSession(state)
});

export default connect(mapStateToProps, null)(ProductUpsellModal);
