import React, { useEffect } from "react";

import ItemList from "#/pages/orderPage/components/ItemList";
import {
    StyledContainer,
    StyledHeaderContainer,
    StyledButtonScheduledOrderDetails,
    StyledEditIcon,
    StyledFlatList,
    styles,
    StyledContentContainer
} from "#/pages/orderPage/components/styles";
import { StyleMenuItem } from "#/pages/orderPage/components/desktopView/components/Desktop.style";
import { ITEMS_IN_CART, RECOMMENDATION_MERCHANTS_WHITELIST } from "#/pages/orderPage/constant";
import Cart from "#/pages/cart/components";
import { Button } from "#/components/index";
import { PromoRibbon } from "../../../../components/promoRibbbon/PromoRibbon";

import { getUrlParams, convert24hrTo12hrTime, displayCategoryName, replaceCurriesCategory } from "#/utils/utils";
import Switch from "react-switch";

const { menuListContainer } = styles;

const Mobile = ({
    scrollPosition,
    isPictureShown,
    setOrderTypeModalOpen,
    openingHours,
    selectedOrderMethod,
    afterBreakFirstPickupTime,
    scheduledOrderTime,
    sortedCurrentActiveItems,
    currentMenuCategory,
    showCategoryPromoBasedOnWeb,
    isScrolling,
    currentMerchantsShop,
    setFocusedCategories,
    setCurrentMenuCategory,
    setIsScrolling,
    handleModalOpenOrAddItem,
    userProfile,
    windowSize,
    handleAddDataToGuest,
    getTotalCartPrice,
    getTotalItemsInCart,
    handleOpenPaymentModal,
    handleClosePaymentModal,
    storeVendor,
    isStoreOpen,
    isPaymentModalOpen,
    handleCollatingCheckoutItems,
    handleSetOpenUpsellModal,
    storeTemplate,
    activeCategoryPromo,
    onBreak,
    setIsRecommendationAvailable,
    isRecommendationAvailable
}) => {
    const merchantId = currentMerchantsShop.categories.merchant_id;

    const handleRecommendationToggle = (e) => {
        setIsRecommendationAvailable(!isRecommendationAvailable);
    };

    const renderMobileOrderText = () => {
        if (openingHours.onBreak) {
            return (
                <div className="info-header">
                    <div className="info-header-container">
                        <span style={{ textTransform: "capitalize" }}>{selectedOrderMethod}</span> for{" "}
                        <span>Today</span> at <span>{afterBreakFirstPickupTime?.label}</span>
                    </div>
                    {RECOMMENDATION_MERCHANTS_WHITELIST.includes(merchantId) && (
                        <div className="recommendation-available" onClick={(e) => e.stopPropagation()}>
                            <span>Chef Suggestions</span>
                            <Switch
                                offColor="#c4c1c3"
                                onColor="#c4c1c3"
                                onHandleColor="#A8272D"
                                handleDiameter={17}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow={"none"}
                                height={22}
                                width={35}
                                onChange={(e) => handleRecommendationToggle(e)}
                                checked={isRecommendationAvailable}
                            />
                        </div>
                    )}
                </div>
            );
        } else if (scheduledOrderTime?.label) {
            return (
                <div className="info-header">
                    <div className="info-header-container">
                        <span style={{ textTransform: "capitalize" }}>{selectedOrderMethod}</span> for{" "}
                        <span>{scheduledOrderTime.label}</span> at{" "}
                        <span>{convert24hrTo12hrTime(scheduledOrderTime?.time)}</span>
                    </div>
                    {RECOMMENDATION_MERCHANTS_WHITELIST.includes(merchantId) && (
                        <div className="recommendation-available" onClick={(e) => e.stopPropagation()}>
                            <span>Chef Suggestions</span>
                            <Switch
                                offColor="#c4c1c3"
                                onColor="#c4c1c3"
                                onHandleColor="#A8272D"
                                handleDiameter={17}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow={"none"}
                                height={22}
                                width={35}
                                onChange={(e) => handleRecommendationToggle(e)}
                                checked={isRecommendationAvailable}
                            />
                        </div>
                    )}
                </div>
            );
        } else {
            return (
                <div className="info-header">
                    <div className="info-header-container">
                        <span style={{ textTransform: "capitalize" }}>{selectedOrderMethod}</span> for Now{" "}
                        <StyledEditIcon />
                    </div>
                    {RECOMMENDATION_MERCHANTS_WHITELIST.includes(merchantId) && (
                        <div className="recommendation-available" onClick={(e) => e.stopPropagation()}>
                            <span>Chef Suggestions</span>
                            <Switch
                                offColor="#c4c1c3"
                                onColor="#c4c1c3"
                                onHandleColor="#A8272D"
                                handleDiameter={17}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow={"none"}
                                height={22}
                                width={35}
                                onChange={(e) => handleRecommendationToggle(e)}
                                checked={isRecommendationAvailable}
                            />
                        </div>
                    )}
                </div>
            );
        }
    };

    //---------------------------------------------
    //TO SCROLL TO ACTIVE CATEGORY ON CATEGORY BAR
    //---------------------------------------------
    const handleScrollIntoView = () => {
        const elem = document.querySelector(".is-active");

        if (elem) {
            elem.scrollIntoView();
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScrollIntoView);

        return () => window.removeEventListener("scroll", handleScrollIntoView);
    }, []);
    //---------------------------------------------
    //---------------------------------------------
    //---------------------------------------------

    let displayedCategoryBar = sortedCurrentActiveItems;

    if (getUrlParams().merchantId == "6075e037666fec40b0bd0773") {
        displayedCategoryBar = replaceCurriesCategory(sortedCurrentActiveItems);
    }

    if (getUrlParams().merchantId == "609396e38b525c2d2e92e0fa") {
        displayedCategoryBar = replaceCurriesCategory(sortedCurrentActiveItems, "superfood");
    }
    return (
        <div>
            <StyledContainer ref={scrollPosition} isPictureShown={isPictureShown}>
                <StyledHeaderContainer>
                    <div className={!isPictureShown ? "fixed_header" : ""}>
                        <p className="header-title">{getUrlParams().deliveryType} 'Menu'</p>
                        {
                            // getUrlParams().channel !== "web" &&
                            currentMerchantsShop?.categories?.promoText && (
                                <PromoRibbon
                                    promoInstruction={currentMerchantsShop?.categories?.promoText}
                                    isRibbonPromoPresent={currentMerchantsShop?.categories?.promoText}
                                />
                            )
                        }

                        {
                            // schedule order UI
                            // lscache.get('scheduledOrderDetails') && (
                            <StyledButtonScheduledOrderDetails onClick={() => setOrderTypeModalOpen(true)}>
                                {renderMobileOrderText()}
                            </StyledButtonScheduledOrderDetails>
                            // )
                        }
                        {/* pass menu to the flatlist */}

                        <StyledFlatList
                            data={displayedCategoryBar}
                            removeClippedSubviews={false}
                            horizontal
                            contentContainerStyle={menuListContainer}
                            showsVerticalScrollIndicator={false}
                            keyExtractor={(item) => item._id.toString()}
                            extraData={currentMenuCategory}
                            renderItem={({ item, index }) => {
                                const { name, is_promo_running, promo_text } = item;

                                return (
                                    <StyleMenuItem
                                        style={{ position: "relative" }}
                                        key={name}
                                        name={name}
                                        to={name}
                                        spy
                                        smooth
                                        offset={index === 0 ? -350 : -150}
                                        duration={1000}
                                        activeClass="is-active">
                                        {displayCategoryName(
                                            is_promo_running,
                                            showCategoryPromoBasedOnWeb,
                                            promo_text,
                                            name
                                        )}
                                    </StyleMenuItem>
                                );
                            }}
                        />
                    </div>
                </StyledHeaderContainer>
                <StyledContentContainer scrollPosition={!isPictureShown}>
                    <div className={isScrolling ? "scrollviewContainerMax" : "scrollviewContainer"}>
                        <div>
                            <ItemList
                                menu={sortedCurrentActiveItems}
                                CURRENT_FOCUSED_CATEGORY={currentMerchantsShop.focusedCategory}
                                focusedCategoryData={currentMerchantsShop.focusedCategoryData}
                                setFocusedCategories={setFocusedCategories}
                                currentMenuCategory={currentMenuCategory}
                                setCurrentMenuCategory={setCurrentMenuCategory}
                                setIsScrolling={setIsScrolling}
                                handleModalOpenOrAddItem={handleModalOpenOrAddItem}
                                userProfile={userProfile}
                                showCategoryPromoBasedOnWeb={showCategoryPromoBasedOnWeb}
                                storeTemplate={storeTemplate}
                            />
                        </div>

                        {windowSize.width < 1024 &&
                            currentMerchantsShop.itemsInCheckout &&
                            currentMerchantsShop.itemsInCheckout.length > 0 && (
                                <div className="buttonContainer">
                                    <div className="buttonTextContainer">
                                        {
                                            /* check if order Type is group and display the add to group order button */
                                            getUrlParams().orderType === "group" ? (
                                                <Button
                                                    firstText="Add to group checkout"
                                                    handleOnPress={handleAddDataToGuest}
                                                    hasDoubleText
                                                    isBigButton={true}
                                                    secondText={`$${getTotalCartPrice(
                                                        currentMerchantsShop.itemsInCheckout,
                                                        false,
                                                        activeCategoryPromo
                                                    )}`}
                                                />
                                            ) : (
                                                <Button
                                                    isWideButton
                                                    isCheckoutButton
                                                    isBigButton={true}
                                                    firstText={ITEMS_IN_CART(getTotalItemsInCart())}
                                                    secondText={`$${getTotalCartPrice(
                                                        currentMerchantsShop.itemsInCheckout,
                                                        false,
                                                        activeCategoryPromo
                                                    )}`}
                                                    handleOnPress={handleOpenPaymentModal}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                            )}
                    </div>
                </StyledContentContainer>

                <Cart
                    handleClosePaymentModal={handleClosePaymentModal}
                    storeVendor={storeVendor}
                    isPaymentModalOpen={isPaymentModalOpen}
                    windowSize={windowSize.width}
                    isStoreOpen={isStoreOpen}
                    totalCartPrice={parseFloat(
                        getTotalCartPrice(currentMerchantsShop.itemsInCheckout, false, activeCategoryPromo)
                    ).toFixed(2)}
                    scheduledOrderTime={scheduledOrderTime}
                    handleCollatingCheckoutItems={handleCollatingCheckoutItems}
                    handleAddDataToGuest={handleAddDataToGuest}
                    handleOpenUpsellModal={handleSetOpenUpsellModal}
                    currentMerchantsShop={currentMerchantsShop}
                    activeCategoryPromo={activeCategoryPromo}
                    onBreak={onBreak}
                    selectedOrderMethod={selectedOrderMethod}
                    afterBreakFirstPickupTime={afterBreakFirstPickupTime}
                    storeTemplate={storeTemplate}
                    setOrderTypeModalOpen={setOrderTypeModalOpen}
                />
            </StyledContainer>
        </div>
    );
};

export default Mobile;
