import React, { useState, useEffect, useRef } from 'react'
import {
    View,
    Text,
    Image,
    FlatList,
    TouchableWithoutFeedback,
    TextInput
} from 'react-native';
import { styles, GuestOrderSuccessStyle } from './styles'
import { Button } from '../../../components';
import Lottie from 'react-lottie'
import success from '../../../assets/success2.json'
import group from '../../../assets/group_success.jpeg'

// options for lottie animations
const SuccessAnimation = {
    animationData: success,
    autoplay: true,
    loop: false,
};

const {
    container,
    successText,
    boldText
} = GuestOrderSuccessStyle

export const GuestOrderSuccess = ({ guestName, groupHost }) => {

    return (
        <View style={container}>
            <View style={{
                alignItems: 'center', justifyContent: 'center', position: 'absolute', top: 0, width: '100%',
                height: '30vh',
            }}>
                <Image
                    style={{
                        width: '100%',
                        height: '30vh',
                        marginBottom: 20
                    }}
                    source={group}
                />
            </View>
            <Lottie
                options={SuccessAnimation}
                width={200}
                height={200}
            />
            <Text style={successText}>Welldone
            <Text style={boldText}> {guestName}</Text></Text>
            <Text style={successText}>Your order has been added to the group order hosted by
            <Text style={boldText}> {groupHost}</Text></Text>
        </View>
    )
}
