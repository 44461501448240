/* eslint-disable react/jsx-fragments */
/* eslint-disable no-shadow */

/* eslint-disable camelcase */
//---------------------
//External Packages
//---------------------
import React, { useState, useEffect, useCallback, useLayoutEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";
import useWindowSize from "react-use/lib/useWindowSize";
import PropTypes from "prop-types";
import lscache from "lscache";
import axios from "axios";

//---------------------
//Styles
//---------------------
import { StyledFullWidth } from "./styles";
import { StyledDesktopBody, StyleImageDiv } from "./desktopView/components/Desktop.style";

//---------------------
// Components
//---------------------
import GuestIntro from "./GuestIntro";
import { GuestOrderSuccess } from "./GuestOrderSuccess";
import { GuestOrderConfirmation } from "./GuestOrderConfirmation";
import Desktop from "./desktopView";
import MobileView from "./mobileView/MobileView";
import { OrderTypeChooser } from "./orderTypeChooserModal/OrderTypeChooser";
import { WebPromoGreeting } from "./webPromoGreeting/WebPromoGreeting";
import ProductUpsellModal from "./productUpsellModal/ProductUpsellModal";
import Modal from "../../modal/components/Modal";
import PromoBanner from "../../../components/promoBanner";
import { PromoRibbon } from "../../../components/promoRibbbon/PromoRibbon";
import { LocationDetailsBanner } from "../../../components/layout";
import NewDesktopSlidingCart from "./newDesktopSlidingCart/NewDesktopSlidingCart";
import WebPromoRecommendation from "./webPromoGreeting/webPromoRecommendation/WebPromoRecommendation";

//---------------------
//Selectors
//---------------------
import {
    getCurrentlySelectedMerchant,
    getError,
    getHasAnErrorOccur,
    getHasCurrentCategoriesLoaded,
    getHasCurrentFocusedCategoryBeenSet,
    getHasCurrentMerchantIdLoaded,
    getHasCurrentMerchantShopLoaded,
    getIsCurrentMerchantIdLoading,
    getIsUserProfileLoading,
    getIsCurrentMerchantShopLoading,
    getIsCurrentCategoriesLoading,
    getVisitedStore,
    getHasOptionsDataLoaded,
    getIsOptionsDataLoading,
    getGuestName
} from "../selectors";

//---------------------
//Actions
//---------------------
import {
    addItemToCheckout,
    handleItemCountChange,
    loadCurrentMerchantsId,
    loadItemsCategories,
    loadMerchantsCurrentShop,
    setFocusedCategories,
    setNameOfGuest,
    handleLoadOptionsDataForFocusedCategory,
    clearRedundantCheckout
} from "../actions";
import { handleLoadGuestOrderSession, addItemToGuestOrder, handleLoadUserProfile } from "../../cart/actions";

//---------------------
//Utils
//---------------------
import {
    getTotalCartPrice,
    getUrlParams,
    renderLoading,
    handlePsId,
    checkDayToUse,
    convert24hrTo12hrTime,
    getTimeInterval,
    handleMakeTimeMultipleOfFifteen,
    setLocalStorage,
    clearLocalStorage,
    replaceCurriesCategory,
    getLocalStorage,
    getUsersCurrentTime
} from "../../../utils/utils";

import cartIcon from "../../../assets/cart.png";
import { RECOMMENDATION_MERCHANTS_WHITELIST } from "#/pages/orderPage/constant";

//----------------------------------------------------------------------------------
//Helpers
//----------------------------------------------------------------------------------
const { locationId, merchantId, pageId, orderType, channel } = getUrlParams();

const mySet = new Set();

//----------------------------------------------------------------------------------
//Main Component
//----------------------------------------------------------------------------------
const Dashboard = ({
    addItemToCheckout,
    currentMerchantsShop,
    clearRedundantCheckout,
    error,
    handleItemCountChange,
    hasAnErrorOccur,
    isCurrentCategoriesLoading,
    isCurrentMerchantIdLoading,
    isCurrentMerchantShopLoading,
    isUserProfileLoading,
    loadCurrentMerchantsId,
    loadItemsCategories,
    setFocusedCategories,
    setNameOfGuest,
    handleLoadGuestOrderSession,
    handleLoadUserProfile,
    userProfile,
    addItemToGuestOrder,
    hasOptionsDataLoaded,
    guestName,
    hasUserProfileLoaded,
    guestSession,
    hasCurrentMerchantShopLoaded
}) => {
    //----------------------------------------------------------------------------------
    //HELPER HOOKS
    //----------------------------------------------------------------------------------
    const windowSize = useWindowSize();
    const history = useHistory();
    const scrollPosition = React.useRef(null);
    const urlSubRoute = useParams();
    const routeMatch = useRouteMatch();

    // set the current focsed category to first item
    const IsUserEligibleForPromo = userProfile?.promo && getUrlParams().channel !== "web";

    //----------------------------------------------------------------------------------
    //STATE
    //----------------------------------------------------------------------------------
    const [isModalShown, setIsModalShown] = useState(false);
    const [isRecommendationShown, setIsRecommendationShown] = useState(false);
    const [isRecommendationAvailable, setIsRecommendationAvailable] = useState(true);
    const [isScrolling, setIsScrolling] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [optionsValue, setOptionsValue] = useState({});
    const [currentActiveItems, setCurrentActiveItems] = useState([]);
    const [sortedCurrentActiveItems, setSortedCurrentActiveItems] = useState([]);
    const [isGuestModalOpen, setIsGuestModalOpen] = useState(false);
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
    const [isGuestOrderModalOpen, setIsGuestOrderModalOpen] = useState(false);
    const [isConfirmOrderOpen, setIsConfirmOrderOpen] = useState(false);
    const [isStoreOpen, setIsStoreOpen] = useState(null);
    const [currentMenuCategory, setCurrentMenuCategory] = useState(null);
    const [openingHours, setOpeningHours] = useState("");
    const [scheduledOrderTime, setScheduledOrderTime] = useState(null);
    const [storeVendor, setStoreVendor] = useState("");
    const [processor, setProcessor] = useState("");
    const [usersDetails, setUsersDetails] = useState({
        name: "",
        phoneNumber: ""
    });
    const [selectedOrderMethod, setSelectedOrderMethod] = React.useState(getUrlParams().deliveryType || "delivery");
    const [isNumberValidated, setIsNumberValidated] = useState(false);
    const [isOrderTypeModalOpen, setOrderTypeModalOpen] = useState(null);
    const [isPictureShown, setIsPictureShown] = useState(true);
    const [usersPsId, setUserPsID] = useState(handlePsId());
    const [promotionText, setPromotionText] = React.useState(null);
    const [promotionDetails, setPromotionDetails] = React.useState(null);
    const [openUpsellModal, setOpenUpsellModal] = useState(false);
    const [recentlyAddedItem, setRecentlyAddedItem] = useState(null);
    const [afterBreakFirstPickupTime, setAfterBreakFirstPickupTime] = useState({
        value: "",
        label: "",
        afterBreak: true
    });
    const [chosenUpsellItems, setChosenUpsellItems] = useState([]);
    const [isSlidingCartOpen, setIsSlidingCartOpen] = useState(false);
    const [storeTemplate, setStoreTemplate] = useState(1);
    const [scheduleDeliveries, setScheduleDeliveries] = useState(false);
    const [schedulePickup, setSchedulePickup] = useState(false);
    const [activeCategoryPromo, setActiveCategoryPromo] = useState(null);
    const [deliveryInitialPrepTime, setDeliveryInitialPrepTime] = useState(0);
    const [pickupInitialPrepTime, setPickupInitialPrepTime] = useState(0);
    const [scheduledTimeIntervals, setScheduledTimeIntervals] = useState(15);
    const [deliveryIntervalPrepTime, setDeliveryIntervalPrepTime] = useState(0);
    const [businessAlertText, setBusinessAlertText] = useState(
        "Store is currently closed but you can place scheduled order right now"
    );

    // const storeTemplate = parseInt(new URLSearchParams(window.location.search).get("template"));

    //----------------------------------------------------------------------------------
    //HELPER FUNCTIONS
    //----------------------------------------------------------------------------------
    //Open or close Desktop sliding cart
    const handleSetIsSlidingCartOpen = useCallback(() => {
        setIsSlidingCartOpen(!isSlidingCartOpen);
    }, [isSlidingCartOpen]);

    const handleCalculateMinMaxTime = (date) => {
        const hoursOfOperation = currentMerchantsShop?.categories?.hours_of_operation;

        if (date !== null && hoursOfOperation) {
            const dayOfChoosenDate = date.toString().split(" ")[0].toLowerCase();
            const today = new Date().toString().split(" ")[0].toLowerCase();
            const dates = Object.keys(hoursOfOperation).find((item) => item.startsWith(dayOfChoosenDate));
            // let currentTime = `${new Date().getHours()}${new Date().getMinutes()}`
            let hour = `${new Date().getHours()}`;
            let minutes = `${new Date().getMinutes()}`;
            let hourPlusFifteen = `${new Date(new Date().getTime() + 15 * 60000).getHours()}`;
            let minutesPlusFifteen = `${new Date(new Date().getTime() + 15 * 60000).getMinutes()}`;

            if (hour < 10) {
                hour = `0${hour}`;
            }
            if (minutes < 10) {
                minutes = `0${minutes}`;
            }
            if (hourPlusFifteen < 10) {
                hourPlusFifteen = `0${hourPlusFifteen}`;
            }
            if (minutesPlusFifteen < 10) {
                minutesPlusFifteen = `0${minutesPlusFifteen}`;
            }

            const currentTimePlusFifteen = `${hourPlusFifteen}${minutesPlusFifteen}`;
            const currentTime = `${hour}${minutes}`;

            if (today === dayOfChoosenDate) {
                // if start is less than now
                const todayTimings = { ...hoursOfOperation[dates] };
                if (todayTimings.start < currentTime && todayTimings.end > currentTime) {
                    // todayTimings.start =  '0000';
                    todayTimings.start = handleMakeTimeMultipleOfFifteen(currentTimePlusFifteen);
                    return todayTimings;
                } else if (currentTime >= todayTimings.end) {
                    todayTimings.start = todayTimings.end;
                    return todayTimings;
                } else {
                    return hoursOfOperation[dates];
                }
            }
            return hoursOfOperation[dates];
        }
    };

    const handleSetOpenUpsellModal = useCallback(
        (val) => {
            setChosenUpsellItems(val);
            setOpenUpsellModal(!openUpsellModal);
            setRecentlyAddedItem(null);
        },
        [openUpsellModal]
    );

    const handleChangeUsersName = (e) => {
        setUsersDetails({
            ...usersDetails,
            name: e.target.value
        });
    };

    /**
     * This functions handle toggling the order method and also the susequent order url
     * @param {string} item the order method to be changed into
     */
    const handleChooseOrderMethod = (item) => {
        if (item === "giftcard") {
            history.push("/giftcard", { ...getUrlParams() });
        }
        setSelectedOrderMethod(item);
        getCurrentBaseUrl(item);
        setOrderTypeModalOpen(false);
    };

    const getCurrentBaseUrl = (fulfillment_type) => {
        // get the baseurl, and if it is a delivery, remove the pickup flag else add the pickup flag to the url
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (fulfillment_type === "delivery") {
            if (urlParams.has("type")) {
                urlParams.set("type", "delivery");
                const new_url = urlParams.toString();
                history.replace(`?${new_url}`);
            } else {
                urlParams.append("type", "delivery");
                const new_url = urlParams.toString();
                history.replace(`?${new_url}`);
            }
        } else if (fulfillment_type === "pickup") {
            if (urlParams.has("type")) {
                urlParams.set("type", "pickup");
                const new_url = urlParams.toString();
                history.replace(`?${new_url}`);
            } else {
                urlParams.append("type", "pickup");
                const new_url = urlParams.toString();
                history.replace(`?${new_url}`);
            }
        }
    };

    const handleDisabled = () => {
        if (selectedOrderMethod === "") {
            return 1;
        }
        return 0;
    };

    const setCategory = () => {
        return (
            currentMerchantsShop &&
            currentMerchantsShop.categories !== undefined &&
            setCurrentMenuCategory((currentMerchantsShop && currentMerchantsShop.categories.menu[0]?.name) || null)
        );
    };

    // this closes the confirm order for group modal
    const handleCloseConfirmModal = () => {
        setIsConfirmOrderOpen(false);
    };

    const handleUserHasPromo = () => {
        if (
            (currentMerchantsShop?.categories?.promo_greeting_text &&
                currentMerchantsShop?.categories?.promo_greeting_text !== "") ||
            (currentMerchantsShop?.categories?.promo_text && currentMerchantsShop?.categories?.promo_text !== "") ||
            (currentMerchantsShop?.categories?.promo_instruction &&
                currentMerchantsShop?.categories?.promo_instruction !== "")
        ) {
            return true;
        } else {
            return false;
        }
    };

    const handleCloseModal = (e, recentlyAddedItem = null) => {
        e.preventDefault();
        setIsModalShown(false);
        setRecentlyAddedItem(recentlyAddedItem);
    };

    // This function coallates all items in the checkout and returns an object consisting of these items
    const handleCollatingCheckoutItems = () => {
        // deep copy the array
        const newCheckoutItems = JSON.parse(JSON.stringify(currentMerchantsShop.itemsInCheckout));
        newCheckoutItems.map((item) => {
            item.price = item.itemPrice * item.quantity;
            item.itemPrice = item.basePrice;
            item.basePrice = undefined;
            item.name = item.name.split(" - ")[0];

            // this will enable guest ordering
            if (guestName !== undefined) {
                item.name = guestName ? `*${guestName}* - ${item.name}` : item.name;
                item.guestName = guestName;
                // item.orderType = 'group';
            }
            // remove the isChecked value so we dont send it to the backend
            item.modifiers.map((mod) => {
                delete mod.isChecked;
                // add a category to the modifiers since other modifiers has categories
                // mod.category = 'Extra Topping Additions($0.50/each)';
                // divide the price by 100
            });

            // combine optional modifiers and required modifiers into a modifier array and delete the optionl modifier data. but if the customer does not select an optional modifier, the required modifier becomes the only modifier
            if (item.modifiers.length > 0) {
                if (item.optionsRadioValue !== undefined) {
                    item.optionsRadioValue.map((value) => {
                        if (storeVendor === "revel") {
                            return (value.price = value.price);
                        } else if (storeVendor?.toLowerCase() !== "chowly") {
                            return (value.price /= 100);
                        } else {
                            return (value.price = value.price);
                        }
                    });
                    item.modifiers = [...item.modifiers, ...item.optionsRadioValue];
                }

                // item.modifiers = item.modifiers && [...item.modifiers, ...item.optionsRadioValue];
                delete item.optionsRadioValue;

                // ORDERMARK
                if (storeVendor === "ordermark") {
                    item.modifiers.map((mod) => {
                        return (mod.name = `${mod.category} || ${mod.name}`);
                    });

                    //OTTER AND CHOWLY
                } else if (storeVendor === "otter" || storeVendor?.toLowerCase() === "chowly") {
                    item.modifiers.map((mod) => {
                        const splittedName = mod.name.split("||");
                        if (
                            splittedName &&
                            splittedName[0] &&
                            mod.category &&
                            splittedName[0].trim() == mod.category.trim()
                        ) {
                            return mod.name;
                        } else {
                            return (mod.name = `${mod.category} || ${mod.name}`);
                        }
                    });
                }
                return item;
            }
            if (item.modifiers.length === 0 && item.optionsRadioValue !== undefined) {
                item.optionsRadioValue.map(
                    (value) => {
                        if (storeVendor === "revel") {
                            return (value.price = value.price);
                        } else if (storeVendor?.toLowerCase() !== "chowly") {
                            return (value.price /= 100);
                        } else {
                            return (value.price = value.price);
                        }
                    }
                    // (value.price = value.price)
                );
                if (item.optionsRadioValue !== undefined) {
                    item.modifiers = [...item.modifiers, ...item.optionsRadioValue];
                }

                delete item.optionsRadioValue;

                //ORDERMARK
                if (storeVendor === "ordermark") {
                    item.modifiers.map((mod) => {
                        return (mod.name = `${mod.category} || ${mod.name}`);
                    });

                    //OTTER AND CHOWLY
                } else if (storeVendor === "otter" || storeVendor?.toLowerCase() === "chowly") {
                    item.modifiers.map((mod) => {
                        const splittedName = mod.name.split("||");
                        if (splittedName && splittedName[0].trim() == mod.category.trim()) {
                            return mod.name;
                        } else {
                            return (mod.name = `${mod.category} || ${mod.name}`);
                        }
                    });
                }

                return item;
            }
        });

        console.log({ newCheckoutItems });
        return newCheckoutItems;
    };

    /**
     * this function handles opening the modal for a selected item or adss the item
     * if the item doesnot have extras like modifiers or options
     * @param {object} item The item to be added to the cart
     */
    const handleModalOpenOrAddItem = useCallback(
        (item) => {
            // setCurrentSelectedModalItem(item);
            const { name, id, price } = item;

            const options = () => {
                if (storeVendor === "otter") {
                    var zeroVal = 0;
                    return price === 0 ? [{ id, name, price: zeroVal }] : [{ id, name, price }];
                }
                if (item.options.length === 0 && price) {
                    return [{ id, name, price }];
                }
                if (storeVendor === "revel") {
                    return [{ id, name, price }];
                }
                if (storeVendor === "square") {
                    return item.options;
                }
            };

            setModalData(item);
            setIsModalShown(true);
            setOptionsValue(options);
            // }
        },
        [storeVendor]
    );

    // This functions gets the total number of items in the car
    const getTotalItemsInCart = () => {
        const itemsInCartArray = [];
        currentMerchantsShop.itemsInCheckout.map((item) => itemsInCartArray.push(item.quantity));
        const totalItems = itemsInCartArray.reduce((acc, curr) => acc + curr, 0);
        return totalItems;
    };

    // this function submits the name of the guest into the redux store
    const handleSubmitName = (name) => {
        setNameOfGuest(name);
        setIsGuestModalOpen(false);
    };

    // handles the payment modal opening
    const handleOpenPaymentModal = () => {
        setIsPaymentModalOpen(true);
    };

    const handleAddDataToGuest = () => {
        addItemToGuestOrder(handleCollatingCheckoutItems());
        setIsConfirmOrderOpen(true);
    };

    // handles the payment modal closing
    const handleClosePaymentModal = () => {
        setIsPaymentModalOpen(false);
    };

    const telephoneCheck = (str) => {
        const regex = /^(1\s?)?(\(\d{3}\)|\d{3})[\s\-]?\d{3}[\s\-]?\d{4}$/;
        return regex.test(str);
    };

    /**
     * this function helps with handling scheduled order.
     */
    const handleStartScheduledOrder = (item) => {
        const { scheduledOrderTime, selectedOrderMethod, baseUrl } = item;
        // use push state to replace the window url since pickup order looks for a flag in the url to know it is a pickup order
        // //save scheduled order time and type to locale storage
        lscache.set("scheduledOrderDetails", item, 20);

        // else continue the order
        setIsStoreOpen(true);
    };

    // handles getting the scheduled order details from the local storage
    const handleGetScheduleOrderDetails = () => {
        return (
            scheduledOrderTime &&
            `${selectedOrderMethod} for ${scheduledOrderTime.label} at ${convert24hrTo12hrTime(
                scheduledOrderTime?.time
            )}`
        );
    };

    //----------------------------------------------------
    //NETWORK REQUEST RELATED FUNCTION
    //----------------------------------------------------
    const handleLoadCheckoutItemFromSession = async (pageId) => {
        const URL = "https://messenger.joyup.me/order";
        const formData = new URLSearchParams();
        formData.append("psid", usersPsId);
        formData.append("botid", pageId);
        try {
            const response = await fetch(URL, {
                body: formData,
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                },
                method: "POST"
            });

            if (response.ok) {
                const data = await response.json();

                const { items } = data;
                items.map((item) => {
                    addItemToCheckout(item);
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    //----------------------------------------------------------------------------------
    //LAYOUT  EFFECTS
    //----------------------------------------------------------------------------------
    useLayoutEffect(() => {
        const handleScroll2 = () => {
            const scrollPos = window.scrollY;
            if (scrollPos < scrollPosition.current) {
                setIsPictureShown(true);
            } else {
                setIsPictureShown(false);
            }
        };

        window.addEventListener("scroll", handleScroll2);
        return () => window.removeEventListener("scroll", handleScroll2);
    }, []);

    //----------------------------------------------------------------------------------
    //SIDE EFFECTS
    //----------------------------------------------------------------------------------

    //If items are in the cart then open the cart on page load
    useEffect(() => {
        if (currentMerchantsShop && currentMerchantsShop.itemsInCheckout) {
            const totalItems = currentMerchantsShop.itemsInCheckout.reduce((total, b) => {
                return total + b.quantity;
            }, 0);

            if (totalItems > 0) {
                setIsSlidingCartOpen(true);
            }
        }
    }, []);

    useEffect(() => {
        mySet.add({ count: 1 });
        if (currentMerchantsShop?.categories?.hours_of_operation) {
            const values = handleCalculateMinMaxTime(new Date());

            if (!values) return;

            const { start, end } = values;
            const openSlots = getTimeInterval(start, end, 15, openingHours.breakStart, openingHours.breakEnd);

            const afterBreakTimes = openSlots.filter((time) => {
                return time.afterBreak === true;
            });

            let afterBreakFirstPickupTime = afterBreakTimes[1]; //Use index 1 because index 0 is always the time the store just opens

            if (!afterBreakFirstPickupTime) return;

            setAfterBreakFirstPickupTime(afterBreakFirstPickupTime);
        }
    }, [openingHours, currentMerchantsShop?.categories?.hours_of_operation]);

    let merchantId = getUrlParams().merchantId;
    useEffect(() => {
        //Specifically still for AGAS
        if (merchantId == "6075e037666fec40b0bd0773") {
            setScheduledTimeIntervals(20);
            setDeliveryIntervalPrepTime(20);
        }
        if (
            currentMerchantsShop?.categories?.init_pickup_prep_time &&
            currentMerchantsShop?.categories?.init_delivery_prep_time
        ) {
            setDeliveryInitialPrepTime(currentMerchantsShop?.categories?.init_delivery_prep_time);
            setPickupInitialPrepTime(currentMerchantsShop?.categories?.init_pickup_prep_time);
        }
    }, [currentMerchantsShop?.categories]);

    useEffect(() => {
        if (currentMerchantsShop?.categories?.template) {
            setStoreTemplate(currentMerchantsShop?.categories?.template);
        }
    }, [currentMerchantsShop?.categories?.template]);

    useEffect(() => {
        if (currentMerchantsShop?.categories?.schedule_delivery) {
            setScheduleDeliveries(currentMerchantsShop?.categories?.schedule_delivery);
        } else {
            setScheduleDeliveries(false);
        }

        if (currentMerchantsShop?.categories?.schedule_pickup) {
            setSchedulePickup(currentMerchantsShop?.categories?.schedule_pickup);
        } else {
            setSchedulePickup(false);
        }
    }, [currentMerchantsShop?.categories]);

    useEffect(() => {
        if (currentMerchantsShop?.categories?.alert_text) {
            if (currentMerchantsShop?.categories?.alert_text.length === 0) {
                return;
            } else {
                setBusinessAlertText(currentMerchantsShop?.categories?.alert_text);
            }
        }
    }, [currentMerchantsShop?.categories]);

    useEffect(() => {
        if (
            currentMerchantsShop?.categories?.is_pickup === true &&
            getUrlParams().merchantId === "6075e037666fec40b0bd0773"
        ) {
            setSelectedOrderMethod(getUrlParams().deliveryType || "pickup");
            return;
        }

        if (currentMerchantsShop?.categories?.is_pickup === true) {
            setSelectedOrderMethod("pickup");
        } else if (currentMerchantsShop?.categories?.is_delivery === true) {
            setSelectedOrderMethod("delivery");
        }
        // else if (!scheduleDeliveries && !isStoreOpen) {
        //     setSelectedOrderMethod("pickup");
        // }
    }, [currentMerchantsShop?.categories, scheduleDeliveries]);

    //------------------------------------------
    //PROMO VARIABLES
    //------------------------------------------
    let showCategoryPromoBasedOnWeb = false;

    if (currentMerchantsShop?.categories?.promoChannel?.includes("WEB") && getUrlParams().channel == "web") {
        showCategoryPromoBasedOnWeb = true;
    } else if (
        currentMerchantsShop?.categories?.promoChannel?.includes("FACEBOOK") &&
        getUrlParams().channel != "web"
    ) {
        showCategoryPromoBasedOnWeb = true;
    } else if (currentMerchantsShop?.categories?.promoChannel?.includes("SMS") && getUrlParams().channel != "web") {
        showCategoryPromoBasedOnWeb = true;
    }

    useEffect(() => {
        const setPromoTextFunction = () => {
            if (currentMerchantsShop?.categories) {
                const promoItem = currentMerchantsShop?.categories?.menu.filter(
                    (item) => item.is_promo_running === true
                );

                //Check that category has mat least one item before displaying it
                let firstFilter = currentMerchantsShop?.categories?.menu.filter((category) => {
                    return category.items.length > 0;
                });

                let categoriesWithItems = [];

                const usersCurrentTime = getUsersCurrentTime();

                firstFilter.forEach((item) => {
                    if (item.hours_of_availability) {
                        const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
                        const today = days[new Date().getDay()];

                        if (item.hours_of_availability[today]) {
                            const { start, end } = item.hours_of_availability[today];
                            if (usersCurrentTime >= start && usersCurrentTime <= end) {
                                categoriesWithItems.push(item);
                            }
                        }
                    } else {
                        categoriesWithItems.push(item);
                    }
                });

                //Check if category shoudl be available by this hour
                if (promoItem.length > 0) {
                    setPromotionText(currentMerchantsShop?.categories && promoItem[0].promo_text);
                    setPromotionDetails(currentMerchantsShop?.categories && promoItem[0].promo_details);
                    setActiveCategoryPromo(promoItem[0]);

                    const running = categoriesWithItems.filter((item) => item.is_promo_running === true);
                    const notRunning = categoriesWithItems.filter((item) => item.is_promo_running !== true);

                    let sorted;
                    if (showCategoryPromoBasedOnWeb) {
                        sorted = [...running, ...notRunning];
                    } else {
                        sorted = categoriesWithItems;
                    }
                    setSortedCurrentActiveItems(sorted);
                } else {
                    setSortedCurrentActiveItems(categoriesWithItems);
                }
            } else {
                setPromotionText(null);
                setPromotionDetails(null);
                setActiveCategoryPromo(null);
            }
        };
        setPromoTextFunction();

        const handleSetStoreOpening = () => {
            const openingHours = currentMerchantsShop?.categories?.hours_of_operation;

            const vendor = currentMerchantsShop?.categories?.vendor;
            const is_active = currentMerchantsShop?.categories?.is_active;

            //If the store is not open (CASE 1)
            // if (vendor === "ordermark" || (vendor === "otter" && is_active === false)) {
            //     setIsStoreOpen(false);
            // } else
            if (
                //If store is not open (CASE 2)
                openingHours &&
                checkDayToUse(openingHours).isOpen === false &&
                lscache.get("scheduledOrderDetails") === null
            ) {
                setIsStoreOpen(false);
                setOpeningHours({
                    closingTime: checkDayToUse(openingHours).closingTime,
                    openingTime: checkDayToUse(openingHours).openingTime,
                    breakStart: checkDayToUse(openingHours).breakStart || "",
                    breakEnd: checkDayToUse(openingHours).breakEnd || "",
                    onBreak: checkDayToUse(openingHours).onBreak
                });
            } else if (openingHours) {
                setIsStoreOpen(true);

                setOpeningHours({
                    closingTime: checkDayToUse(openingHours).closingTime,
                    openingTime: checkDayToUse(openingHours).openingTime,
                    breakStart: checkDayToUse(openingHours).breakStart || "",
                    breakEnd: checkDayToUse(openingHours).breakEnd || "",
                    onBreak: checkDayToUse(openingHours).onBreak
                });
            } else {
                setIsStoreOpen(true);
            }
        };
        handleSetStoreOpening();
    }, [currentMerchantsShop]);

    //remove body scroll when modal is shown
    useEffect(() => {
        const handleRemoveBodyScrollWhenModalIsOpen = () => {
            if (
                isModalShown ||
                isGuestModalOpen ||
                isPaymentModalOpen ||
                isOrderTypeModalOpen ||
                isGuestOrderModalOpen ||
                isConfirmOrderOpen
            ) {
                // When the modal is shown, we want a fixed body
                document.body.style.top = `-${window.scrollY}px`;
                document.body.style.position = "fixed";
                document.body.style.right = "0px";
                document.body.style.left = "0px";
                // console.log(window.scrollY)
            } else {
                // When the modal is hidden, we want to remain at the top of the scroll position
                const scrollY = document.body.style.top;
                document.body.style.position = "unset";
                document.body.style.top = "";
                document.body.style.right = "";
                document.body.style.left = "";
                window.scrollTo(0, parseInt(scrollY || "0") * -1);
            }
        };

        handleRemoveBodyScrollWhenModalIsOpen();
    }, [
        isModalShown,
        isGuestModalOpen,
        isOrderTypeModalOpen,
        isGuestOrderModalOpen,
        isConfirmOrderOpen,
        isPaymentModalOpen
    ]);

    // deduplicate and extract items
    useEffect(() => {
        const getActiveItems = () => {
            if (currentMerchantsShop !== undefined && currentMerchantsShop.categories !== undefined) {
                setStoreVendor(currentMerchantsShop.categories.vendor);
                setProcessor(currentMerchantsShop.categories.processor);
                // sort item by campaign category
                const filterItems = (arr) => {
                    // filter item based on channel to get items
                    if (channel === "web") {
                        const filteredItems = arr.filter(
                            (item) =>
                                item.active === true &&
                                item.id &&
                                (item.is_campaign_category === false || item.is_campaign_category === undefined)
                        );
                        return filteredItems;
                    }
                    if (channel !== "web") {
                        const filteredItems = arr.filter(
                            (item) =>
                                item.active === true &&
                                item.id &&
                                (item.is_campaign_category === true || item.is_campaign_category === undefined)
                        );
                        return filteredItems;
                    }
                };

                const activeItems = filterItems(currentMerchantsShop.categories.menu);

                // checks if user is eligible for promo
                IsUserEligibleForPromo &&
                    activeItems.sort((x, y) =>
                        x.is_promo_running === y.is_promo_running ? 0 : x.is_promo_running ? -1 : 1
                    );
                // replace the name of the category with promo text
                activeItems.map((item) => {
                    if (IsUserEligibleForPromo && item.is_promo_running) item.name = ` ${item.promo_text} `;
                });

                setCurrentActiveItems(activeItems);
            }
        };

        setCategory();
        getActiveItems();
    }, [
        isCurrentCategoriesLoading,
        currentMerchantsShop !== undefined && currentMerchantsShop.categories,
        hasOptionsDataLoaded,
        currentMerchantsShop !== undefined
    ]);

    // clear item in checkout so that you can load the one from server session
    useEffect(() => {
        clearRedundantCheckout();
        // loadMerchantsCurrentShop(pageId);
        window.location.search.includes("group") && handleLoadGuestOrderSession(usersPsId);
    }, []);

    useEffect(() => {
        // check the PSID and chwck if the user already has a name
        const handleGuestModal = () => {
            if (orderType !== undefined && orderType === "group" && guestName === "") {
                setIsGuestModalOpen(true);
            }
        };
        handleGuestModal();
    }, []);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const queryType = urlParams.get("type");

        if (queryType === "giftcard") {
            // dispatchAction(loadItemsCategories({locationId: getUrlParams().locationId,
            //   merchantId: getUrlParams().merchantId})) &&
            history.push("/giftcard", { ...getUrlParams() });
        } else if (queryType === "giftcardbalance") {
            history.push("/giftcardbalance", { ...getUrlParams() });
        }
    }, []);

    useEffect(() => {
        clearLocalStorage("joyup-store-params");

        const { merchantId, locationId } = getUrlParams();

        const fetchStoreParams = async (url, userPromo) => {
            try {
                const BASE_URL = "https://waitstaff.joyup.me";
                const res = await axios.get(`${BASE_URL}/menu/get_store_params/${url}`);

                const { location: locationId, merchant_id: merchantId } = res.data;
                const pageId = res.data.page_id || "369499770162312";

                if (locationId && merchantId) {
                    const data = {
                        merchantId,
                        locationId,
                        pageId
                    };

                    if (userPromo) {
                        data.promo = true;
                    }
                    setLocalStorage("joyup-store-params", data);
                    loadCurrentMerchantsId();

                    loadItemsCategories({
                        locationId,
                        merchantId
                    });
                    handleLoadCheckoutItemFromSession(pageId);
                    handleLoadUserProfile({
                        botId: pageId,
                        merchantId,
                        psId: usersPsId,
                        promo: getUrlParams().promo === "true" ? true : false
                    });
                }
            } catch (err) {
                console.log(err);
            }
        };

        //OPTION 1: We are using the old orderx link that has all parameters in the url
        if (locationId && merchantId) {
            loadItemsCategories({
                locationId,
                merchantId
            });
            handleLoadCheckoutItemFromSession(pageId);
            handleLoadUserProfile({
                botId: pageId,
                merchantId,
                psId: usersPsId,
                promo: getUrlParams().promo === "true" ? true : false
            });
            loadCurrentMerchantsId();
        } else {
            //OPTION 2: We are using the new orderx link that has a shorter link
            const { url } = urlSubRoute;

            if (routeMatch.path === "/cyclebar") {
                fetchStoreParams("fairfield", true);
            } else {
                    fetchStoreParams(url);
            }
        }
    }, []);

    // useEffect(() => {
    //     //----------------------------------------------------
    //     //Check last time user visited
    //     //----------------------------------------------------
    //     let userLastSeen = getLocalStorage("joyup-session-lastSeen");

    //     console.log("======================", userLastSeen);
    //     if (userLastSeen && userLastSeen.userLastSeen) {
    //         //If 60 minutes have passed
    //         if (new Date().getTime() - userLastSeen.userLastSeen > 1000 * 60 * 60) {
    //             console.log("==============================about to clear checkout");
    //             //clear checkout
    //             clearRedundantCheckout();

    //             //Set new last seen
    //             let userLastSeen = new Date().getTime();
    //             setLocalStorage("joyup-session-lastSeen", { userLastSeen });
    //         }
    //     } else {
    //         //Set last seen
    //         let userLastSeen = new Date().getTime();
    //         setLocalStorage("joyup-session-lastSeen", { userLastSeen });
    //     }
    // }, []);

    // const promoChannel = ["FACEBOOK", "WEB", "SMS"];

    const getNumberOfItemsInCheckout = useCallback(() => {
        if (currentMerchantsShop.itemsInCheckout) {
            const totalItems = currentMerchantsShop.itemsInCheckout.reduce((total, b) => {
                return total + b.quantity;
            }, 0);

            return totalItems;
        }
    }, [currentMerchantsShop]);

    const renderItem = () => {
        if (currentMerchantsShop && currentMerchantsShop.categories !== undefined) {
            return (
                <StyledDesktopBody>
                    {/* ---------------------------------------------------------- */}
                    {/* Only show this if user picked storeTemplate 2 */}
                    {/* ---------------------------------------------------------- */}
                    {storeTemplate === 2 || storeTemplate === 3 || storeTemplate === 4 ? (
                        <div className="sliding-cart-opener">
                            <div>
                                <h3 className="store-name">{currentMerchantsShop.categories.merchant_name}</h3>
                                <p className="store-address">{currentMerchantsShop.categories.location_address}</p>
                            </div>
                            <div className="store-logo">
                                {/* STORE LOGO */}
                                <img src={currentMerchantsShop.categories.logo} alt="" />
                            </div>
                            <div className="cart-section" onClick={handleSetIsSlidingCartOpen}>
                                <img src={cartIcon} alt="" />
                                <div className="cart-item-count">{getNumberOfItemsInCheckout()}</div>
                            </div>
                        </div>
                    ) : null}
                    {/* ---------------------------------- */}
                    {/* Category Specific */}
                    {/* ---------------------------------- */}
                    {promotionText !== null && (showCategoryPromoBasedOnWeb || getUrlParams().promo === "true") && (
                        <PromoBanner isPromoPresent promoText={promotionText} promoDetails={promotionDetails} />
                    )}
                    {/* ---------------------------------- */}
                    {/* For User Specific Promo */}
                    {/* ---------------------------------- */}
                    {
                        // getUrlParams().channel === "web" &&
                        (getUrlParams().promo === true || getUrlParams().promo === "true") && handleUserHasPromo() && (
                            <WebPromoGreeting setIsRecommendationShown={setIsRecommendationShown} />
                        )
                    }
                    {/* ---------------------------------- */}
                    {/* For Store Wide Promo */}
                    {/* ---------------------------------- */}
                    {windowSize.width > 1024 &&
                        // getUrlParams().channel !== "web" &&
                        currentMerchantsShop?.categories?.promoText && (
                            <PromoRibbon
                                promoInstruction={currentMerchantsShop?.categories?.promoText}
                                isRibbonPromoPresent={currentMerchantsShop?.categories?.promoText}
                            />
                        )}
                    {/* ------------------------------------------------------------------ */}
                    {/* Desktop Cart that slides in from the right */}
                    {/* ------------------------------------------------------------------ */}
                    {(storeTemplate === 2 || storeTemplate === 3 || storeTemplate === 4) &&
                    currentMerchantsShop.itemsInCheckout ? (
                        <NewDesktopSlidingCart
                            isOpen={isSlidingCartOpen}
                            handleCloseCart={handleSetIsSlidingCartOpen}
                            currentMerchantsShop={currentMerchantsShop}
                            storeVendor={storeVendor}
                            isStoreOpen={isStoreOpen}
                            orderType={orderType}
                            guestSession={guestSession}
                            history={history}
                            handleCollatingCheckoutItems={handleCollatingCheckoutItems}
                            scheduledOrderTime={scheduledOrderTime}
                            handleAddDataToGuest={handleAddDataToGuest}
                            handleOpenUpsellModal={handleSetOpenUpsellModal}
                            activeCategoryPromo={activeCategoryPromo}
                            //////
                            onBreak={openingHours.onBreak}
                            selectedOrderMethod={selectedOrderMethod}
                            afterBreakFirstPickupTime={afterBreakFirstPickupTime}
                            storeTemplate={storeTemplate}
                            setOrderTypeModalOpen={() => {
                                setOrderTypeModalOpen(true);
                                setIsSlidingCartOpen(false);
                            }}
                        />
                    ) : null}
                    {/* ------------------------------------------------------------------ */}
                    {/* Store banner and timings */}
                    {/* ------------------------------------------------------------------ */}
                    <StyleImageDiv isPictureShown={isPictureShown}>
                        <LocationDetailsBanner
                            categories={currentMerchantsShop.categories}
                            storeTemplate={storeTemplate}
                        />
                    </StyleImageDiv>
                    {/* ------------------------------------------------------------------ */}
                    {/* mobile specific code */}
                    {/* ------------------------------------------------------------------ */}
                    {windowSize.width < 1024 && (
                        <MobileView
                            scrollPosition={scrollPosition}
                            isPictureShown={isPictureShown}
                            openingHours={openingHours}
                            scheduledOrderTime={scheduledOrderTime}
                            sortedCurrentActiveItems={sortedCurrentActiveItems}
                            currentMenuCategory={currentMenuCategory}
                            showCategoryPromoBasedOnWeb={showCategoryPromoBasedOnWeb}
                            isScrolling={isScrolling}
                            currentMerchantsShop={currentMerchantsShop}
                            setFocusedCategories={setFocusedCategories}
                            setCurrentMenuCategory={setCurrentMenuCategory}
                            setIsScrolling={setIsScrolling}
                            handleModalOpenOrAddItem={handleModalOpenOrAddItem}
                            userProfile={userProfile}
                            windowSize={windowSize}
                            handleAddDataToGuest={handleAddDataToGuest}
                            getTotalCartPrice={getTotalCartPrice}
                            getTotalItemsInCart={getTotalItemsInCart}
                            handleOpenPaymentModal={handleOpenPaymentModal}
                            handleClosePaymentModal={handleClosePaymentModal}
                            storeVendor={storeVendor}
                            isStoreOpen={isStoreOpen}
                            isPaymentModalOpen={isPaymentModalOpen}
                            handleCollatingCheckoutItems={handleCollatingCheckoutItems}
                            handleSetOpenUpsellModal={handleSetOpenUpsellModal}
                            activeCategoryPromo={activeCategoryPromo}
                            onBreak={openingHours.onBreak}
                            selectedOrderMethod={selectedOrderMethod}
                            afterBreakFirstPickupTime={afterBreakFirstPickupTime}
                            storeTemplate={storeTemplate}
                            setOrderTypeModalOpen={() => {
                                setOrderTypeModalOpen(true);
                                setIsSlidingCartOpen(false);
                            }}
                            setIsRecommendationAvailable={setIsRecommendationAvailable}
                            isRecommendationAvailable={isRecommendationAvailable}
                        />
                    )}
                    {/* ------------------------------------------------------------------ */}
                    {/* ------------------------------------------------------------------ */}
                    {/* ------------------------------------------------------------------ */}
                    {/* ------------------------------------------------------------------ */}
                    {/* desktop specific code */}
                    {/* ------------------------------------------------------------------ */}
                    {windowSize.width >= 1024 && (
                        <Desktop
                            currentActiveItems={sortedCurrentActiveItems}
                            handleModalOpenOrAddItem={handleModalOpenOrAddItem}
                            currentMerchantsShop={currentMerchantsShop}
                            handleCollatingCheckoutItems={handleCollatingCheckoutItems}
                            handleClosePaymentModal={handleClosePaymentModal}
                            storeVendor={storeVendor}
                            isPaymentModalOpen={isPaymentModalOpen}
                            windowSize={windowSize.width}
                            isStoreOpen={isStoreOpen}
                            scheduledOrderTime={scheduledOrderTime}
                            usersDetails={usersDetails}
                            userProfile={userProfile}
                            setOrderTypeModalOpen={setOrderTypeModalOpen}
                            handleOpenUpsellModal={handleSetOpenUpsellModal}
                            // nextIntervalOpeningTime={openingHours.nextIntervalOpeningTime}
                            breakStart={openingHours.breakStart}
                            breakEnd={openingHours.breakEnd}
                            // handleCheckout={handleCheckout}
                            // isPaymentProcessing={isPaymentProcessing}
                            showCategoryPromoBasedOnWeb={showCategoryPromoBasedOnWeb}
                            activeCategoryPromo={activeCategoryPromo}
                            onBreak={openingHours.onBreak}
                            selectedOrderMethod={selectedOrderMethod}
                            afterBreakFirstPickupTime={afterBreakFirstPickupTime}
                            storeTemplate={storeTemplate}
                            setOrderTypeModalOpen={() => {
                                setOrderTypeModalOpen(true);
                                setIsSlidingCartOpen(false);
                            }}
                            setIsRecommendationAvailable={setIsRecommendationAvailable}
                            isRecommendationAvailable={isRecommendationAvailable}
                        />
                    )}
                    {/* ------------------------------------------------------------------ */}
                    {/* ------------------------------------------------------------------ */}
                    {/* ------------------------------------------------------------------ */}
                    {/* ------------------------------------------------------------------ */}
                    {/* Modal for item, opened when user clicks on "add item" on an item */}
                    {/* ------------------------------------------------------------------ */}
                    {/* shared code for both mobile and desktop */}
                    {isModalShown && (
                        <Modal
                            handleCloseModal={handleCloseModal}
                            modalData={modalData}
                            merchantID={currentMerchantsShop.categories.merchant_id}
                            optionsValue={optionsValue}
                            setOptionsValue={setOptionsValue}
                            addItemToCheckout={addItemToCheckout}
                            handleItemCountChange={handleItemCountChange}
                            itemsInCheckout={currentMerchantsShop.itemsInCheckout}
                            openingHours={currentMerchantsShop.categories.hours_of_operation}
                            storeVendor={storeVendor}
                            isStoreOpen={isStoreOpen}
                            totalCartPrice={parseFloat(
                                getTotalCartPrice(currentMerchantsShop.itemsInCheckout, false, activeCategoryPromo)
                            ).toFixed(2)}
                            scheduledOrderTime={scheduledOrderTime}
                            handleSetIsSlidingCartOpen={handleSetIsSlidingCartOpen}
                            storeTemplate={storeTemplate}
                            activeCategoryPromo={activeCategoryPromo}
                            setIsRecommendationShown={setIsRecommendationShown}
                            isRecommendationAvailable={isRecommendationAvailable}
                        />
                    )}
                    {/* ------------------------------------------------------------------ */}
                    {/* ------------------------------------------------------------------ */}
                    {/* ------------------------------------------------------------------ */}
                    <ToastsContainer
                        store={ToastsStore}
                        position={ToastsContainerPosition.BOTTOM_CENTER}
                        lightBackground
                    />
                    {/* modal for guest introducing */}
                    {isGuestModalOpen && (
                        <GuestIntro
                            handleSubmitName={handleSubmitName}
                            adminImage={guestSession.length > 0 && guestSession[0].user.profile_pic}
                            adminName={
                                guestSession.length > 0 &&
                                `${guestSession[0].user.first_name} ${guestSession[0].user.last_name}`
                            }
                        />
                    )}
                    {/* ------------------------------------------------------------------ */}
                    {/* Upsell Modal */}
                    {/* ------------------------------------------------------------------ */}
                    {openUpsellModal && (
                        <ProductUpsellModal
                            handleChangeUsersName={handleChangeUsersName}
                            selectedOrderMethod={selectedOrderMethod}
                            handleChooseOrderMethod={handleChooseOrderMethod}
                            usersDetails={usersDetails}
                            handleDisabled={handleDisabled}
                            setOrderTypeModalOpen={setOrderTypeModalOpen}
                            hoursOfOperation={currentMerchantsShop?.categories?.hours_of_operation}
                            openingTime={openingHours.openingTime}
                            closingTime={openingHours.closingTime}
                            setScheduledOrderTime={setScheduledOrderTime}
                            currentMerchantsShop={currentMerchantsShop}
                            isOrderTypeModalOpen={true}
                            //----
                            //The Props below are as regards displaying the modal
                            //----
                            handleCloseProductUpsellModal={handleSetOpenUpsellModal}
                            handleModalOpenOrAddItem={handleModalOpenOrAddItem}
                            recentlyAddedItem={recentlyAddedItem}
                            //----
                            //The props below are for adding the item to the cart
                            //----
                            storeVendor={storeVendor}
                            optionsValue={optionsValue}
                            merchantID={currentMerchantsShop.categories.merchant_id}
                            isStoreOpen={isStoreOpen}
                            scheduledOrderTime={scheduledOrderTime}
                            setOptionsValue={setOptionsValue}
                            addItemToCheckout={addItemToCheckout}
                            handleItemCountChange={handleItemCountChange}
                            handleCloseModal={handleCloseModal}
                            //----
                            //The props below are for checking out
                            //----
                            handleCollatingCheckoutItems={handleCollatingCheckoutItems}
                            chosenUpsellItems={chosenUpsellItems}
                            activeCategoryPromo={activeCategoryPromo}
                        />
                    )}
                    {/* ------------------------------------------------------------------ */}
                    {/* ------------------------------------------------------------------ */}
                    {/* ------------------------------------------------------------------ */}
                    {currentMerchantsShop?.categories?.hours_of_operation && (
                        <OrderTypeChooser
                            isRecommendationAvailable={isRecommendationAvailable}
                            setIsRecommendationAvailable={setIsRecommendationAvailable}
                            handleChangeUsersName={handleChangeUsersName}
                            selectedOrderMethod={selectedOrderMethod}
                            handleChooseOrderMethod={handleChooseOrderMethod}
                            usersDetails={usersDetails}
                            handleDisabled={handleDisabled}
                            isOrderTypeModalOpen={isOrderTypeModalOpen}
                            setOrderTypeModalOpen={setOrderTypeModalOpen}
                            isStoreOpen={isStoreOpen}
                            hoursOfOperation={currentMerchantsShop?.categories?.hours_of_operation}
                            openingTime={openingHours.openingTime}
                            closingTime={openingHours.closingTime}
                            breakStart={openingHours.breakStart}
                            breakEnd={openingHours.breakEnd}
                            onBreak={openingHours.onBreak}
                            // nextIntervalOpeningTime={openingHours.nextIntervalOpeningTime}
                            scheduledOrderTime={scheduledOrderTime}
                            setScheduledOrderTime={setScheduledOrderTime}
                            currentMerchantsShop={currentMerchantsShop}
                            afterBreakFirstPickupTime={afterBreakFirstPickupTime}
                            scheduleDeliveries={scheduleDeliveries}
                            pickupInitialPrepTime={pickupInitialPrepTime}
                            deliveryInitialPrepTime={deliveryInitialPrepTime}
                            scheduledTimeIntervals={scheduledTimeIntervals}
                            deliveryIntervalPrepTime={deliveryIntervalPrepTime}
                            schedulePickup={schedulePickup}
                            businessAlertText={businessAlertText}
                        />
                    )}
                    {isGuestOrderModalOpen && (
                        <div
                            style={{
                                background: "white",
                                position: "fixed",
                                height: "100vh",
                                maxWidth: 375,
                                marginRight: "auto",
                                marginLeft: "auto",
                                right: 0,
                                left: 0
                            }}>
                            <GuestOrderSuccess
                                setIsGuestOrderModalOpen={setIsGuestOrderModalOpen}
                                guestName={guestName}
                                groupHost={guestSession[0].user.first_name}
                            />
                        </div>
                    )}
                    {isConfirmOrderOpen && (
                        <GuestOrderConfirmation
                            handleCloseConfirmModal={handleCloseConfirmModal}
                            // handleContinueGroupOrder={handleContinueGroupOrder}
                            // isPaymentProcessing={isPaymentProcessing}
                        />
                    )}
                    ]
                    {RECOMMENDATION_MERCHANTS_WHITELIST.includes(merchantId) && (
                        <WebPromoRecommendation
                            show={isRecommendationShown}
                            onHide={setIsRecommendationShown}
                            handleModalOpenOrAddItem={handleModalOpenOrAddItem}
                        />
                    )}
                </StyledDesktopBody>
            );
        }

        if (hasAnErrorOccur) {
            return (
                <StyledFullWidth>
                    <p>Error Loading store Please refresh</p>
                </StyledFullWidth>
            );
        } else {
            return <StyledFullWidth>{renderLoading()}</StyledFullWidth>;
        }
    };

    //----------------------------------------------------------------------------------
    //MAIN RETURNED JSX
    //----------------------------------------------------------------------------------
    return renderItem();
};

//----------------------------------------------------------------------------------
//CONNCECT COMPONENT TO STORE
//----------------------------------------------------------------------------------
//connecting the states to the component
const mapStateToProps = (state) => {
    return {
        currentMerchantsShop: state.dashboard[getUrlParams().merchantId],
        currentlySelectedMerchant: getCurrentlySelectedMerchant(state),
        error: getError(state),
        guestName: getGuestName(state),
        guestSession: state.cart.guestSession,
        userProfile: state.cart.userProfile,
        hasUserProfileLoaded: state.cart.hasUserProfileLoaded,
        hasGuestSessionDataLoaded: state.cart.hasGuestSessionDataLoaded,
        hasAnErrorOccur: getHasAnErrorOccur(state),
        hasCurrentCategoriesLoaded: getHasCurrentCategoriesLoaded(state),
        hasCurrentFocusedCategoryBeenSet: getHasCurrentFocusedCategoryBeenSet(state),
        hasCurrentMerchantIdLoaded: getHasCurrentMerchantIdLoaded(state),
        hasCurrentMerchantShopLoaded: getHasCurrentMerchantShopLoaded(state),
        hasOptionsDataLoaded: getHasOptionsDataLoaded(state),
        isOptionsDataLoading: getIsOptionsDataLoading(state),
        isCurrentCategoriesLoading: getIsCurrentCategoriesLoading(state),
        isCurrentMerchantIdLoading: getIsCurrentMerchantIdLoading(state),
        isUserProfileLoading: getIsUserProfileLoading(state),
        isCurrentMerchantShopLoading: getIsCurrentMerchantShopLoading(state),
        visitedStore: getVisitedStore(state)
    };
};

//connecting the actions to the component
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            addItemToCheckout,
            clearRedundantCheckout,
            handleItemCountChange,
            loadCurrentMerchantsId,
            loadItemsCategories,
            loadMerchantsCurrentShop,
            setFocusedCategories,
            setNameOfGuest,
            handleLoadOptionsDataForFocusedCategory,
            handleLoadGuestOrderSession,
            handleLoadUserProfile,
            addItemToGuestOrder
        },
        dispatch
    );
};

//----------------------------------------------------------------------------------
//PROP TYPES
//----------------------------------------------------------------------------------
Dashboard.propTypes = {
    addItemToCheckout: PropTypes.func.isRequired,
    currentMerchantsShop: PropTypes.objectOf(PropTypes.any),
    currentlySelectedMerchant: PropTypes.string.isRequired,
    clearRedundantCheckout: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
    guestName: PropTypes.string.isRequired,
    handleItemCountChange: PropTypes.func.isRequired,
    hasAnErrorOccur: PropTypes.bool.isRequired,
    handleLoadOptionsDataForFocusedCategory: PropTypes.func.isRequired,
    handleLoadGuestOrderSession: PropTypes.func.isRequired,
    handleLoadUserProfile: PropTypes.func.isRequired,
    isCurrentCategoriesLoading: PropTypes.bool.isRequired,
    isCurrentMerchantIdLoading: PropTypes.bool.isRequired,
    isCurrentMerchantShopLoading: PropTypes.bool.isRequired,
    isUserProfileLoading: PropTypes.bool,
    loadCurrentMerchantsId: PropTypes.func.isRequired,
    loadItemsCategories: PropTypes.func.isRequired,
    loadMerchantsCurrentShop: PropTypes.func.isRequired,
    setFocusedCategories: PropTypes.func.isRequired,
    setNameOfGuest: PropTypes.func.isRequired
};

//----------------------------------------------------------------------------------
//MAIN EXPORT
//----------------------------------------------------------------------------------
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Dashboard));
