import React, { useState, useEffect } from "react";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";

import { StyledInputWrapperWithCalender, StyledSelect, StyledSelectMobile } from "../OrderTypeChooser.style";

const SelectFields = ({
    orderMethod,
    merchantId,
    orderType,
    getBusinessOpenDate,
    handleSelectDate,
    fieldIsDisabled,
    timeOptions,
    handleSelectTime,
    selectedScheduledDate,
    displayedTime,
    displayedDate,
    scheduleDeliveries,
    schedulePickup
}) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    useEffect(() => {
        setMenuIsOpen(false);
    }, [orderMethod]);

    return (
        <>
            <BrowserView>
                <StyledInputWrapperWithCalender
                    orderMethod={orderMethod}
                    merchantId={merchantId}
                    orderType={orderType}
                    scheduleDeliveries={scheduleDeliveries}
                    schedulePickup={schedulePickup}>
                    <StyledSelect
                        options={getBusinessOpenDate()}
                        classNamePrefix="calender-input"
                        onChange={handleSelectDate}
                        placeholder="Select Date"
                        isDisabled={fieldIsDisabled()}
                        value={displayedDate}
                    />

                    <StyledSelect
                        options={timeOptions}
                        classNamePrefix="calender-input"
                        placeholder="Select Time"
                        onChange={(e) => {
                            handleSelectTime(e);
                            setMenuIsOpen(false);
                        }}
                        onFocus={() => {
                            if (displayedDate === null) {
                                ToastsStore.error("Please select a date");
                                setMenuIsOpen(false);
                            } else {
                                setMenuIsOpen(true);
                            }
                        }}
                        onBlur={() => setMenuIsOpen(false)}
                        blurInputOnSelect={true}
                        isDisabled={fieldIsDisabled()}
                        value={displayedTime}
                        menuIsOpen={menuIsOpen}
                    />
                </StyledInputWrapperWithCalender>
            </BrowserView>

            <MobileView>
                <StyledInputWrapperWithCalender
                    orderMethod={orderMethod}
                    merchantId={merchantId}
                    orderType={orderType}
                    scheduleDeliveries={scheduleDeliveries}
                    schedulePickup={schedulePickup}>
                    <StyledSelectMobile data-id="mobile" onChange={handleSelectDate} placeholder="Select Date">
                        <option value={null} selected={displayedDate === null}>
                            Select Date
                        </option>
                        {getBusinessOpenDate().map((item, index) => {
                            const { label, fullDate, value } = item;
                            return (
                                <option
                                    name={label}
                                    value={index}
                                    key={value}
                                    selected={displayedDate?.value === value}>
                                    {label}
                                </option>
                            );
                        })}
                    </StyledSelectMobile>
                    <StyledSelectMobile
                        data-id="mobile"
                        onChange={handleSelectTime}
                        onMouseDown={(e) => {
                            if (displayedDate === null) {
                                ToastsStore.error("Please select a date");
                                e.preventDefault();
                            } else {
                            }
                        }}
                        placeholder="Select Time">
                        <option value={null} selected={displayedTime === null}>
                            Select Time
                        </option>
                        {timeOptions.map((item, index) => {
                            const { label, value } = item;
                            return (
                                <option
                                    name={label}
                                    value={index}
                                    key={value}
                                    selected={displayedTime?.value === value}>
                                    {label}
                                </option>
                            );
                        })}
                    </StyledSelectMobile>
                </StyledInputWrapperWithCalender>
            </MobileView>
        </>
    );
};

export default SelectFields;
