/*
Until a time where the menu is able to "handle sub-categories" feature,
we would display sub categories by manipulating the dom here
*/
import React from "react";
import uuid from "uuid";
import { getUrlParams } from "./utils";
import { StyledItemSection as StyledDesktopItemSection } from "#/pages/orderPage/components/desktopView/components/Desktop.style";
import { RenderItemData } from "#/pages/orderPage/components/ItemList";

import { StyledItemSection as StyledMobileItemSection } from "#/pages/orderPage/components/styles";

export const returnedDesktopCatItemDomNodes = (
    currentActiveItems,
    commonText,
    commonStorageGroupedClassName,
    showCategoryPromoBasedOnWeb,
    handleModalOpenOrAddItem,
    userProfile,
    storeTemplate
) => {
    let mainStorage = [];
    let commonStorage = [];
    let commonStorageGrouped = "";

    currentActiveItems.forEach((categoryItem) => {
        if (categoryItem.name.toLowerCase().includes(commonText)) {
            const { id, items, name } = categoryItem;
            const node = (
                <div id={name} key={id} className="category-wrapper">
                    <p className="item-name" id={name}>
                        {categoryItem.promo_text && (showCategoryPromoBasedOnWeb || getUrlParams().promo === "true")
                            ? categoryItem.promo_text + " - " + name
                            : name}
                    </p>
                    <StyledDesktopItemSection key={id}>
                        {items.map((data, index) => (
                            <RenderItemData
                                key={uuid()}
                                item={data}
                                handleModalOpenOrAddItem={handleModalOpenOrAddItem}
                                menu={currentActiveItems}
                                index={index}
                                userProfile={userProfile}
                                storeTemplate={storeTemplate}
                            />
                        ))}
                    </StyledDesktopItemSection>
                </div>
            );
            commonStorage.push(node);
        } else {
            const { id, items, name } = categoryItem;
            const node = (
                <div id={name} key={id} className="category-wrapper">
                    <p className="item-name" id={name}>
                        {categoryItem.promo_text && (showCategoryPromoBasedOnWeb || getUrlParams().promo === "true")
                            ? categoryItem.promo_text + " - " + name
                            : name}
                    </p>
                    <StyledDesktopItemSection key={id}>
                        {items.map((data, index) => (
                            <RenderItemData
                                key={uuid()}
                                item={data}
                                handleModalOpenOrAddItem={handleModalOpenOrAddItem}
                                menu={currentActiveItems}
                                index={index}
                                userProfile={userProfile}
                                storeTemplate={storeTemplate}
                            />
                        ))}
                    </StyledDesktopItemSection>
                </div>
            );
            mainStorage.push(node);
        }
    });

    commonStorageGrouped = <div id={commonStorageGroupedClassName}>{commonStorage}</div>;
    const indexToInsert = currentActiveItems.findIndex((item) => {
        return item.name.toLowerCase().includes(commonText);
    });
    mainStorage.splice(indexToInsert, 0, commonStorageGrouped);

    return mainStorage;
};

export const returnedMobileCatItemDomNodes = (
    menu,
    commonText,
    commonStorageGroupedClassName,
    showCategoryPromoBasedOnWeb,
    handleModalOpenOrAddItem,
    userProfile,
    storeTemplate
) => {
    let mainStorage = [];
    let commonStorage = [];
    let commonStorageGrouped = "";

    menu.forEach((categoryItem) => {
        if (categoryItem.name.toLowerCase().includes(commonText)) {
            const { id, items, _id, name, is_promo_running, promo_text } = categoryItem;
            const node = (
                <StyledMobileItemSection key={id} id={name}>
                    <p className="item-name">
                        {is_promo_running && (showCategoryPromoBasedOnWeb || getUrlParams().promo === "true")
                            ? `${promo_text.toUpperCase()} - ${name.toUpperCase()}`
                            : name.toUpperCase()}
                    </p>

                    <div className="items-container">
                        {items.map((data, index) => (
                            <RenderItemData
                                key={uuid()}
                                item={data}
                                handleModalOpenOrAddItem={handleModalOpenOrAddItem}
                                menu={menu}
                                index={index}
                                userProfile={userProfile}
                                showCategoryPromoBasedOnWeb={showCategoryPromoBasedOnWeb}
                                storeTemplate={storeTemplate}
                            />
                        ))}
                    </div>
                </StyledMobileItemSection>
            );
            commonStorage.push(node);
        } else {
            const { id, items, _id, name, is_promo_running, promo_text } = categoryItem;
            const node = (
                <StyledMobileItemSection key={id} id={name}>
                    <p className="item-name">
                        {is_promo_running && (showCategoryPromoBasedOnWeb || getUrlParams().promo === "true")
                            ? `${promo_text.toUpperCase()} - ${name.toUpperCase()}`
                            : name.toUpperCase()}
                    </p>

                    <div className="items-container">
                        {items.map((data, index) => (
                            <RenderItemData
                                key={uuid()}
                                item={data}
                                handleModalOpenOrAddItem={handleModalOpenOrAddItem}
                                menu={menu}
                                index={index}
                                userProfile={userProfile}
                                showCategoryPromoBasedOnWeb={showCategoryPromoBasedOnWeb}
                                storeTemplate={storeTemplate}
                            />
                        ))}
                    </div>
                </StyledMobileItemSection>
            );
            mainStorage.push(node);
        }
    });

    commonStorageGrouped = <div id={commonStorageGroupedClassName}>{commonStorage}</div>;
    const indexToInsert = menu.findIndex((item) => {
        return item.name.toLowerCase().includes(commonText);
    });
    mainStorage.splice(indexToInsert, 0, commonStorageGrouped);

    return mainStorage;
};
