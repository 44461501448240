import { StyleSheet, Dimensions } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';

const deviceWidth = Dimensions.get('window').width;
const deviceHeight = Dimensions.get('window').height;
const backgroundColorArray = ['#f45959', '#f8ce45', '#0184f6']
const selectBackgroundColor = () => {
    return backgroundColorArray[Math.floor(Math.random() * backgroundColorArray.length)]
}

export const styles = StyleSheet.create({
    discoveryContainer: {
        // backgroundColor: 'green',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: wp('100%'),
        maxWidth: '1024px',
        padding: '1em',

    },
    discoveryCity: {
        textTransform: 'capitalize',
        fontFamily: 'Rubik',
fontWeight: "600",
        fontFamily: 'Rubik',
fontSize: 20,
        marginBottom: 10,
        color: '#4a4a4a'
    },
    inputContainer: {
        // backgroundColor: 'red'
        position: 'relative',
        marginBottom: 20
    },
    searchIcon: {
        width: 15,
        height: 15,
        position: 'absolute',
        bottom: 6,
        left: 12
    },
    searchInput: {
        height: 30,
        maxWidth: '350px',
        // borderColor: '#f1f1f2',
        // borderWidth: 1,
        backgroundColor: '#f1f1f2',
        paddingLeft: 40,
        borderRadius: 10,
    },
    promoContainer: {
        marginBottom: 20,
        overflow: 'hidden',
    },
    promoTitle: {
        fontFamily: 'Rubik',
fontSize: 17,
        fontFamily: 'Rubik',
fontWeight: '600',
        marginBottom: 10,
    },
    promoItem: {
        height: 180,
        width: wp('35%'),
        maxWidth: 150,
        backgroundColor: '#f45959',
        borderRadius: 10,
        position: 'relative',
        marginRight: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,

        elevation: 6,
    },
    promoItemText: {
        color: '#fff',
        padding: 10,
        flex: 2,
        fontFamily: 'Rubik',
fontWeight: '600',
    },
    promoImage: {
        width: '100%',
        flex: 3,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderTopLeftRadius: 100,
        borderTopRightRadius: 100,
        overflow: "hidden"
    },
    deliveryContainer: {
    },
    deliveryTitle: {
        fontFamily: 'Rubik',
fontSize: 17,
        fontFamily: 'Rubik',
fontWeight: '600',
        marginBottom: 10,
    },
    deliveryCard: {
        marginRight: 20,
        marginBottom: 20,
        // backgroundColor: 'red',
        height: 200,
        width: 2 / 3 * deviceWidth,
        maxWidth: 300,
        borderRadius: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,

        elevation: 6,
        borderRadius: 10,
    },
    deliveryCardImage: {
        height: 2 / 3 * 200,
        width: '100%',
        overflow: 'hidden',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        backgroundColor: selectBackgroundColor(),
        alignContent: 'center',
        justifyContent: 'center',
    },

    deliveryCardName: {
        textAlign: 'center',
        fontFamily: 'Rubik',
fontSize: 30,
        fontFamily: 'Rubik',
fontWeight: '600',
        color: '#fff'

    },
    deliveryCardTextContainer: {
        padding: 10,
    },
    deliveryCardText: {
        fontFamily: 'Rubik',
fontWeight: '600',
        color: '#5d5d5d'
    },
    loadingStyle: {
        flex: 1,
        justifyContent: 'center',
    },
    listContainer: {
        marginBottom: 30,
    },
    link: {
        textDecorationLine: 'none'
    }
})
