import actionTypes from './actionTypes';

const {
    POST_USER_PROFILE,
    POST_USER_PROFILE_SUCCESS,
    POST_USER_PROFILE_FAILURE,
    REQUEST_LOAD_USER_PROFILE,
    SET_USER_PROFILE_SUCCESS,
    SET_USER_PROFILE_FAILURE,
    REQUEST_WHITELIST_DOMAINS,
    SET_WHITELIST_DOMAINS_SUCCESS,
    SET_WHITELIST_DOMAINS_FAILURE,
    REQUEST_LOAD_GUEST_ORDER_SESSION,
    SET_GUEST_ORDER_SESSION_DATA_SUCCESS,
    SET_GUEST_ORDER_SESSION_DATA_FAILURE,
    SET_ADD_ITEM_TO_GUEST_ORDER,
} = actionTypes;

const initialState = {
    isUserProfilePosting: false,
    hasUserProfileBeenPosted: false,
    isUserProfileLoading: false,
    hasUserProfileLoaded: false,
    error: '',
    userProfile: null,
    whitelistedDomains: {},
    isDomainWhitelistingRequesting: false,
    isDomainsWhitelistingSuccessful: false,
    hasDomainWhitelistingFailed: false,
    guestSession: [],
};

export default (state = initialState, action) => {
    const {
        type, error, payload,
    } = action;

    switch (type) {
        case POST_USER_PROFILE:
            return { ...state, isUserProfilePosting: true, hasUserProfileBeenPosted: false };

        case POST_USER_PROFILE_SUCCESS:
            return {
                ...state,
                isUserProfilePosting: false,
                hasUserProfileBeenPosted: true,
                userProfile: payload,
            };
        case POST_USER_PROFILE_FAILURE:
            return {
                ...state,
                isUserProfilePosting: false,
                hasUserProfileBeenPosted: false,
                error: payload.message,
            };

        case REQUEST_LOAD_USER_PROFILE:
            return {
                ...state,
                isUserProfileLoading: true,
                hasUserProfileLoaded: false,
            };

        case SET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                isUserProfileLoading: false,
                hasUserProfileLoaded: true,
                userProfile: payload,
            };

        case SET_USER_PROFILE_FAILURE:
            return {
                ...state,
                isUserProfileLoading: false,
                hasUserProfileLoaded: false,
                error: payload.message,
            };

        case REQUEST_WHITELIST_DOMAINS:
            return {
                ...state,
                isDomainWhitelistingRequesting: true,
                isDomainsWhitelistingSuccessful: false,
                hasDomainWhitelistingFailed: false,
            };

        case SET_WHITELIST_DOMAINS_SUCCESS:
            return {
                ...state,
                whitelistedDomains: payload,
                isDomainWhitelistingRequesting: false,
                isDomainsWhitelistingSuccessful: true,
            };

        case SET_WHITELIST_DOMAINS_FAILURE:
            return {
                ...state,
                isDomainWhitelistingRequesting: false,
                isDomainsWhitelistingSuccessful: false,
                hasDomainWhitelistingFailed: true,
                error: payload,
            };

        case REQUEST_LOAD_GUEST_ORDER_SESSION:
            return {
                ...state, isGuestSessionDataLoading: true, hasGuestSessionDataLoaded: false,
            };
        case SET_GUEST_ORDER_SESSION_DATA_SUCCESS:
            return {
                ...state,
                isGuestSessionDataLoading: false,
                hasGuestSessionDataLoaded: true,
                guestSession: [...payload],
            };
        case SET_GUEST_ORDER_SESSION_DATA_FAILURE:
            return {
                ...state,
                isGuestSessionDataLoading: false,
                hasGuestSessionDataLoaded: false,
                error: payload,
            };

        case SET_ADD_ITEM_TO_GUEST_ORDER:
            const newItem = JSON.stringify([...state.guestSession]);

            const newOrderData = JSON.parse(newItem);
            newOrderData[0].user.orderData.items.push(...payload);

            return {
                ...state, guestSession: [...newOrderData],
            };

        default:
            return { ...state };
    }
};
