import React, { useState, useEffect, useRef } from "react";
import { View, Text, Image, ActivityIndicator, TouchableOpacity, TextInput, StyleSheet } from "react-native";
import Lottie from "react-lottie";
import { pages } from "../../../utils/theme";
import { getUrlParams } from "../../../utils/utils";
// import { styles, GuestOrderSuccessStyle } from './styles'
import { Button } from "../../../components";
import success from "../../../assets/success.json";

// options for lottie animations
const SuccessAnimation = {
    animationData: success,
    autoplay: true,
    loop: false
};

const GuestOrderConfirmationStyle = StyleSheet.create({
    container: {
        zIndex: 2000,
        width: "80%",
        maxWidth: 375,
        height: 200,
        backgroundColor: "#ffffff"
    },
    successText: {
        fontFamily: "Rubik",
        fontSize: 18
    },
    section: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        marginRight: "auto",
        marginLeft: "auto",
        padding: "2em",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0, .4)",
        zIndex: 1
    },
    actionText: {
        flex: 0.7,
        borderBottomColor: "rgba(0,0,0,.1)",
        borderBottomWidth: 1,
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: 10
    },
    actionButton: {
        flex: 0.3,
        flexDirection: "row"
    },
    actionReject: {
        flex: 0.5,
        borderRightColor: "rgba(0,0,0,.1)",
        borderRightWidth: 0.5,
        justifyContent: "center",
        alignItems: "center"
    },
    actionConfirm: {
        flex: 0.5,
        borderLeftColor: "rgba(0,0,0,.1)",
        borderLeftWidth: 0.5,
        justifyContent: "center",
        alignItems: "center"
    },
    actionTextHeader: {
        fontFamily: "Rubik",
        fontWeight: "bold",
        fontFamily: "Rubik",
        fontSize: 18
    },
    actionTextSub: {
        fontFamily: "Rubik",
        fontSize: 14,
        textAlign: "center",
        marginTop: 20
    },
    actionRejectText: {
        fontFamily: "Rubik",
        fontSize: 17,
        color: pages[getUrlParams().merchantId] || "#69E1C6"
    },
    actionConfirmText: {
        fontFamily: "Rubik",
        fontSize: 17,
        color: pages[getUrlParams().merchantId] || "#69E1C6"
    }
});

const {
    container,
    successText,
    section,
    actionText,
    actionButton,
    actionReject,
    actionConfirm,
    actionTextHeader,
    actionTextSub,
    actionRejectText,
    actionConfirmText
} = GuestOrderConfirmationStyle;

export const GuestOrderConfirmation = ({ handleCloseConfirmModal, handleContinueGroupOrder, isPaymentProcessing }) => (
    <View style={section}>
        <View style={container}>
            <View style={actionText}>
                <Text style={actionTextHeader}>Confirm Order</Text>
                <Text style={actionTextSub}>Are you satisfied with your order? there is no going back</Text>
            </View>

            <View style={actionButton}>
                <TouchableOpacity style={actionReject} onClick={handleCloseConfirmModal}>
                    <Text style={actionRejectText}> No</Text>
                </TouchableOpacity>
                <TouchableOpacity style={actionConfirm} onClick={handleContinueGroupOrder}>
                    {isPaymentProcessing ? (
                        <ActivityIndicator color="rgb(105, 225, 199)" size={30} />
                    ) : (
                        <Text style={actionConfirmText}>Yes</Text>
                    )}
                </TouchableOpacity>
            </View>
        </View>
    </View>
);
