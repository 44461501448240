import { StyleSheet, Dimensions, ScrollView } from "react-native";
import styled from "styled-components";
import { ReactComponent as AddImage } from "../../../assets/plus.svg";
import { ReactComponent as MinusImage } from "../../../assets/minus.svg";
import { ReactComponent as CloseImage } from "../../../assets/close.svg";
import { StyledButton } from "../../../components/button/styles";
import { FixedBottom } from "react-fixed-bottom";
import { StyledClosedStoreNotification } from "../../cart/components/styles";

const deviceHeight = Dimensions.get("window").height;
const deviceWidth = Dimensions.get("window").width;

const styles = StyleSheet.create({
    buttonPosition: {
        position: "absolute",
        // bottom: checkForSafariBrowserOnIOS() === 'browser' ? '110px' : '20px',
        bottom: 20,
        width: "100%",
        maxWidth: "400px",
        marginLeft: "auto",
        marginRight: "auto",
        paddingHorizontal: "1em",
        right: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column"
    },
    checkbox: {
        height: 20,
        marginRight: 10,
        width: 20
    },
    checkboxRow: {
        alignItems: "center",
        flexDirection: "row",
        flexWrap: "wrap",
        marginVertical: 1
    },
    checkboxRowPad: {
        alignItems: "center",
        flex: 1,
        flexDirection: "row"
    },
    checkboxText: {
        borderBottomColor: "rgba(0,0,0,.04)",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        marginVertical: 16
    },
    checkboxlabel: {
        borderBottomColor: "rgba(0, 0, 0, 0.04)",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        flex: 1,
        paddingVertical: ".5em"
    },
    currency: {
        color: "#333333b0"
        // marginTop: '50px',
    },
    countContainer: {
        flexDirection: "row",
        flex: 30,
        justifyContent: "space-between",
        alignItems: "center",
        marginRight: "10%"
    },

    itemContainer: {
        alignItems: "center",
        borderBottomColor: "rgba(0, 0, 0, 0.04)",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 10
        // paddingBottom: 10,
    },
    item: {
        padding: "1em"
    },
    itemTopic: {
        fontSize: 25,
        fontFamily: "Rubik",
        fontWeight: "700",
        textAlign: "center",
        textTransform: "capitalize"
    },
    loadingStyle: {
        flex: 1,
        justifyContent: "center"
    },
    modalClose: {
        cursor: "pointer",
        display: "block",
        height: 20,
        marginBottom: 10,
        right: 0,
        width: 20,
        position: "absolute",
        zIndex: 2,
        top: 10,
        right: 10
    },
    modalContainer: {
        backgroundColor: "rgba(0,0,0,.3)",
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        height: "100vh",
        zIndex: 30
    },
    modalMain: {
        backgroundColor: "#fff",
        // borderRadius: 5,
        position: "relative",
        height: deviceWidth < 1024 ? "100vh" : "80vh",
        maxHeight: "900px",
        marginHorizontal: "auto",
        marginVertical: "auto",
        marginTop: "auto",
        width: "100vw",
        maxWidth: "400px",
        position: "relative",
        scrollbarWidth: "none"
    },
    modalScrollView: {
        // marginBottom: checkForSafariBrowserOnIOS() === 'browser' ? '200px' : '100px',
        // marginBottom: '100px',
        // padding: '20px',
        scrollbarWidth: "none"
    },
    optionsContainer: {
        marginVertical: 10
    },
    optionsHeaderText: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    optionsText: {
        fontFamily: "Rubik",
        fontSize: 16,
        fontWeight: "bold",
        textAlign: "left"
    },
    optionsTextRequired: {
        backgroundColor: "#efe29c",
        borderRadius: 4,
        fontFamily: "Rubik",
        fontSize: 12,
        paddingHorizontal: 5,
        paddingVertical: 2
    }
});

export const TextBoxDivStyle = styled.div`
    width: 100%;
    margin: ${(props) => (props.specialInstructionModal ? "10px 0" : "0")};
    height: ${(props) => (props.specialInstructionModal ? "100%" : "0")};
    label {
        font-weight: bold;
        font-size: 16px;
    }
    textarea {
        width: 100%;
        border: 1px solid rgb(101, 119, 134, 0.4);
        padding: 5px;
        font-size: 16px;
        /* height: 70px; */

        height: ${(props) => (props.specialInstructionModal ? "70px" : "0")};
        visibility: ${(props) => (props.specialInstructionModal ? "visible" : "hidden")};
        opacity: ${(props) => (props.specialInstructionModal ? "1" : "0")};
        box-sizing: border-box;
        transition: opacity 0.07 ease-out, all 0.2s ease-in-out;
        &:focus,
        &:hover,
        &:active {
            outline: 2px solid ${(prop) => prop.theme.colors.primary};
            border: 1px solid ${(prop) => prop.theme.colors.primary};
            transition: border 0.3s ease-in;
        }
    }
`;
export const StyledAddImage = styled(AddImage)`
    height: 30px;
    width: 30px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    .plus_image {
        fill: ${(prop) => prop.theme.colors.primary};
    }
    :hover,
    :focus {
        opacity: 0.9;
        transform: scale(1.04);
    }
    :active {
        transform: scale(1.01);
    }
`;

export const StyledCountContainer = styled.div`
    flex-direction: row;
    width: 70%;
    padding: 10px 0;
    justify-content: space-between;
    align-items: center;
    display: flex;

    .countNumber {
        width: 30px;
        height: 30px;
        font-size: 16px;
        /* marginHorizontal: 3; */
        text-align: center;
        border: ${(prop) => `2px solid ${prop.theme.colors.primary}`};
        background-color: "#ffffff";
    }
`;

export const StyledMinusImage = styled(MinusImage)`
    height: 30px;
    width: 30px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    .minus-image {
        fill: ${(prop) => prop.theme.colors.primary};
    }
    :hover,
    :focus {
        opacity: 0.9;
        transform: scale(1.04);
    }
    :active {
        transform: scale(1.01);
    }
`;

export const LabelButtonContainer = styled.div`
    width: 100%;
    height: 30px;
    /* margin-bottom:  ${(props) => !props.isTextBoxOpen && "10px"}; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    & label {
        cursor: pointer;
    }
    &:hover,
    &:hover img {
        opacity: 0.4;
    }
`;
export const ArrowImage = styled.img`
    width: 15px;
    height: 15px;
    transform: ${(props) => (props.isTextBoxOpen ? "rotate(90deg)" : "rotate(0deg)")};
`;
export const InputModalContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    overflow: hidden;
    bottom: 0;
    display: grid;
    place-content: center;
    visibility: ${(props) => (props.isModalOpen ? "visible" : "hidden")};
    opacity: ${(props) => (props.isModalOpen ? "1" : "0")};
    transform: ${(props) => (props.isModalOpen ? "scale(1)" : "scale(0)")};
    transition: opacity 0.07 ease-out, all 0.5s ease-in-out;
`;
export const InputModal = styled.div`
    background-color: white;
    height: 200px;
    width: 300px;
`;
export const ActionButtons = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.colors.primary};
    height: 30px;
    width: 100px;
    display: grid;
    place-content: center;
    color: #fff;
`;
export const StyledModalImageContainer = styled.div`
    height: ${(props) => (props.isImagePresent !== "" ? "30vh" : "2vh")};
    width: ${({ storeTemplate }) => (storeTemplate === 4 ? "30vh" : "100%")};
    background-color: rgba(0, 0, 0, 0.72);
    margin: auto;
    margin-top: ${({ storeTemplate }) => (storeTemplate === 4 ? "15px" : "0px")};

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: ${({ storeTemplate }) => (storeTemplate === 4 ? "50%" : "0px")};
    }
`;
export const StyledItemDescriptionContainer = styled.div`
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .itemTopic {
        font-size: 25px;
        font-family: Rubik;
        font-weight: 700;
        text-align: center;
        text-transform: capitalize;
        margin: 0;
    }
    .itemDescription {
        margin: 0;
        opacity: 0.7;
        text-align: center;
        font-size: 14px;
    }
`;

export const StyledScrollView = styled.div`
    scrollbar-width: none;
    .description_container {
        padding: 1em;
        margin-bottom: 50px;
        padding-bottom: 50px;
    }
`;
export const StyledModalContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100vh;
    z-index: 30;
    display: grid;
    place-items: center;
`;

export const StyledModalMain = styled.div`
    background-color: #fff;
    height: 100vh;
    box-shadow: 0px 3px 4.84px rgba(0, 0, 0, 0.25);
    width: 100vw;
    position: relative;
    scrollbar-width: thin;
    display: flex;
    position: relative;
    flex-direction: column;
    top: 0px;

    .top_modal {
        flex-basis: 80vh;
        margin-bottom: 15vh;
        overflow-y: scroll;
        scrollbar-width: thin;
    }

    @media screen and (min-width: 401px) {
        position: relative !important;
        max-width: 300px;
        height: 90vh;
    }

    @media screen and (min-width: 720px) {
        .top_modal {
            max-height: 700px;
        }
    }
    @media screen and (min-width: 1024px) {
        height: 85vh;
    }
    @media screen and (min-width: 1440px) {
        max-width: 400px;
        height: 95vh;
    }
`;

export const StyledModalClose = styled(CloseImage)`
    cursor: pointer;
    display: block;
    height: 20px;
    margin-bottom: 10px;
    right: 0;
    width: 20px;
    position: absolute;
    z-index: 2;
    top: 10px;
    right: 10px;
    transition: all 0.3s ease-in;

    &:hover,
    :active {
        opacity: 0.7;
    }
`;

export const StyledBottomModal = styled.div`
    /* flex-basis: 20%; */
    box-shadow: -1px 3px 19px 0px rgba(0, 0, 0, 0.25);
    height: ${(props) => (props.specialInstructionModal ? "270px" : "166px")};
    /* padding-bottom: 2em; */
    padding-top: 0.25em;
    /* padding-left: 1em; */
    /* padding-right: 1em; */
    transition: height 0.4s ease-in;
    width: 100%;

    bottom: 5px !important;

    @media screen and (min-width: 401px) {
        max-width: 300px;
    }
    @media screen and (min-width: 720px) {
        position: absolute !important;
    }
    @media screen and (min-width: 1440px) {
        max-width: 400px;
    }
`;

export const StyledButtonPosition = styled.div`
    display: flex;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background: #fff;
    padding-left: 20px;
    padding-right: 20px;
`;

export const StyledFixedButton = styled(StyledButton)`
    height: 50px;
    font-size: 16px;
    color: #ffffff;
    margin-top: ${(props) => props.marginTop + "px" || "0px"};
`;

export const StyledFixedBottom = styled(FixedBottom)`
    @media screen and (min-width: 1024px) {
        position: unset !important;
    }
`;

export const StyledClosedModalStoreNotification = styled(StyledClosedStoreNotification)`
    margin: 0;
    padding: 1px;
`;
export { styles };
