import { StyleSheet, Dimensions } from "react-native";
import styled, { keyframes } from "styled-components";
import { checkForSafariBrowserOnIOS, getUrlParams } from "../../../utils/utils";
import { pages } from "../../../utils/theme";
import { ReactComponent as BackImage } from "../../../assets/back.svg";

const { height, width } = Dimensions.get("window");

const fadeInFromRight = keyframes`
  0% {
    right: 200px;
    /* visibility: hidden;
    opacity: 1; */
  }
  100% {
    right: 0;
    /* visibility: visible;
    opacity: 1; */
  }
`;

export const StyledBackImage = styled(BackImage)`
    height: 20px;
    width: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    transition: 0.2s ease-in;

    :hover,
    :focus {
        opacity: 0.5;
        transform: scale(1.1);
    }

    :active {
        opacity: 1;
        transform: scale(1);
    }
    .back-image,
    .active-path {
        fill: ${(props) => props.theme.colors.primary};
    }
`;
export const styles = StyleSheet.create({
    button: {
        backgroundColor: pages[getUrlParams().merchantId] || "#69E1C6",
        borderRadius: 10,
        elevation: 10,
        paddingVertical: 15,
        shadowColor: "#000",
        shadowOffset: {
            height: 3,
            width: 0
        },
        shadowOpacity: 0.1,
        shadowRadius: 6.27
    },
    buttonColor: {
        color: pages[getUrlParams().merchantId] || "#69E1C6"
    },
    buttonContainer: {
        bottom: 30,
        left: 0,
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "350px",
        position: "fixed",
        right: 0,
        width: "80%"
    },
    buttonText: {
        color: "#ffffff",
        textAlign: "center"
    },

    cartCountAdd: {
        color: "#ffffff",
        cursor: "pointer",
        flex: 1,
        margin: 0,
        textAlign: "center"
    },
    cartCountContainer: {
        alignItems: "center",
        backgroundColor: pages[getUrlParams().merchantId] || "#69E1C6",
        borderRadius: 20,
        display: "flex",
        flexDirection: "row",
        width: 50,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5
    },

    cartCountMinus: {
        color: "#ffffff",
        cursor: "pointer",
        flex: 1,
        margin: 0,
        textAlign: "center"
    },
    cartCountNumber: {
        color: "#ffffff",
        flex: 1.3,
        textAlign: "center"
    },

    cartDescription: {
        flex: 3,
        marginRight: "10px"
    },

    cartFoodImage: {
        borderColor: "rgba(0, 0, 0, .2)",
        borderRadius: 10,
        borderWidth: 0.1,
        height: 100,
        width: 100
    },
    cartFoodModifiers: {
        color: "#808080",
        fontFamily: "Rubik",
        fontSize: 12,
        marginVertical: 0
    },
    cartFoodName: {
        fontSize: 14,
        fontFamily: "Rubik",
        fontWeight: "600",
        // color: 'rgb(128, 128, 128)',
        textTransform: "capitalize"
    },
    cartHeaderText: {
        fontFamily: "Rubik",
        marginVertical: "1em",
        fontWeight: "600",
        color: "#808080",
        fontSize: 20,
        textTransform: "uppercase",
        color: pages[getUrlParams().merchantId] || "#69E1C6"
    },

    cartPrice: {
        fontFamily: "Rubik",
        fontSize: "12px"
    },

    cartSummary: {
        fontSize: 14,
        fontFamily: "Rubik",
        fontWeight: "600",
        marginVertical: "1em"
    },
    container: {
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "400px",
        padding: "1em",
        width: "100%",
        height: "100vh"
    },
    dataLabel: {
        // color: 'green',
        display: "none"
    },
    deliveryAddressContainer: {
        height: "10vh"
    },
    deliveryAddress: {
        // flexDirection: 'row',
        flex: 2,
        marginTop: 10

        // maxHeight: (30 / 100 * height) - 200,
    },
    deliveryAddressTitle: {
        fontSize: 16,
        fontFamily: "Rubik",
        fontWeight: "600"
    },
    deliveryInformtionButton: {
        backgroundColor: `${pages[getUrlParams().merchantId] + 88}` || "#69E1C6",
        borderColor: pages[getUrlParams().merchantId] || "#69E1C6",
        borderRadius: 5,
        paddingHorizontal: 2,
        paddingVertical: 0
    },
    deliveryInformtionEmpty: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    deliveryLogo: {
        height: 20,
        marginRight: 10,
        width: 20
    },
    emptyStateContainer: {
        alignItems: "center",
        justifyContent: "center"
    },
    formErrorStyle: {
        // marginBottom: 20,
    },
    inputContainer: {
        marginVertical: "5px"
    },

    deliveryAddressTitleContainer: {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between"
    },

    inputPickerStyle: {
        // backgroundColor: 'white',

        borderWidth: 0.3,
        marginTop: 10,
        paddingVertical: 5,
        backgroundColor: "transparent",
        color: "#000",
        borderBottomWidth: 1,
        borderColor: "transparent",
        borderBottomColor: "#555",
        borderRadius: 0
    },
    inputStyle: {
        borderColor: "rgba(0,0,0,.2)",
        borderWidth: 0.3,
        paddingLeft: 5,
        paddingVertical: 5
    },
    itemCountContainer: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        alignSelf: "flex-end"
    },
    itemStyle: {
        borderColor: "#d6d7da",
        borderRadius: "15px",
        borderStyle: "solid",
        borderWidth: ".5px",
        color: "#595151",
        display: "inline-block",
        lineHeight: 20,
        marginRight: "10px",
        paddingHorizontal: "1em",
        paddingVertical: ".1em"
    },
    modalClose: {
        cursor: "pointer",
        display: width < 1024 ? "block" : "none",
        height: 15,
        marginLeft: 10,
        right: 0,
        width: 15
    },
    modifierText: {
        color: "#969696",
        fontFamily: "Rubik",
        fontSize: "12px"
    },
    paymentModal: {
        backgroundColor: "#fff",
        borderRadius: 5,
        height: "50%",
        marginHorizontal: "auto",
        marginVertical: "auto",
        maxWidth: 350,
        padding: "1em",
        width: "70%",
        alignItems: "center",
        justifyContent: "center"
    },
    paymentButtonStyle: {
        height: "80%",
        maxWidth: "400px",
        width: "100%",
        // marginHorizontal: 'auto',
        // position: 'absolute',
        // justifyContent: 'space-evenly',
        alignItems: "center",
        color: "red"
    },
    paymentModalContainer: {
        backgroundColor: "rgba(130, 130, 130, 0.5)",
        bottom: 0,
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
        width: "100vw"
    },
    paymentModalClose: {
        cursor: "pointer",
        // display: 'block',
        position: "absolute",
        marginLeft: 10,
        left: 10,
        top: 10,
        zIndex: 200
    },

    paymentModalCloseImage: {
        height: 20,
        width: 20
    },
    paymentTitle: {
        fontFamily: "Rubik",
        fontSize: 18,
        marginVertical: 20
    },
    removeItemButton: {
        alignItems: "center",
        backgroundColor: "red",
        borderRadius: "50%",
        cursor: "pointer",
        height: 15,
        marginLeft: 10,
        width: 15
    },
    removeItemButtonText: {
        color: "#fff",
        fontFamily: "Rubik",
        fontSize: 10,
        lineHeight: 15
    },
    selected: {
        backgroundColor: "rgba(105, 225, 199, 0.295)",
        borderColor: pages[getUrlParams().merchantId] || "#69E1C6",
        color: pages[getUrlParams().merchantId] || "#69E1C6"
    },
    shippingInformationActionButton: {
        paddingVertical: 5,
        paddingHorizontal: 10,
        borderRadius: 5
    },
    shippingInformationCancelButton: {
        borderColor: "grey",
        borderWidth: 1,
        marginRight: 10
    },
    shippingInformationUpdateButton: {
        backgroundColor: "rgb(105, 225, 198)"
    },
    shippingInformationContainer: {
        bottom: -height,
        backgroundColor: "white",
        borderRadius: 10,
        left: 0,
        marginLeft: "auto",
        marginRight: "auto",
        marginVertical: "auto",
        maxWidth: "400px",
        padding: 20,
        width: "90%",
        marginBottom: "15%"
    },
    shippingInformationModal: {
        backgroundColor: "rgba(130, 130, 130, 0.5)",
        bottom: 0,
        left: 0,
        position: "fixed",
        right: 0,
        top: 0,
        width: "100vw"
    }
});

export const GuestOrderSuccessStyle = StyleSheet.create({
    container: {
        padding: "2em",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        maxWidth: 350,
        marginHorizontal: "auto"
    },
    successText: {
        fontFamily: "Rubik",
        fontSize: 18,
        textAlign: "center"
    },
    boldText: {
        fontFamily: "Rubik",
        fontWeight: "bold"
    }
});

export const StyledModalOuterContainer = styled.div`
    background-color: ${(props) =>
        props.isPaymentModalOpen && props.windowSize < 1024 ? "rgba(0,0,0,.3)" : "rgba(243, 243, 243, 0.4)"};
        position: ${(props) => (props.isPaymentModalOpen && props.windowSize < 1024 ? "fixed" : "block")};
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index:3;
        height:100%;
            justify-content: center;
    display: ${(props) => {
        if ((props.isPaymentModalOpen && props.windowSize < 1024) || props.windowSize >= 1024) {
            return "flex";
        }
        return "none";
    }};
        align-items:${(props) => (props.windowSize < 1024 ? "center" : "unset")};
        .modal-content {
        background-color: #fff;
        border-radius: 10px;
        height:100%;
        position: relative;
        margin: ${(props) => (props.windowSize < 1024 ? "1em auto" : "0 auto")};
        width: 100%;
        max-width: 450px;
        padding: 1em;
        /* display: flex; */
        flex-direction: column;
        justify-content: space-between;

       @media screen and (max-width: 1024px){
        animation: .2s ${fadeInFromRight} ease-in;
       }
    }};


        .item-body{
        padding-top: 40px;
        height: 84%;
        }
        }

        .cart-header{
            color: ${(props) => props.theme.colors.primary};
            font-size: 20px;
            font-weight: 600;
        }

        .ordertype-section {
            font-weight: 600;
            margin-bottom: 15px;
            margin-top: 15px;
            display: flex;
    
            p {
                margin-right: 10px;
            }
    
            span {
                color: ${(props) => props.theme.colors.primary};
            }
        }
`;
export const StyledCartItemContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    transition: all 0.2s ease-in 2s;

    .itemCountContainer {
        align-items: center;
        display: flex;
        flex-direction: row;
        align-self: flex-end;
    }
    .cartCountContainer {
        align-items: center;
        background-color: ${(props) => props.theme.colors.primary};
        border-radius: 20px;
        display: flex;
        flex-direction: row;
        width: 50px;
    }
    .cartCountMinus {
        color: #ffffff;
        cursor: pointer;
        flex: 1;
        margin: 0;
        text-align: center;
    }
    .cartCountNumber {
        color: #ffffff;
        text-align: center;
        margin: 0;
    }
    .cartCountAdd {
        color: #ffffff;
        cursor: pointer;
        flex: 1;
        margin: 0;
        text-align: center;
    }
`;

export const StyledCartContainer = styled.div`
    margin: 10px 0 100px 0;
    height: 75%;
    transition: all 0.2s ease-in 2s;

    .cart-data {
        height: 100%;
        overflow: auto;
    }
`;

export const StyledTotalTab = styled.div`
    text-align: center;
    margin-bottom: ${checkForSafariBrowserOnIOS() === "browser" ? "80px" : "20px"};
    .sub-total {
        font-size: 12px;
        font-weight: 400;
        margin: 0;
        opacity: 0.8;
    }

    .money-tab {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .total-cost {
        font-weight: 600;
        font-size: 24px;
        margin: 0;
        opacity: 0.9;
    }

    .discounted-item {
        color: ${(props) => props.theme.colors.primary};
        font-weight: 600;
        text-align: center;
        margin: 15px 0px;

        span {
            font-weight: 100;
        }
    }

    .shipping-text {
        font-size: 10px;
        margin: 10px auto;
        opacity: 0.6;
        text-align: center;
    }
`;

export const StyledButtonContainer = styled.div`
    flex-basis: 10%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: ${checkForSafariBrowserOnIOS() === "browser" ? "80px" : "20px"};
    padding: 20px;
    max-width: 380px;
    margin: auto;
`;
export const StyledClosedStoreNotification = styled.p`
    color: red;
    font-size: 12px;
    width: 100%;
    text-align: center;
    background-color: #ff00001f;
    padding: 5px 0;
    border-radius: 5px;
    border: 1px solid red;
`;

export const StyledAddMoreItemButton = styled.div`
    margin: 15px 0;

    .addMoreButtonText {
        color: #fff;
        text-align: center;
        margin: auto;
        border-radius: 15px;
        padding: 5px 10px;
        max-width: 150px;
        background-color: ${(props) => props.theme.colors.primary};
    }
`;
