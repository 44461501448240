import React from "react";
import PropTypes from "prop-types";

import {
    Dimensions,
    FlatList,
    Image,
    ImageBackground,
    ScrollView,
    Text,
    TextInput,
    View,
    TouchableOpacity,
    ActivityIndicator
} from "react-native";
import { Link } from "react-router-dom";
import { styles } from "./styles";
import { getUrlParams } from "../../../utils/utils";

const { deliveryCard, deliveryCardImage, deliveryCardName, deliveryCardTextContainer, deliveryCardText } = styles;

/**
 * This function generates random bacground colors from 3 colors
 */
const selectBackgroundColor = () => {
    const backgroundColorArray = ["#f45959", "#f8ce45", "#0184f6"];
    return backgroundColorArray[Math.floor(Math.random() * backgroundColorArray.length)];
};

//render the merchants data
const MerchantsData = ({ item, searchData }) => {
    const { id, page_hero_image, name, location_address, merchant_uid, page_id, square_location_id } = item;

    const psId = getUrlParams().psId;
    return (
        <TouchableOpacity
            style={
                searchData.length > 0
                    ? [
                          deliveryCard,
                          {
                              width: "45%"
                          }
                      ]
                    : deliveryCard
            }
        >
            <Link
                to={`/merchant/?merchant_id=${merchant_uid}&location_id=${square_location_id}&page_id=${page_id}&ps_id=${psId}`}
                style={{
                    textDecorationLine: "none"
                }}
            >
                {page_hero_image ? (
                    <Image
                        source={page_hero_image}
                        // style={deliveryCardImage}
                        style={
                            searchData.length > 0
                                ? [
                                      deliveryCardImage,
                                      {
                                          height: "50%"
                                      }
                                  ]
                                : deliveryCardImage
                        }
                        resizeMode="cover"
                    />
                ) : (
                    <ImageBackground
                        source={null}
                        style={[deliveryCardImage, { backgroundColor: selectBackgroundColor() }]}
                        resizeMode="cover"
                    >
                        <Text style={deliveryCardName}> {name}</Text>
                    </ImageBackground>
                )}
                <View style={deliveryCardTextContainer}>
                    <Text style={deliveryCardText}>{name}</Text>
                    {location_address && (
                        <Text style={{ marginTop: 5, fontFamily: "Rubik", fontSize: 12, opacity: 0.5 }}>
                            {location_address}
                        </Text>
                    )}
                </View>
            </Link>
        </TouchableOpacity>
    );
};

MerchantsData.propTypes = {};

export default MerchantsData;
