import React, { useState, useEffect, useRef } from "react";
import uuid from "uuid";
import { RenderItemData } from "../../ItemList";
import Cart from "../../../../cart/components";
import {
    convert24hrTo12hrTime,
    getUrlParams,
    displayCategoryName,
    replaceCurriesCategory
} from "../../../../../utils/utils";
import {
    StyledButtonScheduledOrderDetails,
    StyledEditIcon,
    TemplateTwoStyledButtonScheduledOrderDetails
} from "../../styles";

import CategoryItems from "./categoryItems/CategoryItems";
import OpeningHoursDropdown from "./openingHoursDropdown/OpeningHoursDropdown";

import {
    StyledCartSection,
    StyledNavBar,
    StyledContentContainer,
    StyledDesktopBody,
    StyledDashboardSection,
    StyledItemSection,
    StyleMenuItem,
    StyledDesktopMenus,
    StyledDesktopMenuItem
} from "./Desktop.style";
import { StyledFlatList, styles } from "#/pages/orderPage/components/styles";
import { useCallback } from "react";
import ForwardArrow from "#/assets/ForwardArrow";
import BackwardArrow from "#/assets/BackwardArrow";
import Switch from "react-switch";
import { RECOMMENDATION_MERCHANTS_WHITELIST } from "#/pages/orderPage/constant";

const { menuListContainer } = styles;
export const Desktop = React.memo(
    ({
        currentActiveItems,
        handleModalOpenOrAddItem,
        currentMerchantsShop,
        handleCheckout,
        isPaymentProcessing,
        handleClosePaymentModal,
        storeVendor,
        isPaymentModalOpen,
        windowSize,
        isStoreOpen,
        scheduledOrderTime,
        usersDetails,
        userProfile,
        setOrderTypeModalOpen,
        selectedOrderMethod,
        handleCollatingCheckoutItems,
        handleOpenUpsellModal,
        breakStart,
        breakEnd,
        afterBreakFirstPickupTime,
        onBreak,
        storeTemplate,
        showCategoryPromoBasedOnWeb,
        currentMenuCategory,
        activeCategoryPromo,
        setIsRecommendationAvailable,
        isRecommendationAvailable
    }) => {
        const categoryWrapperRef = useRef(null);
        const recommendationAvailable = useRef(false);
        const { categories } = currentMerchantsShop;
        const merchantId = currentMerchantsShop.categories.merchant_id;

        //----------------------------------------------------------------------------------
        //STATE
        //----------------------------------------------------------------------------------
        const [promotionText, setPromotionText] = useState(null);
        const [promotionDetails, setPromotionDetails] = useState(null);
        const [currentlySelected, setCurrentlySelected] = useState("");

        //----------------------------------------------------------------------------------
        //SIDE EFFECTS
        //----------------------------------------------------------------------------------
        useEffect(() => {
            const setPromoTextFunction = () => {
                if (categories) {
                    const promoItem = categories.menu.filter((item) => item.is_promo_running === true);
                    if (promoItem.length > 0) {
                        setPromotionText(categories && promoItem[0].promo_text);
                        setPromotionDetails(categories && promoItem[0].promo_details);
                    }
                }
            };
            setPromoTextFunction();
            if (currentMerchantsShop?.categories.is_delivery === false && getUrlParams().deliveryType == "delivery") {
                setOrderTypeModalOpen(true);
            } else if (
                currentMerchantsShop?.categories.is_pickup === false &&
                getUrlParams().deliveryType == "pickup"
            ) {
                setOrderTypeModalOpen(true);
            }
        }, [categories]);

        //---------------------------------------------
        //TO SCROLL TO ACTIVE CATEGORY ON CATEGORY BAR
        //---------------------------------------------
        const handleScrollIntoView = () => {
            const elem = document.querySelector(".is-active");
            const wrapper = document.querySelector(".inner-wrapper");

            if (elem && wrapper) {
                wrapper.scrollLeft = elem.offsetLeft;
            }
        };

        useEffect(() => {
            // if (storeTemplate === 2 || storeTemplate === 3 || storeTemplate === 4) {
            window.addEventListener("scroll", handleScrollIntoView);

            return () => window.removeEventListener("scroll", handleScrollIntoView);
            // }
        }, []);
        //---------------------------------------------
        //---------------------------------------------
        //---------------------------------------------

        //----------------------------------------------------------------------
        //Helper Functions
        //----------------------------------------------------------------------
        //Move upsell modal content to the right
        const handleIncreaseScroll = () => {
            // const value = parseInt(categoryWrapperRef.current.scrollWidth / itemsForUpsell.length);
            categoryWrapperRef.current.scroll({
                left: categoryWrapperRef.current.scrollLeft + 100,
                behaviour: "smooth"
            });
        };

        //Move upsell modal content to the left
        const handleDecreaseScroll = () => {
            // const value = parseInt(categoryWrapperRef.current.scrollWidth / itemsForUpsell.length);
            categoryWrapperRef.current.scroll({
                left: categoryWrapperRef.current.scrollLeft - 100,
                behaviour: "smooth"
            });
        };

        const handleRecommendationToggle = (e) => {
            setIsRecommendationAvailable(!isRecommendationAvailable);
        };

        const renderOrderText = () => {
            if (onBreak) {
                return (
                    <div className="info-header">
                        {RECOMMENDATION_MERCHANTS_WHITELIST.includes(merchantId) && (
                            <div className="recommendation-available" onClick={(e) => e.stopPropagation()}>
                                <span>Chef Suggestions</span>
                                <Switch
                                    offColor="#c4c1c3"
                                    onColor="#c4c1c3"
                                    onHandleColor="#A8272D"
                                    handleDiameter={17}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow={"none"}
                                    height={22}
                                    width={35}
                                    onChange={(e) => handleRecommendationToggle(e)}
                                    checked={isRecommendationAvailable}
                                />
                            </div>
                        )}
                        <div className="info-header-type">
                            <span style={{ textTransform: "capitalize" }}>{selectedOrderMethod}</span> for{" "}
                            <span>Today</span> at <span>{afterBreakFirstPickupTime?.label}</span>
                            <StyledEditIcon onClick={() => setOrderTypeModalOpen(true)} storeTemplate={storeTemplate} />
                        </div>
                    </div>
                );
            } else if (scheduledOrderTime?.label) {
                return (
                    <div className="info-header">
                        {RECOMMENDATION_MERCHANTS_WHITELIST.includes(merchantId) && (
                            <div className="recommendation-available" onClick={(e) => e.stopPropagation()}>
                                <span>Chef Suggestions</span>
                                <Switch
                                    offColor="#c4c1c3"
                                    onColor="#c4c1c3"
                                    onHandleColor="#A8272D"
                                    handleDiameter={17}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow={"none"}
                                    height={22}
                                    width={35}
                                    onChange={(e) => handleRecommendationToggle(e)}
                                    checked={isRecommendationAvailable}
                                />
                            </div>
                        )}

                        <div className="info-header-type">
                            <span style={{ textTransform: "capitalize" }}>{selectedOrderMethod}</span> for{" "}
                            <span>{scheduledOrderTime.label}</span> at{" "}
                            <span>{convert24hrTo12hrTime(scheduledOrderTime?.time)}</span>
                            <StyledEditIcon onClick={() => setOrderTypeModalOpen(true)} storeTemplate={storeTemplate} />
                        </div>
                    </div>
                );
            } else {
                console.log();
                return (
                    <div className="info-header">
                        {RECOMMENDATION_MERCHANTS_WHITELIST.includes(merchantId) && (
                            <div className="recommendation-available" onClick={(e) => e.stopPropagation()}>
                                <span>Chef Suggestions</span>
                                <Switch
                                    offColor="#c4c1c3"
                                    onColor="#c4c1c3"
                                    onHandleColor="#A8272D"
                                    handleDiameter={17}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow={"none"}
                                    height={22}
                                    width={35}
                                    onChange={(e) => handleRecommendationToggle(e)}
                                    checked={isRecommendationAvailable}
                                />
                            </div>
                        )}

                        <div className="info-header-type">
                            <span style={{ textTransform: "capitalize" }}>{selectedOrderMethod}</span> for Now
                            <StyledEditIcon onClick={() => setOrderTypeModalOpen(true)} storeTemplate={storeTemplate} />
                        </div>
                    </div>
                );
            }
        };

        let displayedCategoryBar = currentActiveItems;

        if (getUrlParams().merchantId == "6075e037666fec40b0bd0773") {
            displayedCategoryBar = replaceCurriesCategory(currentActiveItems, "curries");
        }

        if (getUrlParams().merchantId == "609396e38b525c2d2e92e0fa") {
            displayedCategoryBar = replaceCurriesCategory(currentActiveItems, "superfood");
        }
        console.log(handleModalOpenOrAddItem);

        return (
            <StyledDesktopBody>
                {/* --------------------------------------------------------- */}
                {/* Pick up text */}
                {/* --------------------------------------------------------- */}
                {storeTemplate === 2 || storeTemplate === 3 || storeTemplate === 4 ? (
                    <TemplateTwoStyledButtonScheduledOrderDetails storeTemplate={storeTemplate}>
                        <div
                            style={{
                                display: "flex",
                                position: "absolute",
                                right: "25%",
                                transform: "translateX(-40px)"
                            }}>
                            {renderOrderText()}
                        </div>
                        <OpeningHoursDropdown
                            categories={categories}
                            styles={{ position: "absolute", left: "50%", transform: "translateX(40px)", zIndex: "4" }}
                        />
                    </TemplateTwoStyledButtonScheduledOrderDetails>
                ) : (
                    <StyledButtonScheduledOrderDetails
                        storeTemplate={storeTemplate}
                        onClick={() => setOrderTypeModalOpen(true)}>
                        {renderOrderText()}
                    </StyledButtonScheduledOrderDetails>
                )}

                {/* --------------------------------------------------------- */}
                {/* Pickup MENU i.e Categories TEMPLATE TWO*/}
                {/* --------------------------------------------------------- */}
                {storeTemplate === 2 || storeTemplate === 3 || storeTemplate === 4 ? (
                    <StyledDesktopMenus>
                        <div className="backward-icon" onClick={handleDecreaseScroll}></div>
                        <div className="inner-wrapper" style={{ position: "relative" }} ref={categoryWrapperRef}>
                            {" "}
                            {displayedCategoryBar.map((item, index) => {
                                const { name, is_promo_running, promo_text } = item;
                                return (
                                    <StyledDesktopMenuItem
                                        key={name}
                                        name={name}
                                        to={name}
                                        spy
                                        smooth
                                        offset={index === 0 ? -370 : -150}
                                        duration={1000}
                                        activeClass="is-active"
                                        // onClick={() => setCurrentlySelected(name)}
                                    >
                                        {displayCategoryName(
                                            is_promo_running,
                                            showCategoryPromoBasedOnWeb,
                                            promo_text,
                                            name
                                        )}
                                    </StyledDesktopMenuItem>
                                );
                            })}
                        </div>
                        <div className="forward-icon" onClick={handleIncreaseScroll}></div>
                    </StyledDesktopMenus>
                ) : null}

                <StyledContentContainer storeTemplate={storeTemplate}>
                    {/* --------------------------------------------------------- */}
                    {/* Pickup MENU i.e Categories TEMPLATE ONE*/}
                    {/* --------------------------------------------------------- */}
                    {storeTemplate === 2 || storeTemplate === 3 || storeTemplate === 4 ? null : (
                        <StyledNavBar scheduledOrderTime={scheduledOrderTime}>
                            <p className="header-title">
                                {getUrlParams().deliveryType ? `${getUrlParams().deliveryType} MENU` : `MENU`}
                            </p>
                            <div className="nav_container">
                                {displayedCategoryBar.map((item, index) => {
                                    const { name, is_promo_running, promo_text } = item;
                                    return (
                                        <StyleMenuItem
                                            style={{ position: "relative" }}
                                            key={name}
                                            to={name}
                                            spy
                                            smooth
                                            offset={index === 0 ? -370 : -20}
                                            duration={1000}
                                            activeClass="is-active"
                                            onClick={() => setCurrentlySelected(name)}>
                                            {displayCategoryName(
                                                is_promo_running,
                                                showCategoryPromoBasedOnWeb,
                                                promo_text,
                                                name
                                            )}
                                        </StyleMenuItem>
                                    );
                                })}
                            </div>
                        </StyledNavBar>
                    )}

                    {/* --------------------------------------------------------- */}
                    {/* Items */}
                    {/* --------------------------------------------------------- */}
                    <CategoryItems
                        currentActiveItems={currentActiveItems}
                        handleModalOpenOrAddItem={handleModalOpenOrAddItem}
                        userProfile={userProfile}
                        storeTemplate={storeTemplate}
                        showCategoryPromoBasedOnWeb={showCategoryPromoBasedOnWeb}
                    />

                    {/* --------------------------------------------------------- */}
                    {/* CART (NOTE: This has been replaced with the sliding cart) */}
                    {/* --------------------------------------------------------- */}

                    {storeTemplate === 2 || storeTemplate === 3 || storeTemplate === 4 ? null : (
                        <StyledCartSection scheduledOrderTime={scheduledOrderTime}>
                            <Cart
                                handleCheckout={handleCheckout}
                                isPaymentProcessing={isPaymentProcessing}
                                handleClosePaymentModal={handleClosePaymentModal}
                                storeVendor={storeVendor}
                                isPaymentModalOpen={isPaymentModalOpen}
                                windowSize={windowSize}
                                usersDetails={usersDetails}
                                isStoreOpen={isStoreOpen}
                                scheduledOrderTime={scheduledOrderTime}
                                handleCollatingCheckoutItems={handleCollatingCheckoutItems}
                                handleOpenUpsellModal={handleOpenUpsellModal}
                                currentMerchantsShop={currentMerchantsShop}
                                activeCategoryPromo={activeCategoryPromo}
                                onBreak={onBreak}
                                selectedOrderMethod={selectedOrderMethod}
                                afterBreakFirstPickupTime={afterBreakFirstPickupTime}
                                storeTemplate={storeTemplate}
                                setOrderTypeModalOpen={setOrderTypeModalOpen}
                            />
                        </StyledCartSection>
                    )}
                </StyledContentContainer>
            </StyledDesktopBody>
        );
    }
);
