import styled, { keyframes } from "styled-components";
import { StyledModalContent, StyledModalOverlay } from "../orderTypeChooserModal/OrderTypeChooser.style";

const appear = keyframes`
    from {
        opacity: 0;
        transform: scale(0)
    }

    to {
        opacity: 1;
        transform: scale(1)
    }
`;

export const StyledOuterContainer = styled(StyledModalOverlay)`
    visibility: ${(props) => (!props.on ? "visible" : "hidden")};
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url(${({ background }) => (background ? background : null)});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 6;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

    :before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: inherit;
        z-index: -1;
        filter: blur(10px);
        transition: all 0.3s linear;
    }

    .chat-container,
    .image-section {
        width: 100%;
        max-width: 440px;
    }

    .image-section img {
        width: 100%;
        height: 30vh;
        object-fit: cover;
        background-blend-mode: lighten;
        border-radius: 20px;
        opacity: 0;
        transform: translateX(-100px);
    }
    .ratingSection {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 10px;
    }
    .ratingSection > p {
        color: #ffffff;
        font-size: 24px;
        font-weight: 500;
    }
    .mainRatingSection {
        width: 100%;
        margin-top: 20px;
    }
    .stars {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    @media screen and (min-width: 1024px) {
        flex-direction: row-reverse;
        & .image-section img {
            height: 300px;
        }
        .button-container {
            width: 40%;
            margin: 0 auto;
            margin-top: 0px;
            opacity: 0;
        }
    }
    .user-card {
        transform: translateY(130px);
        opacity: 0;
        /* visibility: hidden; */
        /* display:none; */
    }

    .chat-bubble {
        overflow-wrap: break-word;
        margin: 0 auto;
        background-color: #fff;
        padding: 1em;
        border-radius: 10px;
        position: relative;

        &:not(:nth-last-child(1)) {
            margin-bottom: 20px;
        }
        &:nth-last-child(0):after {
            content: "";
            position: absolute;
            bottom: 27px;
            left: -16px;
            border-width: 15px 20px 0 0;
            border-color: transparent #fff;
            border-style: solid;
            width: 0;
        }
        margin-bottom: 20px;
    }

    .chat-header {
        width: 50px;
        height: 50px;
        border-radius: 20px;
        position: absolute;
        transform: translateX(230px);
        opacity: 0;
        visibility: hidden;
    }

    .chat-header img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
    }
    .button-container {
        width: 40%;
        margin: 0 auto;
        margin-top: 70px;
        opacity: 0;
    }
    .buttons-container {
        display: flex;
    }
    /* filter:blur(9px) */
`;
export const StyledInnerContainer = styled(StyledModalContent)`
    top: 0;
    visibility: visible;
    animation: ${appear} 0.5s ease-in 3s;
`;
