import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";

import {
    handleDuplicateAndAddToCheckout,
    sortValues,
    handleOptions,
    provider,
    getUrlParams,
    getTotalCartPrice
} from "../../utils/utils";

import { STRINGS, getCheckedCheckBox, handleOrderMarkPrice } from "../../pages/modal/constant";

const useAddToCart = (
    forUpsellModal = false,
    modalData,
    storeVendor,
    optionsValue,
    merchantID,
    isStoreOpen,
    scheduledOrderTime,
    setOptionsValue,
    addItemToCheckout,
    handleItemCountChange,
    handleCloseModal,
    activeCategoryPromo,
    setIsRecommendationShown,
    isRecommendationAvailable
) => {
    //-----------------------------------------------------------------------------------------------------------------
    //STORE
    //-----------------------------------------------------------------------------------------------------------------
    const state = useSelector((state) => state.dashboard);

    //-----------------------------------------------------------------------------------------------------------------
    //HELPERS
    //-----------------------------------------------------------------------------------------------------------------
    const currentMerchantsShop = state[getUrlParams().merchantId];
    const { itemsInCheckout } = currentMerchantsShop;
    const { vendor, is_active } = currentMerchantsShop.categories;
    const scrollViewRef = React.useRef(React.createRef()); // get access to the scroll view so that we can perform auto scroll
    const layoutArray = [];
    const { name, options, image, description } = modalData;
    const currentSelectedMerchant = state["currentlySelectedMerchant"];
    const currentMerchantData = state[currentSelectedMerchant];

    //-----------------------------------------------------------------------------------------------------------------
    //STATES
    //-----------------------------------------------------------------------------------------------------------------
    const [modifierData, setModifierData] = useState([]);
    const [modifiedModifiersData, setModifiedModifiersData] = useState([]);
    const [checkboxDisabled, setCheckboxDisabled] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [optionsRadioValue, setoptionsRadioValue] = useState([]);
    const [loading, setLoading] = useState(null);
    const [currentSelectedModifierContainerSizeArray, setCurrentSelectedModifierContainerSizeArray] = useState(0);
    const [currentPosition, setCurrentPosition] = useState(0);
    const [specialInstructions, setSpecialInstructions] = useState("");
    const [specialInstructionModal, setSpecialInstructionModal] = useState(false);
    const [countValue, setCountValue] = useState("1");
    const [selectedCompulsoryModifiers, setselectedCompulsoryModifiers] = useState(false);
    const [attributeData, setAttributeData] = useState([]);

    //-----------------------------------------------------------------------------------------------------------------
    //HELPER FUNCTIONS
    //-----------------------------------------------------------------------------------------------------------------
    const handleDisplayStoreClosedToast = () => {
        if (is_active === false) {
            return "THIS LOCATION IS NOT ACCEPTING ORDERS RIGHT NOW";
        }

        if (!isStoreOpen && scheduledOrderTime.date === null) {
            return "Store is Currently Closed";
        }
    };

    // controls adding options data
    const handleOptionsSelected = (id, optionItem) => {
        console.log(id, optionItem, "Option item");
        if (storeVendor === "revel") {
            setButtonDisabled(false);
        }
        setOptionsValue(optionItem);
    };

    // controls the items to be added to checkout only add item when the store is open else open the store closed Modal
    const handleAddItem = (e, isFromUpsell = false) => {
        // handleCloseShop()
        e.preventDefault();
        e.stopPropagation();
        const { name, id, image, price } = modalData;

        /// this is the fix for handling the inability to add upsell item to revel store
        /// due to the introduction of options

        let basePriceOption;
        if (currentMerchantsShop.categories.vendor === "revel" && isFromUpsell) {
            basePriceOption = parseFloat(Number(price)).toFixed(2);
        } else if (currentMerchantsShop.categories.vendor === "revel") {
            basePriceOption = handleOptions(options, optionsValue || []).price;
        } else {
            basePriceOption = parseFloat(Number(price)).toFixed(2);
        }

        // console.log(getCheckedCheckBox(modalData, modifierData, storeVendor));
        let selectedItem = {
            quantity: Number(countValue),
            // _id:uuid(), //add unique item id
            id,
            image: isFromUpsell ? "" : image,
            itemPrice: isFromUpsell
                ? parseFloat(Number(price)).toFixed(2)
                : parseFloat(Number(getCheckBoxOptionPrice()) + Number(getRadioOptionPrice())).toFixed(2),
            basePrice: basePriceOption,
            modifiers: getCheckedCheckBox(modalData, modifierData, storeVendor),
            name,
            options: handleOptions(options, optionsValue || []) || {
                id,
                name,
                price: isFromUpsell
                    ? storeVendor === "otter"
                        ? Number(parseFloat(Number(price)).toFixed(2))
                        : parseFloat(Number(price)).toFixed(2)
                    : parseFloat(Number(getCheckBoxOptionPrice()) + Number(getRadioOptionPrice())).toFixed(2)
            },
            optionsRadioValue,
            specialInstructions:
                specialInstructions !== "" ? `${modalData.name} - ${specialInstructions}` : specialInstructions
        };

        if (currentMerchantsShop.categories.vendor === "revel") {
            selectedItem.id = attributeData.length === 0 ? selectedItem.id : selectedItem.options.id;
            selectedItem.name =
                attributeData.length === 0 ? selectedItem.name : selectedItem.name + "-" + selectedItem.options.name;
        }

        // use the handleDuplicateAndAddToCheckout function to add the item
        handleDuplicateAndAddToCheckout(itemsInCheckout, addItemToCheckout, handleItemCountChange, selectedItem);

        // reset the data
        extractModifiersAndInsertCheckState();
        setOptionsValue({});
        handleCloseModal(e, modalData);

        const categoryId = currentMerchantData?.categories?.menu?.filter((el) =>
            el.name.toLowerCase().includes("salad")
        )?.[0];

        if (categoryId.items.includes(modalData) && isRecommendationAvailable) {
            setIsRecommendationShown(true);
        }
    };

    // data does not come with check state, this
    // function add a checkk state to the data for each item in the data
    const extractModifiersAndInsertCheckState = () => {
        // extract modifiers
        if (modifierData?.length === 0) {
            return setModifiedModifiersData([]);
        }

        const tempFilteredModifier = modifierData?.map((item2) => {
            const { modifiers } = item2;
            modifiers &&
                modifiers.map((modItem) => {
                    modItem.isChecked = false;
                    return modItem;
                });
            return item2;
        });

        // sort the array based on the key
        const sortedData = tempFilteredModifier?.sort(
            storeVendor === "revel"
                ? sortValues("admin_mod_key" && "forced", "desc")
                : sortValues("selection_type", "desc")
        );
        setModifiedModifiersData(sortedData?.sort(sortValues("sort")));
    };

    // calculates the price of the optonal modifiers selected
    const getCheckBoxOptionPrice = () => {
        const amountArray = [];

        // get the price of the option value
        const optionsValuePrice = () => {
            if (optionsValue) {
                if (optionsValue && optionsValue.price) {
                    return optionsValue.price;
                }
                if (optionsValue && optionsValue[0].price) {
                    return optionsValue[0].price;
                }
            }
            if (!optionsValue && options && options.length > 0) {
                return options[0].price || 0;
            }
            return 0;
        };
        if (getCheckedCheckBox(modalData, modifierData, storeVendor).length > 0) {
            getCheckedCheckBox(modalData, modifierData, storeVendor).map((items) => {
                const { price } = items;
                // divide it by hundred because it is provided in the data as cents
                // amountArray = price && [...amountArray, price];
                price && amountArray.push(price);

                return amountArray;
            });

            const totalItemPrice = amountArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            return parseFloat(totalItemPrice + optionsValuePrice()).toFixed(2);
        }
        return optionsValuePrice();
    };

    // calculate the price of the required modifiers selected
    const getRadioOptionPrice = () => {
        const amountArray = [];
        // if processor is revel dont divide the amount by 100, divide it by 100 is it is square
        optionsRadioValue.map(
            (item) => item.price && amountArray.push(storeVendor === "revel" ? item.price : item.price / 100)
        );
        const total =
            amountArray !== 0 ? amountArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0) : 0;
        return total;
    };

    // controls toggle of the checkbox
    const handleCheckBoxToggle = (id, checkState, mods) => {
        const tempModifiersData = [...modifiedModifiersData];
        tempModifiersData.map((modData) => {
            const { modifiers } = modData;
            modifiers.map((item) => {
                if (item.id === id) {
                    item.isChecked = !checkState;
                    handleMaximumItemsSelect(modifiers, mods);
                }
            });
        });

        setModifiedModifiersData(tempModifiersData);
    };

    function handleMaximumItemsSelect(modifiers, mods) {
        const maximumAmount =
            storeVendor === "ordermark" || storeVendor === "otter" || storeVendor === "CHOWLY"
                ? mods?.quantity_rules[0]?.max_allowed
                : mods?.maximum_amount;
        const checkedItem = modifiers.filter((item) => item.isChecked);
        if (maximumAmount === checkedItem.length && maximumAmount !== 0) {
            modifiers.map((v) => {
                if (!v.isChecked) {
                    v.disabled = true;
                }
            });
        } else {
            modifiers.map((v) => {
                v.disabled = false;
            });
        }
    }

    // controls special instruction textbox
    const handleSpecialInstructions = (e) => {
        setSpecialInstructions(e.target.value);
    };

    const handleSpecialInstructionModal = () => {
        return specialInstructionModal ? setSpecialInstructionModal(false) : setSpecialInstructionModal(true);
    };

    // controls modifiers which are radio buttons and whose group only one item you can select
    const handleModifierRadioSelected = (id, item, modifier_name, e) => {
        let name;
        let price;
        if (storeVendor === "ordermark" || storeVendor === "otter" || storeVendor === "CHOWLY") {
            name = item.title;
            price = handleOrderMarkPrice(item.price_rules).price;
        } else {
            name = item.modifier_data ? item.modifier_data.name : item.name;
            price = item.modifier_data ? item.modifier_data.price_money.amount : item.price;
        }

        // because there ar multiple values with similar version. we want one  selected item so on click, we set the value of that version to the item
        const newSingleModifier = {
            category: modifier_name,
            id,
            name,
            price
        };

        // deep copy the array
        let newOptionValue = JSON.parse(JSON.stringify(optionsRadioValue));
        if (optionsRadioValue.length === 0) {
            setoptionsRadioValue([...newOptionValue, newSingleModifier]);
        } else if (optionsRadioValue.length > 0) {
            newOptionValue.map((data) => {
                if (data.category === newSingleModifier.category) {
                    // get the index of item
                    const itemIndex = newOptionValue.indexOf(data);
                    // sawp that item with current item
                    newOptionValue.splice(itemIndex, 1);
                    newOptionValue[itemIndex] = newSingleModifier;
                    // set the resukt to state
                    // return data;
                    return newOptionValue;
                }
                if (data.category !== newSingleModifier.category) {
                    newOptionValue = [...newOptionValue, newSingleModifier];
                    return newOptionValue;
                }
                return newOptionValue;
            });
            // set unique values
            setoptionsRadioValue([...new Set(newOptionValue)]);
        }
    };

    const handleChangeCountValue = (e, sign) => {
        if (sign == "minus") {
            setCountValue((previousCount) => `${Number(previousCount) - 1 || 1}`);
        } else if (sign == "add") {
            setCountValue((previousCount) => `${Number(previousCount) + 1}`);
        }
    };

    const handleChanged = (e) => {
        const text = e.target.value;
        let newText = "";
        const numbers = "0123456789";
        for (let i = 0; i < text.length; i++) {
            if (numbers.indexOf(text[i]) > -1) {
                newText += text[i];
            } else {
                // your call back function
                alert("please enter numbers only");
            }
        }
        setCountValue(newText);
    };

    const handleCheckMinimumOrderItem = (minimumDeliveryAmount) => {
        const { merchantId, deliveryType } = getUrlParams();

        if (deliveryType === "pickup") {
            return false;
        }

        const totalCartPrice = getTotalCartPrice(currentMerchantsShop.itemsInCheckout, false, activeCategoryPromo);
        if (minimumDeliveryAmount && currentMerchantsShop.itemsInCheckout.length > 0) {
            return parseFloat(minimumDeliveryAmount) > totalCartPrice
                ? `The minimum amount for delivery is $${minimumDeliveryAmount}`
                : false;
        }
        if (
            !minimumDeliveryAmount &&
            merchantId === "5f010924df0b114b4f98e7cb" &&
            currentMerchantsShop.itemsInCheckout.length > 0
        ) {
            return totalCartPrice < parseFloat(25) ? "The minimum amount for delivery is $25" : false;
        }
        return false;
    };

    //-----------------------------------------------------------------------------------------------------------------
    //SIDE EFFECTS
    //-----------------------------------------------------------------------------------------------------------------
    //fetch modifier data based on the merchant id and item id
    useEffect(() => {
        const itemID = currentMerchantsShop.categories.vendor === "ordermark" ? modalData._id : modalData.id;
        // this is used to cancel request
        const source = axios.CancelToken.source();
        setLoading(true);
        setButtonDisabled(true);

        const fetchData = async () => {
            const orderMarkURL = `https://waitstaff.joyup.me/menu/ordermark/getmodifier/${merchantID}/${modalData.id}`;
            const squareURL = `https://pay.joyup.me/${provider(storeVendor)}/${modalData.id}/modifiers/${merchantID}`;
            const otterUrl = `https://waitstaff.joyup.me/menu/get_otter_modifiers/${merchantID}/${modalData.id}`;
            const chowlyUrl = `https://waitstaff.joyup.me/menu/get_chowly_modifiers/${merchantID}/${modalData.id}`;
            let URL;
            if (currentMerchantsShop.categories.vendor === "ordermark") {
                URL = orderMarkURL;
            } else if (currentMerchantsShop.categories.vendor === "otter") {
                URL = otterUrl;
            } else if (currentMerchantsShop.categories.vendor === "CHOWLY") {
                URL = chowlyUrl;
            } else {
                URL = squareURL;
            }

            try {
                const result = await axios.get(URL, { cancelToken: source.token });
                console.log("==========THE DATA", result.data);
                if (currentMerchantsShop.categories.vendor === "ordermark") {
                    setModifierData(result.data.data);
                } else if (currentMerchantsShop.categories.vendor === "otter") {
                    setModifierData(result.data.data);
                } else if (currentMerchantsShop.categories.vendor === "CHOWLY") {
                    setModifierData(result.data.data);
                } else {
                    setModifierData(result.data);
                }
                setLoading(false);
                setButtonDisabled(false);
            } catch (error) {
                if (axios.isCancel(error)) {
                    setLoading(false);
                    console.log("request cancelled", axios.isCancel(error));
                } else {
                    setLoading(false);
                    setModifierData([]);
                }
            }
        };

        fetchData();
        return () => {
            source.cancel();
        };
    }, [URL]);

    useEffect(() => {
        extractModifiersAndInsertCheckState();
    }, [modifierData]);

    //+++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // This handles the fetching attribute for REVEL Stores
    //+++++++++++++++++++++++++++++++++++++++++++++++++++++++

    useEffect(() => {
        const { vendor, is_active } = currentMerchantsShop.categories;
        const handleDisplayStoreClosedToast = () => {
            if (is_active === false) {
                return ToastsStore.error("THIS LOCATION IS NOT ACCEPTING ORDERS RIGHT NOW", 3000, "error");
            } else {
                if (!isStoreOpen && scheduledOrderTime.date === null) {
                    return ToastsStore.error("Store is Currently Closed ", 3000, "error");
                }
            }
        };

        handleDisplayStoreClosedToast();
    }, [isStoreOpen, scheduledOrderTime]);

    useEffect(() => {
        const fetchAttributes = async () => {
            try {
                if (currentMerchantsShop.categories.vendor === "revel") {
                    setLoading(true);
                    const url = `https://pay.joyup.me/revel/product/${modalData.id}/attributes/${merchantID}`;
                    const res = await axios.get(url);
                    if (res.status === 200) {
                        if (res.data !== undefined) {
                            console.log(url, res.data, "Fetched Attributes");
                            const newAttrribute = res.data.map((item) => {
                                const refactoredAttribute = {
                                    name: item.attributes.attribute_value_1_name,
                                    id: item.product.id,
                                    price: item.product.price
                                };
                                return refactoredAttribute;
                            });
                            setAttributeData(newAttrribute);
                            setLoading(false);
                            setOptionsValue([]);
                        }
                    } else {
                        setAttributeData([]);
                        setLoading(false);
                    }
                }
            } catch (error) {
                console.log(error);
                setAttributeData([]);
            }
        };
        fetchAttributes();
    }, []);

    useEffect(() => {
        //------------------------------------------------------------------------
        // get the length of modifiers which  are single and are compulsory for
        //------------------------------------------------------------------------
        // sqaure or forced for revel
        const compulsoryModifiers =
            modifiedModifiersData.length > 0 &&
            modifiedModifiersData?.filter(
                (item) =>
                    item.selection_type === "SINGLE" ||
                    (item.minimum_amount === 1 && item.maximum_amount === 1) ||
                    item.forced === true ||
                    item.admin_mod_key === "SIZE" ||
                    storeVendor === "ordermark" ||
                    storeVendor === "otter" ||
                    (storeVendor === "CHOWLY" &&
                        Array.isArray(item.quantity_rules) &&
                        item.quantity_rules.length > 0 &&
                        item.quantity_rules[0].max_allowed === 1 &&
                        item.quantity_rules[0].min_allowed === 1)
            );
        //------------------------------------------------------------------------
        // control the this able state of the button, remove disable state if
        // options is selected
        //------------------------------------------------------------------------
        const controlCheckBoxDisabled = () => optionsValue !== "" && setCheckboxDisabled(false);

        const controlButtonDisabled = () => {
            //------------------------------------------------------------------------
            // enable button if there is an option selected and there is no compusolry modifier
            //  this checks if there is no compulsory item selected and if tehre is no options value selecetd
            // from the compulsory option, divide the items ito 2, onw which is a radio
            //bbutton which will be selected as options value and the other which is a checkbox and whose checked
            //boxes we can compare to the minimum amount required. if both are true then enable button
            //------------------------------------------------------------------------
            const compulsoryLiteModRadio =
                compulsoryModifiers &&
                compulsoryModifiers.filter(
                    (item) =>
                        item.admin_mod_key === "SIZE" ||
                        (item.minimum_amount === 1 && item.maximum_amount === 1) ||
                        (item?.quantity_rules &&
                            item.quantity_rules[0].max_allowed === 1 &&
                            item.quantity_rules[0].min_allowed === 1)
                );

            const compulsoryLiteModCheck =
                compulsoryModifiers &&
                compulsoryModifiers.filter(
                    (item) =>
                        (item.minimum_amount >= 1 && item.maximum_amount > 1) ||
                        (item?.quantity_rules &&
                            item.quantity_rules[0].max_allowed > 1 &&
                            item.quantity_rules[0].min_allowed >= 1)
                );

            const selectedModifier =
                compulsoryLiteModCheck &&
                compulsoryLiteModCheck.map((item) => item.modifiers.filter((value) => value.isChecked));

            const hasEmptySelection = selectedModifier && selectedModifier.some((item) => item.length === 0);

            const getFinalCompulsoryValue = () => {
                return optionsRadioValue.length === compulsoryLiteModRadio.length && hasEmptySelection === false;
            };

            if (loading) {
                setButtonDisabled(true);
            } else if (options.length > 1 && optionsValue === undefined && compulsoryModifiers.length === 0) {
                setButtonDisabled(true);
            } else if (
                options.length === 1 &&
                optionsValue === undefined &&
                compulsoryModifiers.length === 0 &&
                compulsoryLiteModRadio.length === 0
            ) {
                setButtonDisabled(false);
            } else if (optionsValue && optionsValue.id && compulsoryModifiers.length === 0) {
                setButtonDisabled(false);
            } else if (optionsValue && optionsValue.length === 1 && compulsoryModifiers.length === 0) {
                setButtonDisabled(false);
            } else if (
                !loading &&
                compulsoryModifiers.length > 0 &&
                compulsoryModifiers.length === optionsRadioValue.length &&
                ((optionsValue && optionsValue.length === 1) ||
                    (optionsValue && optionsValue.id) ||
                    (options && options.length === 1) ||
                    (options && options.id))
            ) {
                setButtonDisabled(false);
            } else if (
                !loading &&
                compulsoryModifiers === false &&
                ((optionsValue && optionsValue.length === 1) || (optionsValue && optionsValue.id))
            ) {
                setButtonDisabled(false);
            } else if (
                storeVendor === "revel" &&
                optionsRadioValue.length !== compulsoryLiteModRadio.length &&
                compulsoryLiteModCheck !== true
            ) {
                setButtonDisabled(true);
            } else if (storeVendor === "revel" && getFinalCompulsoryValue() === false) {
                setButtonDisabled(true);
            } else if (
                (storeVendor === "ordermark" && getFinalCompulsoryValue() === false) ||
                (storeVendor === "otter" && getFinalCompulsoryValue() === false)
            ) {
                console.log(storeVendor, ">>Mod item", getFinalCompulsoryValue());

                setButtonDisabled(true);
            } else if (storeVendor === "square" && getFinalCompulsoryValue() === false) {
                setButtonDisabled(true);
            } else if (!loading && modalData.options.length === 1 && !compulsoryModifiers && !optionsValue) {
                setButtonDisabled(false);
            } else if (storeVendor === "otter" && setselectedCompulsoryModifiers === false) {
                setButtonDisabled(false);
            } else {
                return storeVendor === "revel" || storeVendor === "otter" || storeVendor === "ordermark"
                    ? setButtonDisabled(false)
                    : setButtonDisabled(true);
            }
        };
        controlCheckBoxDisabled();
        controlButtonDisabled();
    }, [
        loading,
        optionsValue,
        getCheckedCheckBox(modalData, modifierData, storeVendor),
        optionsRadioValue,
        modifiedModifiersData
    ]);

    useEffect(() => {
        // handle when ordermark item has pricing context
        // with pricing context we use the selected  modifier price instead of the
        // price. so we set the item price to zero so that selected item price will
        // be usthe calculation of pricing
        const handleOrderMarkContextPricing = () => {
            if (storeVendor === "ordermark") {
                const price_rules = [];
                // because we want to find price rule for items whic are cumbusory and
                // which you can only select one? we need to filter the modifiers and only
                // use the modifier group that has a rule to select only compuslory ine
                const modifierGroupWithCompulsoryOneItemSelection = modifiedModifiersData.filter(
                    (item) =>
                        item?.quantity_rules &&
                        item?.quantity_rules[0]?.max_allowed === 1 &&
                        item?.quantity_rules[0]?.min_allowed === 1
                );

                // from that group we need to isolate the modifiers because we need to check
                // at least one modifier has a price contex rule, this means we can now use
                // that price iiof the main item price
                let modifiersOfCompulsoryGroup = [];
                modifierGroupWithCompulsoryOneItemSelection.map((item) => {
                    modifiersOfCompulsoryGroup = [...modifiersOfCompulsoryGroup, ...item.modifiers];
                });

                // group all the modifiers price rules of every modifier into one array
                let modifiersPriceRules = [];
                modifiersOfCompulsoryGroup.map((item) => {
                    modifiersPriceRules = [...modifiersPriceRules, ...item.price_rules];
                });

                // from the mprice rule check if at least one has a price rule context array
                // grthan one. this shows that the base price of that item has a whole will
                // be overridden by any of the modifier selected
                const shouldItemPriceBeReplacedByModifierPrice = modifiersPriceRules.some(
                    (item) => item.contexts.length > 0
                );

                // set baseprice to be 0 if shouldItemPriceBeReplacedByModifierPrice is true
                if (shouldItemPriceBeReplacedByModifierPrice) {
                    const newOptionsValue = [...optionsValue];
                    newOptionsValue[0].price = 0;
                    setOptionsValue(newOptionsValue);
                }
            }
        };

        handleOrderMarkContextPricing();
    }, [modifiedModifiersData]);

    return {
        scrollViewRef,
        modalData,
        handleOptionsSelected,
        modifiedModifiersData,
        attributeData,
        loading,
        optionsRadioValue,
        handleModifierRadioSelected,
        handleCheckBoxToggle,
        checkboxDisabled,
        specialInstructionModal,
        handleSpecialInstructionModal,
        specialInstructions,
        handleSpecialInstructions,
        handleChangeCountValue,
        countValue,
        handleChanged,
        handleAddItem,
        buttonDisabled,
        handleDisplayStoreClosedToast,
        handleCheckMinimumOrderItem,
        currentMerchantsShop
    };
};

export default useAddToCart;
