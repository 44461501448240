import { ToastsStore } from "react-toasts";
import React from "react";
import lscache from "lscache";
import randomBytes from "randombytes";
import uuid from "uuid";

import axios from "axios";
import { dateFormat } from "./dateUtil";
import { Loader } from "../components/loader/Loader";

export const setLocalStorage = (identifier, payload) => {
    localStorage.setItem(identifier, JSON.stringify(payload));
};

export const clearLocalStorage = (identifier) => {
    localStorage.removeItem(identifier);
};

export const getLocalStorage = (identifier) => {
    const info = localStorage.getItem(identifier);
    if (!!info) {
        return JSON.parse(info);
    } else {
        return null;
    }
};

export const getTotalCartPrice = (
    itemInCheckout,
    withCurrency = false,
    activeCategoryPromo,
    returnValueWithoutDiscount = false
) => {
    const totalAmount = [];
    if (itemInCheckout.length > 0) {
        itemInCheckout.map((item) => {
            totalAmount.push((item.itemPrice || item.price) * item.quantity);
        });
    }

    if (getUrlParams().deliveryType === "pickup" || returnValueWithoutDiscount) {
        const calculatedAmount = totalAmount
            .reduce((acc, curr) => acc + curr, 0)
            .toFixed(2)
            .toLocaleString();
        return withCurrency ? `$${calculatedAmount}` : calculatedAmount;
    } else {
        const discountedItem = getFreeItem(itemInCheckout, activeCategoryPromo);
        let calculatedAmount = totalAmount.reduce((acc, curr) => acc + curr, 0);
        calculatedAmount = discountedItem ? calculatedAmount - discountedItem.itemPrice : calculatedAmount;

        return withCurrency
            ? `$${calculatedAmount.toFixed(2).toLocaleString()}`
            : calculatedAmount.toFixed(2).toLocaleString();
    }
};

export const USD = "$";

/**
 * //this function gets the query parameter from the url
 * @function getUrlParams
 * @returns { locationId, merchantId, pageId,}
 */
export function getUrlParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const urlMerchantId = urlParams.get("merchant_id");
    const lsParams = getLocalStorage("joyup-store-params");

    if (urlMerchantId) {
        return {
            locationId: urlParams.get("location_id"),
            merchantId: urlParams.get("merchant_id"),
            pageId: urlParams.get("page_id"),
            psId: urlParams.get("ps_id"),
            orderType: urlParams.get("order_type"),
            deliveryType: urlParams.get("type"),
            channel: urlParams.get("channel"),
            promo: urlParams.get("promo"),
            userId: urlParams.get("user_id")
        };
    } else if (lsParams) {
        const { merchantId, locationId, pageId } = lsParams;

        return {
            locationId,
            merchantId,
            pageId,
            // psId: handlePsId(),
            deliveryType: urlParams.get("type"),
            channel: urlParams.get("channel") || "web",
            promo: lsParams?.promo ? lsParams?.promo : urlParams.get("promo"),
            userId: urlParams.get("user_id"),
            orderType: urlParams.get("order_type")
        };
    } else {
        return {
            locationId: "",
            merchantId: "",
            pageId: "",
            psId: handlePsId(),
            deliveryType: urlParams.get("type")
        };
    }
}

export const handlePsId = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let psId = urlParams.get("ps_id");
    let orderType = urlParams.get("order_type");

    if (psId === "" || psId === null || psId === "null" || psId === undefined || orderType === "guest") {
        //previously generate random psid but instead use abhis own
        const number = lscache.get("newId") || uuid();
        // return number;
        lscache.set("newId", number);
        // return randomBytes(6).join('')
        return number;
    }
    lscache.set("newId", psId);
    return psId;
};
// used to check if an array is empty
export const isArrayEmpty = (a) => Array.isArray(a) && a.every(isArrayEmpty);

// used to check if an object is empty
export const isObjectEmpty = (obj) => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
};

/**
 * this function handles duplicates before the item is added to checkout.
 */
export const handleDuplicateAndAddToCheckout = (
    itemsInCheckoutArray,
    addItemsToCheckoutFunction,
    changeItemCountFunction,
    selectedItem
) => {
    // we want to take selected item and pass that item through a function that checks equivalence, this pushes the boolean result to an array. at the end of the loop we check if there is a falsy value in the equivalency array. if there is a falsy value we get the index and add 1 to the item in checkout else we add the item to the checkout
    if (itemsInCheckoutArray.length === 0) {
        // push the first item into checkout
        addItemsToCheckoutFunction(selectedItem);
        ToastsStore.info(`${selectedItem.name} added to cart`, 0, "info");
    } else {
        // create an array that stores true or false based on if the passed element is a duplicate
        const isEquivalentArray = [];
        console.log(selectedItem, "Selected Item from Util");
        /**
         * This Function checks the similarity between two objects
         * @param {object} element This is the element in the array that will be compared to the element to be added to the checkout
         */
        const isEquivalent = (element) => {
            // we need to remove the quantity from both data since counts can change and will make the data not equivalent
            const newElement = { ...element, quantity: undefined };
            const newSelectedElement = { ...selectedItem, quantity: undefined };

            return JSON.stringify(newElement) === JSON.stringify(newSelectedElement);
        };

        // map through the checkout item and run the equivalent function against the items in the Storage then save the result in an array
        itemsInCheckoutArray.map((item) => {
            isEquivalentArray.push(isEquivalent(item));
            return isEquivalentArray;
        });

        // map through the equivalent array and check if any of the element is true (is duplicate)
        const EquivalentChecker = isEquivalentArray.some((element) => element === true);

        if (EquivalentChecker && itemsInCheckoutArray.length > 0) {
            // this function takes an element in an array and returns that element if true
            const isNumberADuplicate = (element) => element === true;
            // this function finds the index of that element which is an equivalentItemArray(which value is truthy)
            const equivalentItemIndex = isEquivalentArray.findIndex(isNumberADuplicate);

            // get the item from the array using the index gotten from the above step
            const equivalentItemArray = itemsInCheckoutArray[equivalentItemIndex];

            // create a new object and add 1 to the quantity value
            const newItem = {
                ...equivalentItemArray,
                quantity: equivalentItemArray.quantity + 1
            };
            changeItemCountFunction(newItem);
            ToastsStore.info(`${selectedItem.name} updated in cart`, 0, "info");
        } else {
            addItemsToCheckoutFunction(selectedItem);
            ToastsStore.info(`${selectedItem.name} added to cart`, 0, "info");
        }
    }
};

// this function filters an array
export const shuffleArray = (array) => {
    let tmp;
    let current;
    let top = array.length;

    if (top) {
        while (--top) {
            current = Math.floor(Math.random() * (top + 1));
            tmp = array[current];
            array[current] = array[top];
            array[top] = tmp;
        }
    }
    return array;
};

// this functions sends the details of the current user to slack but does that only if adress is not empty
export const sendNotificationToSlackForUserLogin = () => {
    const getSlackLocation = () => "https://hooks.slack.com/services/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";
    // CONVERT TIME TO PST
    const time = new Date(new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" })).toLocaleTimeString(
        "en-US"
    );
    const date = new Date(new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" })).toLocaleDateString(
        "en-US"
    );

    axios
        .post(
            getSlackLocation(),
            JSON.stringify({
                text: `Time: ${time},
                merchantID: ${getUrlParams().merchantId},
                location: ${getUrlParams().locationId},
                userId: ${getUrlParams().psId},
                pageId: ${getUrlParams().pageId},
                date: ${date}`,
                // if url has a /order/ then post to the #grouporders channel
                channel: window.location.pathname === "/order/" && "#grouporders"
            })
        )
        .then((response) => {
            console.log(response);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const baseUrl = process.env.NODE_ENV !== "development" ? "https://app.joyup.me" : "http://localhost:4000";

// handles opening hours of shops based on supplied opening times
export const handleCloseShop = (data) => {
    const now = new Date();
    const today = dateFormat(now, "dddd").toLowerCase();
    const currentTime = process.env.NODE_ENV === "development" ? dateFormat(now, "HHMM") : dateFormat(now, "HHMM");
    const days = Object.keys(data);
    const filteredDate = days.filter((day) => day === today);
    const openingHours = data[filteredDate];

    if (filteredDate.length === 0) {
        return { isOpen: false };
    }
    if (
        openingHours &&
        Number(currentTime) >= Number(openingHours.start) &&
        Number(currentTime) <= Number(openingHours.end)
    ) {
        return true;
    }
    return {
        isOpen: false,
        closingTime: openingHours.end,
        openingTime: openingHours.start
    };
};

const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
const today = days[new Date().getDay()];
const yesterday = today === "sunday" ? "saturday" : days[new Date().getDay() - 1];
const hours = new Date().getHours() < 10 ? `0${new Date().getHours()}` : new Date().getHours();
const minute = new Date().getMinutes() < 10 ? `0${new Date().getMinutes()}` : new Date().getMinutes();

export const convertTimeToHourAndMinutes = (time) => ({
    hour: time.slice(0, 2),
    minute: time.slice(2, 5)
});
const currentTime = `${hours}${minute}`;

export const getUsersCurrentTime = () => {
    const hours = new Date().getHours() < 10 ? `0${new Date().getHours()}` : new Date().getHours();
    const minute = new Date().getMinutes() < 10 ? `0${new Date().getMinutes()}` : new Date().getMinutes();

    const currentTime = `${hours}${minute}`;

    return currentTime;
};

export const checkIsOpen = (startTime, endTime, breakStart = "", breakEnd = "", time = currentTime) => {
    if (!endTime || !startTime) {
        return { isOpen: false, closingTime: endTime, openingTime: startTime, breakStart, breakEnd, onBreak: false };
    }
    if (startTime > "23:59" || endTime > "23:59") {
        return { isOpen: false, closingTime: endTime, openingTime: startTime, breakStart, breakEnd, onBreak: false };
    }

    if (time >= breakStart && time <= breakEnd) {
        //NOTE: breakEnd and breakStart would never be formed if user time doesnt fall inside break
        return { isOpen: false, closingTime: endTime, openingTime: startTime, breakStart, breakEnd, onBreak: true };
    }

    if (time === startTime || time === endTime) {
        return { isOpen: true, breakStart, breakEnd, onBreak: false };
    }
    // when it is within 0000 to 24 and current is in the middle
    if (time >= startTime && time <= endTime && time <= "23:59") {
        return { isOpen: true, breakStart, breakEnd, onBreak: false };
    }
    if (endTime >= "0000" && endTime <= "0300" && time >= "0000" && time <= endTime) {
        return { isOpen: true, breakStart, breakEnd, onBreak: false };
    }
    if (endTime >= "0000" && endTime <= "0300" && time >= startTime) {
        return { isOpen: true, breakStart, breakEnd, onBreak: false };
    }
    return { isOpen: false, closingTime: endTime, openingTime: startTime, breakStart, breakEnd, onBreak: false };
};

// this function chooses the day to use. if previous day spills into midnight it
// uses previous day to determine when the store is open else it uses today
// opening and closing times.
export const checkDayToUse = (workingHours) => {
    if (
        workingHours[yesterday] &&
        workingHours[yesterday].end < workingHours[yesterday] &&
        workingHours[yesterday].start &&
        workingHours[yesterday].end >= "0000" &&
        workingHours[yesterday].end <= "0300" &&
        currentTime <= workingHours[yesterday].end
    ) {
        return checkIsOpen(
            workingHours[yesterday] && workingHours[yesterday].start,
            workingHours[yesterday] && workingHours[yesterday].end,
            (workingHours[yesterday] && workingHours[yesterday].breakStart) || "",
            (workingHours[yesterday] && workingHours[yesterday].breakEnd) || ""
        );
    }
    return checkIsOpen(
        workingHours[today] && workingHours[today].start,
        workingHours[today] && workingHours[today].end,
        (workingHours[today] && workingHours[today].breakStart) || "",
        (workingHours[today] && workingHours[today].breakEnd) || ""
    );
};

// check for safari so that we can give a good measurement.this help in knowing the bottom margin for safari
export const checkForSafariBrowserOnIOS = () => {
    const { standalone } = window.navigator;
    const userAgent = window.navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
        if (!standalone && safari) {
            return "browser";
        }
        if (!standalone && !safari) {
            return "uiwebview";
        }
    } else {
        return "not iOS";
    }
};

/**
 *This function extract the maximum value of options a use can *select
 * @param {String} name: the name of the modifier group
 */
export const extractMaxModifierFromString = ({ name, maximum_amount }) => {
    const value = name.match(/(\d+)/);
    return value ? maximum_amount : null;
};

/**

* This function sorts an array of objects
* @param {string} key the key we want to sort for
* @param {string} order the order we want to sort, ascending ('asc') or descending ('desc')
* @example array.sort(compareValue('value', 'desc'))
* @returns {array} sorted array based on provided key
*/
export function sortValues(key, order = "asc") {
    return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0;
        }

        const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
        const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return order === "desc" ? comparison * -1 : comparison;
    };
}

// convert 24 hours time to 12 hours
export function timeConvert(time) {
    const item = `${time.substr(0, 2)}:${time.substr(2, 3)}`;
    // Check correct time format and split into components
    time = item.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [item];

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        // time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        if (time[0] < 12) {
            time[5] = "AM";
        } else if (time[0] > 12) {
            time[5] = "PM";
        } else if (time[0] == 12 && time[2] == "00") {
            time[5] = " NOON";
        } else if (time[0] == 12 && time[2] != "00") {
            time[5] = "PM";
        }
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }

    return time.join(""); // return adjusted time or original string
}

export const handleOptions = (options, optionsValue) => {
    if (Array.isArray(options) && options.length === 1) {
        options[0].name = options[0].name.toLowerCase() === "regular" ? "" : options[0].name;
        return options[0];
    }
    if (Array.isArray(optionsValue) && optionsValue.length === 1) {
        optionsValue[0].name = optionsValue[0].name.toLowerCase() === "regular" ? "" : optionsValue[0].name;
        return optionsValue[0];
    }
    if (!Array.isArray(options)) {
        options.name = options.name.toLowerCase() === "regular" ? "" : options.name;
        return options;
    }
    if (!Array.isArray(optionsValue)) {
        optionsValue.name = optionsValue.name.toLowerCase() === "regular" ? "" : optionsValue.name;
        return optionsValue;
    }
    if (Array.isArray(options) && options.length > 1 && !Array.isArray(optionsValue)) {
        optionsValue.name = optionsValue.name.toLowerCase() === "regular" ? "" : optionsValue.name;
        return optionsValue;
    }
};

export const convertWordToCapitalize = (word) => {
    const splitWord = word.toLowerCase().split(" ");
    return splitWord.map((element) => element.charAt(0).toUpperCase() + element.substring(1)).join(" ");
};

export const renderLoading = () => {
    return getUrlParams().merchantId === "5b4672ba986044252e7f14ae" ? (
        <div
            style={{
                display: "flex",
                height: "20vh",
                width: "100%",
                justifyContent: "center",
                alignItems: "center"
            }}>
            <img
                alt="loader"
                src={require("../assets/bobba_loader.gif")}
                style={{ height: 150, width: 100, marginHorizontal: "auto" }}
            />
        </div>
    ) : getUrlParams().merchantId === "5f8a18846a20c35824b897d9" ? (
        <div
            style={{
                display: "flex",
                height: "20vh",
                width: "100%",
                justifyContent: "center",
                alignItems: "center"
            }}>
            <img
                alt="loader"
                src={require("../assets/bucket.gif")}
                style={{ height: 150, width: 150, marginHorizontal: "auto" }}
            />
        </div>
    ) : getUrlParams().merchantId === "6075e037666fec40b0bd0773" ? (
        <div
            style={{
                display: "flex",
                height: "20vh",
                width: "100%",
                justifyContent: "center",
                alignItems: "center"
            }}>
            <img
                alt="loader"
                src={require("../assets/agas_12.gif")}
                style={{ height: 150, width: 150, marginHorizontal: "auto" }}
            />
        </div>
    ) : getUrlParams().merchantId === "5df17e95fe3d5646adf194a2" ? (
        <div
            style={{
                display: "flex",
                height: "20vh",
                width: "100%",
                justifyContent: "center",
                alignItems: "center"
            }}>
            <img
                alt="loader"
                src={require("../assets/pizza-loader.gif")}
                style={{ height: 100, width: 100, marginHorizontal: "auto" }}
            />
        </div>
    ) : getUrlParams().merchantId === "5e28eb7db3ea2b73fb73285a" ||
      getUrlParams().merchantId === "5e94c4288747b92052220f52" ? (
        <div
            style={{
                display: "flex",
                height: "20vh",
                width: "100%",
                justifyContent: "center",
                alignItems: "center"
            }}>
            <img
                alt="loader"
                src={require("../assets/plate.gif")}
                style={{ height: 100, width: 100, marginHorizontal: "auto" }}
            />
        </div>
    ) : getUrlParams().merchantId === "5e558cac894d114471770902" ? (
        <div
            style={{
                display: "flex",
                height: "20vh",
                width: "100%",
                justifyContent: "center",
                alignItems: "center"
            }}>
            <img
                alt="loader"
                src={require("../assets/chopstick.gif")}
                style={{ height: 100, width: 100, marginHorizontal: "auto" }}
            />
        </div>
    ) : (
        <div
            style={{
                flex: 1,
                justifyContent: "center"
            }}>
            <Loader width={30} />
        </div>
    );
};
// set url provider based on revel or square
export const provider = (storeVendor) => {
    if (storeVendor === "revel") {
        return "revel/product";
    }
    if (storeVendor === "square") {
        return "square/item";
    }
    if (storeVendor === "ordermark") {
        return "ordermark";
    }

    return "square/item";
};

export function makeTimeIntervals(
    startString,
    endString,
    intervalString = 15,
    breakStart = "",
    breakEnd = "",
    isToday = false,
    deliveryIntervalPrepTime = 0,
    initialPreparationTime,
    merchantId,
    orderMethod
) {
    let intialPrepTime = initialPreparationTime ? initialPreparationTime - 15 : 0;
    //If not break exists
    if (breakStart === "" && breakEnd === "") {
        // This is used to omit the first opening time
        let newStartString = (
            parseInt(startString) +
            intialPrepTime +
            intervalString +
            deliveryIntervalPrepTime
        ).toString();
        let newEndString = (parseInt(endString) + intervalString + deliveryIntervalPrepTime).toString();

        newStartString = newStartString.length !== 4 ? `0${newStartString}` : newStartString;
        newEndString = newEndString.length !== 4 ? `0${newEndString}` : newEndString;

        var start = (newStartString.slice(0, 2) + ":" + newStartString.slice(2, 4)).split(":");
        var end = (newEndString.slice(0, 2) + ":" + newEndString.slice(2, 4)).split(":");

        var interval = intervalString + deliveryIntervalPrepTime;
        var startInMinutes = start[0] * 60 + start[1] * 1;
        var endInMinutes = end[0] * 60 + end[1] * 1;
        var intervalInMinutes = interval * 1;
        var times = [];
        var intervalsOfTime = [];

        for (var i = startInMinutes; i <= endInMinutes; i += intervalInMinutes) {
            var hour = Math.floor(i / 60) + "";
            var minute = (i % 60) + "";

            // console.log(hour, minute, "this is here");
            minute = minute.length < 2 ? "0" + minute : minute;
            hour = hour.length < 2 ? "0" + hour : hour;
            //---------------------------------------------
            //Need to check if hour and minute fall inside our break
            //---------------------------------------------
            //Dont add time to the available time intervals if the time falls under restaurant break period
            // console.log(hour, minute, breakStart, breakEnd);
            if (breakEnd && `${hour}` + `${minute}` > breakStart && `${hour}` + `${minute}` < breakEnd) {
                //dont add that time to the possible delivery times
            } else {
                //If this is a pickup order
                let allowanceTime = 30;

                //If this is a delivery order
                if (deliveryIntervalPrepTime !== 0) {
                    allowanceTime = 60;
                }

                let focusedTime = `${hour}${minute}`;

                if (isToday) {
                    if (focusedTime - currentTime > allowanceTime) {
                        let data = {
                            value: hour + ":" + minute,
                            label: convert24hrTo12hrTime(hour + ":" + minute),
                            afterBreak: `${hour}` + `${minute}` >= breakEnd ? true : false
                        };

                        if (orderMethod === "pickup" && focusedTime <= endString) {
                            times.push(data);
                        } else if (focusedTime < endString) {
                            times.push(data);
                        }
                    }
                } else {
                    let data = {
                        value: hour + ":" + minute,
                        label: convert24hrTo12hrTime(hour + ":" + minute),
                        afterBreak: `${hour}` + `${minute}` >= breakEnd ? true : false
                    };

                    if (orderMethod === "pickup" && focusedTime <= endString) {
                        times.push(data);
                    } else if (focusedTime < endString) {
                        times.push(data);
                    }
                }

                //A check to make sure that only times above 60 minutes from users time are displayed
            }
        }

        for (var i = 0; i < times.length - 1; i++) {
            intervalsOfTime.push(times[i] + " - " + times[i + 1]);
        }

        return times;
    } else {
        function extractTimes(customStart, customEnd) {
            // This is used to omit the first opening time
            let newStartString = (
                parseInt(customStart) +
                intialPrepTime +
                intervalString +
                deliveryIntervalPrepTime
            ).toString();
            let newEndString = (parseInt(customEnd) + intervalString + deliveryIntervalPrepTime).toString();

            newStartString = newStartString.length !== 4 ? `0${newStartString}` : newStartString;
            newEndString = newEndString.length !== 4 ? `0${newEndString}` : newEndString;

            var start = (newStartString.slice(0, 2) + ":" + newStartString.slice(2, 4)).split(":");
            var end = (newEndString.slice(0, 2) + ":" + newEndString.slice(2, 4)).split(":");

            var interval = intervalString + deliveryIntervalPrepTime;
            var startInMinutes = start[0] * 60 + start[1] * 1;
            var endInMinutes = end[0] * 60 + end[1] * 1;
            var intervalInMinutes = interval * 1;
            var times = [];
            var intervalsOfTime = [];

            for (var i = startInMinutes; i <= endInMinutes; i += intervalInMinutes) {
                var hour = Math.floor(i / 60) + "";
                var minute = (i % 60) + "";

                // console.log(hour, minute, "this is here");
                minute = minute.length < 2 ? "0" + minute : minute;
                hour = hour.length < 2 ? "0" + hour : hour;
                //---------------------------------------------
                //Need to check if hour and minute fall inside our break
                //---------------------------------------------
                //Dont add time to the available time intervals if the time falls under restaurant break period
                // console.log(hour, minute, breakStart, breakEnd);

                if (breakEnd && `${hour}` + `${minute}` > breakStart && `${hour}` + `${minute}` < breakEnd) {
                    //dont add that time to the possible delivery times
                } else {
                    //If this is a pickup order
                    let allowanceTime = 30;

                    //If this is a delivery order
                    if (deliveryIntervalPrepTime !== 0) {
                        allowanceTime = 60;
                    }

                    let focusedTime = `${hour}${minute}`;

                    if (isToday) {
                        if (focusedTime - currentTime > allowanceTime) {
                            let data = {
                                value: hour + ":" + minute,
                                label: convert24hrTo12hrTime(hour + ":" + minute),
                                afterBreak: `${hour}` + `${minute}` >= breakEnd ? true : false
                            };
                            if (orderMethod === "pickup" && focusedTime <= endString) {
                                times.push(data);
                            } else if (focusedTime < endString) {
                                times.push(data);
                            }
                        }
                    } else {
                        let data = {
                            value: hour + ":" + minute,
                            label: convert24hrTo12hrTime(hour + ":" + minute),
                            afterBreak: `${hour}` + `${minute}` >= breakEnd ? true : false
                        };

                        if (orderMethod === "pickup" && focusedTime <= endString) {
                            times.push(data);
                        } else if (focusedTime < endString) {
                            times.push(data);
                        }
                    }
                    //A check to make sure that only times above 60 minutes from users time are displayed
                }
            }

            for (var i = 0; i < times.length - 1; i++) {
                intervalsOfTime.push(times[i] + " - " + times[i + 1]);
            }

            return times;
        }

        const beforeBreakTimes = extractTimes(startString, breakStart);
        const afterBreakTimes = extractTimes(breakEnd, endString);

        return [...beforeBreakTimes, ...afterBreakTimes];
    }
}

export const getTimeInterval = (
    start,
    end,
    interval = 15,
    breakStart = "",
    breakEnd = "",
    isToday = false,
    deliveryIntervalPrepTime = 0,
    intitialPreparationTime = 0,
    merchantId,
    orderMethod
) => {
    let newTime = [];
    if (end < start) {
        let firstTime = makeTimeIntervals(
            start,
            "23:59",
            interval,
            breakStart,
            breakEnd,
            isToday,
            deliveryIntervalPrepTime,
            intitialPreparationTime,
            merchantId,
            orderMethod
        );
        let secondTime = makeTimeIntervals(
            "00:00",
            end,
            interval,
            breakStart,
            breakEnd,
            isToday,
            deliveryIntervalPrepTime,
            intitialPreparationTime,
            merchantId,
            orderMethod
        );
        return [...newTime, ...firstTime, ...secondTime];
    } else {
        return [
            ...newTime,
            ...makeTimeIntervals(
                start,
                end,
                interval,
                breakStart,
                breakEnd,
                isToday,
                deliveryIntervalPrepTime,
                intitialPreparationTime,
                merchantId,
                orderMethod
            )
        ];
    }
};

export function convert24hrTo12hrTime(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
}

export const handleMakeTimeMultipleOfFifteen = (time) => {
    let hour = time.slice(0, 2);
    let minutes = time.slice(2);
    if (minutes <= 15) {
        minutes = 15;
    }
    if (minutes > 15 && minutes <= 30) {
        minutes = 30;
    }
    if (minutes > 30 && minutes <= 45) {
        minutes = 45;
    }
    if (minutes > 45 && hour < 23) {
        minutes = "00";
        if (hour < 10) {
            hour = `0${Number(hour) + 1}`;
        } else {
            hour = Number(hour) + 1;
        }
    }
    if (minutes > 45 && hour >= 23) {
        minutes = "00";
        hour = "00";
    }
    return `${hour}${minutes}`;
};

export const formartModifiersAsParagraph = (data) => {
    let newItem = [];
    if (data?.length > 0) {
        data.map((order) => {
            let newOrderItem = order.name.split("||");
            let transformedItem = newOrderItem[0] + "-" + newOrderItem[newOrderItem.length - 1];

            transformedItem = order.category ? ` ${order.category} - ${transformedItem}` : transformedItem;
            let newTransformedOrder = transformedItem.split("-");
            let newTransformedOrder2 =
                newTransformedOrder[0] + "-" + newTransformedOrder[newTransformedOrder.length - 1];

            newItem = [...newItem, newTransformedOrder2];
            return newItem;
        });
        return `With ${newItem.join(", ").trim()}`;
    }
    return newItem;
};

export const displayCategoryName = (is_promo_running, showCategoryPromoBasedOnWeb, promo_text, name) => {
    let modifiedName = "";

    if (name.toLowerCase().includes("curries") && getUrlParams().merchantId == "6075e037666fec40b0bd0773") {
        modifiedName = "CURRIES";
    } else if (name.toLowerCase().includes("superfood") && getUrlParams().merchantId == "609396e38b525c2d2e92e0fa") {
        modifiedName = "SUPERFOOD SMOOTHIES";
    } else {
        modifiedName = name;
    }
    if (is_promo_running && (showCategoryPromoBasedOnWeb || getUrlParams().promo === "true")) {
        return `${promo_text.toUpperCase()} - ${modifiedName.toUpperCase()}`;
    } else {
        return modifiedName.toUpperCase();
    }
};

export const replaceCurriesCategory = (categories, commonString = "curries") => {
    let displayedCurrentActiveItems = categories;

    //find the first category that has curries in its name
    const firstCurryCategory = displayedCurrentActiveItems.find((item) => {
        return item.name.toLowerCase().includes(commonString);
    });

    //find the first category that has curries in its name
    const firstCurryCategoryPosition = displayedCurrentActiveItems.findIndex((item) => {
        return item.name.toLowerCase().includes(commonString);
    });

    //remove all curreies from the category bar
    displayedCurrentActiveItems = displayedCurrentActiveItems.filter((item) => {
        return !item.name.toLowerCase().includes(commonString);
    });

    if (!firstCurryCategory) return [];

    displayedCurrentActiveItems.splice(firstCurryCategoryPosition, 0, firstCurryCategory);

    return displayedCurrentActiveItems;
};

export function getFreeItem(itemsInCheckout, activeCategoryPromo) {
    if (!activeCategoryPromo) {
        return null;
    }

    //When there is a promo this item would be for free
    const addedItems = itemsInCheckout;
    let cartItemsUnderPromo = [];

    // 1. Check if any BOGO promo exists
    addedItems.forEach((item) => {
        const itemIsInCategoryPromo = activeCategoryPromo.items.find((a) => {
            return a.name === item.name;
        });

        if (itemIsInCategoryPromo) {
            cartItemsUnderPromo.push(item);
        }
    });

    if (cartItemsUnderPromo.length > 1) {
        const sortedCartItemsUnderPromo = cartItemsUnderPromo.sort((a, b) => {
            return a.itemPrice - b.itemPrice;
        });
        const promoItemWithLowestPrice = sortedCartItemsUnderPromo[0];

        return promoItemWithLowestPrice;
    } else if (cartItemsUnderPromo.length === 1) {
        if (cartItemsUnderPromo[0].quantity > 1) {
            return cartItemsUnderPromo[0];
        } else {
            return null;
        }
    } else {
        return null;
    }

    //TODO : If length is 1 but quantity is greater than 1
}
