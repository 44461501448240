export const STRINGS = {
    DESCRIPTION: " Description of the image",
    MENU: "Menu",
    PRICE: "$200.00",
    TITLE: "Title",
    NAME: "dashboard",
    ADD_TO_GROUP_CHECKOUT: "Add to group checkout"
};

export const ITEMS_IN_CART = (amount) => {
    if (amount === 0) {
        return "No item in cart";
    }
    if (amount === 1) {
        return `${amount} item in cart`;
    }
    return `${amount} items in cart`;
};

export const CURRENT_OPENING = {
    friday: { end: "2329", start: "0600" },
    monday: { end: "2329", start: "0600" },
    saturday: { end: "2329", start: "0600" },
    sunday: { end: "2329", start: "0600" },
    thursday: { end: "2329", start: "0600" },
    tuesday: { end: "2329", start: "0600" },
    wednesday: { end: "2329", start: "0600" }
};

export const FUTURE_CURRENT_OPENING = {
    friday: {
        firstOpening: { end: "2329", start: "0600" },
        secongOpening: {}
    },
    monday: {
        firstOpening: { end: "2329", start: "0600" },
        secongOpening: {}
    },
    saturday: {
        firstOpening: { end: "2329", start: "0600" },
        secongOpening: {}
    },
    sunday: {
        firstOpening: { end: "2329", start: "0600" },
        secongOpening: {}
    },
    thursday: {
        firstOpening: { end: "2329", start: "0600" },
        secongOpening: {}
    },
    tuesday: {
        firstOpening: { end: "2329", start: "0600" },
        secongOpening: {}
    },
    wednesday: {
        firstOpening: { end: "2329", start: "0600" },
        secongOpening: {}
    }
};

export const RECOMMENDATION_MERCHANTS_WHITELIST = [
    "5f010924df0b114b4f98e7cb",
    "5a7371c9a67ad0001a1023f8",
    "5ed830de44a2794e3694111d"
];
