import { put, call, takeEvery } from "redux-saga/effects";
import actionTypes from "./actionTypes";
import {
    PostUserProfileSuccess,
    PostUserProfileFailure,
    setUserProfileFailure,
    setUserProfileSuccess,
    setWhitelistDomainsSuccess,
    setWhitelistDomainsFailure,
    setGuestOrderSessionSuccess,
    setGuestOrderSessionFailure
} from "./actions";
import { baseUrl } from "../../utils/utils";

//--------------------------------------------------
//Helpers
//--------------------------------------------------
const {
    POST_USER_PROFILE,
    REQUEST_LOAD_USER_PROFILE,
    REQUEST_WHITELIST_DOMAINS,
    REQUEST_LOAD_GUEST_ORDER_SESSION
} = actionTypes;
const url = "https://pay.joyup.me/save/delivery";
const pageToken =
    "EAADxqKWGDYkBAOSRVaxOAVeQ8EmMgFNpN2JZCTBS6FXnzpP3GnUbKqablOps3pMHgg0TqOPJlpZAuNzxp4165V5DKSQaobBSbo4eHOZBw1DbAgy9Y3vuaLraoOZCZAOedtjKWK5S5N2CqVxtY17FTKm1hZCsaOFIMG6n76J8O5aZBF77apGlLO1L8nXLdHnLYsZD";

//--------------------------------------------------
//User related generators
//--------------------------------------------------
function* handlePostUserProfile({ payload }) {
    const {
        streetAddressLine1,
        streetAddressLine2,
        addressCity,
        telephoneNumber,
        addressState,
        postalCode,
        botId,
        merchantId,
        psId
    } = payload;

    const requestBody = {
        body: JSON.stringify({
            address1: streetAddressLine1,
            address2: streetAddressLine2,
            botId,
            city: addressCity,
            merchantId,
            phone_number: telephoneNumber,
            psId,
            state: addressState,
            zip: postalCode
        }),
        headers: {
            "Content-Type": "application/json"
        },
        method: "POST"
    };

    try {
        const response = yield call(fetch, url, requestBody);
        if (response.status >= 200 && response.status < 300) {
            yield put(PostUserProfileSuccess(payload));
        } else {
            yield put(PostUserProfileFailure(response.statusText));
        }
    } catch (error) {
        yield put(PostUserProfileFailure(error));
    }
}

function* LoadUserProfile({ payload }) {
    if (payload === null) {
        yield put(setUserProfileSuccess({}));
    } else {
        const url = "https://pay.joyup.me/receive/delivery";
        const requestBody = {
            body: JSON.stringify(payload),
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST"
        };
        try {
            const response = yield call(fetch, url, requestBody);

            if (response.status >= 200 && response.status < 300) {
                const responseData = yield response.json();

                yield put(setUserProfileSuccess(...responseData.data));
            } else {
                yield put(setUserProfileFailure(response.statusText));
            }
        } catch (error) {
            yield put(setUserProfileFailure(error.message));
        }
    }
}

//--------------------------------------------------
//Domain related generators
//--------------------------------------------------
function* handleWhitelistingOfDomains({ payload }) {
    const url = `https://graph.facebook.com/v4.0/me/messenger_profile?access_token=${payload}`;
    const data = {
        whitelisted_domains: [
            window.location.origin,
            "https://e6000468.ngrok.io",
            "https://www.netlify.com",
            "https://www.joyup.me",
            "https://www.messenger.com",
            "https://www.facebook.com"
        ]
    };

    const requestBody = {
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        },
        method: "post"
    };

    try {
        const response = yield call(fetch, url, requestBody);
        if (response.status >= 200 && response.status < 300) {
            yield put(setWhitelistDomainsSuccess(data.whitelisted_domains));
        } else {
            yield put(setWhitelistDomainsFailure(response.statusText));
        }
    } catch (error) {
        yield put(setWhitelistDomainsFailure("please check your network and try again"));
    }
}

//--------------------------------------------------
//Guest related generators
//--------------------------------------------------
function* handleSetGuestOrderSession({ payload }) {
    const response = yield call(fetch, `${baseUrl}/group/order/${payload}`);
    if (response) {
        const responseData = yield response.json();
        yield put(setGuestOrderSessionSuccess(responseData));
    } else {
        yield put(setGuestOrderSessionFailure(response.statusText));
    }
}

export default function* watchCartSagas() {
    yield takeEvery(POST_USER_PROFILE, handlePostUserProfile);
    yield takeEvery(REQUEST_LOAD_USER_PROFILE, LoadUserProfile);
    yield takeEvery(REQUEST_WHITELIST_DOMAINS, handleWhitelistingOfDomains);
    yield takeEvery(REQUEST_LOAD_GUEST_ORDER_SESSION, handleSetGuestOrderSession);
}
