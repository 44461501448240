import { StyleSheet } from "react-native";
import styled from "styled-components";
import { pages } from "../../utils/theme";
import { getUrlParams } from "../../utils/utils";

export const StyledButton = styled.button`
    border-radius: 100px;
    color: #ffffff;
    padding: ${(props) => (props.isWideButton ? "10px" : "5px")};
    box-shadow: rgba(12, 10, 10, 0.25) 0px 2px 2.84px;
    background-color: ${(props) => (props.disabled ? "#cccccc" : props.theme.colors.primary)};
    border: ${(props) => (props.disabled ? "#cccccc" : "none")};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    width: 100%;
    transition: all 0.1s ease-in;
    height: ${(props) => props.isBigButton && "50px"};
    font-size: ${(props) => (props.isBigButton ? "16px" : "14px")};

    :hover,
    :focus {
        box-shadow: 0px 3px 4.84px rgba(0, 0, 0, 0.25);
        opacity: 0.7;
    }

    :active {
        box-shadow: none;
    }

    .buttonTextContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /* padding: 1em 0; */
    }

    .buttonText {
        color: #ffffff;
        text-align: center;
        font-size: inherit;
        margin: 0;
        padding: 0 5px;
    }

    .buttonImage {
        width: 20px;
        height: 20px;
    }
`;

export const StyledRadioButtonContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1px;
    margin-bottom: 1px;
    cursor: pointer;
    :hover,
    :focus,
    :active {
        opacity: 0.9;
    }

    .radioItems {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-start;
    }

    .circle {
        align-items: center;
        border-radius: 100%;
        height: 20px;
        justify-content: center;
        width: 20px;
        border: 2px solid rgb(101, 119, 134);
        display: flex;
    }

    .checked-circle {
        background-color: #009688;
        border-radius: 7px;
        height: 14px;
        width: 14px;
    }

    .radioLabel {
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.04);
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        margin-left: 10px;
    }

    .radioText {
        margin: 0;
        padding-bottom: 0.5em;
        padding-top: 0.5em;
        font-size: 14px;
    }
    .currency {
        color: #333333b0;
        margin: 0;
        font-size: 14px;
    }
`;

export const PaymentButtonStyle = StyleSheet.create({
    paymentButton: {
        width: "100%",
        height: "40px",
        maxWidth: "375px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 5,
        backgroundColor: pages[getUrlParams().merchantId] || "#69E1C6",
        marginVertical: 30
    },
    paymentLabel: {
        flexDirection: "row",
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
    },
    payLogo: {
        width: "80px",
        height: "50%",
        marginRight: 2
    },
    buttonTextStyle: {
        position: "relative",
        right: 27,
        fontFamily: "Rubik",
        fontWeight: "500",
        fontSize: 16
    },
    activityIndicatorStyle: {
        backgroundColor: "red",
        height: "100%",
        width: "100%"
    }
});
