import styled from "styled-components";

export const StyledNewDesktopSlidingCartWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 100vh;
    visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
    transition: visibility 200ms ease-in-out;

    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

export const StyledOverlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
    transition: opacity 200ms ease-in-out;
`;

export const StyledContent = styled.div`
    width: 400px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    background-color: #fff;
    transform: ${({ isOpen }) => (isOpen ? "translateX(0px)" : "translateX(100vw)")};
    transition: transform 200ms ease-in-out;
    padding: 30px;

    .cart-header {
        font-family: Rubik;
        font-weight: 600;
        color: ${(props) => props.theme.colors.primary};
        font-size: 20px;
        // text-transform: uppercase;
        margin: 16px 0px;
    }

    .ordertype-section {
        font-weight: 600;
        margin-bottom: 10px;
        display: flex;

        p {
            margin-right: 10px;
        }

        span {
            color: ${(props) => props.theme.colors.primary};
        }
    }

    .cart-item {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.04);

        &__details {
            &__name {
                font-size: 14px;
                font-family: Rubik;
                font-weight: 600;
                text-transform: capitalize;
            }

            &__modifier {
                color: #969696;
                font-size: 12px;
                font-family: Rubik;
            }
        }

        &__count {
            background-color: ${(props) => props.theme.colors.primary};
            border-radius: 20px;
            display: flex;
            width: 50px;
            color: #fff;
            align-self: flex-end;
            flex-shrink: 0;

            .action {
                flex: 1;
                text-align: center;
                cursor: pointer;
            }
        }
    }

    .discounted-item {
        color: ${(props) => props.theme.colors.primary};
        font-weight: 600;
        text-align: center;
        margin: 15px 0px;

        span {
            font-weight: 100;
        }
    }

    .shipping-text {
        font-size: 10px;
        margin: 10px auto;
        opacity: 0.6;
        text-align: center;
    }
`;
