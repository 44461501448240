import actionTypes from './actionTypes'
const {
    SET_MERCHANTS_DATA_SUCCESS,
    SET_MERCHANTS_DATA_FAILURE,
    REQUEST_LOAD_MERCHANTS_DATA,
} = actionTypes

const initialState = {
    error: null,
    merchantsData: [],
    isMerchantDataLoading: false,
    hasMerchantDataLoaded: false,
}

export default (state = initialState, action) => {

    const {
        type, payload,
    } = action;

    switch (type) {
        case REQUEST_LOAD_MERCHANTS_DATA:
            return { ...state, isMerchantDataLoading: true, hasMerchantDataLoaded: false };

        case SET_MERCHANTS_DATA_SUCCESS:
            return {
                ...state,
                isMerchantDataLoading: false,
                hasMerchantDataLoaded: true,
                merchantsData: [...payload],
            };

        case SET_MERCHANTS_DATA_FAILURE:
            return {
                ...state,
                isMerchantDataLoading: false,
                hasMerchantDataLoaded: false,
                error: payload,
            };

        default:
            return { ...state };
    }

}
