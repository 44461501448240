export const actionTypes = {
    //------
    SET_CURRENT_MERCHANTS_ID: "SET_CURRENT_MERCHANTS_ID",
    REQUEST_CURRENT_MERCHANTS_ID: "REQUEST_CURRENT_MERCHANTS_ID",

    //------
    CLEAR_CHECKOUT: "CLEAR_CHECKOUT",

    //------
    REQUEST_CURRENT_SHOP: "REQUEST_CURRENT_SHOP",
    SET_CURRENT_SHOP_SUCCESS: "SET_CURRENT_SHOP_SUCCESS",
    SET_CURRENT_SHOP_FAILURE: "SET_CURRENT_SHOP_FAILURE",

    //------
    REQUEST_CATEGORIES: "REQUEST_CATEGORIES",
    SET_CATEGORIES_SUCCESS: "SET_CATEGORIES_SUCCESS",
    SET_CATEGORIES_FAILURE: "SET_CATEGORIES_FAILURE",

    //------
    SET_FOCUSED_CATEGORY: "SET_FOCUSED_CATEGORY",

    //------
    SET_ORDER_TYPE: "SET_ORDER_TYPE",

    //------
    HANDLE_ITEM_COUNT_CHANGE: "HANDLE_ITEM_COUNT_CHANGE",

    //------
    REQUEST_USER_ADDRESS: "REQUEST_USER_ADDRESS",
    SET_USER_ADDRESS_SUCCESS: "SET_USER_ADDRESS_SUCCESS",
    SET_USER_ADDRESS_FAILURE: "SET_USER_ADDRESS_FAILURE",
    SET_USER_FACEBOOK_ID: "SET_USER_FACEBOOK_ID",
    SET_NAME_OF_GUEST: "SET_NAME_OF_GUEST",

    //------
    REQUEST_OPTIONS_DATA_FOR_FOCUSED_CATEGORY: "REQUEST_OPTIONS_DATA_FOR_FOCUSED_CATEGORY",
    SET_OPTIONS_DATA_FOR_FOCUSED_CATEGORY_SUCCESS: "SET_OPTIONS_DATA_FOR_FOCUSED_CATEGORY_SUCCESS",
    SET_OPTIONS_DATA_FOR_FOCUSED_CATEGORY_FAILURE: "SET_OPTIONS_DATA_FOR_FOCUSED_CATEGORY_FAILURE",

    RESET_HAS_CART_CHANGED: "RESET_HAS_CART_CHANGED",
    ADD_ITEM_TO_CHECKOUT: "ADD_ITEM_TO_CHECKOUT",
    HANDLE_REMOVE_ITEM_FROM_CART: "HANDLE_REMOVE_ITEM_FROM_CART"
};

export default actionTypes;
