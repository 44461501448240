import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Dimensions,
    Image,
    ScrollView,
    Text,
    TextInput,
    View,
} from 'react-native'

import {
    getError,
    getMerchantsData,
    getIsMerchantDataLoading,
    getHasMerchantDataLoaded,
} from '../selectors'

import {
    loadMerchantsData,
    setMerchantsDataSuccess,
    setMerchantsDataFailure
} from "../actions"

import { styles } from './styles'
import search from '../../../assets/search.svg'

import ShopsItem from './ShopsItem';


const {
    discoveryContainer,
    discoveryCity,
    inputContainer,
    searchIcon,
    searchInput,
} = styles


const Discovery = ({
    error,
    merchantsData,
    isMerchantDataLoading,
    hasMerchantDataLoaded,
    loadMerchantsData
}) => {

    //value of the search input
    const [searchValue, setsearchValue] = useState('')
    //data returned from search
    const [searchData, setSearchData] = useState([])
    const [merchants, setMerchants] = useState([])

    // filter merchant to remove buggy data
    const filterMerchant = () => {
        let filteredMerchant = merchantsData.filter(item => item.is_active_web_order === true || item.is_active_web_order === undefined)
        setMerchants(filteredMerchant)
    }

    useEffect(() => {
        /**
         * This function fetches the merchants data, it does this if the merchats data has not been populated
         */
        if (merchantsData.length === 0) {
            loadMerchantsData()
            filterMerchant()
        } else {
            filterMerchant()
        }
    }, [merchantsData])


    /**
     *This function collects the values form the search input field and sets the state of the search value. it is called when there is any change in ths search input field
     * @param {string} value The value inputted in the search input
     */
    const onChangeText = (value) => {
        setsearchValue(value)
    }

    useEffect(() => {
        /**
         * This function handles search
         */
        const handleSearch = () => {
            let filteredData = []
            if (searchValue && searchValue.length > 0) {
                filteredData = merchants.filter(merchant => {
                    const { name } = merchant
                    return name.trim()
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                })
                setSearchData(filteredData)
            } else {
                setSearchData([])
            }
        };

        handleSearch();
    }, [searchValue])


    // removes items with no promo text
    const PROMOITEMS = merchantsData.filter(item => item.promo_text !== undefined)


    const keyExtractor = item => `${item._id.$oid}`;
    const promoKeyExtractor = item => `${item.id}`

    const renderShopsItem = () => {
        return <ShopsItem
            searchData={searchData}
            searchValue={searchValue}
            PROMOITEMS={PROMOITEMS}
            keyExtractor={keyExtractor}
            promoKeyExtractor={promoKeyExtractor}
            removeClippedSubviews={false}
            merchants={merchants}
        />
    }


    return (
        <View style={discoveryContainer}>
            {/* header city */}
            <Text style={discoveryCity}>San Francisco, CA</Text>
            {/* search */}
            <View
                style={inputContainer}
            >
                <Image
                    source={search}
                    style={searchIcon}
                />
                <TextInput
                    placeholder="Search for restaurant"
                    style={searchInput}
                    onChangeText={text => onChangeText(text)}
                    value={searchValue}
                />
            </View>
            <View>
                {/* <View style={{ height: deviceHeight - 90 }}> */}
                <ScrollView>
                    {
                        renderShopsItem()
                    }
                </ScrollView>
            </View>

        </View >
    )
}

const mapStateToProps = state => ({
    error: getError(state),
    merchantsData: getMerchantsData(state),
    isMerchantDataLoading: getIsMerchantDataLoading(state),
    hasMerchantDataLoaded: getHasMerchantDataLoaded(state),
});

const DiscoveryAction = {
    loadMerchantsData
}

const mapDispatchToProps = dispatch => bindActionCreators(DiscoveryAction, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Discovery);
