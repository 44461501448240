export const STRINGS = {
    NAME: 'discovery'
}

export const PROMO = [
    {
        id: 1,
        text: 'Get free Delivery on next order',
        image: require('../../assets/burrito.jpeg'),
        background: '#f6c649',
        link: `/merchant/?merchant_id=5a7371c9a67ad0001a1023f8&location_id=3H2E3TNAV342N&page_id=113923763334198`
    },
    {
        id: 2,
        text: 'Buy one get one free',
        image: "https://s3.amazonaws.com/joyup.images-1/5a70fcfab4cd49001afd2e63/1549398719503_ramen.jpeg"
    },
    {
        id: 3,
        text: 'Buy one get one free on next order',
        image: "https://joyup-images-1.s3.amazonaws.com/5a7371c9a67ad0001a1023f8/1568850529765_lopc3lhwlxidj6slcwlq.jpeg"
    },

]
