import lscache from 'lscache';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const reduxExtension = require('redux-devtools-extension');

const bindMiddleware = middleware => {
    if (process.env.NODE_ENV !== 'production') {
        const { composeWithDevTools } = reduxExtension;
        return composeWithDevTools(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};

const JOYUP_STATE = 'JOYUP_STATE'
// Load State
export const loadState = () => {
    try {
        const serializedState = lscache.get(JOYUP_STATE);
        if (serializedState === null) {
            return undefined;
        }
        return serializedState;
    } catch (err) {
        return undefined;
    }
};

// Save State
export const saveState = state => {
    try {
        const serializedState = JSON.stringify(state);
        // use lscache to set the data expiry time to 5 minutes
        lscache.set(JOYUP_STATE, state, 5);
    } catch (err) {
        console.log('Error saving data');
    }
};

const persistedState = loadState();



const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    rootReducer,
    persistedState,
    bindMiddleware([sagaMiddleware])
);

store.subscribe(() => {
    saveState(store.getState());
});
store.sagaTask = sagaMiddleware.run(rootSaga);




export default store;
