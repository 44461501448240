/** style.js layout* */

import { Dimensions, StyleSheet } from "react-native";
import styled from "styled-components";

const deviceWidth = Dimensions.get("window").width;
const deviceHeight = Dimensions.get("window").height;

const styles = StyleSheet.create({
    dateDay: {
        fontFamily: "Rubik",
        fontSize: 12,
        textTransform: "capitalize",
        color: "white"
    },
    dateItemContainer: {
        marginRight: "20px"
    },
    dateListContainer: {
        justifyContent: "space-evenly"
    },
    dateTime: {
        fontFamily: "Rubik",
        fontSize: 10,
        opacity: 0.8,
        color: "white"
    },
    featuredItemCategoryName: {
        fontFamily: "Rubik",
        fontSize: 20,
        fontWeight: "600",
        color: "white"
    },

    featuredItemCategoryLocation: {
        color: "white",
        // fontFamily: 'Rubik',
        fontSize: 10
    },
    heroImage: {
        height: deviceWidth < 760 ? 150 : deviceWidth < 1024 ? 200 : 300,
        // width: deviceWidth,
        maxHeight: 360,
        marginHorizontal: "auto"
        // position: 'absolute',
        // top: 0,
        // right:0,
        // left: 0
    },

    loadingStyle: {
        flex: 1,
        justifyContent: "center"
    },
    storeDetails: {
        marginBottom: 10
    }
});

export { styles };

export const StyledPageLayoutContainer = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--color-text);
`;
export const StyledFeaturedItemContainer = styled.div`
    background-color: rgba(128, 128, 128, 0.8);
    z-index: 0;
    border-radius: 10px;
    flex: 1;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    width: 89%;
    max-width: fit-content;
    overflow: hidden;
    padding: 1em;
    position: absolute;
    right: 0;
    top: 12vh;
    box-shadow: 7px 0px 5px 4px rgba(0, 0, 0, 0.1);

    @media screen and (min-width: 500px) {
        top: 100px;
    }

    @media screen and (min-width: 900px) {
        top: 150px;
    }

    @media screen and (min-width: 1024px) {
        top: 200px;
    }

    @media screen and (min-width: 1024px) {
        display: ${({ storeTemplate }) =>
            storeTemplate == 2 || storeTemplate == 3 || storeTemplate == 4 ? "none" : "block"};
    }
`;

export const StyledContainerHeroImage = styled.div`
    background-position: unset;
    height: 100%;
    /* height: deviceWidth * (360 / 820); */
    height: 20vh;
    flex-basis: 160px;
    max-height: 400px;
    width: deviceWidth;
`;

export const StyledLayoutContainerWithRandomCategory = styled.div`
    flex-direction: row;
    flex-wrap: wrap;
    //margin-bottom: 50px;
    position: relative;
    flex-basis: 20vh;
`;

// ////STORE CLOSED ALERT

export const StyledModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    padding: 48px;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: ${(props) => (props.isStoreOpen === false ? "scale(1)" : "scale(0)")};
    visibility: ${(props) => (props.isStoreOpen === false ? "visible" : "hidden")};
    height: ${(props) => (props.isStoreOpen === false ? "100%" : "0px")};
    transition: transform 0.001s ease-in 0.1s, visibility 0.001s ease-in 0.1s;

    @media only screen and (max-height: 500px), only screen and (max-width: 768px) {
        padding: 20px;
    }
`;
export const StyledModalContent = styled.div`
    z-index: 5;
    max-height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 350px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
    position: relative;
    top: ${(props) => (props.isStoreOpen === false ? "0" : "3000px")};
    visibility: ${(props) => (props.isStoreOpen === false ? "visible" : "hidden")};
    transition: top 0.2s ease-in-out 0.2s;
    /* height: ${(props) => (props.isStoreOpen ? "0px" : "100%")} */
`;
export const StyledModalTop = styled.div`
    background-color: red;
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    & img {
        width: 100px;
    }
`;
export const StyledModalBottom = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    background-color: ${(props) => (props.scheduledOrderTime === null ? "#e0e0e0" : props.theme.colors.primary)};
    cursor: ${(props) => (props.scheduledOrderTime === null ? "not-allowed" : "pointer")};
    pointer-events: ${(props) => (props.scheduledOrderTime === null ? "none" : "visible")};
    cursor: pointer;

    :hover {
        opacity: 0.6;
    }
    button {
        pointer-events: ${(props) => (props.scheduledOrderTime === null ? "none" : "visible")};
        text-align: center;
        color: #fff;
        height: 70px;
        width: 100%;
        border: none;
        background: none;
        text-transform: capitalize;
        font-size: 20px;
        cursor: pointer;
    }
`;
export const StyledModalCloser = styled.div`
    width: 10px;

    img {
        width: 30px;
        position: absolute;
        top: 10px;
        left: 10px;
        cursor: pointer;
    }
`;
export const StyledModalHeader = styled.div`
    padding: 20px 10px 10px;
    display: flex;
    p {
        margin: 0;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.17;
        letter-spacing: 0.3px;
        text-align: center;
        width: 100%;
        text-transform: capitalize;
    }
`;
export const StyledModalMiddleContent = styled.div`
    max-height: calc(100vh - 200px);
    /* overflow-y: auto;
    overflow-x: hidden; */
    padding: 12px;

    .modal-content {
    }

    .content-warning {
        background: ${(props) => `${props.theme.colors.primary}10`};
        padding: 10px;
        font-size: 15px;
        margin: 0;
        color: ${(props) => `${props.theme.colors.primary}`};
        border-radius: 10px;
    }

    .schedule-title {
        font-size: 16px;
        line-height: 1.33;
        letter-spacing: 0.3px;
        margin: 16px 0 0;
        font-weight: 500;
        text-transform: capitalize;
    }

    @media only screen and (max-height: 500px), only screen and (max-width: 768px) {
        max-height: none;
        /* overflow-y: scroll; */
    }
`;
export const StyledOrderTypeButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px auto;

    input[type="button"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .pickup_button,
    .delivery_button {
        flex-basis: 45%;
        height: 50px;
        color: ${(props) => props.theme.colors.primary};
        cursor: pointer;
        border-radius: 5px;
        font-weight: 700;
        font-size: 14px;
        border: none;
        letter-spacing: 0.63px;
        font-family: inherit;

        :hover {
            border: ${(props) => `2px solid ${props.theme.colors.primary}`};
        }
    }
    .pickup_button {
        background-color: ${(props) =>
            props.selectedOrderMethod === "pickup" ? `${props.theme.colors.primary}21` : "#f5f5f5"};
        border: ${(props) =>
            props.selectedOrderMethod === "pickup" ? `2px solid ${props.theme.colors.primary}` : null};
    }
    .delivery_button {
        background-color: ${(props) =>
            props.selectedOrderMethod === "delivery" ? `${props.theme.colors.primary}21` : "#f5f5f5"};
        border: ${(props) =>
            props.selectedOrderMethod === "delivery" ? `2px solid ${props.theme.colors.primary}` : null};
    }
`;
export const StyledAddressWrapper = styled.div`
    margin: 30px 0;
    input {
        width: calc(100% - 20px);
        border: none;
        border-bottom: 1px solid black;
        padding: 10px;
        font-size: 14px;
    }
    p {
        margin-bottom: 10px;
        font-weight: 500;
    }
`;
export const StyledOrderChooserWrapper = styled.div`
    margin: 30px 0;
    p {
        margin-bottom: 10px;
        font-weight: 500;
    }
    div {
        padding: 15px 0;
        border-bottom: 1px solid #f5f5f5;
    }
    input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    input[type="radio"]:checked ~ label:after {
        background-color: ${(props) => props.theme.colors.primary};
    }
    /* input[type="radio"]:hover ~ label:after{
background-color: #ccc;;
} */

    label {
        position: relative;
        padding-left: 30px;
        display: flex;
        cursor: pointer;
        :before {
            display: table;
            content: "";
            width: 15px;
            height: 15px;
            border-radius: 50%;
            border: 1px solid #ccc;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
        }

        :hover :after {
            background-color: #ccc;
        }

        :after {
            display: table;
            content: "";
            width: 11px;
            height: 11px;
            border-radius: 50%;
            position: absolute;
            top: 3px;
            bottom: 0;
            left: 3px;
            background-color: white;
        }
    }

    .order_now {
        opacity: ${(props) => (props.disabled ? ".4" : 1)};
    }
`;
export const StyledInputWrapperWithCalender = styled.div`
    margin: 30px 0;

    input[value] :not([value=""]) + input:after {
        content: "" !important;
    }
    .calender-input {
        background-image: none;
        background-color: transparent;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        font-size: 15px;
        height: 41px;
        width: calc(100% - 40px);
        padding: 0 20px;
        border-radius: 7px;
        border: 1px solid #dbdbdb;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:hover {
            border: 1px solid #888;
        }
        &:focus {
            border-color: #aaa;
            box-shadow: 0 0 1px 3px rgba(170, 170, 170, 0.7);
            box-shadow: 0 0 0 3px-moz-mac-focusring;
            outline: none;
        }

        :focus :after {
            content: "" !important;
        }

        :after {
            color: #000;
            content: attr(placeholder) !important;
        }
    }
`;
