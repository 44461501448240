import React from 'react';
import { View, StyleSheet, Animated } from 'react-native';
import styled from 'styled-components';

const styles = StyleSheet.create({
    imageOverlay: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        // borderRadius: '10px',
    },
    container: {
        backgroundColor: '#ffffff',
        // backgroundColor: '#38ffff',
        // borderRadius: '10px',
        flex: 1,
    },
});

const PromoRibbon = styled.div`
position: absolute;
right: -5px; top: -5px;
z-index: 1;
overflow: hidden;
width: 75px; height: 75px;
text-align: right;
`;
const PromoSpan = styled.div`
font-family:'rubik';
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #69e2c7;
  background: linear-gradient(90deg, #69e2c7, #295e7e);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px; right: -21px;

  &:before{
      content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #69e2c7;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #69e2c7;
  }

  &:after{
    content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #69e2c7;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #69e2c7;
  }
`;

class ProgressiveImage extends React.Component {
    thumbnailAnimated = new Animated.Value(0);

    imageAnimated = new Animated.Value(0);

    handleThumbnailLoad = () => {
        Animated.timing(this.thumbnailAnimated, {
            toValue: 1,
        }).start();
    }

    onImageLoad = () => {
        Animated.timing(this.imageAnimated, {
            toValue: 1,
        }).start();
    }

    render() {
        const {
            thumbnailSource,
            source,
            style,
            isPromoPresent,
            promoText,
            loading,
            ...props
        } = this.props;

        return (
            <View style={styles.container}>
                <Animated.Image
                    {...props}
                    source={thumbnailSource}
                    style={[styles.imageOverlay, { opacity: this.thumbnailAnimated }]}
                    onLoad={this.handleThumbnailLoad}
                    blurRadius={1}
                    width={100}
                    height={100}
                />
                <Animated.Image
                    {...props}
                    source={source}
                    style={[styles.imageOverlay, { opacity: this.imageAnimated }, style]}
                    onLoad={this.onImageLoad}
                    loading={loading}
                    width={100}
                    height={100}
                />

                {
                    isPromoPresent && (
                        <PromoRibbon>
                            <PromoSpan>{promoText}</PromoSpan>
                        </PromoRibbon>
                    )
                }
            </View>
        );
    }
}

export default React.memo(ProgressiveImage)
