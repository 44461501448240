/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { ActivityIndicator, View, TouchableWithoutFeedback } from "react-native";
import createGlobalStyle from "styled-components";
import {
    styles,
    StyledPageLayoutContainer,
    StyledLayoutContainerWithRandomCategory,
    StyledFeaturedItemContainer
} from "./styles";
import PromoBanner from "../promoBanner";
import PromoRibbon from "../promoRibbbon";
import { PageHeroImageComponent } from "./PageHeroImageComponent";
import { StoreNameAddressComponent } from "./StoreNameAddressComponent";
import { HoursOfOperationComponent } from "./HoursOfOperationComponent";
import { TrackingCodeComponent } from "./TrackingCodeComponent";
import { getUrlParams } from "../../utils/utils";

const { featuredItemContainer, layoutContainerWithRandomCategory, loadingStyle } = styles;

export const LocationDetailsBanner = ({ categories, handleImageLoad, storeTemplate }) => (
    <StyledLayoutContainerWithRandomCategory>
        {/* use helmet to add the document to the header */}
        <TrackingCodeComponent categories={categories} />
        <PageHeroImageComponent categories={categories} handleImageLoad={handleImageLoad} />
        {categories && categories.merchant_name && (
            <StyledFeaturedItemContainer storeTemplate={storeTemplate}>
                <StoreNameAddressComponent categories={categories} />
                <HoursOfOperationComponent categories={categories} />
            </StyledFeaturedItemContainer>
        )}
    </StyledLayoutContainerWithRandomCategory>
);

const Layout = ({ children, currentMerchantsShop, scrollStarts, userProfile }) => {
    const { categories } = currentMerchantsShop;

    // set heroImage load
    const [heroImageLoaded, setHeroImageLoaded] = React.useState(false);
    const [promotionText, setPromotionText] = React.useState(null);
    const [promotionDetails, setPromotionDetails] = React.useState(null);

    // function to set the hero image load true
    const handleImageLoad = () => {
        setHeroImageLoaded(true);
    };

    // runs when image load stage changes
    React.useEffect(() => {
        handleImageLoad();
    }, [heroImageLoaded]);

    React.useEffect(() => {
        const setPromoTextFunction = () => {
            if (categories) {
                const promoItem = categories.menu.filter((item) => item.is_promo_running === true);
                if (promoItem.length > 0) {
                    setPromotionText(categories && promoItem[0].promo_text);
                    setPromotionDetails(categories && promoItem[0].promo_details);
                }
            }
        };
        setPromoTextFunction();
    }, [categories]);

    return currentMerchantsShop && currentMerchantsShop.categories !== undefined ? (
        <StyledPageLayoutContainer>
            {
                <PromoRibbon
                    promoInstruction={
                        userProfile?.promo &&
                        getUrlParams().channel !== "web" &&
                        currentMerchantsShop?.categories?.promoText
                    }
                    isRibbonPromoPresent={
                        userProfile?.promo &&
                        currentMerchantsShop?.categories?.promoText &&
                        getUrlParams().channel !== "web"
                    }
                />
            }
            {promotionText !== null && (
                <PromoBanner
                    isPromoPresent
                    promoText={userProfile?.promo && getUrlParams().channel !== "web" && promotionText}
                    promoDetails={userProfile?.promo && getUrlParams().channel !== "web" && promotionDetails}
                />
            )}
            {!scrollStarts ? <LocationDetailsBanner categories={categories} handleImageLoad={handleImageLoad} /> : null}

            {children}
        </StyledPageLayoutContainer>
    ) : (
        <View style={loadingStyle}>
            <ActivityIndicator color="rgb(105, 225, 198)" size="large" />
        </View>
    );
};

export default Layout;

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    currentMerchantsShop: PropTypes.shape({
        categories: PropTypes.shape({
            hours_of_operation: PropTypes.object.isRequired,
            image_url: PropTypes.string.isRequired,
            location_address: PropTypes.string.isRequired,
            menu: PropTypes.arrayOf(
                PropTypes.shape({
                    is_promo_running: PropTypes.string.isRequired,
                    promo_details: PropTypes.string.isRequired,
                    promo_text: PropTypes.string.isRequired
                })
            ).isRequired,
            merchant_name: PropTypes.string.isRequired,
            page_hero_image: PropTypes.string,
            promoText: PropTypes.string.isRequired
        }).isRequired
    }),
    scrollStarts: PropTypes.bool.isRequired
};

Layout.defaultProps = {
    currentMerchantsShop: {
        categories: {
            page_hero_image: ""
        }
    }
};
