import { STRINGS } from "./constant";
import { getUrlParams } from "../../utils/utils";

const { NAME } = STRINGS;
const currentShop = getUrlParams().merchantId;

export const getCurrentlySelectedMerchant = (state) => state[NAME].currentlySelectedMerchant;
export const getCurrentMerchantsShop = (state) => state[NAME][getUrlParams().merchantId];
export const getCurrentShop = (state) => state[NAME][currentShop];
export const getVisitedStore = (state) => state[NAME].visitedStore;
export const getHasCurrentMerchantShopLoaded = (state) => state[NAME].hasCurrentMerchantShopLoaded;
export const getIsCurrentMerchantShopLoading = (state) => state[NAME].isCurrentMerchantShopLoading;
export const getIsCurrentMerchantIdLoading = (state) => state[NAME].isCurrentMerchantIdLoading;
export const getIsUserProfileLoading = (state) => state[NAME].isUserProfileLoading;
export const getHasCurrentMerchantIdLoaded = (state) => state[NAME].hasCurrentMerchantIdLoaded;
export const getIsCurrentCategoriesLoading = (state) => state[NAME].isCurrentCategoriesLoading;
export const getHasCurrentCategoriesLoaded = (state) => state[NAME].hasCurrentCategoriesLoaded;
export const getHasCurrentFocusedCategoryBeenSet = (state) => state[NAME].hasCurrentFocusedCategoryBeenSet;
export const getHasAnErrorOccur = (state) => state[NAME].hasAnErrorOccur;
export const getError = (state) => state[NAME].error;
export const getHasOptionsDataLoaded = (state) => state[NAME].hasOptionsDataLoaded;
export const getIsOptionsDataLoading = (state) => state[NAME].isOptionsDataLoading;
export const getUserFacebookId = (state) => state[NAME].userFacebookId;
export const getGuestName = (state) => state[NAME].guestName;
