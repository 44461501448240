import React from "react";
import { Text, View, Picker, TouchableOpacity, ActivityIndicator, Image, StyleSheet } from "react-native";
import styled from "styled-components";
import { pages } from "../../utils/theme";
import { getUrlParams } from "../../utils/utils";

const StyledPaymentButton = styled.button`
    /* flex-direction: row;
        height: 100%;
        justify-content: center;
        align-items: center;
        display:flex;
        width:100%;
        */

    /* .paymentButton{ */
    border: none;
    width: 100%;
    height: 40px;
    max-width: 375px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.primary};
    /* }; */

    .payLogo {
        width: 80px;
        height: 50%;
        margin-right: 2px;
    }
    .buttonTextStyle {
        font-family: "Rubik";
        font-weight: 500;
        font-size: 16px;
        color: #fff;
    }
    .activityIndicatorStyle {
        background-color: red;
        height: 100%;
        width: 100%;
    }
`;

const PaymentButton = ({ isLoading, source, buttonText, handleButtonClick }) => {
    return (
        // <TouchableOpacity
        //     style={paymentButton}
        //     onClick={handleButtonClick}
        // >
        <StyledPaymentButton className="paymentButton" onClick={handleButtonClick}>
            {/* <div className='paymentLabel'> */}
            {source && <img src={source} alt="payment logo" className="payLogo" />}
            {buttonText && <p className="buttonTextStyle">{buttonText}</p>}
            {/* </div> */}
            {/* </TouchableOpacity> */}
        </StyledPaymentButton>
    );
};

export default PaymentButton;
