import React from "react";
import styled from "styled-components";

const PromoBannerStyle = styled.div`
    background-color: ${(props) => props.theme.colors.primary};
    z-index: 3;
    width: 100%;
    position: relative;

    @media (max-width: 768px) {
        border-radius: 50px;
    }
`;
const BannerText = styled.p`
    color: white;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin: 0;

    span {
        margin-right: 20px;
    }

    p {
        @media (max-width: 768px) {
            font-size: 14px;
            font-weight: 200;
            padding: 20px 0px;
        }
    }
`;
const BannerCloseContainer = styled.div`
    position: absolute;
    right: 10px;
    top: 7px;
    height: 15px;
    width: 15px;
    display: grid;
    place-items: center;

    @media (max-width: 768px) {
        top: 32px;
    }
`;
const BannerClose = styled.span`
    height: 15px;
    width: 2px;
    background-color: white;
    transform: rotate(45deg);
    position: absolute;
    &:after {
        display: table;
        content: "";
        height: 15px;
        width: 2px;
        background-color: white;
        transform: rotate(90deg);
        cursor: pointer;
    }
`;

export const PromoRibbon = ({ promoInstruction, isRibbonPromoPresent = false }) => {
    const [isPromoBannerOpen, setIsPromoBannerOpen] = React.useState(true);
    const handleIsPromoBannerVisibility = () => {
        setIsPromoBannerOpen(!isPromoBannerOpen);
    };

    if (!isPromoBannerOpen) return null;

    return (
        <PromoBannerStyle>
            <BannerCloseContainer onClick={handleIsPromoBannerVisibility}>
                <BannerClose />
            </BannerCloseContainer>
            <BannerText>
                <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img
                        src="https://img.icons8.com/emoji/48/000000/-emoji-sparkles.png"
                        style={{ height: "30px", width: "30px" }}
                    />
                    <p>{`  ${promoInstruction}`}</p>
                </span>
            </BannerText>
        </PromoBannerStyle>
    );
};
