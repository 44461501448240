import actionTypes from "./actionTypes";
import { getUrlParams } from "../../utils/utils";

const {
    //------
    SET_CURRENT_MERCHANTS_ID,
    REQUEST_CURRENT_MERCHANTS_ID,

    //------
    CLEAR_CHECKOUT,

    //------
    REQUEST_CURRENT_SHOP,
    SET_CURRENT_SHOP_SUCCESS,
    SET_CURRENT_SHOP_FAILURE,

    //------
    REQUEST_CATEGORIES,
    SET_CATEGORIES_SUCCESS,
    SET_CATEGORIES_FAILURE,

    //------
    SET_FOCUSED_CATEGORY,

    //------
    SET_ORDER_TYPE,

    //------
    HANDLE_ITEM_COUNT_CHANGE,

    //------
    SET_USER_FACEBOOK_ID,
    SET_NAME_OF_GUEST,

    ADD_ITEM_TO_CHECKOUT,
    HANDLE_REMOVE_ITEM_FROM_CART,

    //------
    REQUEST_OPTIONS_DATA_FOR_FOCUSED_CATEGORY,
    SET_OPTIONS_DATA_FOR_FOCUSED_CATEGORY_SUCCESS,
    SET_OPTIONS_DATA_FOR_FOCUSED_CATEGORY_FAILURE,

    RESET_HAS_CART_CHANGED
} = actionTypes;

const initialState = {
    currentlySelectedMerchant: "",
    isCurrentMerchantIdLoading: false,
    hasCurrentMerchantIdLoaded: false,
    visitedStore: [],
    hasCartChanged: null,
    isCurrentMerchantShopLoading: false,
    hasCurrentMerchantShopLoaded: false,
    isCurrentCategoriesLoading: false,
    hasCurrentCategoriesLoaded: false,
    hasCurrentFocusedCategoryBeenSet: false,
    hasAnErrorOccur: false,
    error: "",
    isOptionsDataLoading: false,
    hasOptionsDataLoaded: false,
    guestName: "",
    guestSession: [],
    orderType: ""
};

export default (state = initialState, action) => {
    const { type, error, payload } = action;
    const { merchantId } = getUrlParams();
    let tempObj;
    let newIncheckout;
    let newArray;
    let pushUniqueValue;
    let checkoutItems = [];

    switch (type) {
        //-----------------------------
        //MERCHANT ID
        //-----------------------------
        case REQUEST_CURRENT_MERCHANTS_ID:
            return {
                ...state,
                isCurrentMerchantIdLoading: true,
                hasAnErrorOccur: false,
                error: ""
            };

        case SET_CURRENT_MERCHANTS_ID:
            return {
                ...state,
                currentlySelectedMerchant: payload,
                hasCurrentMerchantIdLoaded: true,
                isCurrentMerchantIdLoading: false,
                hasAnErrorOccur: false,
                error: "",
                [payload]: {
                    ...state[merchantId]
                }
            };

        //-----------------------------
        //MERCHANT SHOP
        //-----------------------------
        case REQUEST_CURRENT_SHOP:
            // take note of visited store so that we dont need to load them again
            newArray = state.visitedStore && Array.from(state.visitedStore);
            pushUniqueValue = (value) => {
                if (newArray.includes(value)) {
                    return newArray;
                }
                return [...newArray, value];
            };
            return {
                ...state,
                isCurrentMerchantShopLoading: true,
                [merchantId]: {
                    ...state[merchantId]
                },
                visitedStore: pushUniqueValue(state.currentlySelectedMerchant),
                hasAnErrorOccur: false,
                error: ""
            };

        case SET_CURRENT_SHOP_SUCCESS:
            return {
                ...state,
                hasCurrentMerchantShopLoaded: true,
                isCurrentMerchantShopLoading: false,
                [state.currentlySelectedMerchant]: {
                    ...state[merchantId],
                    currentShop: payload
                },
                hasAnErrorOccur: false,
                error: ""
            };

        case SET_CURRENT_SHOP_FAILURE:
            return {
                ...state,
                error: payload,
                hasAnErrorOccur: true,
                hasCurrentMerchantShopLoaded: false,
                isCurrentMerchantShopLoading: false,
                [state.currentlySelectedMerchant]: {
                    ...state[merchantId]
                }
            };

        //-----------------------------
        //USER DATA
        //-----------------------------
        case SET_USER_FACEBOOK_ID:
            const facebookId = payload === "null" ? null : payload;
            return {
                ...state,
                userFacebookId: facebookId,
                hasAnErrorOccur: false,
                error: ""
            };

        case SET_NAME_OF_GUEST:
            return {
                ...state,
                guestName: payload,
                orderType: "group",
                hasAnErrorOccur: false,
                error: ""
            };

        //-----------------------------
        //ORDER TYPE
        //-----------------------------
        case SET_ORDER_TYPE:
            return {
                ...state,
                orderType: payload
            };

        //-----------------------------
        //CATEGORIES / ITEMS
        //-----------------------------
        case REQUEST_CATEGORIES:
            return {
                ...state,
                isCurrentCategoriesLoading: true,
                hasAnErrorOccur: false,
                error: "",
                [merchantId]: {
                    ...state[merchantId]
                }
            };

        case SET_CATEGORIES_SUCCESS:
            const newCategories = { ...payload };
            newCategories.menu.map((item) => {
                item.items.map((v) => (v.modifiers = []));
            });
            if (state[merchantId].itemsInCheckout === undefined) {
                checkoutItems = [];
            } else {
                checkoutItems = [...state[merchantId].itemsInCheckout];
            }

            const keyValue =
                state.currentlySelectedMerchant == "" ? getUrlParams().merchantId : state.currentlySelectedMerchant;

            return {
                ...state,
                hasCurrentCategoriesLoaded: true,
                isCurrentCategoriesLoading: false,
                hasAnErrorOccur: false,
                error: "",
                [keyValue]: {
                    ...state[merchantId],
                    categories: newCategories,
                    itemsInCheckout: [...checkoutItems]
                }
            };

        case SET_CATEGORIES_FAILURE:
            return {
                ...state,
                isCurrentCategoriesLoading: false,
                hasCurrentCategoriesLoaded: true,
                hasAnErrorOccur: true,
                [state.currentlySelectedMerchant]: {
                    ...state[merchantId]
                }
            };

        case SET_FOCUSED_CATEGORY:
            return {
                ...state,
                [state.currentlySelectedMerchant]: {
                    ...state[merchantId],
                    focusedCategory: payload.id,
                    focusedCategoryData: payload
                },
                hasCurrentFocusedCategoryBeenSet: true,
                hasAnErrorOccur: false,
                error: ""
            };

        case REQUEST_OPTIONS_DATA_FOR_FOCUSED_CATEGORY:
            return {
                ...state,
                [state.currentlySelectedMerchant]: {
                    ...state[merchantId]
                },
                isOptionsDataLoading: true,
                hasOptionsDataLoaded: false,
                hasAnErrorOccur: false,
                error: ""
            };

        case SET_OPTIONS_DATA_FOR_FOCUSED_CATEGORY_SUCCESS:
            const { newModifiersArray, itemIds, focusedCategory } = payload;
            tempObj = JSON.parse(JSON.stringify(state[state.currentlySelectedMerchant]));
            tempObj.categories.menu.map((category) => {
                if (category.id === focusedCategory) {
                    category.items.map((item) =>
                        newModifiersArray.map((newModifier) => {
                            if (newModifier.id === item.id) {
                                item.newModifiers = newModifier.data;
                            }
                        })
                    );
                    return tempObj.categories.menu;
                }
                return tempObj.categories.menu;
            });

            return {
                ...state,
                [state.currentlySelectedMerchant]: {
                    ...state[merchantId],
                    categories: tempObj.categories
                },
                isOptionsDataLoading: false,
                hasOptionsDataLoaded: true,
                hasAnErrorOccur: false,
                error: ""
            };

        case SET_OPTIONS_DATA_FOR_FOCUSED_CATEGORY_FAILURE:
            return {
                ...state,
                [state.currentlySelectedMerchant]: {
                    ...state[merchantId]
                },
                isOptionsDataLoading: false,
                hasOptionsDataLoaded: false,
                error: payload
            };

        //-----------------------------
        //CHECKOUT/CART
        //-----------------------------
        case CLEAR_CHECKOUT:
            return {
                ...state,
                [merchantId]: {
                    ...state[merchantId],
                    itemsInCheckout: []
                }
            };

        case ADD_ITEM_TO_CHECKOUT:
            if (payload.price) {
                payload.basePrice = payload.itemPrice;
                payload.itemPrice = payload.price;
                delete payload.price;
            }
            payload.name = payload.name.split(" - ")[0];

            if (payload.name === "Gift Card") {
                return {
                    ...state,
                    [merchantId]: {
                        ...state[merchantId],
                        itemsInCheckout: [...state[merchantId].itemsInCheckout]
                    },
                    hasAnErrorOccur: false,
                    error: "",
                    hasCartChanged: Math.random()
                };
            }
            return {
                ...state,
                [merchantId]: {
                    ...state[merchantId],
                    itemsInCheckout: [...state[merchantId].itemsInCheckout, payload]
                },
                hasAnErrorOccur: false,
                error: "",
                hasCartChanged: Math.random()
            };

        case HANDLE_ITEM_COUNT_CHANGE:
            tempObj = JSON.parse(JSON.stringify(state[state.currentlySelectedMerchant]));
            if (tempObj.itemsInCheckout) {
                tempObj.itemsInCheckout.map((data, index) => {
                    if (data.id === payload.id) {
                        newIncheckout = [...state[state.currentlySelectedMerchant].itemsInCheckout];
                        newIncheckout[index] = payload;
                        return newIncheckout;
                    }
                    return newIncheckout;
                });
            }
            return {
                ...state,
                hasAnErrorOccur: false,
                error: "",
                hasCartChanged: Math.random(),
                [state.currentlySelectedMerchant]: {
                    ...state[merchantId],
                    itemsInCheckout: newIncheckout
                }
            };

        case HANDLE_REMOVE_ITEM_FROM_CART:
            tempObj = JSON.parse(JSON.stringify(state[state.currentlySelectedMerchant]));
            // tempObj.itemsInCheckout.splice(action.payload, 1);
            let newCheckoutItems = tempObj.itemsInCheckout.filter((item, index) => index !== action.payload);
            return {
                ...state,
                hasAnErrorOccur: false,
                error: "",
                //only fetch if this changes
                hasCartChanged: Math.random(),
                [state.currentlySelectedMerchant]: {
                    ...state[merchantId],
                    itemsInCheckout: [...newCheckoutItems]
                }
            };

        case RESET_HAS_CART_CHANGED:
            return {
                ...state,
                //only fetch if this changes
                hasCartChanged: null
            };

        default:
            return { ...state };
    }
};
