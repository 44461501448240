import React from "react";
import PropTypes from "prop-types";
import { StyledButton } from "./styles";
import checkoutImage from "../../assets/shopping-cart.svg";

// pass a function that defaults to null if no function is paseed
const Button = ({
    text,
    handleOnPress,
    hasDoubleText,
    firstText,
    secondText,
    disabled = "true",
    isCheckoutButton,
    isWideButton,
    isBigButton,
    id
}) => (
    <StyledButton
        disabled={disabled}
        onClick={disabled ? null : handleOnPress}
        isWideButton={isWideButton}
        isBigButton={isBigButton}
        id={id}>
        {
            // cheks if a button has a isde by side text
            hasDoubleText ? (
                <div className="buttonTextContainer">
                    <p className="buttonText">{firstText}</p>
                    <p className="buttonText">{secondText}</p>
                </div>
            ) : isCheckoutButton ? (
                <div className="buttonTextContainer">
                    <img src={checkoutImage} alt="Checkout" className="buttonImage" />

                    <div className="buttonText">{firstText}</div>
                    <div className="buttonText">{secondText}</div>
                </div>
            ) : (
                <div className="buttonText">{text}</div>
            )
        }
    </StyledButton>
    // </View>
);

Button.propTypes = {
    disabled: PropTypes.bool,
    firstText: PropTypes.string,
    handleOnPress: PropTypes.func,
    hasDoubleText: PropTypes.bool,
    isCheckoutButton: PropTypes.bool,
    isWideButton: PropTypes.bool,
    secondText: PropTypes.string,
    text: PropTypes.string
};

Button.defaultProps = {
    disabled: null,
    firstText: null,
    handleOnPress: null,
    hasDoubleText: false,
    isCheckoutButton: false,
    isWideButton: false,
    secondText: null,
    text: null
};
export default Button;
