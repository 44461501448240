import React from 'react'
import PropTypes from 'prop-types'
import { styles } from './styles'
import PromosData from './PromosData'
import MerchantsData from './MerchantsData'
import uuid from 'uuid'

import {
    FlatList,
    Text,
    View,
    ActivityIndicator
} from 'react-native'

const {
    promoContainer,
    promoTitle,
    deliveryContainer,
    deliveryTitle,
    loadingStyle,
    listContainer
} = styles

const ShopsItem = ({
    searchData,
    searchValue,
    PROMOITEMS,
    keyExtractor,
    promoKeyExtractor,
    merchants
}) => {

    //render the merchants data
    const renderMerchantsData = ({ item }) => {
        return <MerchantsData item={item} searchData={searchData} />
    }


    if (searchData.length === 0 && searchValue.length === 0) {
        return <>
            <View>
                {/* promo */}
                <View style={promoContainer}>
                    <Text style={promoTitle}>Promo</Text>
                    <FlatList
                        data={PROMOITEMS}
                        horizontal
                        contentContainerStyle={listContainer}
                        showsHorizontalScrollIndicator={false}
                        removeClippedSubviews={false}
                        keyExtractor={promoKeyExtractor}
                        renderItem={PromosData}
                    />

                </View>
                {/* free delivery */}
                {
                    merchants.length > 0 ?
                        <View style={deliveryContainer}>
                            <Text style={deliveryTitle}>Free Delivery</Text>
                            <FlatList
                                data={merchants}
                                horizontal
                                contentContainerStyle={listContainer}
                                showsHorizontalScrollIndicator={false}
                                removeClippedSubviews={false}
                                keyExtractor={keyExtractor}
                                renderItem={renderMerchantsData}
                            />
                        </View> :
                        <View style={loadingStyle}>
                            <ActivityIndicator color="rgb(105, 225, 198)" size="small" />
                        </View>
                }
            </View>
        </>

    } else if (searchData.length === 0 && searchValue.length > 0) {
        return <View style={{
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Text>No store found</Text>
        </View>
    } else {
        return <View style={deliveryContainer}>
            <Text style={deliveryTitle}>Search result...</Text>
            <FlatList
                data={searchData}
                vertical
                numColumns={2}
                contentContainerStyle={listContainer}
                showsHorizontalScrollIndicator={false}
                removeClippedSubviews={false}
                keyExtractor={keyExtractor}
                key={uuid()}
                renderItem={renderMerchantsData}
            />
        </View>
    }
}

ShopsItem.propTypes = {

}

export default ShopsItem
