import React from "react";
import styled from "styled-components";

const StyledImage = styled.img`
    display: block;
    width: 100%;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    border-radius: ${({ storeTemplate }) => (storeTemplate === 4 ? "50%" : "10px")};

    @media screen and (min-width: 1024px) {
        height: ${({ storeTemplate }) =>
            storeTemplate === 2 ? "120px" : storeTemplate === 3 ? "140px" : storeTemplate === 4 ? "120px" : "100px"};
        width: ${({ storeTemplate }) => (storeTemplate === 2 ? "150px" : storeTemplate === 4 ? "120px" : "100px")};
    }
`;

export default class LazyLoadImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            src: this.placeholderSrc(props.img)
        };
        this.loadImage = this.loadImage.bind(this);
    }

    loadImage(e) {
        this.setState({
            src: this.props.img.url,
            loaded: true
        });
    }

    placeholderSrc(img) {
        const { width, height } = img;
        return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;
    }

    render() {
        const { url, alt } = this.props.img;
        const { src } = this.state;
        return (
            <React.Fragment>
                <StyledImage
                    src={src}
                    data-src={url}
                    alt={alt}
                    onLoad={(e) => this.loadImage(e)}
                    storeTemplate={this.props.storeTemplate}
                />
            </React.Fragment>
        );
    }
}
