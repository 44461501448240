/* eslint-disable camelcase */
import PropTypes from "prop-types";
import React from "react";

import { RadioButtonStyle, StyledRadioButtonContainer } from "./styles";
import { USD } from "../../utils/utils";

const RadioButton = ({ data, optionsValue, handleOptionsSelected }) =>
    data.map((item) => {
        const { id, name, price } = item;
        return (
            <StyledRadioButtonContainer key={id}>
                <div className="radioItems" onClick={() => handleOptionsSelected(id, item)}>
                    {/* when value is equal to key */}
                    <div className="circle">
                        {optionsValue && optionsValue.id === id && <div className="checked-circle" />}
                    </div>

                    <div className="radioLabel">
                        <p className="radioText">{name}</p>
                    </div>
                </div>
                {price && (
                    <p className="currency">
                        {USD}
                        {Number.parseFloat(price).toFixed(2)}
                    </p>
                )}
            </StyledRadioButtonContainer>
        );
    });

const handleDisplayPrice = (price) => {
    if (price && price > 0) {
        return `${USD}${Number.parseFloat(price).toFixed(2)}`;
    }
    if (price && price < 0) {
        const newPrice = price.toString().substr(1);
        return `-${USD}${Number.parseFloat(newPrice).toFixed(2)}`;
    }
    return `${USD}${Number.parseFloat(0).toFixed(2)}`;
};
// this button is a checkbox for the modifier
export const ModifierRadioButton = ({ handleOptionsSelected, modifier_name, name, optionsRadioValue, price }) => (
    <StyledRadioButtonContainer>
        <div className="radioItems" onClick={handleOptionsSelected}>
            {/* check the radio button if the name is same as in the modifier state */}
            <div className="circle">
                {optionsRadioValue.map(
                    (item) =>
                        item.name === name &&
                        item.category === modifier_name && <div className="checked-circle" key={item.id}></div>
                )}
            </div>

            <div className="radioLabel">
                <p className="radioText">{name}</p>
            </div>
        </div>
        <p className="currency">{handleDisplayPrice(price)}</p>
    </StyledRadioButtonContainer>
);
export default RadioButton;

ModifierRadioButton.propTypes = {
    handleOptionsSelected: PropTypes.func,
    modifier_name: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    optionsRadioValue: PropTypes.arrayOf(
        PropTypes.shape({
            "Sweetness Level(min 25%)": PropTypes.object,
            "Toppings( 1 free topping per drink)": PropTypes.object
        })
    ),
    price: PropTypes.number.isRequired
};

ModifierRadioButton.defaultProps = {
    handleOptionsSelected: null,
    optionsRadioValue: []
};
