import { takeEvery, call, put } from "redux-saga/effects";
import actionTypes from "./actionTypes";
import { setMerchantsDataSuccess, setMerchantsDataFailure } from "./actions.js";
import { baseUrl } from "../../utils/utils";

const { REQUEST_LOAD_MERCHANTS_DATA } = actionTypes;

function* handleSetMerchantsData() {
    const response = yield call(fetch, `${baseUrl}/merchants/all`);
    if (response) {
        const responseData = yield response.json();
        yield put(setMerchantsDataSuccess(responseData));
    } else {
        yield put(setMerchantsDataFailure(response.statusText));
    }
}

export default function* watchDiscoverySagas() {
    yield takeEvery(REQUEST_LOAD_MERCHANTS_DATA, handleSetMerchantsData);
}
