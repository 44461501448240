const actionTypes = {
    POST_USER_PROFILE_SUCCESS: 'POST_USER_PROFILE_SUCCESS',
    POST_USER_PROFILE_FAILURE: 'POST_USER_PROFILE_FAILURE',
    POST_USER_PROFILE: 'POST_USER_PROFILE',
    REQUEST_LOAD_USER_PROFILE: 'REQUEST_LOAD_USER_PROFILE',
    SET_USER_PROFILE_SUCCESS: 'SET_USER_PROFILE_SUCCESS',
    SET_USER_PROFILE_FAILURE: 'SET_USER_PROFILE_FAILURE',
    REQUEST_WHITELIST_DOMAINS: 'REQUEST_WHITELIST_DOMAINS',
    SET_WHITELIST_DOMAINS_SUCCESS: 'SET_WHITELIST_DOMAINS_SUCCESS',
    SET_WHITELIST_DOMAINS_FAILURE: 'SET_WHITELIST_DOMAINS_FAILURE',
    REQUEST_LOAD_GUEST_ORDER_SESSION: 'REQUEST_LOAD_GUEST_ORDER_SESSION',
    SET_GUEST_ORDER_SESSION_DATA_SUCCESS: 'SET_GUEST_ORDER_SESSION_DATA_SUCCESS',
    SET_GUEST_ORDER_SESSION_DATA_FAILURE: 'SET_GUEST_ORDER_SESSION_DATA_FAILURE',
    SET_ADD_ITEM_TO_GUEST_ORDER: 'SET_ADD_ITEM_TO_GUEST_ORDER',
};
export default actionTypes;
