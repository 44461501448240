export const STRINGS = {
    ADD: "+",
    ADD_DELIVERY_INFORMATION: " ➕Add Delivery Address ",
    COLON: ":",
    DASH: "-",
    DELIVERY_INFORMATION: "Delivery Information",
    EDIT_DELIVERY_INFORMATION: " Edit Address ",
    EMPTY_STATE_TEXT: " There is currently no item in your cart",
    STORE_LOCATION: "Store Location",
    YOUR_CART: "YOUR CART",
    NAME: "cart",
    SUBTRACT: "subtract",
    MAKE_AN_ORDER: "Proceed to Checkout",
    ADD_TO_GROUP_CHECKOUT: "Add to group checkout",
    EDIT_DELIVERY_ADDRESS: "Edit Delivery Address",

    BACK_TO_DASHBOARD: "back to dashboard"
};

export const FORMAT_ADDRESS = (
    streetAddressLine1,
    streetAddressLine2 = "",
    addressCity,
    addressState,
    postalCode,
    telephoneNumber
) => `${streetAddressLine1}, ${streetAddressLine2}
${addressCity}, ${addressState}, ${postalCode},
${telephoneNumber}`;

export const DELIVERY_CHOICE = [
    {
        id: 1,
        name: "Delivery"
    },
    {
        id: 2,
        name: "Pickup"
    }
];
