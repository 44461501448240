import React, { useEffect, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import WebPromoRecommendationItemWrapper from "./webPromoRecommendationItem/WebPromoRecommendationItem";
import { StyledOuterContainer } from "./WebPromoRecommendation.style";
import { gsap } from "gsap";
import { useSelector } from "react-redux";
import JoyupRobo from "#/assets/joyup.jpg";
import axios from "axios";
import { getUrlParams } from "#/utils/utils";

const WebPromoRecommendation = ({ show, onHide, handleModalOpenOrAddItem }) => {
    const [recommendationItems, setRecommendationItems] = useState([]);

    let imgRef = useRef();
    let messageRef = useRef();
    let listRef = useRef();

    const state = useSelector((state) => state.dashboard);

    let currentSelectedMerchant = state["currentlySelectedMerchant"];
    let currentMerchantData = state[currentSelectedMerchant];

    const logo = currentMerchantData?.categories?.logo;

   // useEffect(async () => {
     //   const { merchantId, locationId } = getUrlParams();
       // const categoryId = currentMerchantData?.categories?.menu?.filter((el) =>
     //       el.name.toLowerCase().includes("salad")
       // )?.[0]._id;

       // const response = await axios.get(
       //     `https://waitstaff.joyup.me/menu/get_suggested_items/${merchantId}/${locationId}/${categoryId}`
       // );

       // if (response.data && response.data.suggestedItems.length > 0) {
    //        setRecommendationItems(response.data.suggestedItems);
       // }
   // }, []);

    useEffect(() => {
        gsap.timeline()
            .to(imgRef.current, {
                duration: 0.5,
                y: 0,
                stagger: 2,
                autoAlpha: 1
            })
            .to(messageRef.current, {
                delay: 0.5,
                duration: 0.5,
                x: 0,
                stagger: 2,
                autoAlpha: 1
            })
            .to(listRef.current, {
                delay: 0.8,
                duration: 0.5,
                y: 0,
                stagger: 2,
                autoAlpha: 1
            });
    }, [show]);

    if (!show) return "";

    return (
        <StyledOuterContainer>
            <section className="suggestions">
                <div className="suggestions-hide" onClick={() => onHide(false)}>
                    <div className="suggestions-hide-out">
                        <div className="suggestions-hide-in" />
                    </div>
                </div>
                <div className="suggestions-container">
                    <div className="suggestions-message">
                        <div ref={imgRef} className="suggestions-message-icon">
                            <img src={logo || JoyupRobo} alt="joyup robo head" />
                        </div>
                        <div ref={messageRef} className="suggestions-message-content">
                            <p>
                                HI, Our Chef thinks you may like items based on <br /> your current selection
                            </p>
                        </div>
                    </div>
                    <div className="suggestions-items-container" ref={listRef}>
                        <ScrollContainer
                            vertical={false}
                            horizontal={true}
                            stopPropagation={true}
                            className="suggestions-items">
                            {recommendationItems.length === 0
                                ? "Loading"
                                : recommendationItems.slice(0, 3).map((el, key) => {
                                      return (
                                          <WebPromoRecommendationItemWrapper
                                              key={key}
                                              item={el}
                                              handleModalOpenOrAddItem={handleModalOpenOrAddItem}
                                              onHide={onHide}
                                          />
                                      );
                                  })}
                        </ScrollContainer>
                    </div>
                </div>
            </section>
        </StyledOuterContainer>
    );
};

export default React.memo(WebPromoRecommendation);
