import styled from "styled-components";

export const StyledModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 48px;
    z-index: 29;

    @media (max-width: 600px) {
        padding: 10px;
    }
`;

export const StyledModalOverlay = styled.div`
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3;

    /* transform:${(props) => (props.isOrderTypeModalOpen === true ? "scale(1)" : "scale(0)")}; */
    visibility: ${(props) => (props.isOpen === true ? "visible" : "hidden")};
    /* height: ${(props) => (props.isOrderTypeModalOpen === true ? "100%" : "0px")}; */
    transition: opacity 0.001s ease-in, transform 0.001s ease-in 0.1s, visibility 0.2s ease-in 0.2s;
    /* opacity:${(props) => (props.isOrderTypeModalOpen === true ? "0" : "1")}; */

    @media only screen and (max-height: 500px), only screen and (max-width: 768px) {
        padding: 20px;
    }
`;
export const StyledModalContent = styled.div`
    z-index: 4;
    max-height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
    position: relative;
    width: 70%;
    padding-bottom: 30px;
    top: ${(props) => (props.isOpen === true ? "0" : "3000px")};
    visibility: ${(props) => (props.isOpen === true ? "visible" : "hidden")};
    transition: ${(props) =>
        props.isOpen === true
            ? "top 568.546ms ease-in-out 10.7681ms, visibility 0s ease-in"
            : "top 568.546ms ease-in-out 10.7681ms, visibility 1s ease-in"};

    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const StyledModalContentHeader = styled.div`
    padding: 20px;
    border-bottom: 1px solid rgb(229, 231, 235);
    position: relative;
    text-align: center;

    h2 {
        font-size: 18px;
        font-weight: 500;

        @media (max-width: 600px) {
            font-size: 16px;
        }
    }

    h4 {
        font-size: 14px;
        font-weight: 500;
        color: rgb(107, 114, 128);

        @media (max-width: 600px) {
            font-size: 12px;
        }
    }

    .cancel-icon {
        position: absolute;
        top: 25px;
        right: 20px;
        width: 15px;
        height: 15px;
        cursor: pointer;

        span {
            width: 2px;
            height: 15px;
            background-color: ${(props) => props.theme.colors.primary};
            position: absolute;
            display: inline-block;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);

            &:nth-child(2) {
                transform: translateX(-50%) rotate(-45deg);
            }
        }
    }
`;

export const StyledModalContentMiddle = styled.div`
    max-height: calc(100vh - 200px);
    overflow: auto;
    padding: 10px;
    margin: 0px 25px;
    margin-bottom: 10px;
    scroll-behavior: smooth;

    @media (max-width: 600px) {
        margin: 0px 6px 10px;
    }

    @media only screen and (max-height: 500px), only screen and (max-width: 768px) {
        max-height: none;
    }

    &::-webkit-scrollbar {
        width: 0px;
        height: 4px;
    }

    &::-webkit-scrollbar-track {
        background: #e2e6e1;
        margin-left: 20px;
        margin-right: 20px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.primary};
    }

    .slider-controls {
        position: absolute;
        background-color: ${(props) => props.theme.colors.primary};
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.22);
        z-index: 20;

        &.backward {
            top: 50%;
            left: 2px;
            transform: translateY(-100%);
        }
        &.forward {
            top: 50%;
            right: 2px;
            transform: translateY(-100%);
        }
        svg {
            transform: scale(0.7);
        }
    }

    .meal-cards-wrapper {
        display: flex;
        flex-wrap: nowrap;
        // height: 145px;

        @media (max-width: 600px) {
            height: auto;
        }
    }
`;

export const StyledModalContentBottom = styled.button`
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    border-radius: 30px;
    padding: 0px 25px;
    background-color: ${(props) => (props.disabled ? "#e0e0e0" : props.theme.colors.primary)};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    pointer-events: ${(props) => (props.disabled ? "none" : "visible")};
    box-shadow: rgba(12, 10, 10, 0.25) 0px 2px 2.84px;
    height: 55px;
    border: none;
    text-align: center;
    color: #fff;
    text-transform: capitalize;
    font-size: 17px;
    font-family: "rubik";
    pointer-events: ${(props) => (props.scheduledOrderTime === null ? "none" : "visible")};
    flex-shrink: 0;
    margin-bottom: 20px;

    @media (max-width: 600px) {
        height: 45px;
    }

    :hover {
        opacity: ${(props) => (props.disabled ? "1" : "0.6")};
    }
`;
