import styled from "styled-components";

export const StyledOuterContainer = styled.div`
    .suggestions {
        backdrop-filter: blur(4px);
        width: 100%;
        min-height: 100vh;
        background-color: rgb(171, 160, 160, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 30;
    }

    .suggestions-hide {
        position: absolute;
        right: 20px;
        width: 40px;
        height: 40px;
        top: 20px;
        z-index: 100;
    }

    .suggestions-hide:hover {
        cursor: pointer;
    }

    .suggestions-hide-out {
        height: 40px;
        width: 2px;
        background-color: #ccc;
        transform: rotate(45deg);
        z-index: 1;
        margin-left: 20px;
    }

    .suggestions-hide-in {
        height: 40px;
        width: 2px;
        background-color: #ccc;
        transform: rotate(90deg);
        z-index: 2;
    }

    .suggestions-container {
        width: 1200px;
        padding: 70px 0;
        position: relative;
    }

    .suggestions-message {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
    }

    .suggestions-message-content {
        padding: 15px 20px;
        background-color: #1868fb;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        border-top-right-radius: 25px;
        color: #fff;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        opacity: 0;
        transform: translateX(200px);
    }

    .suggestions-message-icon {
        opacity: 0;
        transform: translateY(200px);
    }

    .suggestions-message-icon img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        margin-right: 15px;
    }

    .suggestions-items-container {
        opacity: 0;
        transform: translateY(200px);
    }

    .suggestions-items {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        padding: 10px;
    }

    .loading {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    @media (max-width: 1200px) {
        .suggestions-container {
            width: 850px;
        }
    }
    @media (max-width: 800px) {
        .suggestions-container {
            width: 650px;
        }
    }
    @media (max-width: 650px) {
        .suggestions-container {
            width: 400px;
        }

        .suggestion-item-container {
            min-width: 300px;
            max-width: 300px;
        }
    }
    @media (max-width: 400px) {
        .suggestions-container {
            width: 350px;
        }
    }
    @media (max-width: 350px) {
        .suggestions-container {
            width: 250px;
        }

        .suggestion-item-container {
            min-width: 210px;
            max-width: 210px;
        }
    }
`;
