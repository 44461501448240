import { combineReducers } from "redux";
import * as discovery from "../../pages/discovery";
import * as dashboard from "../../pages/orderPage";
import * as cart from "../../pages/cart";

const rootReducer = combineReducers({
    discovery: discovery.reducers,
    dashboard: dashboard.reducers,
    cart: cart.reducers
});

export default rootReducer;
