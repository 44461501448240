/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { View, Text, FlatList } from "react-native";
import { styles } from "./styles";
import { STRINGS } from "./constant";
import { timeConvert } from "../../utils/utils";

const { DASH } = STRINGS;
const { dateItemContainer, dateTime, dateDay, dateListContainer } = styles;

export const HoursOfOperationComponent = ({ categories }) => {
    if (categories && categories.hours_of_operation) {
        return (
            <View>
                <FlatList
                    data={Object.entries(categories.hours_of_operation).reverse()}
                    horizontal
                    removeClippedSubviews={false}
                    keyExtractor={(item) => item[0]}
                    contentContainerStyle={dateListContainer}
                    showsVerticalScrollIndicator={false}
                    renderItem={({ item }) => {
                        if (item[1].breakStart || item[1].breakEnd) {
                            return (
                                <View style={dateItemContainer}>
                                    <Text style={dateDay}>{item[0]}</Text>
                                    <Text style={dateTime}>
                                        {timeConvert(item[1].start)}
                                        {DASH}
                                        {timeConvert(item[1].breakStart)}
                                    </Text>
                                    <Text style={dateTime}>
                                        {timeConvert(item[1].breakEnd)}
                                        {DASH}
                                        {timeConvert(item[1].end)}
                                    </Text>
                                </View>
                            );
                        } else {
                            return (
                                <View style={dateItemContainer}>
                                    <Text style={dateDay}>{item[0]}</Text>
                                    <Text style={dateTime}>
                                        {timeConvert(item[1].start)}
                                        {DASH}
                                        {timeConvert(item[1].end)}
                                    </Text>
                                </View>
                            );
                        }
                    }}
                />
            </View>
        );
    } else {
        return null;
    }
};

HoursOfOperationComponent.propTypes = {
    categories: PropTypes.shape({
        hours_of_operation: PropTypes.object.isRequired
    }).isRequired
};
