import React from 'react';
import PropTypes from 'prop-types';
import {
    View
} from 'react-native';
import { styles, StyledContainerHeroImage } from './styles';
import { ProgressiveImage } from '../progressiveImage';

const {
    containerHeroImage,
    heroImage,
} = styles;

export const PageHeroImageComponent = ({ categories, handleImageLoad }) => (
    JSON.stringify(categories) !== '{}' ? (
        <StyledContainerHeroImage>
            <ProgressiveImage
                thumbnailSource={{
                    uri: categories && categories.image_url,
                }}
                source={{
                    uri: categories && categories.image_url,
                }}
                style={heroImage}
                resizeMode="cover"
                handleImageLoad={handleImageLoad}
                loading="eager"
            />
        </StyledContainerHeroImage>
    ) : null);

PageHeroImageComponent.propTypes = {
    categories: PropTypes.shape({
        image_url: PropTypes.string.isRequired,
    }).isRequired,
    handleImageLoad: PropTypes.func.isRequired,
};
