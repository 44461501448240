import React from "react";
import { ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";
import { getUrlParams, getLocalStorage } from "./utils";

export const pages = {
    "5e558cac894d114471770902": "#6b0297",
    "5e8bdeb593157366c2ee3a91": "#ce2f2f",
    "5ec43deb44a2794e36940d24": "#B2D25E",
    "5ed17f0244a2794e36940fb1": "#EF5A28",
    "5ed6c6a144a2794e36941116": "#f1c587",
    "5ed830de44a2794e3694111d": "#A8272D",
    "5ede629544a2794e369411d6": "#fdc22d",
    "5ef52e857bb3265a0d964a09": "#A8272D",
    "5f010888df0b114b4f98e790": "#B42472",
    "5f010924df0b114b4f98e7cb": "#0834c9",
    "5f0794ddcc511278f6780a89": "#593e00",
    "5f08bba5cc511278f6780e15": "#bf4294",
    "5f08e8f3cc511278f6780e33": "#bf4294",
    "5f08ebfccc511278f6780e38": "#bf4294",
    "5a7371c9a67ad0001a1023f8": "#EC7372",
    "5ef3e79f7bb3265a0d964a06": "#F7911B",
    "5e94c4288747b92052220f52": "#ED6029"
};

const Theme = ({ children }) => {
    const lsParams = getLocalStorage("joyup-store-params");

    const merchantID = new URLSearchParams(window.location.search).get("merchant_id") || lsParams?.merchantId;
    const merchantColor = pages[merchantID];

    const [theme, setTheme] = React.useState({
        colors: {
            primary: merchantColor || "#69E1C6"
        }
    });
    const dashboard = useSelector((state) => state.dashboard);
    React.useEffect(() => {
        const handleGetMerchantTheme = () => {
            const themeColorFromServer = dashboard[merchantID]?.categories?.template_brand_color;
            themeColorFromServer &&
                setTheme({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: themeColorFromServer
                    }
                });
        };
        handleGetMerchantTheme();
    }, [dashboard]);
    // console.log({ dashboard });
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;

// export default GlobalStyles;
