/* eslint-disable camelcase */
import React from "react";
import { View, Text, Image, CheckBox, ActivityIndicator } from "react-native";
import { STRINGS } from "../constant";
import { styles } from "./styles";

import { getUrlParams, renderLoading } from "../../../utils/utils";

const { OPTIONS, REQUIRED, OPTIONAL, USD } = STRINGS;

const {
    checkboxRow,
    checkboxRowPad,
    checkbox,
    checkboxlabel,
    currency,
    loadingStyle,
    optionsContainer,
    optionsHeaderText,
    optionsText,
    optionsTextRequired
} = styles;

export const RenderRevelMenu = ({ props }) => {
    const {
        loading,
        modifiedModifiersData,
        ModifierRadioButton,
        optionsRadioValue,
        optionsValue,
        handleModifierRadioSelected,
        handleCheckBoxToggle,
        checkboxDisabled,
        storeVendor
    } = props;
    return modifiedModifiersData
        .filter((item) => item.active)
        .map((modifier) => {
            const { active, id, modifiers, name, forced, minimum_amount, maximum_amount } = modifier;
            return (
                <View key={name} style={optionsContainer}>
                    <View style={optionsHeaderText}>
                        <Text style={optionsText}>{name}</Text>
                        <Text style={optionsTextRequired}>
                            {minimum_amount > 0 || modifier.admin_mod_key === "SIZE" ? REQUIRED : OPTIONAL}
                        </Text>
                    </View>

                    {modifiers.map((item) => {
                        const {
                            sort,
                            sku,
                            cost,
                            name,
                            barcode,
                            active,
                            price,
                            selected,
                            is_quick,
                            id,
                            isChecked,
                            disabled
                        } = item;
                        return modifier.admin_mod_key === "SIZE" || (minimum_amount === 1 && maximum_amount === 1) ? (
                            <ModifierRadioButton
                                name={name}
                                price={price}
                                id={id}
                                key={id}
                                item={item}
                                optionsRadioValue={optionsRadioValue}
                                modifier_name="test"
                                modifier_name={modifier.name}
                                handleOptionsSelected={
                                    optionsValue !== ""
                                        ? (e) => handleModifierRadioSelected(id, item, modifier.name, e)
                                        : null
                                }
                            />
                        ) : (
                            <View style={checkboxRow} key={id}>
                                <View style={checkboxRowPad}>
                                    <CheckBox
                                        value={isChecked}
                                        onValueChange={() => handleCheckBoxToggle(id, isChecked, modifier)}
                                        style={checkbox}
                                        disabled={disabled}
                                    />

                                    <Text
                                        style={
                                            disabled ? [checkboxlabel, { color: "rgb(204, 214, 221)" }] : checkboxlabel
                                        }
                                        onPress={disabled ? null : () => handleCheckBoxToggle(id, isChecked, modifier)}
                                        disabled={disabled}>
                                        {name}
                                    </Text>
                                </View>
                                <Text
                                    // style={currency}
                                    style={disabled ? [currency, { color: "rgb(204, 214, 221)" }] : currency}>
                                    {USD}

                                    {Number.parseFloat(price).toFixed(2) || Number.parseFloat(0).toFixed(2)}
                                </Text>
                            </View>
                        );
                    })}
                </View>
            );
        });
};
