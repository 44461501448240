import styled from "styled-components";

import { ReactComponent as ClockIcon } from "../../../../../../assets/clock.svg";

export const StyledOpeningHoursDropdown = styled.div`
    display: inline-block;
    position: relative;
    ${({ styles }) => styles};

    .title {
        text-align: center;
        display: flex;
        align-items: center;
    }

    ul {
        list-style: none;
        box-shadow: 0 2.5px 5px rgba(0, 0, 0, 0.22);
        padding: 10px;
        position: absolute;
        width: 250px;
        z-index: 4;
        background-color: #fff;
        border-radius: 7px;
        left: -45%;
        top: 38px;
        visibility: hidden;
        opacity: 0;
        transition: opacity 300ms ease-in-out;

        .day-time {
            display: flex;
            justify-content: space-between;
            padding: 5px 5px;

            &__day {
                width: 90px;
                text-transform: capitalize;
                font-weight: 600;
                text-align: left;
            }

            h5 {
                font-weight: 400;
                white-space: nowrap;
            }
        }
    }

    &:hover {
        ul {
            opacity: 1;
            visibility: visible;
        }
    }
`;

export const StyledClockIcon = styled(ClockIcon)`
    width: 25px;
    margin-left: 12px;

    circle,
    polyline,
    line {
        stroke: ${(props) => props.theme.colors.primary};
        stroke-width: 1px;
    }
`;
