import React from "react";
import axios from "axios";

import useAddToCart from "../../../../../../components/hooks/useAddToCart";

import { getUrlParams, convertWordToCapitalize } from "../../../../../../utils/utils";

import turkey from "../../../../../../assets/turkey-susage.jpeg";

import { Box } from "./Item.styles";

const Item = ({
    allData,
    handleModalOpenOrAddItem,
    bg_color,
    //---
    //The props below are for adding the item to the cart
    //---
    storeVendor,
    optionsValue,
    merchantID,
    isStoreOpen,
    scheduledOrderTime,
    setOptionsValue,
    addItemToCheckout,
    handleItemCountChange,
    handleCloseModal,
    handlePsId,
    activeCategoryPromo
}) => {
    //--------------------------------------------------------------------------
    //Helpers/Variables
    //--------------------------------------------------------------------------
    const { name, price, image, description, promo_text: promoText } = allData;

    //--------------------------------------------------------------------------
    //Helpers hooks
    //--------------------------------------------------------------------------
    const {
        scrollViewRef,
        handleOptionsSelected,
        modifiedModifiersData,
        loading,
        optionsRadioValue,
        handleModifierRadioSelected,
        handleCheckBoxToggle,
        checkboxDisabled,
        specialInstructionModal,
        handleSpecialInstructionModal,
        specialInstructions,
        handleSpecialInstructions,
        handleChangeCountValue,
        countValue,
        handleChanged,
        handleAddItem,
        buttonDisabled,
        handleDisplayStoreClosedToast,
        handleCheckMinimumOrderItem,
        currentMerchantsShop
    } = useAddToCart(
        true,
        allData,
        storeVendor,
        optionsValue,
        merchantID,
        isStoreOpen,
        scheduledOrderTime,
        setOptionsValue,
        addItemToCheckout,
        handleItemCountChange,
        handleCloseModal,
        activeCategoryPromo
    );

    //--------------------------------------------------------------------------
    //Functions
    //--------------------------------------------------------------------------
    //Fire when user tries to add item
    const onAddItem = async (e) => {
        const { psId, merchantId, locationId, pageId } = getUrlParams();

        const url = process.env.NODE_ENV === "development" ? "http://localhost:3300" : "https://waitstaff.joyup.me";

        const time = new Date(
            new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
        ).toLocaleTimeString("en-US");
        const date = new Date(
            new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
        ).toLocaleDateString("en-US");

        axios({
            method: "POST",
            url: `${url}/menu/post_upsell_to_slack`,
            data: {
                text: `${name.toUpperCase()} melbas upsell Item has been added to cart
                    TIME: ${time},
                    MERCHANTID: ${merchantId},
                    LOCATIONID: ${locationId},
                    USERID: ${handlePsId()},
                    PAGEID: ${pageId},
                    DATE:${date}`
            }
        })
            .then(({ data }) => {
                console.log(">>>Successful Slack POST log", data);
            })
            .catch(({ response }) => {
                console.log(">>ERROR SLACK POST ", response);
            });
        handleAddItem(e, true);
    };

    return (
        <Box className="box" bg_color={bg_color} onClick={onAddItem}>
            {/* -------------------------------------------------------------------------- */}
            {/* Image */}
            {/* -------------------------------------------------------------------------- */}
            {image && image !== "" && <img src={image} alt="" className="left-column" />}

            {/* -------------------------------------------------------------------------- */}
            {/* Text and Button */}
            {/* -------------------------------------------------------------------------- */}
            <section className="right-column">
                <div className="right-column__info-section">
                    <h1 className="product-header">{name || ""}</h1>

                    <p className="product-description">
                        {description
                            ? `${description.substring(0, 50)}...`
                            : `${name} is one of the best, you are certain to enjoy every bit of it.`}
                    </p>
                    <p className="product-price">${price.toFixed(2)}</p>
                </div>
                <button className="right-column__btn-add" onClick={onAddItem}>
                    Add Item
                </button>
            </section>

            {/* -------------------------------------------------------------------------- */}
            {/* Ribbon */}
            {/* -------------------------------------------------------------------------- */}
            {/* {promoText && (
                <div className="ribbon ribbon-top-left">
                    <span>{promoText}</span>
                </div>
            )} */}
        </Box>
    );
};

export default Item;
