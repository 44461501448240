import React from "react";
import styled from "styled-components";
import happyWoman from "../../assets/happy_feeling.svg";

const PromoBannerStyle = styled.div`
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 5;
    position: fixed;
    right: 0;
    left: 0;
    width: 100vw;
    top: ${(props) => (props.isPromoBannerOpen ? "0px" : "-100vh")};
    opacity: ${(props) => (props.promoText ? "1" : "0")};
    display: ${(props) => (props.promoText ? "grid" : "none")};
    transition: opacity 0.3s ease-in;
    place-content: center;
`;
const BannerText = styled.div`
    flex-basis: 40%;
    p {
        color: black;
        text-align: center;
        font-weight: 600;
        color: #ffffff;
    }
    .subtitle {
        font-weight: 100;
    }
`;
const BannerCloseContainer = styled.div`
    position: absolute;
    right: -9px;
    top: -12px;
    height: 20px;
    width: 20px;
    display: grid;
    place-items: center;
    cursor: pointer;
    background: black;
    border-radius: 50%;
    border: 3px solid white;
    box-shadow: -4px 2px 2px rgba(0, 0, 0, 0.4);
`;

const BannerClose = styled.span`
    height: 15px;
    width: 5px;
    background-color: white;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;

    &:after {
        display: table;
        content: "";
        height: 15px;
        width: 5px;
        background-color: white;
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }
`;

const BannerInnerModal = styled.div`
    height: fit-content;
    width: 80vh;
    max-width: 250px;
    max-height: 60vh;
    background-color: ${(props) => `${props.theme.colors.primary}`};
    position: relative;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    background-clip: padding-box;
`;

const BannerImage = styled.img`
    width: 100%;
    flex-basis: 60%;
    max-width: 150px;
    margin: 0 auto;
`;

export const PromoBanner = ({ promoText, isPromoPresent, promoDetails }) => {
    const [isPromoBannerOpen, setIsPromoBannerOpen] = React.useState(true);

    const handleIsPromoBannerVisibility = () => {
        setIsPromoBannerOpen(!isPromoBannerOpen);
    };

    return (
        <PromoBannerStyle isPromoBannerOpen={isPromoBannerOpen} promoText={promoText}>
            <BannerInnerModal>
                <BannerCloseContainer onClick={handleIsPromoBannerVisibility}>
                    <BannerClose />
                </BannerCloseContainer>
                <BannerText>
                    <p className="header">{` ${promoText} `}</p>
                    <p className="subtitle">{promoDetails}</p>
                </BannerText>
                <BannerImage src={happyWoman} alt="happy woman" />
            </BannerInnerModal>
        </PromoBannerStyle>
    );
};
