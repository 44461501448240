import React from "react";

import { StyledOpeningHoursDropdown, StyledClockIcon } from "./OpeningHoursDropdown.styles";
import { timeConvert } from "../../../../../../utils/utils";

const OpeningHoursDropdown = ({ categories, styles }) => {
    return (
        <StyledOpeningHoursDropdown styles={styles}>
            <h4 className="title">
                Hours of Operation
                <StyledClockIcon />
            </h4>
            <ul>
                {categories.hours_of_operation &&
                    Object.entries(categories.hours_of_operation)
                        .reverse()
                        .map((item) => {
                            if (item[1].breakStart || item[1].breakEnd) {
                                return (
                                    <li className="day-time">
                                        <h4 className="day-time__day">{item[0]}</h4>
                                        <div>
                                            <h5>
                                                {timeConvert(item[1].start)} - {timeConvert(item[1].breakStart)}
                                            </h5>
                                            <h5>
                                                {timeConvert(item[1].breakEnd)} - {timeConvert(item[1].end)}
                                            </h5>
                                        </div>
                                    </li>
                                );
                            } else {
                                return (
                                    <li className="day-time">
                                        <h4 className="day-time__day">{item[0]}</h4>
                                        <h5>
                                            {" "}
                                            {timeConvert(item[1].start)} - {timeConvert(item[1].end)}
                                        </h5>
                                    </li>
                                );
                            }
                        })}
            </ul>
        </StyledOpeningHoursDropdown>
    );
};

export default OpeningHoursDropdown;
