/* eslint-disable camelcase */
import React from "react";
import { getUrlParams, renderLoading } from "../../../utils/utils";
import { RenderOrderMarkMenu } from "./RenderOrderMarkMenu";
import { RenderRevelMenu } from "./RenderRevelMenu";
import { RenderSquareMenu } from "./RenderSquareMenu";

const ModifiersList = (props) => {
    const { loading, storeVendor } = props;

    const renderModifier = () => {
        if (loading) {
            return renderLoading();
        } else if (storeVendor === "revel") {
            return <RenderRevelMenu props={props} />;
        } else if (storeVendor === "ordermark") {
            return <RenderOrderMarkMenu props={props} />;
        } else if (storeVendor === "otter") {
            return <RenderOrderMarkMenu props={props} />;
        } else if (storeVendor === "CHOWLY") {
            return <RenderOrderMarkMenu props={props} />;
        } else return <RenderSquareMenu props={props} />;
    };

    return renderModifier();
};

export default React.memo(ModifiersList);
