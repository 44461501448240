import styled from "styled-components";
import { Link, animateScroll as scroll } from "react-scroll";
// import cursor from '../../../assets/cursor/cursor1.svg';

export const StyledDesktopBody = styled.div`
    height: 100%;

    .info-header {
        position: relative;
        display: flex;
        width: 820px;
        justify-content: space-between;
        .info-header-type {
            display: flex;
            gap: 5px;
		right:0;
        }


       // .recommendation-available {
         //   display: flex;
         //   justify-content: center;
         //   align-items: center;
         //   gap: 5px;
         //   width: max-content;
         //   color: #a8272d;
       // }
    }

    .sliding-cart-opener {
        display: none;

        @media screen and (min-width: 1024px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 80px;
            background-color: #fff;
            position: sticky;
            top: 0px;
            z-index: 5;
            padding: 0px 20px;

            .store-name {
                font-size: 16px;
                color: ${(props) => `${props.theme.colors.primary}`};
                font-weight: 800;
            }

            .store-address {
                // color: ${(props) => `${props.theme.colors.primary}`};
                // font-weight: 600;
                font-size: 15px;
                opacity: 0.3;
            }

            .store-logo {
                margin: auto;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);

                img {
                    width: 60px;
                }
            }

            .cart-section {
                position: relative;
                width: 35px;

                img {
                    width: 30px;
                    height: 25px;
                    object-fit: cover;
                    cursor: pointer;
                    margin-right: 20px;
                }

                .cart-item-count {
                    background-color: ${(props) => `${props.theme.colors.primary}`};

                    font-size: 12px;
                    color: #fff;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    padding: 1px;
                    min-width: 14px;
                    max-height: 14px;
                }
            }
        }
    }
`;
export const StyledTopBar = styled.nav`
    height: 100px;
    padding: 0 50px;
    background-color: #fff;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    right: 0;
    left: 0;
`;

export const StyleImageDiv = styled.div`
    height: fit-content;
    width: 100%;
    position: ${(props) => (props.isPictureShown ? "unset" : "absolute")};
    bottom: ${(props) => (props.isPictureShown ? "unset" : "1000px")};
    transition: bottom 2s ease-in;
    background-color: ${(props) => `${props.theme.colors.primary}26`};

    @media screen and (min-width: 1024px) {
        height: 300px;
        position: static;
    }
`;

export const StyledCartSection = styled.div`
    position: sticky;
    top: 100px;
    flex-basis: 300px;
    border-radius: 10px;
    height: fit-content;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

export const StyledNavBar = styled.nav`
    flex-basis: 250px;
    position: sticky;
    height: fit-content;
    max-height: 90vh;
    border-radius: 10px;
    top: 100px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    height: fit-content;
    background-color: #fff;
    padding: 1em;
    & .header-title {
        color: #808080;
        font-size: 20px;
        font-family: "Rubik", serif;
        font-weight: 600;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    .nav_container {
        overflow: auto;
        height: fit-content;
        max-height: 80vh;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    @media screen and (min-width: 1024px) {
        padding: 0em;
        & .header-title {
            color: #808080;
            text-align: center;
        }
    }
`;

export const StyledContentContainer = styled.main`
    display: flex;
    flex-basis: calc (100vw - 250px);
    max-width: 1440px;
    margin: 0 auto;
    padding: 50px;
    justify-content: ${({ storeTemplate }) =>
        storeTemplate === 2 || storeTemplate === 3 || storeTemplate === 4 ? "center" : "space-between"};

    @media screen and (min-width: 1024px) {
        padding: 50px 20px;
    }
`;
export const StyledFoodCard = styled.div`
    margin: 10px;
    display: flex;
    flex-direction: column;
    height: 400px;
    max-width: 300px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    cursor: pointer;
    &:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
        opacity: 0.8;
    }

    .food_image__card {
        background-color: yellow;
        flex-basis: 70%;
        width: 100%;
    }
    .food_image__details {
        flex-basis: 30%;
        padding: 10px;
    }

    .details_name {
        font-size: 20px;
    }
    .details_description {
        font-size: 14px;
    }
    .details_price {
        font-weight: bold;
        font-size: 20px;
    }
`;

export const StyledDashboardSection = styled.section`
    // display: flex;
    // flex-wrap: wrap;
    // flex-basis
    // storeTemplate === 2 || storeTemplate === 3 || storeTemplate === 4 ? "100%" : "calc(100% - 550px)"};
    // justify-content: center;

    flex: 1;

    & .item-name {
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
    }

    & .header-title {
        color: #808080;
        font-size: 20px;
        font-family: "Rubik", serif;
        font-weight: 600;
        margin-bottom: 20px;
        text-transform: uppercase;
        padding-top: 16px;
    }
`;

export const StyledItemSection = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-column-gap: 2em;
    margin: 0 2em;
    margin-bottom: 50px;
`;
export const StyleMenuItem = styled(Link)`
    align-items: center;
    border-radius: 15px;
    border: 0.5px solid ${(props) => props.theme.colors.primary};
    display: flex;
    margin: 0 10px 0 0;
    padding: 0.1em 1em;

    font-size: 16px;
    cursor: pointer;
    color: ${(props) => `${`${`${props.theme.colors.primary}db`}`}`};
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
    &.is-active,
    &:hover {
        background-color: ${(props) => `${props.theme.colors.primary}`};
        color: #fff;
    }
    @media screen and (min-width: 1024px) {
        border-radius: 0px;
        padding: 0.6em 1em;
        font-size: 14px;
        margin: 0;
        border: 0.2px solid rgba(243, 243, 243, 0.4);
        box-shadow: none;
    }
`;

export const StyledDesktopMenus = styled.div`
    height: 40px;
    background-color: #fff;
    position: sticky;
    top: 80px;
    z-index: 3;
    box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
    padding: 0px 45px;
    display: flex;
    justify-content: center;

    .forward-icon {
        border-right: 2px solid ${(props) => `${props.theme.colors.primary}`};
        border-top: 2px solid ${(props) => `${props.theme.colors.primary}`};
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        align-self: center;
        margin-left: 15px;
        cursor: pointer;
    }

    .backward-icon {
        border-left: 2px solid ${(props) => `${props.theme.colors.primary}`};
        border-bottom: 2px solid ${(props) => `${props.theme.colors.primary}`};
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        align-self: center;
        cursor: pointer;
    }

    .inner-wrapper {
        display: flex;
        overflow: auto;
        width: 50vw;
        // justify-content: center;
        // scrollbar-width: none;
        // scrollbar-height: none;

        &::-webkit-scrollbar {
            height: 0px;
        }

        .btn-more {
            font-size: 16px;
            cursor: pointer;
            color: ${(props) => `${`${`${props.theme.colors.primary}db`}`}`};
            padding: 0px 20px;
            display: flex;
            align-items: center;
            white-space: nowrap;
            text-transform: uppercase;
            position: relative;

            .arrow {
                width: 10px;
                height: 10px;
                border-bottom: 2px solid ${(props) => `${props.theme.colors.primary}`};
                border-right: 2px solid ${(props) => `${props.theme.colors.primary}`};
                transform: rotate(45deg) translateX(-4px);
                margin-left: 10px;
            }

            ul {
                position: absolute;
                top: 100%;
                background-color: #fff;
                box-shadow: 0 2.5px 5px rgba(0, 0, 0, 0.22);
                right: 0px;
                border-radius: 0px 0px 7px 7px;
                visibility: hidden;
                opacity: 0;
                transition: opacity 300ms ease-in-out;
                max-height: 500px;
                overflow: auto;
            }

            &:hover {
                background-color: ${(props) => `${props.theme.colors.primary}`};
                color: #fff;

                ul {
                    opacity: 1;
                    visibility: visible;
                }

                .arrow {
                    border-color: #fff;
                }
            }
        }
    }
`;

export const StyledDesktopMenuItem = styled(Link)`
    font-size: 16px;
    cursor: pointer;
    color: ${(props) => `${`${`${props.theme.colors.primary}db`}`}`};
    padding: 0px 20px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    height: 40px;

    &.is-active,
    &:hover {
        background-color: ${(props) => `${props.theme.colors.primary}`};
        color: #fff;
    }
`;
