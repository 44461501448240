/** StoreClosedAlert.jsx* */

import React from 'react';
import styled from 'styled-components';
import closed from '../../assets/closed.svg';

const StyledModalOverlay = styled.div`
background-color: rgba(0,0,0,.6);
position: fixed;
top:0;
right: 0;
left: 0;
bottom:0;
height:100vh;
width: 100vw;
display: grid;
place-content: center;
transform:${props => (props.isStoreOpen === false ? 'scale(1)' : 'scale(0)')};
visibility: ${props => (props.isStoreOpen === false ? 'visible' : 'hidden')};
height: ${props => (props.isStoreOpen === false ? '100%' : '0px')};
transition: transform .001s ease-in .1s, visibility .001s ease-in .1s;
`;
const StyledModalContent = styled.div`
    z-index: 2000;
    height:300px;
    max-width:400px;
width: 90%;
    background-color: white;
    border-radius:10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
    position: relative;
 top:${props => (props.isStoreOpen === false ? '0' : '3000px')};
visibility: ${props => (props.isStoreOpen === false ? 'visible' : 'hidden')};
transition: top .2s ease-in-out .2s;
/* height: ${props => (props.isStoreOpen ? '0px' : '100%')} */
`;
const StyledModalTop = styled.div`
background-color: red;
width: 100%;
height: 50%;
 display: flex;
flex-direction: column;
align-items: center;
& img {
    width: 100px
}
`;
const StyledModalBottom = styled.div`
background-color: white;
width: 100%;
height: 50%;
p {
        margin: 1em;
    text-align: center;
}
`;

export const StoreClosedAlert = ({ isStoreOpen }) => (
    <StyledModalOverlay
        isStoreOpen={isStoreOpen}
    >
        <StyledModalContent isStoreOpen={isStoreOpen}>
            <StyledModalTop>
                <img src={closed} alt="shop closed" />
            </StyledModalTop>
            <StyledModalBottom>
                <p>oh Snap! the store is curently closed</p>
            </StyledModalBottom>
        </StyledModalContent>
    </StyledModalOverlay>
);
