import food1 from "../../../../assets/food1.jpg";
import food2 from "../../../../assets/food2.jpeg";
import food3 from "../../../../assets/food3.jpg";
import bacon from "../../../../assets/bacon.jpg";
import cheese from "../../../../assets/cheese.jpg";
import peanut from "../../../../assets/peanut-butter.jpg";
import ham from "../../../../assets/side-ham.jpg";
import turkey from "../../../../assets/turkey-susage.jpeg";

export const MELBAS_MOCK_DATA = [
    {
        _id: "5fbda0550dee574eb2feedd6",
        id: "445",
        name: "Brownie",
        description: null,
        image: "https://www.recipetineats.com/wp-content/uploads/2020/03/Brownies_0-SQ.jpg",
        price: 2.99,
        active: true,
        options: [],
        modifiers: []
    },
    {
        _id: "5fbda0550dee574eb2feedd8",
        id: "444",
        name: "Cake",
        description: null,
        image: "https://thenovicechefblog.com/wp-content/uploads/2016/04/Banana-Cake-1-720x720.jpeg",
        price: 2.99,
        active: true,
        options: [],
        modifiers: []
    },
    {
        _id: "5fbda0550dee574eb2feedd9",
        id: "977",
        name: "Ice Cream Cone",
        description: null,
        image: "https://cookienameddesire.com/wp-content/uploads/2020/06/ice-cream-cone-cupcakes-4-720x720.jpg",
        price: 0.89,
        active: true,
        options: [],
        modifiers: []
    },
    {
        _id: "5fbda0550dee574eb2feedd7",
        id: "443",
        name: "Bread Pudding",
        description: null,
        image: "https://assets.simplyrecipes.com/wp-content/uploads/2014/03/bread-pudding-vertical-b-1600.jpg",
        price: 3.99,
        active: true,
        options: [],
        modifiers: []
    },
    {
        _id: "5fbda0550dee574eb2feedd5",
        id: "978",
        name: "Ice Cream Cup",
        description: null,
        image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/Ice_cream_in_cup_with_sprinkles_and_spoon.jpg/1200px-Ice_cream_in_cup_with_sprinkles_and_spoon.jpg",
        price: 1.49,
        active: true,
        options: [],
        modifiers: []
    },
    {
        _id: "5fbda0550dee574eb2feeda1",
        id: "492",
        name: "1/2 Baked Chicken",
        description: null,
        image:
            "https://atlas-s3-proxy.revelup.com/product/melbas/7f9b1c76c9e454a6ea999bdc5ea39a213b7fcf32a86fc4b2844d2a99b6a0f879e831a7fb7fae91cd1028a9e31befcb4f8f818fbc70d5c292dc3928c451c78c7a.jpeg",
        price: 5.99,
        active: true,
        options: [],
        modifiers: []
    },
    {
        _id: "5fbda0550dee574eb2feeda5",
        id: "666",
        name: "Boiled Turkey Neck (1pc.)",
        description: "",
        image:
            "https://atlas-s3-proxy.revelup.com/product/melbas/42342293dfe72bf3ebb1e0ec4bc3f3de03a31f7ab728218a3725350ec7bf8f684c1abe869b116a91d5b14ea5cde70da7edfa325ca6494a20ffdb49980978e03f.jpeg",
        price: 1.99,
        active: true,
        options: [],
        modifiers: []
    },
    {
        _id: "5fbda0550dee574eb2feeda8",
        id: "493",
        name: "Corn Bread",
        description: null,
        image:
            "https://atlas-s3-proxy.revelup.com/product/melbas/0d93058597834bd200707e82765965aba98758d4b2a8e708eaa76fed214412f259a503c3dc666505d10dd4920cfa6b42d925779f60d19b4f96b75511e8517934.jpeg",
        price: 1,
        active: true,
        options: [],
        modifiers: []
    },
    {
        _id: "5fbda0550dee574eb2feedad",
        id: "381",
        name: "French Fries",
        description: null,
        image:
            "https://atlas-s3-proxy.revelup.com/product/melbas/1b970570dfcc6859fcb814bada71ce698616c487a66ffe2b5b4941f6103e08a7e7a298cacec905242c04ea8d4311ab5ad3690f1e7b7e8a0be07c15d4f5944192.jpeg",
        price: 2.19,
        active: true,
        options: [],
        modifiers: []
    },
    {
        _id: "5fbda0550dee574eb2feedb4",
        id: "483",
        name: "Side Ribs",
        description: null,
        image:
            "https://atlas-s3-proxy.revelup.com/product/melbas/5c7ce52241ff88433967eeef11bb35c37c318e2c6cbe6c768233b9979b857a3ae84a72df35d2f2893368e1f68d6272673d36ba0431e07848df46791d10b16f7a.jpeg",
        price: 5.99,
        active: true,
        options: [],
        modifiers: []
    },
    {
        _id: "5fbda0550dee574eb2feedae",
        id: "1043",
        name: "Smoked Sausage (1pc.)",
        description: null,
        image:
            "https://atlas-s3-proxy.revelup.com/product/melbas/42342293dfe72bf3ebb1e0ec4bc3f3de03a31f7ab728218a3725350ec7bf8f684c1abe869b116a91d5b14ea5cde70da7edfa325ca6494a20ffdb49980978e03f.jpeg",
        price: 2.49,
        active: true,
        options: [],
        modifiers: []
    },
    {
        _id: "5fbda0550dee574eb2feeda6",
        id: "480",
        name: "Stuffed Bell Pepper",
        description: null,
        image:
            "https://atlas-s3-proxy.revelup.com/product/melbas/756080554b7c71a9c36d5f6095aa197c77c5c525f343239faf16ad9f01de7f80d2e2ff59238ec81b2a8189bd5aefae03fdc91bdb3874e87e4917ea5d15402e1b.jpeg",
        price: 5.99,
        active: true,
        options: [],
        modifiers: []
    },
    {
        _id: "5fbda0550dee574eb2feedac",
        id: "726",
        name: "Turkey Leg",
        description: null,
        image:
            "https://atlas-s3-proxy.revelup.com/product/melbas/720a1bc4e78c29919fb0b8cae56848d0e61a6402c8fd37b6a47b9c25ad845319edb91506093039ce8315126a33aab49657bb43714c67ef4353abd0cadbfabebf.jpeg",
        price: 4.99,
        active: true,
        options: [],
        modifiers: []
    }
];

export const ABHIS_SUGGESTIONS = [
    {
        _id: "60072eab498848fa28bc9aa9",
        id: "Q2B5PO7QION6U4LQWWO5TGDH",
        active: true,
        description: null,
        name: "Two Eggs",
        image: food1,
        price: 3,
        taxes: 0,
        title: "",
        options: [
            {
                id: "GRL5PVONQ7JN6FGG7NLEK5YI",
                name: "",
                price: 3
            }
        ]
    },
    {
        _id: "60072eab498848fa28bc9acd",
        id: "6Q7FLFAS4RUU2DQIZCLH63AX",
        active: true,
        description: null,
        name: "Sd Nutella",
        image: food2,
        price: 1,
        taxes: 0,
        title: "",
        options: [
            {
                id: "XXXTO3HGYQEJZQWVEMPZBA4L",
                name: "",
                price: 1
            }
        ]
    },
    {
        _id: "60072eab498848fa28bc9aeb",
        id: "HHBEC6JLJ3BU6RMNP6U4POUA",
        active: true,
        description: null,
        name: "Fruit Cup",
        image: food3,
        price: 3,
        taxes: 0,
        title: "",
        options: [
            {
                id: "QVJE5XPLQJVVNL52JIO6OW5M",
                name: "",
                price: 3
            }
        ]
    },
    {
        _id: "60072eab498848fa28bc9b08",
        id: "QWKYOUUCCKVUL7GUTNLD6GWE",
        active: true,
        description: null,
        name: "Side Turkey Sausage",
        image: turkey,
        price: 4,
        taxes: 0,
        title: "",
        options: [
            {
                id: "2KKF26TPX5D3YQZJYMEA5IHQ",
                name: "",
                price: 4
            }
        ]
    },
    {
        _id: "60072eab498848fa28bc9b28",
        id: "OLAJ3N7ZQ74GRT2NG7KDKZHU",
        active: true,
        description: null,
        name: "Side Ham",
        image: ham,
        price: 4,
        taxes: 0,
        title: "",
        options: [
            {
                id: "RNEZWSN2BF6J34YX3M4KJGCD",
                name: "",
                price: 4
            }
        ]
    },
    {
        _id: "60072eac498848fa28bc9b68",
        id: "625QKSD5ES4NIE7RI2OQG4B5",
        active: true,
        description: null,
        name: "Hash Browns with Cheese",
        image: cheese,
        price: 4.75,
        taxes: 0,
        title: "",
        options: [
            {
                id: "ESLNHLBTNYUOCL2KJBSBAQ5S",
                name: "",
                price: 4.75
            }
        ]
    },
    {
        _id: "60072eac498848fa28bc9b89",
        id: "WIONRYRIPX4PAG7DKPLNC6FR",
        active: true,
        description: null,
        name: "Side Bacon",
        image: bacon,
        price: 4,
        taxes: 0,
        title: "",
        options: [
            {
                id: "BTYEGLHGLSOPECCUQELAPL3W",
                name: "",
                price: 4
            }
        ]
    },
    {
        _id: "60072eac498848fa28bc9bb2",
        id: "MK54RPMTIBEJAQGDC5EVWSQN",
        active: true,
        description: null,
        name: "Sd Peanut Butter",
        price: 1,
        image: peanut,
        taxes: 0,
        title: "",
        options: [
            {
                id: "I45OU467PNXHF76DUBD4U7JL",
                name: "",
                price: 1
            }
        ]
    }
];

export const SPECIAL_THREE_WITH_IMAGE = [
    {
        _id: "5f35982ca65306e41ca9fd9f",
        id: "FOHSFLYXLJZQI4ZIMPU54NZX",
        active: true,
        description: "Create a pizza with toppings of your toppings of choice",
        image: "https://joyup-images-1.s3.amazonaws.com/596e83905421630017cae7c4/1599069658350_Gourmet.png",
        name: "Make your Own Gourmet Pizza",
        price: 9.99,
        taxes: 0,
        title: "",
        promo_text: "50% off",
        options: [
            {
                id: "A5HW5IAADUYIRN3J5VRK4ZHG",
                name: "Regular",
                price: 9.99
            }
        ]
    },
    {
        _id: "5f359828a65306e41ca9f897",
        id: "I3LOIRFRZUGGS5MHETAVL3RK",
        active: true,
        description:
            "New York Style Pizza Pie with Crispy Bacon Bits, Chicken Cutlet Bites, Parmesan, and Delicious Ranch Sauce",
        image: "https://joyup-images-1.s3.amazonaws.com/596e83905421630017cae7c4/1599069676423_Chicken_Bacon_Ranch.jpg",
        name: "Chicken Bacon Ranch",
        price: 14.99,
        taxes: 0,
        title: "",
        options: [
            {
                id: "ISX5BCAWFM2DZM35E5F7DTVQ",
                name: "Regular",
                price: 14.99
            }
        ]
    },
    {
        _id: "5f359828a65306e41ca9f8e8",
        id: "QSH5E53PBNQJAUUXB3KWMHDK",
        active: true,
        description: "Sausage, Pepperoni, Mushroom, Ham, Mozzarella, Parmesan, and our homestyle Marinara Sauce.",
        image: "https://joyup-images-1.s3.amazonaws.com/596e83905421630017cae7c4/1599069686069_The_Supreme_Pizza.jpg",
        name: "The Supreme Pizza",
        price: 15.99,
        taxes: 0,
        title: "",
        options: [
            {
                id: "EORNTIASCBGZ4KWCXRKQJ4WL",
                name: "Regular",
                price: 15.99
            }
        ]
    }
];
