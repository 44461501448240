import React, { memo } from "react";
import { useSelector, connect } from "react-redux";
import uuid from "uuid";
import axios from "axios";
import { Text, View, Dimensions } from "react-native";

import useProceedToCheckout from "../../../../components/hooks/useProceedToCheckout";
import { Loader } from "../../../../components/loader/Loader";
import { Button } from "../../../../components";

import { StyledNewDesktopSlidingCartWrapper, StyledOverlay, StyledContent } from "./NewDesktopSlidingCart.styles";
import { StyledButtonContainer, StyledClosedStoreNotification } from "../../../cart/components/styles";
import { StyledFixedBottom } from "../../../modal/components/styles";

import { StyledEditIcon } from "#/pages/orderPage/components/styles";
import { handleRemoveItemfromCart, handleItemCountChange, handleResetHasCartChanged } from "../../actions";
import {
    getUrlParams,
    formartModifiersAsParagraph,
    getTotalCartPrice,
    getFreeItem,
    convert24hrTo12hrTime
} from "../../../../utils/utils";
import {
    getIsAddressPosting,
    getHasAddressBeenPosted,
    getError,
    getAddress,
    getGuestSession
} from "../../../cart/selectors";
import {
    handleUserProfile,
    handleDomainsWhitelisting,
    handleLoadUserProfile,
    addItemToGuestOrder
} from "../../../cart/actions";
import { bindActionCreators } from "redux";

const params = getUrlParams();

const { locationId, merchantId, pageId, psId, orderType, channel } = params;

const NewDesktopSlidingCart = ({
    isOpen,
    handleCloseCart,
    currentMerchantsShop,
    storeVendor,
    isStoreOpen,
    orderType,
    guestSession,
    history,
    handleCollatingCheckoutItems,
    scheduledOrderTime,
    handleAddDataToGuest,
    handleOpenUpsellModal,
    handleRemoveItemfromCart,
    handleItemCountChange,
    handleResetHasCartChanged,
    hasCartChanged,
    activeCategoryPromo,
    onBreak,
    selectedOrderMethod,
    afterBreakFirstPickupTime,
    storeTemplate,
    setOrderTypeModalOpen
}) => {
    const { itemsInCheckout } = currentMerchantsShop;

    const {
        isPaymentProcessing,
        handleCountChange,
        handleSendDataToMessenger,
        handleCheckMinimumOrderItem,
        is_active,
        handleDisplayStoreClosedToast,
        isGuestOrderModalOpen,
        setIsGuestOrderModalOpen,
        isConfirmOrderOpen,
        handleCloseConfirmModal,
        handleContinueGroupOrder,
        handleCheckout,
        setIsPaymentProcessing
    } = useProceedToCheckout(
        currentMerchantsShop,
        hasCartChanged,
        storeVendor,
        isStoreOpen,
        handleRemoveItemfromCart,
        handleItemCountChange,
        orderType,
        handleResetHasCartChanged,
        guestSession,
        history,
        handleCollatingCheckoutItems,
        scheduledOrderTime,
        activeCategoryPromo
    );

    const handleFireCheckout = async () => {
        const { merchantId, locationId } = getUrlParams();
        //Get upsell items
        try {
            setIsPaymentProcessing(true);
            const response = await axios.get(
                `https://waitstaff.joyup.me/menu/get_upsell_items/${merchantId}/${locationId}`
            );

            if (response.data && response.data.chosenUpsellItems.length > 0) {
                setIsPaymentProcessing(false);
                handleCloseCart();
                handleOpenUpsellModal(response.data.chosenUpsellItems);
            } else {
                setIsPaymentProcessing(false);
                handleCheckout("credit");
            }
        } catch (err) {
            setIsPaymentProcessing(false);
            console.log(err, "THE ERROR");
        }
    };

    const renderPromoNote = () => {
        if (getUrlParams().deliveryType === "pickup" || !activeCategoryPromo || !itemsInCheckout) {
            return null;
        } else {
            const promoItem = getFreeItem(itemsInCheckout, activeCategoryPromo);

            if (!promoItem) {
                return null;
            } else {
                return (
                    <p className="discounted-item">
                        {activeCategoryPromo.promo_text} applied
                        <br />
                        <span>
                            ({promoItem.name} - ${parseFloat(promoItem.itemPrice).toFixed(2)})
                        </span>
                    </p>
                );
            }
        }
    };

    const renderOrderText = () => {
        if (onBreak) {
            return (
                <p>
                    <span style={{ textTransform: "capitalize" }}>{selectedOrderMethod}</span> for <span>Today</span> at{" "}
                    <span>{afterBreakFirstPickupTime?.label}</span>
                </p>
            );
        } else if (scheduledOrderTime?.label) {
            return (
                <p>
                    <span style={{ textTransform: "capitalize" }}>{selectedOrderMethod}</span> for{" "}
                    <span>{scheduledOrderTime.label}</span> at{" "}
                    <span>{convert24hrTo12hrTime(scheduledOrderTime?.time)}</span>
                </p>
            );
        } else {
            return (
                <p>
                    <span style={{ textTransform: "capitalize" }}>{selectedOrderMethod}</span> for Now
                </p>
            );
        }
    };

    return (
        <StyledNewDesktopSlidingCartWrapper isOpen={isOpen}>
            <StyledOverlay isOpen={isOpen} onClick={handleCloseCart}></StyledOverlay>
            <StyledContent isOpen={isOpen}>
                <h2 className="cart-header">Your Cart</h2>

                <div className="ordertype-section">
                    {renderOrderText()}
                    <StyledEditIcon onClick={setOrderTypeModalOpen} storeTemplate={storeTemplate} />
                </div>
                <div>
                    {itemsInCheckout.length > 0 ? (
                        itemsInCheckout.map((item) => {
                            const {
                                id,
                                itemPrice,
                                modifiers,
                                quantity,
                                options,
                                name,
                                specialInstructions,
                                price,
                                optionsRadioValue,
                                basePrice
                            } = item;

                            const renderPrice = () => {
                                if (itemPrice === "0.00") {
                                    return basePrice || "0.00";
                                }
                                return itemPrice
                                    ? itemPrice.toLocaleString()
                                    : price
                                    ? price.toLocaleString()
                                    : basePrice;
                            };

                            return (
                                <div key={item.id} className="cart-item">
                                    <div className="cart-item__details">
                                        <h4 className="cart-item__details__name">
                                            {name.split(" - ")[0]}
                                            {specialInstructions !== ""
                                                ? ` - ${specialInstructions.split(" - ")[1]}`
                                                : null}
                                        </h4>
                                        {/* Modifier */}
                                        <h5 className="cart-item__details__modifier">
                                            {modifiers && formartModifiersAsParagraph(modifiers)}
                                        </h5>
                                        {/* Options */}
                                        {optionsRadioValue?.length > 0 &&
                                            optionsRadioValue.map((item2) => {
                                                return (
                                                    <h5 className="cart-item__details__modifier" key={uuid()}>
                                                        {item2.category.split("(")[0]}-{item2.name}
                                                    </h5>
                                                );
                                            })}
                                        {/* Price */}
                                        <h5
                                            className="cart-item__details__modifier"
                                            style={{ color: "#000" }}
                                            key={uuid()}>
                                            ${renderPrice()}
                                        </h5>
                                    </div>

                                    <div className="cart-item__count">
                                        <div
                                            className="action"
                                            onClick={() => {
                                                console.log("here");
                                                handleCountChange(id, "subtract", handleSendDataToMessenger);
                                            }}>
                                            -
                                        </div>
                                        <div className="current-number">{quantity}</div>
                                        <div
                                            className="action"
                                            onClick={() => handleCountChange(id, "+", handleSendDataToMessenger)}>
                                            +
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <p style={{ fontSize: "16px", textAlign: "center" }}>There is currently no item in your cart</p>
                    )}

                    {renderPromoNote()}

                    <p className="shipping-text">(**Please proceed to payment page for final total amount)</p>
                </div>
                {/* button to update the delivery address */}
                {/* only show the button if there is item in the checkout */}
                {isPaymentProcessing ? (
                    <StyledButtonContainer>
                        <Loader width={30} />
                    </StyledButtonContainer>
                ) : (
                    itemsInCheckout.length > 0 && (
                        <StyledButtonContainer>
                            {/* check if order Type is group and display the add to group order button */}
                            <StyledFixedBottom>
                                {getUrlParams().orderType === "group" ? (
                                    <View>
                                        <Button
                                            firstText={"Add to group checkout"}
                                            handleOnPress={handleAddDataToGuest}
                                            hasDoubleText
                                            secondText={`$${getTotalCartPrice(
                                                currentMerchantsShop.itemsInCheckout,
                                                false,
                                                activeCategoryPromo
                                            )}`}
                                            isBigButton={true}
                                        />
                                    </View>
                                ) : (
                                    <>
                                        <Button
                                            isWideButton
                                            firstText={"Proceed to Checkout"}
                                            handleOnPress={handleFireCheckout}
                                            hasDoubleText
                                            isBigButton={true}
                                            disabled={
                                                (!isStoreOpen && !scheduledOrderTime?.time) ||
                                                handleCheckMinimumOrderItem(
                                                    currentMerchantsShop.categories.minimum_delivery_amount
                                                )
                                                    ? 1
                                                    : 0
                                            }
                                            secondText={`$${getTotalCartPrice(
                                                currentMerchantsShop.itemsInCheckout,
                                                false,
                                                activeCategoryPromo
                                            )}`}
                                        />
                                        {((!isStoreOpen && !scheduledOrderTime?.time) || is_active === false) && (
                                            <StyledClosedStoreNotification>
                                                {handleDisplayStoreClosedToast()}
                                            </StyledClosedStoreNotification>
                                        )}
                                        {handleCheckMinimumOrderItem(
                                            currentMerchantsShop.categories.minimum_delivery_amount
                                        ) && (
                                            <StyledClosedStoreNotification>
                                                {handleCheckMinimumOrderItem(
                                                    currentMerchantsShop.categories.minimum_delivery_amount
                                                )}
                                            </StyledClosedStoreNotification>
                                        )}
                                    </>
                                )}
                            </StyledFixedBottom>
                        </StyledButtonContainer>
                    )
                )}
            </StyledContent>
        </StyledNewDesktopSlidingCartWrapper>
    );
};

const mapStateToProps = (state) => ({
    // currentMerchantsShop: state.dashboard[getUrlParams().merchantId],
    // orderType: state.dashboard.orderType,
    // guestName: state.dashboard.guestName,

    hasCartChanged: state.dashboard.hasCartChanged,
    isAddressPosting: getIsAddressPosting(state),
    hasAddressBeenPosted: getHasAddressBeenPosted(state),
    error: getError(state),
    address: getAddress(state),
    guestSession: getGuestSession(state)
});

const CartAction = {
    handleUserProfile,
    handleItemCountChange,
    handleRemoveItemfromCart,
    handleDomainsWhitelisting,
    handleLoadUserProfile,
    addItemToGuestOrder,
    handleResetHasCartChanged
};

const mapDispatchToProps = (dispatch) => bindActionCreators(CartAction, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(memo(NewDesktopSlidingCart));
