import React, { useState, useEffect, useRef } from "react";
import { View, Text, Image, FlatList, TouchableWithoutFeedback, TextInput, ImageBackground } from "react-native";
import { styles, GuestIntroStyle } from "./styles";
import { Button } from "../../../components";
import Lottie from "react-lottie";
import happy from "../../../assets/pumped-up.json";
import group from "../../../assets/group_fun_2.svg";
import { getUrlParams } from "../../../utils/utils";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ProgressiveImage } from "../../../components/progressiveImage";
import background from "../../../assets/background.jpeg";
import { sendNotificationToSlackForUserLogin } from "../../../utils/utils";
// options for lottie animations

const {
    guestIntroSectionStyle,
    container,
    containerBackground,
    adminPicture,
    topText,
    bottomText,
    input,
    buttonStyle,
    GuestIntroContainerStyle
} = GuestIntroStyle;

let imageLink = (pageToken) =>
    `https://graph.facebook.com/${
        getUrlParams().psId
    }/picture?type=large&height=320&width=420&access_token=${pageToken}`;

const GuestIntro = ({ handleSubmitName, adminImage, adminName, currentMerchantsShop }) => {
    const [inputValue, setInputValue] = useState("");
    const inputEl = useRef(null);

    React.useEffect(() => {
        const sendSlackNotification = () => {
            return process.env.NODE_ENV !== "development" && sendNotificationToSlackForUserLogin();
        };

        sendSlackNotification();
    }, []);

    //focus on the input when the app loads
    useEffect(() => {
        inputEl.current.focus();
    }, []);

    //handle changing of the input
    const onChangeText = (text) => {
        setInputValue(text.toString());
    };

    return (
        <View style={GuestIntroContainerStyle}>
            <View style={container}>
                <ImageBackground source={background} style={containerBackground}>
                    <View style={{ alignItems: "center", justifyContent: "center" }}>
                        <Text style={topText}>Welcome to the Party</Text>
                        <ProgressiveImage
                            source={{
                                uri: imageLink(
                                    currentMerchantsShop &&
                                        currentMerchantsShop.currentShop !== undefined &&
                                        currentMerchantsShop.currentShop[0].page_token
                                )
                            }}
                            thumbnailSource={{
                                uri: null
                            }}
                            style={adminPicture}
                            resizeMode="cover"
                        />
                    </View>
                    <Text style={bottomText}>You have been invited to this group order by {adminName}</Text>
                    <TextInput
                        style={!inputValue.replace(/\s/g, "").length > 0 ? input : [{ borderColor: "#69e1c6" }, input]}
                        onChangeText={(text) => onChangeText(text)}
                        value={inputValue}
                        ref={inputEl}
                        placeholder="Name (Join group as)"
                    />
                    <Button
                        btnStyle={buttonStyle}
                        text="Join Group Order"
                        disabled={!inputValue.replace(/\s/g, "").length > 0}
                        handleOnPress={() => handleSubmitName(inputValue)}
                    />
                    {/* <Lottie
                options={pummpedAnimation}
                width={200}
                height={190}
            /> */}
                </ImageBackground>
            </View>
        </View>
    );
};

const mapStateToProps = (state) => ({
    currentMerchantsShop: state.dashboard[getUrlParams().merchantId]
});

const GuestIntroAction = {};

const mapDispatchToProps = (dispatch) => bindActionCreators(GuestIntroAction, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GuestIntro);
