import { StyleSheet, Dimensions, FlatList, Text, TouchableOpacity } from "react-native";
import styled from "styled-components";
import { pages } from "../../../utils/theme";
import { getUrlParams } from "../../../utils/utils";
import { ReactComponent as EditIcon } from "../../../assets/edit.svg";

const { height, width } = Dimensions.get("window");

export const DashboardCountContainer = styled.div`
    cursor: pointer;
    min-width: 80px;
`;

export const styles = StyleSheet.create({
    button: {
        backgroundColor: pages[getUrlParams().merchantId] || "#69E1C6",
        borderRadius: 10,
        elevation: 10,
        paddingVertical: 15,
        shadowColor: "#000",
        shadowOffset: {
            height: 3,
            width: 0
        },
        shadowOpacity: 0.1,
        shadowRadius: 6.27
    },

    buttonText: {
        color: "#ffffff",
        textAlign: "center"
    },

    dashBoardItemContainer: {
        alignItems: "center",
        flex: 1,
        justifyContent: "center",
        marginBottom: 20
    },
    dashboardButtonText: {
        color: "#ffffff"
    },
    dashboardCountContainer: {
        alignItems: "center",
        backgroundColor: pages[getUrlParams().merchantId] || "#69E1C6",
        borderRadius: 20,
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        paddingHorizontal: 10,
        paddingVertical: 5,
        cursor: "pointer",
        minWidth: "80px",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    dashboardDescription: {
        flex: 10,
        marginHorizontal: "10px",
        width: 100
    },
    dashboardFoodImage: {
        borderColor: "rgba(0, 0, 0, .2)",
        borderRadius: 10,
        borderWidth: 0.1,
        flex: 1,
        height: "100%",
        width: "100%"
    },
    dashboardFoodCategory: {
        color: "#808080",
        fontSize: 18,
        fontFamily: "Rubik",
        fontWeight: "600",
        marginBottom: "20px"
    },
    dashboardFoodName: {
        color: "#808080",
        fontSize: 14,
        fontFamily: "Rubik",
        fontWeight: "600",
        textTransform: "capitalize"
    },
    dashboardFoodPrice: {
        fontFamily: "Rubik",
        fontSize: "12px"
    },
    dashboardItemContainer: {
        marginTop: width > 1023 ? 15 : 5,
        padding: width > 1023 ? 15 : 10,
        borderColor: "rgba(0,0,0,0.04)",
        borderRadius: width > 1023 ? "15px" : "10px",
        borderStyle: "solid",
        borderWidth: ".5px",
        height: width > 1023 ? 120 : 600,
        flex: 1,
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: width > 1023 ? 15 : 5,
        position: "relative"
    },
    descriptionImageContainer: {
        alignItems: "center",
        flex: 4,
        flexDirection: "row"
    },
    dashboardFeaturedItemContainer: {
        height: width > 1023 && 50,
        borderRadius: "10px",
        marginRight: 10
    },
    descriptionFeaturedImageContainer: {},
    dashboardFeaturedFoodImage: {
        width: 300,
        height: 150
    },
    featuredItemsContainer: {
        borderBottomColor: "rgba(0,0,0,.04)",
        borderBottomWidth: 2,
        paddingBottom: 10,
        marginBottom: 20
    },
    dashboardFeaturedDescription: {
        marginVertical: 10
    },
    dashboardFeaturedFoodName: {
        color: "#808080",
        fontFamily: "Rubik",
        fontSize: 14,
        fontWeight: "600"
    },
    dashboardFeaturedFoodPrice: {},
    loadingStyle: {
        flex: 1,
        justifyContent: "center"
    },
    errorStyle: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
    },

    itemStyle: {
        borderColor: "#d6d7da",
        borderRadius: "15px",
        borderStyle: "solid",
        borderWidth: ".5px",
        color: "#595151",
        display: "inline-block",
        lineHeight: 20,
        marginRight: "10px",
        paddingHorizontal: "1em",
        paddingVertical: ".1em",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1
        },
        shadowOpacity: 0.2,
        shadowRadius: 1.41,

        elevation: 2
    },

    listContainer: {
        marginBottom: 80,
        flex: 1
    },

    menuListContainer: {
        marginBottom: "1em",
        paddingTop: "1em"
    },
    modalContainer: {
        bottom: 0,
        height: "100vh",
        marginLeft: "auto",
        marginRight: "auto",
        position: "absolute",
        top: 0,
        width: "100vw",
        zIndex: 3,
        borderRadius: "10px"
    },
    paymentModalContainer: {
        backgroundColor: "rgba(130, 130, 130, 0.5)",
        bottom: 0,
        left: 0,
        position: "fixed",
        right: 0,
        top: 0,
        width: "100vw"
    },
    paymentModalClose: {
        cursor: "pointer",
        // display: 'block',
        position: "absolute",
        marginLeft: 10,
        left: 10,
        top: 10,
        zIndex: 200
    },
    paymentModal: {
        backgroundColor: "#fff",
        borderRadius: 5,
        height: "50%",
        marginHorizontal: "auto",
        marginVertical: "auto",
        maxWidth: 350,
        padding: "1em",
        width: "70%",
        alignItems: "center",
        justifyContent: "center"
    },
    paymentButtonStyle: {
        height: "80%",
        maxWidth: "400px",
        width: "100%",
        alignItems: "center",
        color: "red"
    },
    paymentModalCloseImage: {
        height: 20,
        width: 20
    },
    paymentTitle: {
        fontFamily: "Rubik",
        fontSize: 18,
        marginVertical: 20
    },
    productDescription: {
        color: "rgb(150, 150, 150)",
        fontFamily: "Rubik",
        fontSize: 12,
        marginVertical: 5
    },
    promoBadge: {
        fontFamily: "Rubik",
        fontSize: 15,
        height: 15,
        width: 15,
        position: "absolute",
        right: 10,
        top: -10,
        zIndex: 4
    },
    scrollviewContainer: {
        // height: (height - (height / 4) - 100),
        // height: 'calc(100vh - 300px)',
        height: width > 1023 ? "calc(100vh - 6em - 352px)" : "calc(100vh - 300px)"
    },
    scrollviewContainerMax: {
        height: width > 1023 ? "calc(100vh - 6em - 130.19px)" : "calc(100vh - 200px)"
        // height: 'calc(100vh - 102px)',
    },
    selected: {
        backgroundColor: `${pages[getUrlParams().merchantId]}88` || "#69E1C688",
        borderColor: pages[getUrlParams().merchantId] || "#69E1C6",
        color: pages[getUrlParams().merchantId] || "#69E1C6"
    },
    shippingInformationContainer: {
        backgroundColor: "white",
        borderRadius: 10,
        left: 0,
        marginLeft: "auto",
        marginRight: "auto",
        marginVertical: "auto",
        maxWidth: "400px",
        padding: 20,
        width: "90%"
        // bottom: -height,
    },
    shippingInformationModal: {
        backgroundColor: "rgba(130, 130, 130, 0.5)",
        bottom: 0,
        left: 0,
        position: "fixed",
        right: 0,
        top: 0,
        width: "100vw"
    }
});

export const GuestIntroStyle = StyleSheet.create({
    GuestIntroContainerStyle: {
        backgroundColor: "rgba(0,0,0,.4)",
        position: "fixed",
        height: "100vh",
        width: "100vw",
        marginRight: "auto",
        marginLeft: "auto",
        right: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    containerBackground: {
        width: "100%",
        height: "100%",
        padding: "1em"
    },

    adminPicture: {
        width: 50,
        height: 50,
        // marginBottom: 20,
        borderRadius: "100%"
    },
    topText: {
        fontFamily: "Rubik",
        fontSize: 20,
        marginBottom: 20,
        textAlign: "center"
    },
    bottomText: {
        fontFamily: "Rubik",
        fontSize: 15,
        textAlign: "center",
        marginVertical: 20
    },
    input: {
        borderWidth: 0.4,
        borderColor: "#d8d8d8",
        marginTop: 10,
        marginBottom: 10,
        paddingHorizontal: 20,
        paddingVertical: 10,
        fontFamily: "Rubik",
        fontSize: 15
    },
    buttonStyle: {
        position: "absolute",
        width: "calc(100% - 2em)",
        marginLeft: "auto",
        marginRight: "auto",
        right: 0,
        left: 0,
        bottom: 10
    }
});

export const GuestOrderSuccessStyle = StyleSheet.create({
    container: {
        padding: "2em",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        maxWidth: 350,
        marginHorizontal: "auto"
    },
    successText: {
        fontFamily: "Rubik",
        fontSize: 18,
        textAlign: "center"
    },
    boldText: {
        fontFamily: "Rubik",
        fontWeight: "bold"
    }
});

export const StyledFlatList = styled(FlatList)``;
export const StyledTouchable = styled.div`
    @media screen and (min-width: 1024px) {
        display: none;
    }
`;
export const StyledButtonScheduledOrderDetails = styled.div`
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    font-weight: 600;
    padding: 10px;
    height: 60px;
    cursor: pointer;
    font-size: 14px;
    border: none;
    background-color: #f5f5f5;
    p {
        font-size: 15px;
        margin-right: 20px;
    }

    span {
        color: ${(props) => props.theme.colors.primary};
    }

    img {
        width: 20px;
        flex-basis: 5%;
    }

    :hover,
    :focus {
        box-shadow: ${({ storeTemplate }) =>
            storeTemplate === 2 || storeTemplate === 3 || storeTemplate === 4
                ? "none"
                : "0 5px 10px rgba(0, 0, 0, 0.22)"};
    }
    :active {
        box-shadow: ${({ storeTemplate }) =>
            storeTemplate === 2 || storeTemplate === 3 || storeTemplate === 4
                ? "none"
                : "0 5px 10px rgba(0, 0, 0, 0.22)"};
    }

    @media screen and (min-width: 1024px) {
        position: ${({ storeTemplate }) =>
            storeTemplate === 2 || storeTemplate === 3 || storeTemplate === 4 ? "static" : "sticky"};
        top: 0px;
    }
`;
export const TemplateTwoStyledButtonScheduledOrderDetails = styled.div`
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    font-weight: 600;
    padding: 10px;
    height: 50px;
    cursor: pointer;
    font-size: 14px;
    border: none;
    background-color: #f5f5f5;
    p {
        font-size: 15px;
        margin-right: 20px;
    }

    span {
        color: ${(props) => props.theme.colors.primary};
    }

    img {
        width: 20px;
        flex-basis: 5%;
    }

    :hover,
    :focus {
        box-shadow: ${({ storeTemplate }) =>
            storeTemplate === 2 || storeTemplate === 3 || storeTemplate === 4
                ? "none"
                : "0 5px 10px rgba(0, 0, 0, 0.22)"};
    }
    :active {
        box-shadow: ${({ storeTemplate }) =>
            storeTemplate === 2 || storeTemplate === 3 || storeTemplate === 4
                ? "none"
                : "0 5px 10px rgba(0, 0, 0, 0.22)"};
    }

    @media screen and (min-width: 1024px) {
        position: ${({ storeTemplate }) =>
            storeTemplate === 2 || storeTemplate === 3 || storeTemplate === 4 ? "static" : "sticky"};
        top: 0px;
        z-index: 4;
    }
`;

export const StyleMenuItem = styled.p`
    border-radius: 15px;
    border: 0.5px solid ${(props) => props.theme.colors.primary};
    display: inline-block;
    line-height: 20px;
    margin-right: 10px;
    padding: 0.1em 1em;
    color: ${(props) => (props.name === props.currentMenuCategory ? props.theme.colors.primary : "#595151")};
    background-color: ${(props) => (props.name === props.currentMenuCategory ? props.theme.colors.primary + 26 : null)};
    color: ${(props) => `${`${props.theme.colors.primary}db`}`};
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
`;

export const StyledContainer = styled.div`
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 1em 0.3em;
    height: calc(100vh - 220px);
    margin-top: ${(props) => (props.isPictureShown ? "50px" : "100px")};

    @media screen and (min-width: 350px) {
        padding: 1em;
    }

    @media screen and (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
        max-width: 1440px;
        height: 70vh;
    }
`;

export const StyledHeaderContainer = styled.div`
    .info-header {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .info-header-container {
            display: flex;
            gap: 5px;
        }

        .recommendation-available {
            position: relative !important;
            left: 0 !important;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            top: 0;
            width: max-content;
            color: #a8272d;
        }
    }

    & .fixed_header {
        position: fixed;
        top: 0;
        left: 0;
        padding: 1em;
        right: 0;
        background: #fafafa;
        z-index: 1;
    }

    flex: 1;
    justify-content: space-between;

    & .header-title {
        color: #808080;
        font-size: 18px;
        font-family: "Rubik", serif;
        font-weight: 600;
        margin-bottom: 20px;
        text-transform: uppercase;
        display: none;

        @media screen and (min-width: 1024px) {
            & {
                display: block;
            }
        }
    }
`;
export const StyledContentContainer = styled.div`
    margin-top: ${(props) => props.scrollPosition && "40px"};
    .scrollviewContainer {
        height: calc(100vh - 300px);
    }
    .scrollviewContainerMax {
        height: calc(100vh - 200px);
    }

    .buttonContainer {
        bottom: 30px;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        max-width: 350px;
        position: fixed;
        right: 0;
        width: 80%;
    }

    @media screen and (min-width: 1024px) {
        margin-top: ${(props) => props.scrollPosition && "135px"};
        .scrollviewContainerMax {
            height: calc(100vh - 6em - 130.19px);
        }
        .scrollviewContainer {
            height: calc(100vh - 6em - 352px);
        }
    }
`;
export const StyledItemDetail = styled.div`
    cursor: pointer;
    margin: 15px 0 0 0;
    border: 1px solid rgba(0, 0, 0, 0.04);
    padding: 10px;

    :active {
        opacity: 0.8;
    }
`;

export const StyledRibbon = styled.div`
    display: block;
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: 0;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
    font-family: "rubik";
    & span {
        font-size: 9px;
        /* font-weight: bold; */
        color: #fff;
        /* text-transform: uppercase; */
        text-align: center;
        line-height: 20px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        width: 100px;
        background: ${(props) => props.theme.colors.primary};
        box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
        position: absolute;
        top: 19px;
        left: -21px;
    }
    & span::before {
        content: "";
        position: absolute;
        left: 0px;
        top: 100%;
        z-index: -1;
        border-left: 3px solid ${(props) => props.theme.colors.primary};
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-top: 3px solid ${(props) => props.theme.colors.primary};
    }
    & span::after {
        content: "";
        position: absolute;
        right: 0px;
        top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-right: 3px solid ${(props) => props.theme.colors.primary};
        border-bottom: 3px solid transparent;
        border-top: 3px solid ${(props) => props.theme.colors.primary};
    }
`;

export const StyledDashboardContainer = styled.div`
    background: #fff;
    box-sizing: border-box;
    margin-top: 5px;
    padding: 10px;
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    display: flex;
    align-items: center;
    /* flexDirection: 'row', */
    justify-content: space-between;
    margin-bottom: 5px;
    position: relative;
    cursor: pointer;
    min-height: 120px;
    height: ${(props) => props.is_promo_running === true && "120px"};
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    @media screen and (min-width: 1024px) {
        margin-top: 15px;
        padding: 15px;
        border-radius: 15px;
        min-height: 120px;
        margin-bottom: 15px;
        &:hover,
        &:focus {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.22);
        }
        &:active {
            box-shadow: 0 0px 0px rgba(0, 0, 0, 0.22);
        }
    }
`;

export const StyledDashboardDescription = styled.div`
    flex: 10;
    margin: 0 10px;
    width: 100px;
    display: flex;
    flex-direction: column;

    & .dashboardFoodName,
    & .price {
        color: #000000;
        font-size: 14px;
        font-family: "Rubik";
        font-weight: 600;
        text-transform: capitalize;
        display: block;
        margin: 0;

        &::first-line {
            text-transform: capitalize;
        }
    }

    & .price {
        font-weight: 400;
    }
`;
export const StyledItemSection = styled.div`
    margin-bottom: 50px;
    width: 100%;

    .item-name {
        /* text-align: center; */
        font-weight: 600;
        text-transform: uppercase;
        color: #808080;
        font-size: 18px;
        font-family: "Rubik";
        margin: 0;
        margin-bottom: 20px;

        @media (max-width: 768px) {
            text-align: center;
        }
    }
    .items-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        width: 100%;
        grid-column-gap: 2em;
    }

    @media screen and (min-width: 800px) {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
`;

export const StyledEditIcon = styled(EditIcon)`
    .edit {
        fill: ${(props) => props.theme.colors.primary};
    }
    width: 20px;
    height: 20px;
    transition: transform 200ms ease-in-out;

    &:hover {
        transform: ${({ storeTemplate }) =>
            storeTemplate === 2 || storeTemplate === 3 || storeTemplate === 4 ? "scale(1.15)" : "scale(1)"};
    }
`;
export const StyledAnimatedCart = styled.div`
    height: 100vh;
    background-color: red;
    position: absolute;
    top: 0;
    bottom: 0;
`;
export const StyledFullWidth = styled.div`
    height: 100vh;
    width: 100vw;
    display: grid;
    place-items: center;
`;

/* StyledContentContainer */
/* buttonContainer
buttonTextContainer */
