import React, { useEffect, useState, createRef, useRef } from "react";
import uuid from "uuid";
import { View, Text, Image, FlatList, TouchableWithoutFeedback, SafeAreaView, ScrollView } from "react-native";
import {
    styles,
    StyledItemDetail,
    StyledRibbon,
    StyledDashboardContainer,
    StyledDashboardDescription,
    StyledItemSection,
    DashboardCountContainer
} from "./styles";
import { ProgressiveImage } from "../../../components/progressiveImage";
import { loadImage } from "../../../assets/loadImage.png";
import tagIcon from "../../../assets/tag.svg";
import LazyLoadImage from "../../../components/lazyLoadImage/LazyLoadImage";
import { getUrlParams, convertWordToCapitalize } from "../../../utils/utils";
import { Button } from "../../../components";
import { returnedMobileCatItemDomNodes } from "#/utils/domManipulations";

const {
    listContainer,
    descriptionImageContainer,
    dashboardFoodPrice,
    dashboardFoodCategory,
    dashboardButtonText,
    dashboardItemContainer,
    dashboardFoodImage,
    dashboardDescription,
    dashboardFoodName,
    dashboardCountContainer,
    productDescription
} = styles;

const keyExtractor = (item) => `${item._id}`;

const getMinAndMaxPrice = (array, key) => {
    let minimum = Number.POSITIVE_INFINITY;
    let maximum = Number.NEGATIVE_INFINITY;
    let tmp;
    for (let i = array.length - 1; i >= 0; i--) {
        tmp = array[i][key];
        if (tmp < minimum) minimum = parseFloat(tmp).toFixed(2);
        if (tmp > maximum) maximum = parseFloat(tmp).toFixed(2);
    }
    return `$${minimum} - $${maximum}`;
};

// get access to the scroll view so that we can perform auto scroll
export const RenderItemData = React.memo(
    ({ item, handleModalOpenOrAddItem, menu, index, userProfile, storeTemplate, showCategoryPromoBasedOnWeb }) => {
        const { id, name, image, price, active, description, is_promo_running, promo_text } = item;

        const handleImageLoad = () => {
            let imageCount = 0;
            if (menu.length > 10 && image) {
                if (imageCount <= 4) {
                    imageCount += 1;
                    return (
                        <LazyLoadImage
                            img={{
                                url: image,
                                height: 100,
                                width: 100
                            }}
                            storeTemplate={storeTemplate}
                        />
                    );
                }
                return null;
            }
            if (menu.length < 10 && image) {
                return (
                    <LazyLoadImage
                        img={{
                            url: image,
                            height: 100,
                            width: 100
                        }}
                        storeTemplate={storeTemplate}
                    />
                );
            }
            return null;
        };

        // return only active items
        return (
            // <TouchableWithoutFeedback
            // >
            <StyledDashboardContainer
                key={id}
                onClick={() => handleModalOpenOrAddItem(item)}
                is_promo_running={is_promo_running && (showCategoryPromoBasedOnWeb || getUrlParams().promo === "true")}>
                <View style={descriptionImageContainer}>
                    {image ? (
                        <LazyLoadImage
                            img={{
                                url: image,
                                height: 100,
                                width: 100,
                                alt: name.toLowerCase()
                            }}
                            storeTemplate={storeTemplate}
                        />
                    ) : null}

                    <StyledDashboardDescription>
                        <p className="dashboardFoodName">{name && convertWordToCapitalize(name)}</p>
                        {description !== "" && description !== null ? (
                            <Text style={productDescription}>
                                {description &&
                                    convertWordToCapitalize(
                                        description.length > 50 ? `${description.substring(0, 50)}...` : description
                                    )}
                            </Text>
                        ) : null}
                        <p className="dashboardFoodName price">
                            {item?.options.length > 1 && getMinAndMaxPrice(item.options, "price")}

                            {item?.options.length === 1 && `$${parseFloat(item.options[0].price).toFixed(2)}`}
                            {(item?.options && !Array.isArray(item?.options)) === undefined ||
                                (item?.options.length === 0 && `$${parseFloat(item.price).toFixed(2)}`)}
                        </p>
                    </StyledDashboardDescription>
                </View>

                <DashboardCountContainer>
                    <Button text="Add Item" />
                </DashboardCountContainer>
                {/* <View style={dashboardCountContainer}> */}
                {/* <Text style={dashboardButtonText}>Add Item</Text> */}
                {/* </View> */}
                {is_promo_running && (
                    <StyledRibbon>
                        <span>{promo_text || "placeholder text"}</span>
                    </StyledRibbon>
                )}
            </StyledDashboardContainer>
            // </TouchableWithoutFeedback>
        );
    }
);

const ItemList = ({
    menu,
    CURRENT_FOCUSED_CATEGORY,
    focusedCategoryData,
    setIsScrolling,
    handleModalOpenOrAddItem,
    setFocusedCategories,
    currentMenuCategory,
    setCurrentMenuCategory,
    userProfile,
    showCategoryPromoBasedOnWeb,
    storeTemplate = { storeTemplate }
}) => {
    const [elementSizeArray, setElementSizeArray] = useState(0);

    const scrollViewRef = useRef(createRef());
    let layoutArray = [];

    useEffect(() => {
        // This handle scroll to element div when the elements item is clicked
        const handleScrollToItem = () => {
            const position = elementSizeArray[currentMenuCategory];
            const scrollPosition = position !== undefined && position.y;
            scrollViewRef.current.scrollTo({ y: scrollPosition, animated: true });
        };

        handleScrollToItem();
    }, [currentMenuCategory]);

    const handleScrollCalculation = (currentPosition) => {
        const values = Object.values(elementSizeArray);
        const keys = Object.keys(elementSizeArray);
        values.map((item, index) => {
            if (index === 0 && currentPosition <= values[index + 1].y) {
                // setCurrentMenuCategory(keys[0])
            } else if (index > 0 && index < values.length - 1) {
                if (currentPosition >= values[index].y && currentPosition <= values[index + 1].y) {
                    // setCurrentMenuCategory(keys[index])
                }
            } else if (index === values.length - 1 && currentPosition > values[index].y) {
                // setCurrentMenuCategory(keys[values.length - 1])
                console.log("last", keys[values.length - 1]);
            }
        });
    };

    const handleScroll = (event) => {
        setIsScrolling(true);
    };

    const renderNodes = () => {
        if (getUrlParams().merchantId == "6075e037666fec40b0bd0773") {
            //AGAS RESTAURANT
            return returnedMobileCatItemDomNodes(
                menu,
                "curries",
                "Chicken Curries",
                showCategoryPromoBasedOnWeb,
                handleModalOpenOrAddItem,
                userProfile,
                storeTemplate
            );
        } else if (getUrlParams().merchantId == "609396e38b525c2d2e92e0fa") {
            //GT STORES
            return returnedMobileCatItemDomNodes(
                menu,
                "superfood",
                "Superfood Smoothies - Large",
                showCategoryPromoBasedOnWeb,
                handleModalOpenOrAddItem,
                userProfile,
                storeTemplate
            );
        } else {
            return (
                menu &&
                menu.map((categoryItem, i) => {
                    const { id, items, _id, name, is_promo_running, promo_text } = categoryItem;
                    return (
                        <StyledItemSection key={id} id={name}>
                            <p className="item-name">
                                {is_promo_running && (showCategoryPromoBasedOnWeb || getUrlParams().promo === "true")
                                    ? `${promo_text.toUpperCase()} - ${name.toUpperCase()}`
                                    : name.toUpperCase()}
                            </p>

                            <div className="items-container">
                                {items.map((data, index) => (
                                    <RenderItemData
                                        key={uuid()}
                                        item={data}
                                        handleModalOpenOrAddItem={handleModalOpenOrAddItem}
                                        menu={menu}
                                        index={index}
                                        userProfile={userProfile}
                                        showCategoryPromoBasedOnWeb={showCategoryPromoBasedOnWeb}
                                        storeTemplate={storeTemplate}
                                    />
                                ))}
                            </div>
                        </StyledItemSection>
                    );
                })
            );
        }
    };

    return (
        <SafeAreaView style={{ flex: 1 }}>
            <ScrollView
                onScroll={(e) => handleScroll(e)}
                onScrollEndDrag={() => setIsScrolling(false)}
                scrollEventThrottle={200}
                ref={scrollViewRef}
                id="scrollViewRef">
                <View>{renderNodes()}</View>
            </ScrollView>
        </SafeAreaView>
    );
};

export default React.memo(ItemList);
